import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { CompactPicker, SketchPicker, SliderPicker } from 'react-color';
import MultiSelect from '../MultiSelect';


export default function MembershipModal({ title, open, setOpen, onSave, theme, currentMembership,modules }) {


    useEffect(() => {

        if (currentMembership) {

            let newModules =[];

            currentMembership?.Modules?.map((m)=>{
                newModules.push(m?.id ? m.id : m);
            })

            currentMembership.Modules = newModules;
            setMembershipInfo(currentMembership);

        } else {
            setMembershipInfo({
                "id": null,
                "name": "",
                "description": "",
                "price": 0,
                "maxWhats": "",
                "maxBooks": 10,
                "maxUsers": 5,
                "maxPages": 0,
                "massiveCall": null,
                "massiveMessages": null,
                Modules:[]
            });
        }
    }, [currentMembership]);

    /**
     * Variable para el nuevo perfil
     */
    const [membershipInfo, setMembershipInfo] = useState({
        "id": null,
        "name": "",
        "description": "",
        "price": 0,
        "maxWhats": "",
        "maxBooks": 10,
        "maxUsers": 5,
        "maxPages": 0,
        "massiveCall": null,
        "massiveMessages": null,
        Modules:[]
    });

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setMembershipInfo({
            ...membershipInfo,
            [event.name]: event.value,
        })

    }


    const enableFeature = (isEnabled, name) => {
        
        setMembershipInfo({
            ...membershipInfo,
            [name]: isEnabled ? 1 : 0,
        })

    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={membershipInfo.name}
                                label="Nombre del paquete"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={membershipInfo.description}
                                label="Descripción"
                                name="description"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                fullWidth
                                value={membershipInfo.maxWhats}
                                label="Sesiones de whatsapp"
                                name="maxWhats"
                                variant="outlined"
                                onChange={handleChange}
                              
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                fullWidth
                                value={membershipInfo.maxUsers}
                                label="Máximo de usuarios"
                                name="maxUsers"
                                variant="outlined"
                                onChange={handleChange}
                                type="number"
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                fullWidth
                                value={membershipInfo.maxBooks}
                                label="Máximo de libros"
                                name="maxBooks"
                                variant="outlined"
                                onChange={handleChange}
                                type="number"
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                title="Modulos"
                                theme={theme}
                                list={modules}
                                onChange={(list)=>{setMembershipInfo({...membershipInfo,Modules:list})}}
                                initSelect={membershipInfo.Modules}
                            />

                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={membershipInfo.massiveMessages == 1}  onChange={(event)=>{enableFeature(event.target.checked,'massiveMessages')}} />} label="Activar mensajes masivos" />

                            </FormGroup>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={membershipInfo.massiveCall == 1}  onChange={(event)=>{enableFeature(event.target.checked,'massiveCall')}} />} label="Activar llamadas masivas" />

                            </FormGroup>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(membershipInfo)}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
