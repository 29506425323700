import { createContext, useEffect, useReducer } from "react";
import api from "../services/api";

import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import Cookies from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

// Note: If you"re trying to connect JWT to your own backend, don"t forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const [currentPath, setCurrentPath] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const initialize = async () => {
      try {
        const cookies = new Cookies();
        const token = cookies.get(process.env.REACT_APP_COOKIE);


        if (token && isValidToken(token)) {

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: null,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

          if(location.pathname.includes("/calendarios") || location.pathname.includes("/quotes")){

          }else{
            navigate('/');
          }

         
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });

        navigate('/');
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    console.log(location.pathname)
    const data = state.isAuthenticated ?
      children
      :
      location.pathname == '/calendarios'   ||  location.pathname == '/quotes'?
        children
        :
        location.pathname != '/' ? '' : children

    console.log(data)
    setCurrentPath(location.pathname);

  }, [location])

  const signIn = async (email, password) => {
    const response = await api.post("/api/login", {
      email: email,
      password: password,
    });

    if (response.data.status === "Success") {
      const { token, user } = response.data;
      const cookies = new Cookies();
      cookies.set("token2", token, { path: "/" });

      setSession(token);
      dispatch({
        type: SIGN_IN,
        payload: {
          user
        },
      });

      return true;
    } else {
      return false;
    }

  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {state.isAuthenticated ?
        children
        :
        currentPath?.includes("/calendarios") || currentPath?.includes("/quotes")?
          children
          :
          currentPath != '/' ? '' : children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
