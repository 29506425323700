import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Checkbox, Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';
import { useSnackbar } from 'notistack';

export default function AddSequenceModal({ title, open, setOpen, relaod, theme, selectedSequence }) {


    const [templates, setTemplates] = useState([]);

    const [sequence, setSequence] = useState({
        templates: [],
        sequenceName: '',
        clientIDs: [],
        startDate: new Date()
    })

    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        setTemplates(null);

        async function getTemplates() {
            try {
                const { data } = await api.post("/api/getTemplate");
                setTemplates(data.messageTemplates);

            } catch (err) {
                console.log(err);
            }
        }

        getTemplates();
    }, [])

    useEffect(() => {

        let tempSequece = {
            templates: [],
            sequenceName: '',
            clientIDs: [],
            startDate: new Date()
        };
        
        tempSequece.sequenceName = selectedSequence.name;

        if (selectedSequence?.ScheduleConfigurations) {
          
            selectedSequence?.ScheduleConfigurations.map((seqConfig) => {
                tempSequece.templates.push({
                    id: seqConfig.templateID,
                    delay: seqConfig.delayTimeMinutes
                });
            })

            setSequence(tempSequece)
        }

       
    }, [selectedSequence])



    const handleClose = () => {
        setOpen(false);
    }


    const saveSequence = async () => {

        if (!sequence.sequenceName) {
            enqueueSnackbar("Debe indicar un nombre", {
                variant: 'warning',
            });
            return;
        }

        if (sequence.templates.length <= 0) {
            enqueueSnackbar("Debe seleccionar al menos dos secuencias", {
                variant: 'warning',
            });
            return;
        }
        try {

            let request = {...sequence}

            if(selectedSequence?.id){
                request.id = selectedSequence.id
            }
            const { data } = await api.post('/api/sequence/add', request)
            if (data.status == "Success") {
                relaod();
            } else {
                throw "Ocurrio un error";
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al guardar la secuencia", {
                variant: 'error',
            });
        }
    }

    const addTemplateToSequence = () => {

        let tempSequence = { ...sequence };

        tempSequence.templates.push({
            id: templates[0]?.id ? templates[0].id : 0,
            delay: 1
        })

        setSequence(tempSequence);
    }

    const modifyTemplateSequence = (index, idTemplate, delay) => {

        let tempSequence = { ...sequence };

        tempSequence.templates[index] = {
            id: idTemplate,
            delay: delay
        }

        setSequence(tempSequence);
    }


    const modifySequenceName = (name) => {

        let tempSequence = { ...sequence };
        tempSequence.sequenceName = name;
        setSequence(tempSequence);
    }

    const deleteTemplateSequence = (index) => {

        let tempSequence = { ...sequence };
        tempSequence.templates.splice(index, 1);
        setSequence(tempSequence);
    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={sequence.sequenceName}
                                label="Nombre de la secuencia de envio"
                                name="name"
                                variant="outlined"
                                onChange={(e) => modifySequenceName(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button fullWidth onClick={addTemplateToSequence}>Agregar secuencia</Button>
                        </Grid>
                        {
                            sequence.templates.map((templateInfo, index) => {

                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                Secuencia {index + 1}
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                                <Button
                                                    onClick={() => deleteTemplateSequence(index)}
                                                    variant="contained"
                                                    fullWidth color="error"
                                                >Eliminar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <TextField
                                                    fullWidth
                                                    value={templateInfo?.delay ? templateInfo?.delay : 1}
                                                    label="Tiempo de espra en minutos"
                                                    name="Minutos"
                                                    variant="outlined"
                                                    onChange={(e) => modifyTemplateSequence(index, templateInfo?.id, e.target.value)}
                                                    type="number"
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <TextField
                                                    select
                                                    label="Plantilla"
                                                    value={templateInfo?.id ? templateInfo?.id : templates[0]?.id ? templates[0].id : 0}
                                                    onChange={(e) => modifyTemplateSequence(index, e.target.value, templateInfo?.delay)}
                                                    variant="outlined"
                                                    color="primary"
                                                    fullWidth
                                                >
                                                    {templates?.map((template) => (
                                                        <MenuItem key={template.id} value={template.id}>
                                                            {template.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <br />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                )
                            })
                        }



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => saveSequence()}>
                        Guardar secuencia
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
