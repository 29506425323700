import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MultiSelect from '../MultiSelect';

export default function ShareModal({ title, open, setOpen, onSave, theme, users,selectedUsers=[]}) {


    const [localSelectedUsers, setLocalSelectedUsers] = useState(selectedUsers);
    const [removedUsers, setRemovedUsers] = useState([]);

    useEffect(()=>{

        
        if(selectedUsers?.length != 0 && selectedUsers.length!= localSelectedUsers.length){
          
            setLocalSelectedUsers(selectedUsers);
        }
        
    },[selectedUsers])

 


    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = () => {
        // Identificar los usuarios deseleccionados
        const removedUsers = selectedUsers.filter(user => !localSelectedUsers.includes(user));
        
        onSave(localSelectedUsers, removedUsers);
    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                label="Selecciona los usuarios para compartir"
                                labelStyle={{ color: 'black' }}
                                style={{ textAlign: 'center', }}
                                sx={{  width: '80%' }}
                                title="Usuarios"
                                theme={theme}
                                list={users?.length ? users : []}
                                onChange={(data) => {setLocalSelectedUsers(data); }}
                                initSelect={localSelectedUsers}

                            />

                        </Grid>
                    


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { handleSave() }}>
                        {
                          "Compartir"
                        }
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
