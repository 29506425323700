import * as React from 'react';

import { useState } from 'react';
import api from '../../services/api';
import { Breadcrumbs, Divider, Grid, Typography, Link } from '@mui/material';
import { useSnackbar } from "notistack";
import { NavLink, useOutletContext } from "react-router-dom";

import Loading from '../../components/Loading';
import { Helmet } from 'react-helmet-async';
import ModuleCard from '../../components/ModuleCard';
import { ViewModule } from '@mui/icons-material';


/**
 * Contenedor para gestionar las sesiones de FB y TK
 * @returns 
 */
export default function UserModules() {



  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);
  /**
  * Variable para recargar
  */
  const [reload, setReload] = useOutletContext();

  const [isLoading, setIsLoading] = useState(true);

  const [userModules, setUserModules] = useState([]);
  /**
  * Notifiaciones
  */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);
    setIsLoading(true);

    async function getModules() {

      try {

        const { data } = await api.post("/api/userModule/");

        if (data?.data) {
          setUserModules(data.data);
        } else {
          console.log(data);
          enqueueSnackbar('Ocurrió un error al consultar los modulos', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al consultar los modulos', {
          variant: 'error',
        });
      }

      setIsLoading(false);
    }

    getModules();

  }, [reload]);



  /**
   * Agrega los modulos al perfil
   * @param {*} webhookUrl 
   */
  const addUserModule = async (id,name) => {
    try {
      setIsLoading(true);
      const { data } = await api.post('/api/addUserModule/', { modules: [id]});

      if(data.status=="Success"){
        enqueueSnackbar(`Se activó el modulo ${name} correctamente`, {
          variant: 'success',
        });
      }else{
        enqueueSnackbar(`'Ocurrió un error al activar el modulo ${name}`, {
          variant: 'error',
        });
      }
    
    } catch (err) {
      enqueueSnackbar('Ocurrió un error al activar el modulo', {
        variant: 'error',
      });
      console.log(err)
    }
    setReload(!reload);
  }


  return (
    <>
      <Helmet title="Modulos" />

      <Typography variant="h2" gutterBottom display="inline">
        Modulos
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Inicio
        </Link>
        <Typography>Modulos</Typography>

      </Breadcrumbs>
      <br />
      <Divider my={6} />

      <Loading open={isLoading} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        spacing={2}
        alignItems="center"
        justify="center"
      >

        {
          userModules?.map((module) => {

            return (
              <Grid
                key={module.id}
                item
                xs={12}
                sm={12}
                md={3}
                lg={4}
                align="center"
     
              >

                <ModuleCard 
                  title={module.name}
                  description={module.description}
                  action={()=>{addUserModule(module.id,module.name)}}
                  icon={()=>{return (<ViewModule sx={{ fontSize: 78 }} />)}}
                />

              </Grid>
            )
          })
        }


      </Grid>

    </>
  );
}