import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink, useSearchParams } from "react-router-dom";

import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  IconButton,
  Chip,
  Button,
  MenuItem,
  Checkbox,
  Menu,
  MenuList,
  Autocomplete,
} from "@mui/material";
import { spacing, width } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { AccountCircle, AccountTree, ArrowDropDown, Assignment, AttachFile, Check, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, Close, Cloud, CoPresentOutlined, ConstructionOutlined, ContentCopy, ContentCut, ContentPaste, Edit, FireHydrantAltRounded, InsertDriveFile, Label, LabelOff, LinearScale, MarkChatUnread, Markunread, Message, Person, PersonAdd, PushPin, Save, Schedule, Share, ShoppingCart, ThreeP, VolumeOff, VolumeUp, WhatsApp } from "@mui/icons-material";
import api from "../../services/api";

import { SocketContext } from '../../services/socket';
import './Chat2.css';
import { LoadingButton } from "@mui/lab";
import FilterListIcon from '@mui/icons-material/FilterList';
import AddTagmodal from "../../components/AddTagModal";
import AddProductModal from "../../components/AddProductModal";
import MassiveMsgModal from "../../components/MassiveMsgModal";
import { getContrastColor } from "../../utils/generalFunctions";
import notification from '../../assets/notification.mp3';
import ChatInputMessage from "../../components/ChatInputMessage";
import ShareModal from "../../components/ShareModal";
import { useSnackbar } from "notistack";
import AddStatusModal from "../../components/AddStatusModal";
import ClientInfo from "../../components/ClientInfo";
import EmojiPicker from "emoji-picker-react";


import Cookies from 'universal-cookie';
import AddparticipantToGroupModal from "../../components/AddParticipantToGroupModal";


import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)`width: 200px%;`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 90%;
  width: 100%;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
 
`;

const ChatMessageInner = styled.div`
  display: inline-block;
  max-width: 100%;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const getMessageBytLines = (message) => {

  const resp = message.split("\n").map((i, key) => {
    if (i != '') {
      return <div key={key}>{i}</div >;
    }

  })

  return resp;
}

function ChatMessageComponent({
  name,
  message,
  time,
  time2,
  avatar,
  author,
  position = "left",
  messageObj,
  showAuthor,
  isGroup
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner style={{ maxWidth: '50%' }}>

        <ChatMessageBubble style={{ maxWidth: '100%' }} highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {messageObj.sendBy ? messageObj.sendBy : isGroup ? author : ''}
            </ChatMessageBubbleName>
          </Box>
          {
            messageObj.hasMedia == 1 ?

              messageObj?.mediaUrl?.includes('.oga') || messageObj?.mediaUrl?.includes('.mpga') ?


                <audio controls>
                  <source src={`${process.env.REACT_APP_BACK_URL}/media/${messageObj.mediaUrl}`} type={messageObj?.mediaUrl?.includes('.mpga') ? "audio/mpeg" : "audio/ogg"} />

                  Su navegador no soporta el audio
                </audio>
                :
                messageObj?.mediaUrl?.includes('.jpeg') || messageObj?.mediaUrl?.includes('.webp') ?
                  <>
                    <img onClick={() => { window.open(`${process.env.REACT_APP_BACK_URL}/media/${messageObj.mediaUrl}`, '_blank').focus(); }} style={{ maxWidth: '100%', maxHeight: '200px' }} src={`${process.env.REACT_APP_BACK_URL}/media/${messageObj.mediaUrl}`} alt="img" />
                    <Typography className="chat-long-text" variant="body2">{getMessageBytLines(message)}</Typography>
                  </>
                  :
                  <>
                    <IconButton onClick={() => { window.open(`${process.env.REACT_APP_BACK_URL}/media/${messageObj.mediaUrl}`, '_blank').focus(); }}>
                      <InsertDriveFile />
                    </IconButton>
                    <Typography className="chat-long-text" variant="body2">{getMessageBytLines(message)}</Typography>
                  </>
              :
              <Typography className="chat-long-text" variant="body2">{getMessageBytLines(message)}</Typography>
          }

        </ChatMessageBubble>
        <ChatMessageTime style={{ display: 'none' }} variant="body2">{new Date(time).toString()}</ChatMessageTime>
        <ChatMessageTime variant="body2">{getDate(time2)}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}
function getDate(time2) {
  var utc = new Date(time2);
  var offset = utc.getTimezoneOffset();

  var local = '';

  if (time2.indexOf('T') > -1) {
    var local = utc;
  } else {
    var local = new Date(utc.getTime() + offset * 60000);
  }

  const zonaHorariaNavegador = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const opciones = { timeZone: zonaHorariaNavegador, hour: '2-digit', minute: '2-digit', hour12: false };




  const horaLocal = utc.toLocaleTimeString([], { ...opciones });

  var year = local.getFullYear();
  var month = ("0" + (local.getMonth() + 1)).slice(-2);
  var day = ("0" + local.getDate()).slice(-2);

  var fechaFormateada = year + "-" + month + "-" + day + " " + horaLocal;
  return fechaFormateada;


}
function Chat2() {

  const cookies = new Cookies();
  const currentMessage = useRef();

  /**
   * Objetos para el manejo de los chats
   */
  const [chats, _setChats] = useState([]);

  const chatsRef = React.useRef(chats);

  const setChats = data => {
    chatsRef.current = data;
    _setChats(data);
  }

  /**
   * Objetos para le manejos del chat actual
   */
  const [currentChat, _setCurrentChat] = useState(null);

  const currentChatRef = React.useRef(currentChat);

  const setCurrentChat = data => {
    currentChatRef.current = data;
    _setCurrentChat(data);
  }
  /**
   * Objetos para le manejo de los chats que llegan desde el socket
   */
  const [currentMessages, _setCurrentMessages] = useState([]);

  const currentMessagesRef = React.useRef(currentMessages);

  const setCurrentMessages = data => {
    currentMessagesRef.current = data;
    _setCurrentMessages(data);
  }

  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);




  const [status, setStatus] = useState([]);

  const [products, setProducts] = useState([]);

  const [currentChatIndex, setCurrentChatIndex] = useState(null);


  const [currentWindow, _setCurrentWindow] = useState(1);

  const currentWindowRef = React.useRef(currentWindow);

  const setCurrentWindow = data => {
    currentWindowRef.current = data;
    _setCurrentWindow(data);
  }

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingChats, setIsloadingChats] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [blockButtons, setBlockButtons] = useState(false);



  const [showCheckBox, setShowCheckBox] = useState(false);

  const [selectAll, setSelectAll] = useState(false);


  const [selectedChats, setSelectedChats] = useState([]);
  const [selectedInfoChats, setSelectedInfoChats] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);


  const [reload, _setReload] = useState(false);

  const reloadRef = React.useRef(reload);

  const setReload = data => {
    reloadRef.current = data;
    _setReload(data);
  }
  const open = Boolean(anchorEl);


  const [filters, setFilters] = useState({
    unread: false,
    replyed: false,
    tags: [],
    whatsapp: [],
    status: [],
    phone: '',
  })


  const [tags, setTags] = useState([]);


  const [anchorChatMenu, setAnchorChatMenu] = useState(null);

  const [anchorActionMenu, setAnchorActionMenu] = useState(null);

  const [idShowChatMenu, setIdShowChatMenu] = useState(null);
  const [idKeepChatMenu, setIdKeepChatMenu] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);


  const [openMove, setOpenMove] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);

  const [openStatus, setOpenStatus] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [sessions, setSessions] = useState([]);

  const [filterNameValue, setFilterNameValue] = useState('');


  const [openMassiveMessage, setOpenMassiveMessage] = useState(false);

  const [scheduleMsg, setScheduleMsg] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [chatUrlParamID, setChatUrlParamID] = useState(null);

  const [openShareModal, setOpenSharemodal] = useState(false);

  const [advisers, setAdvisers] = useState([]);

  const [chatPage, setChatPage] = useState(1);
  const [nextChatPage, setNextChatPage] = useState(2);

  const [reloadNewChats, setReloadNewChats] = useState(false);

  const [attachedFiles, setAttachedFiles] = useState([]);
  /**
 * Notifiaciones
 */
  const { enqueueSnackbar } = useSnackbar();


  const [reloadNewMessage, _setReloadNewMessage] = useState(false);

  const reloadNewMessageRef = React.useRef(reloadNewMessage);

  const setReloadNewMessage = data => {
    reloadNewMessageRef.current = data;
    _setReloadNewMessage(data);
  }

  const [chatSound, _setChatSound] = useState(true);

  const chatSoundRef = React.useRef(chatSound);

  const setChatSound = data => {
    chatSoundRef.current = data;
    _setChatSound(data);
  }

  const [reloadSound, setReloadSound] = useState(true);


  const [openAddParticipants, setOpenAddParticipants] = useState(false);

  const [currGroupID, setCurrGroupID] = useState(null);

  const [isSequence, setIsSequence] = useState(false);

  const [showNewMessageModal, setShowNewMessageModal] = useState(false);

  /**Nuevo mensaje */
  const [newPhoneNumber, setNewPhoneNumber] = useState(null);
  const [newWpSession, setNewWpSession] = useState(null);

  useEffect(() => {

    async function getChatConfig() {
      try {
        const { data } = await api.get("/api/config/chatSound");


        if (data.status == "Success") {

          setChatSound(data.chatSoundEnabled ? true : false);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar la configuración de audio del chat 1", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error al consultar la configuración de audio del chat", {
          variant: 'error',
        });
      }
    }

    getChatConfig();
  }, [reloadSound])

  useEffect(() => {



    async function getstatus() {
      try {
        const { data } = await api.post("/api/getStatus");


        if (data.status == "Success") {

          setStatus(data.statusList);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar los estatus", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error", {
          variant: 'error',
        });
      }
    }

    async function getProducts() {
      try {

        const { data } = await api.post("/api/getProducts");



        if (data.status == "Success") {

          setProducts(data.products);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar los productos", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error", {
          variant: 'error',
        });

      }
    }

    async function getTags() {
      try {

        const { data } = await api.post("/api/getTags");



        if (data.status == "Success") {

          setTags(data.tags);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar las etiquetas", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error al consultar las etiquetas", {
          variant: 'error',
        });
      }
    }

    async function getTemplates() {

      try {


        const { data } = await api.post("/api/getTemplate");

        setTemplates(data.messageTemplates);


      } catch (err) {
        console.log(err)
      }
    }


    async function getWhatsapps() {

      try {


        const { data } = await api.post("api/v2/getUserSessions");
        // console.log(data)
        setSessions(data.sessions);


      } catch (err) {
        console.log(err)
      }
    }

    async function getAdvisers() {

      try {

        const { data } = await api.get("api/users/all");
        setAdvisers(data);


      } catch (err) {
        console.log(err);
      }


    }

    getstatus();
    getWhatsapps();
    getProducts();
    getTags();
    getTemplates();
    getAdvisers();

    let chatUrlIdParam = searchParams.get("chatID");
    setChatUrlParamID(chatUrlIdParam);

  }, []);


  useEffect(() => {

    if (chatUrlParamID && chatUrlParamID != '' && !isLoadingChats) {
      searchChatAngGetMessages();
    }

  }, [chatUrlParamID, chats, isLoadingChats])


  /**
  * Funcion para actualizar las sesiones con el socket
  */
  const onMessage = React.useCallback((chatID, message, unreadCount, reloadMess, hasMedia) => {

    if (reloadMess && currentWindowRef.current == 0) {

      setReloadNewMessage(true);
      return;
    }
    let chatsTemp = chatsRef.current;
    if (chatsTemp.length == 0) {
      return;
    }
    const updatedChatindex = chatsTemp.findIndex(x => x?.id === chatID);


    let updatedChat = chatsTemp[updatedChatindex];


    if (!updatedChat && currentWindowRef.current == 0) {

      setReloadNewMessage(true);
      return;
    }

    if (!updatedChat) {
      return;
    }
    if (updatedChat?.lastMessage) {
      updatedChat.lastMessage = message.body;
    }


    if (hasMedia && !message.body) {
      if (updatedChat?.lastMessage) {
        updatedChat.lastMessage = message.fromMe ? 'Archivo enviado' : 'Archivo recibido';
      }

    }

    updatedChat.unreadCount = unreadCount;

    if (updatedChatindex > -1) {

      chatsTemp.splice(updatedChatindex, 1);
    }

    setChats([updatedChat, ...chatsTemp]);

    if (chatID == currentChatRef?.current?.id) {

      let newMessages = [...currentMessagesRef.current, message];
      setCurrentMessages(newMessages);

    } else {

    }

    if (!message.fromMe && currentWindowRef.current != 0 && chatSoundRef.current) {
      const audio = new Audio(notification);
      audio.play();
    }

  }, [currentMessagesRef, currentChatRef, chatsRef])

  useEffect(() => {

    if (socket) {

      socket.on('NEW_MESSAGE', (data) => {

        onMessage(data.id, data.msg, data.unreadCount, data.reload, data.hasMedia);
      })
    }

  }, [socket, onMessage]);


  /**
   * Cada que cambia el tipo de chats que consulta se refrescan
   */
  useEffect(() => {


    // setCurrentMessages([]);
    //setCurrentChat(null);
    setIsLoading(true);
    setBlockButtons(true);
    setShowCheckBox(false);
    setOpenSharemodal(false);
    setSelectedChats([]);
    setSelectedInfoChats([])
    setChatPage(1);
    setNextChatPage(2);
    setReloadNewChats(true);
    async function getChats(clientType) {
      try {
        const { data } = await api.post('/api/getChats', { onlyGroups: clientType == 2, withOutAdviser: clientType == 0, onlyUnread: filters.unread, onlyRead: filters.replyed, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone });

        if (data.status == "Success") {

          setChats(data.chats);

        } else if (data?.code) {

          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });

        } else {

          enqueueSnackbar("Ocurrio un error al consultar los chats", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error al consultar los chats", {
          variant: 'error',
        });
      }

      setIsLoading(false);
      setBlockButtons(false);

    }
    getChats(currentWindow);

  }, [currentWindow, reload, filters])

  /**
  * Carga los nuevos mensajes sin recargar todo
  */
  useEffect(() => {

    async function getChats(clientType) {
      try {
        const { data } = await api.post('/api/getChats', { onlyGroups: clientType == 2, withOutAdviser: clientType == 0, onlyUnread: filters.unread, onlyRead: filters.replyed, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone });

        if (data.status == "Success") {

          setChats([data.chats[0], ...chats]);

        } else if (data?.code) {

          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });

        } else {

          enqueueSnackbar("Ocurrio un error al consultar los chats nuevos", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error al consultar los chats nuevos", {
          variant: 'error',
        });
      }

    }

    if (reloadNewMessage) {

      setReloadNewMessage(false);
      getChats(currentWindow);
    }


  }, [currentWindow, reloadNewMessage, filters])

  /**
   * Cada que cambia el tipo de chats que consulta se refrescan
   */
  useEffect(() => {

    async function getChats(clientType) {
      try {
        const { data } = await api.post('/api/getChats', { onlyGroups: clientType == 2, page: chatPage, withOutAdviser: clientType == 0, onlyUnread: filters.unread, onlyRead: filters.replyed, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone });

        if (data.status == "Success") {

          if (data.chats.length == 0) {
            setReloadNewChats(false);
          } else {
            let newChats = [...chats, ...data.chats]
            setChats(newChats);
          }
          setNextChatPage(chatPage + 1);

        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error al consultar los chats", {
          variant: 'error',
        });
      }
    }

    if (chatPage > 1) {
      if (nextChatPage == chatPage) {

        getChats(currentWindow);
      }

    }


  }, [currentWindow, filters, chats, chatPage, nextChatPage])


  const searchChatAngGetMessages = () => {

    const searchChats = chats.filter((chat) => chat.id == chatUrlParamID);

    if (searchChats.length > 0) {
      let chat = searchChats[0];

      if (chat === currentChat) {
        return;
      }
      setCurrentChat(chat);
      let index = chats.findIndex((chat) => chat.id == chatUrlParamID);
      setCurrentChatIndex(index);
      getMessages('', chat.id, chat.phone);
    }
  }
  const onClick = async (chat, index) => {
    setCurrentChat(chat);
    setCurrentChatIndex(index);
    // console.log("Setteando chat")
    // console.log(chat)
    getMessages('', chat.id, chat.phone);
  }


  const getMessages = async (session, chatID, phone) => {
    setIsloadingChats(true);
    setChatUrlParamID(null);
    // console.log("Cargando mensajes"+chatID)
    try {
      const { data } = await api.post('/api/getMessages', { session: '', chatID: chatID, phone: phone });

      if (data.status == "Success") {

        setCurrentMessages(data.messages);
        // console.log(data.messages)

      } else {
        enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
        variant: 'error',
      });
    }
    setIsloadingChats(false);
  }

  const sendMessage = async (msg, filePath, attachedFiles) => {
    try {
      let objToSend = {
        message: currentMessage.current.firstChild.control.value,
        idChat: currentChat.whatsappId,
        wpsession: currentChat.WpSession.sessionAuth,
        audioMedia: filePath,
        attached: attachedFiles,
        token: cookies.get(process.env.REACT_APP_COOKIE)
      }


      if (showNewMessageModal) {

        if (!objToSend.idChat) {
          enqueueSnackbar("Es necesario agregar un teléfono", {
            variant: 'warning',
          });
          return;
        }
        if (!objToSend.wpsession) {
          enqueueSnackbar("Es necesario seleccionar un whatsapp", {
            variant: 'warning',
          });
          return;
        }
      }

      if (!objToSend.message) {
        enqueueSnackbar("Es necesario escribir un mensaje", {
          variant: 'warning',
        });
        return;
      }

      socket.emit('sendMessage', objToSend);

      if (showNewMessageModal) {
        setOpenMassiveMessage(false);
        enqueueSnackbar("Se envio el mensaje de forma correcta", {
          variant: 'success',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar("Ocurrio un error al enviar el mensaje", {
        variant: 'error',
      });
    }
  }



  const saveChatClientInfo = async (updatedChat) => {
    try {

      const { data } = await api.post('/api/updateChatClient', { chat: updatedChat });

      if (data.status == "Success") {
        let tempChats = [...chats];
        let tempChat = { ...updatedChat }

        if (!tempChat.Client.Status?.name) {
          tempChat.Client.Status = {
            id: 0,
            name: '',
            color: '#0000000'
          }
        }
        tempChat.Client.Status.id = updatedChat.Client.status;
        let tempStatus = status.filter(s => s.id == updatedChat.Client.status);

        if (tempStatus[0]?.name) {
          tempChat.Client.Status.name = tempStatus[0].name;
          tempChat.Client.Status.color = tempStatus[0].color;
        }


        tempChats[currentChatIndex] = updatedChat;

        setChats(tempChats);
      } else {
        console.log(data)
        enqueueSnackbar("Ocurrio un error al guardar la información", {
          variant: 'error',
        });

      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Ocurrio un error al guardar la información", {
        variant: 'error',
      });
    }

    return true;
  }

  const setAdviserToClient = async (chat) => {
    try {

      const { data } = await api.post('/api/setChatAdviser', { chat: chat });
      if (data.status == "Success") {

        if (chat.isGroup == "1") {
          setCurrentWindow(2);
        } else {
          setCurrentWindow(1);
        }

        setTimeout(setChatUrlParamID(chat.id), 1000)

      } else {
        console.log(data)
        enqueueSnackbar("Ocurrio un error al asignar el asesor", {
          variant: 'error',
        });

      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Ocurrio un error al asignar el asesor", {
        variant: 'error',
      });
    }
  }

  const bottomEl = useRef(null);

  const scrollToBottomDelay = () => {
    setTimeout(scrollToBottom, 1000)

  };

  const scrollToBottom = () => {

    if (bottomEl.current) {
      const { scrollHeight, clientHeight } = bottomEl.current;
      bottomEl.current.scrollTop = scrollHeight - clientHeight;
    }
    bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const isChatSelected = (id) => {
    return selectedChats.indexOf(id) != -1;
  }

  const selectAllChats = (newValue) => {

    let selectedTemp = [];
    if (newValue) {


      chats.map((c) => {
        selectedTemp.push(c.id);
      })

      setSelectedChats(selectedTemp)
      setSelectedInfoChats(chats);
    } else {

      setSelectedChats(selectedTemp)
      setSelectedInfoChats(selectedTemp)
    }
    setSelectAll(newValue);
  }

  /**
   * Agrega o elimina un chat de la selección
   * @param {*} id 
   * @param {*} selected 
   */
  const addRemoveChatFromSelect = (id, selected, chat) => {

    let selectedTemp = [...selectedChats];
    let selecetdInfoTemp = [...selectedInfoChats]
    if (selected) {

      selectedTemp.push(id);
      selecetdInfoTemp.push(chat)


    } else {
      const index = selectedTemp.indexOf(id);
      if (index > -1) { // only splice array when item is found
        selectedTemp.splice(index, 1); // 2nd parameter means remove one item only
        selecetdInfoTemp.splice(index, 1);
      }

      setSelectAll(false);
    }
    setSelectedChats(selectedTemp);
    setSelectedInfoChats(selecetdInfoTemp);
  }

  const pinnedChat = async (selectedChatId) => {


    if (selectedChats.length == 0 && selectedChatId == null) {
      enqueueSnackbar("Debe seleccionar al menos un chat", {
        variant: 'warning',
      });

      return;
    }
    try {

      let chatsToSend = [...selectedChats]
      if (selectedChatId) {
        chatsToSend.push(selectedChatId.id);
      }
      const { data } = await api.post('/api/pinnChat', { chats: chatsToSend, pinned: true });
      if (data.status == "Success") {

      } else {
        console.log(data)
        enqueueSnackbar("Debe seleccionar al anclar los chats", {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Debe seleccionar al anclar los chats", {
        variant: 'error',
      });
    }
    handleClose();
    setReload(!reload);
  }

  const unpinnedChat = async (selectedChatId) => {


    if (selectedChats.length == 0 && selectedChatId == null) {
      enqueueSnackbar("Debe seleccionar al menos un chat", {
        variant: 'warning',
      });
      return;
    }
    try {
      let chatsToSend = [...selectedChats]
      if (selectedChatId) {
        chatsToSend.push(selectedChatId.id);
      }
      const { data } = await api.post('/api/pinnChat', { chats: chatsToSend, pinned: false });
      if (data.status == "Success") {

      } else {
        console.log(data)
        enqueueSnackbar("Debe seleccionar al desanclar los chats", {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Debe seleccionar al desanclar los chats", {
        variant: 'error',
      });
    }

    handleClose();
    setReload(!reload);
  }


  const addFilter = (name, value) => {
    let filtersTemp = filters;
    if (name == "unread") {
      filtersTemp.unread = !filtersTemp.unread;
      if (filtersTemp.unread) {
        filtersTemp.replyed = false;
      }
      setFilters({ ...filtersTemp })
    } else if (name == "tags") {

      let tagsTemp = filtersTemp.tags;
      let index = tagsTemp.indexOf(value);
      if (index > -1) {
        tagsTemp.splice(index, 1);
      } else {
        tagsTemp.push(value)
      }

      setFilters({ ...filtersTemp, tags: tagsTemp })

    } else if (name == "replyed") {
      filtersTemp.replyed = !filtersTemp.replyed;

      if (filtersTemp.replyed) {
        filtersTemp.unread = false;
      }
      setFilters({ ...filtersTemp })
    } else if (name == "phone") {
      filtersTemp.phone = value;
      setFilters({ ...filtersTemp })
    } else {
      let wpTemps = filtersTemp[name];
      let index = wpTemps.indexOf(value);
      if (index > -1) {
        wpTemps.splice(index, 1);
      } else {
        wpTemps.push(value)
      }

      setFilters({ ...filtersTemp, [name]: wpTemps })
      //console.log({ ...filtersTemp, whatsapp: wpTemps })
    }


    handleClose();
  }

  const isTagSelected = (id) => {
    let tagsTemp = filters.tags;
    let index = tagsTemp.indexOf(id);
    return index != -1
  }

  const isWhatsappSelected = (id) => {
    let wpTemp = filters.whatsapp;
    let index = wpTemp.indexOf(id);
    return index != -1
  }

  const isStatusSelected = (id) => {
    let wpTemp = filters.status;
    let index = wpTemp.indexOf(id);
    return index != -1
  }
  const showChatMenu = (id) => {
    setIdShowChatMenu(id);
    setShowNewMessageModal(false);
  }

  const hideChatMenu = () => {
    setIdShowChatMenu(null);
  }


  const openChatMenu = (chat, button) => {
    // console.log(button.currentTarget)
    setIdKeepChatMenu(chat.id);
    setSelectedChat(chat);
    setAnchorChatMenu(button.currentTarget);
  }

  const closeChatMenu = () => {
    setIdKeepChatMenu(null);
    setAnchorChatMenu(null);
    setSelectedChat(null);
  }


  const closeActionMenu = () => {
    setAnchorActionMenu(null);
  }

  const openActionMenu = (event) => {
    setAnchorActionMenu(event.currentTarget);
  };


  const addTagToClients = async (tags) => {



    let clientList = [...selectedChats];


    try {
      await api.post('/api/addTagToClients', {

        clients: clientList,
        idTags: tags,
        isFromChat: true
      });


      enqueueSnackbar("Se agregaron las etiquetas de forma correcta", {
        variant: 'success',
      });
      setReload(!reload);
      setOpenTags(false);
      closeActionMenu()
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrio un error al agregar las etiquetas`, {
        variant: 'error',
      });

    }


  }


  const addProductToClients = async (products) => {

    let clientList = [...selectedChats];

    try {
      await api.post('/api/addProductToClients', {

        clients: clientList,
        products: products,
        isFromChat: true
      });
      enqueueSnackbar(`Se agregaron los productos de forma correcta`, {
        variant: 'success',
      });


      setReload(!reload);
      setOpenProducts(false);
      closeActionMenu()
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrio un error al agregar los productos`, {
        variant: 'error',
      });
    }


  }

  /**
   * Cambia el estatus de los clientes
   * @param {*} products 
   */
  const addStatusToClient = async (status) => {

    let clientList = [...selectedChats];

    try {
      await api.post('/api/addStatusToClients', {

        clients: clientList,
        status: status,
        isFromChat: true
      });
      enqueueSnackbar(`Se agregaron los productos de forma correcta`, {
        variant: 'success',
      });


      setReload(!reload);
      setOpenStatus(false);
      closeActionMenu()
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrio un error al agregar los productos`, {
        variant: 'error',
      });
    }
  }



  const shareChat = async (usersID) => {


    if (selectedChats.length == 0) {
      enqueueSnackbar("Debe seleccionar al menos un chat", {
        variant: 'warning',
      });
      return;
    }
    try {
      let chatsToShare = [...selectedChats]

      if (selectedChat) {
        chatsToShare.push(selectedChat);
      }
      const { data } = await api.post('/api/addChatToClient', { chats: chatsToShare, users: usersID });
      if (data.status == "Success") {

      } else {
        console.log(data)
        enqueueSnackbar("Ocurrio un error al compartir los chats", {
          variant: 'error',
        });

      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Ocurrio un error al compartir los chats", {
        variant: 'error',
      });
    }

    setReload(!reload);
  }


  const getPhones = () => {
    let phones = [];

    selectedChats.map((id) => {
      chats.map((chat) => {
        if (chat.id == id) {
          phones.push(chat.phone);
        }
      })
    })
    return phones;
  }


  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;

    if (bottom && reloadNewChats) {

      if (chatPage < nextChatPage) {
        setChatPage(chatPage + 1)
      }
      ;
    }
  }


  const changeSoundConfig = async () => {
    try {

      const { data } = await api.post('/api/config/chatSound', { enableChatSound: chatSound ? 0 : 1 });

      if (data.status == "Success") {
        setChatSound(!chatSound)
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrio un error al cambiar la configuración de sonido', {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err);
      enqueueSnackbar('Ocurrio un error al cambiar la configuración de sonido', {
        variant: 'error',
      });
    }
  }

  /**
   * 
   */
  const initNewChat = () => {
    setShowNewMessageModal(!showNewMessageModal);
    setCurrentChat({
      whatsappId: null,
      WpSession: {
        sessionAuth: null
      }
    })
  }


  /**
   * 
   */
  const handleChangeCurrChat = (phone, wpSession) => {
    const newChat = {
      whatsappId: phone ? `${phone}@c.us` : currentChat.phone,
      WpSession: {
        sessionAuth: wpSession ? wpSession : currentChat.WpSession.sessionAuth
      }
    }

    setCurrentChat(newChat)
  }
  return (
    <>
      <Helmet title="Chat" />



      <Grid container sx={{ height: '85vh', maxWidth: '100vw' }}>
        <Grid item sx={{ height: '85vh' }} xs={12} md={4} lg={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid sx={{ minHeight: '50px' }} item xs={4}>
                <LoadingButton
                  loading={isLoading && currentWindow == 0}
                  loadingIndicator="Cargando..."
                  disabled={blockButtons} onClick={() => setCurrentWindow(0)} sx={{ minHeight: '50px' }} fullWidth variant={currentWindow == 0 ? "contained" : "outlined"}>Pendientes</LoadingButton>
              </Grid>
              <Grid sx={{ minHeight: '50px' }} item xs={4}>
                <LoadingButton
                  loading={isLoading && currentWindow == 1}
                  loadingIndicator="Cargando..."
                  disabled={blockButtons} onClick={() => setCurrentWindow(1)} sx={{ minHeight: '50px' }} fullWidth variant={currentWindow == 1 ? "contained" : "outlined"}>Atendidos</LoadingButton>
              </Grid>
              <Grid sx={{ minHeight: '50px' }} item xs={4}>
                <LoadingButton
                  loading={isLoading && currentWindow == 2}
                  loadingIndicator="Cargando..."
                  disabled={blockButtons} onClick={() => setCurrentWindow(2)} sx={{ minHeight: '50px' }} fullWidth variant={currentWindow == 2 ? "contained" : "outlined"}>Grupos</LoadingButton>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12}>

            <Box p={0}>
              <Grid container>
                <Grid item xs={11}>
                  <TextField onChange={(e) => addFilter('phone', e.target.value)} label="Buscar chat" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={1}>
                  {
                    !showCheckBox ?
                      <IconButton id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                        variant="contained" sx={{ height: '100%' }} fullWidth>
                        <FilterListIcon />
                      </IconButton>
                      :
                      <IconButton
                        onClick={openActionMenu}
                        variant="contained" sx={{ height: '100%' }} fullWidth>
                        <LinearScale />
                      </IconButton>
                  }

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    <MenuList

                    >
                      <Box p={2}>
                        <Typography variant="body1" >
                          Acciones
                        </Typography>
                      </Box>
                      <MenuItem onClick={() => { changeSoundConfig(); handleClose() }}>
                        <ListItemIcon>
                          {
                            chatSound ?
                              <VolumeOff fontSize="small" />
                              :
                              <VolumeUp fontSize="small" />
                          }


                        </ListItemIcon>
                        <ListItemText>{chatSound ? "Desactivar audio" : "Activar audio"}</ListItemText>

                      </MenuItem>
                      <MenuItem onClick={() => { setShowCheckBox(!showCheckBox); handleClose() }}>
                        <ListItemIcon>
                          {showCheckBox ?
                            <CheckBoxOutlineBlank fontSize="small" />
                            :
                            <CheckBox fontSize="small" />
                          }
                        </ListItemIcon>
                        <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

                      </MenuItem>
                      <MenuItem onClick={() => { initNewChat(); handleClose() }}>
                        <ListItemIcon>

                          <WhatsApp fontSize="small" />

                        </ListItemIcon>
                        <ListItemText>Nuevo mensaje</ListItemText>

                      </MenuItem>
                      <Divider />
                      <Box p={2}>
                        <Typography variant="body1" >
                          Filtros
                        </Typography>
                      </Box>
                      <Divider />
                      <MenuItem selected={filters.replyed} onClick={() => addFilter('replyed', null)}>
                        <ListItemIcon>
                          <MarkChatUnread fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Respondidos</ListItemText>
                      </MenuItem>
                      <MenuItem selected={filters.unread} onClick={() => addFilter('unread', null)}>
                        <ListItemIcon>
                          <MarkChatUnread fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>No leidos</ListItemText>
                      </MenuItem>
                      <Box p={2}>
                        <Typography variant="body1" >
                          Whatsapps
                        </Typography>
                      </Box>
                      <Divider />

                      {
                        sessions.map((s) => {


                          return (
                            <MenuItem onClick={() => addFilter('whatsapp', s.id)} selected={isWhatsappSelected(s.id)} key={s.id}>
                              <ListItemIcon >
                                <WhatsApp style={{ color: s.color ? s.color : 'black' }} fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{s.name}</ListItemText>
                            </MenuItem>
                          )
                        })
                      }
                      <Box p={2}>
                        <Typography variant="body1" >
                          Estatus
                        </Typography>
                      </Box>
                      <Divider />

                      {
                        status.map((s) => {


                          return (
                            <MenuItem onClick={() => addFilter('status', s.id)} selected={isStatusSelected(s.id)} key={s.id}>
                              <ListItemIcon >
                                <WhatsApp style={{ color: s.color ? s.color : 'black' }} fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{s.name}</ListItemText>
                            </MenuItem>
                          )
                        })
                      }
                      <Box p={2}>
                        <Typography variant="body1" >
                          Etiquetas
                        </Typography>
                      </Box>
                      <Divider />
                      <MenuItem onClick={() => addFilter('tags', 0)} selected={isTagSelected(0)} key={0}>
                        <ListItemIcon>
                          <LabelOff fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Sin etiquetas</ListItemText>
                      </MenuItem>
                      {
                        tags.map((t) => {


                          return (
                            <MenuItem onClick={() => addFilter('tags', t.id)} selected={isTagSelected(t.id)} key={t.id}>
                              <ListItemIcon >
                                <Label style={{ color: t.color ? t.color : 'black' }} fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{t.name}</ListItemText>
                            </MenuItem>
                          )
                        })
                      }

                    </MenuList>
                  </Menu>

                  <Menu

                    anchorEl={anchorActionMenu}
                    open={Boolean(anchorActionMenu)}
                    onClose={closeActionMenu}
                  >
                    <MenuList

                    >
                      <Box p={2}>
                        <Typography variant="body1" >
                          Acciones
                        </Typography>
                      </Box>
                      <Divider />
                      <MenuItem onClick={() => { setShowCheckBox(!showCheckBox); closeActionMenu() }}>
                        <ListItemIcon>
                          {showCheckBox ?
                            <CheckBoxOutlineBlank fontSize="small" />
                            :
                            <CheckBox fontSize="small" />
                          }
                        </ListItemIcon>
                        <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

                      </MenuItem>

                      <MenuItem onClick={() => { pinnedChat(); closeActionMenu(); }}>
                        <ListItemIcon>
                          <PushPin fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Anclar</ListItemText>
                      </MenuItem>

                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });

                          return;
                        }

                        setOpenTags(true);

                      }}>
                        <ListItemIcon>
                          <Label fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Modificar etiquetas</ListItemText>
                      </MenuItem>

                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });
                          return;
                        }

                        setOpenStatus(true);

                      }}>
                        <ListItemIcon>
                          <Assignment fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Modificar estatus</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });
                          return;
                        }

                        setOpenProducts(true);

                      }}>
                        <ListItemIcon>
                          <ShoppingCart fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Modificar producto</ListItemText>
                      </MenuItem>
                      <Divider />
                      <Box p={2}>
                        <Typography variant="body1" >
                          Masivo
                        </Typography>
                      </Box>
                      <MenuItem onClick={() => { setOpenSharemodal(true); closeChatMenu(); }}>
                        <ListItemIcon>
                          <Share />
                        </ListItemIcon>
                        <ListItemText>Compartir chat</ListItemText>

                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });
                          return;
                        }
                        setScheduleMsg(false);
                        setIsSequence(false);
                        setOpenMassiveMessage(true);

                      }}>

                        <ListItemIcon>
                          <Message fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Enviar mensaje masivo</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });
                          return;
                        }
                        setScheduleMsg(true);
                        setIsSequence(false);
                        setOpenMassiveMessage(true);

                      }}>

                        <ListItemIcon>
                          <Schedule fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Programar mensaje</ListItemText>
                      </MenuItem>

                      <MenuItem onClick={() => {
                        if (selectedChats.length == 0) {
                          enqueueSnackbar("Debe seleccionar al menos un contacto", {
                            variant: 'warning',
                          });
                          return;
                        }
                        setIsSequence(true);
                        setScheduleMsg(false);
                        setOpenMassiveMessage(true);

                      }}>

                        <ListItemIcon>
                          <AccountTree fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Enviar secuencia</ListItemText>
                      </MenuItem>
                    </MenuList>

                  </Menu>
                </Grid>

              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={2}>
              <Grid container>
                {
                  showCheckBox &&
                  <Grid item xs={3} >
                    <Grid container justifyContent="center">
                      <Checkbox checked={selectAll} onChange={(e) => selectAllChats(e.target.checked)} />
                    </Grid>
                  </Grid>
                }
              </Grid>

            </Box>
          </Grid>

          <Menu
            anchorEl={anchorChatMenu}
            open={Boolean(anchorChatMenu)}
            onClose={closeChatMenu}>
            <MenuList

            >

              <MenuItem selected={filters.unread} onClick={() => { setShowCheckBox(!showCheckBox); closeChatMenu() }}>
                <ListItemIcon>
                  {showCheckBox ?
                    <CheckBoxOutlineBlank fontSize="small" />
                    :
                    <CheckBox fontSize="small" />
                  }
                </ListItemIcon>
                <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

              </MenuItem>
              {
                !showCheckBox &&
                <MenuItem selected={filters.unread} onClick={() => {
                  if (selectedChat?.pinned == 0) {
                    pinnedChat(selectedChat);
                  } else {
                    unpinnedChat(selectedChat);
                  }
                  closeChatMenu();
                }}>
                  <ListItemIcon>
                    <PushPin fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{selectedChat?.pinned == 0 ? "Anclar" : "Desanclar"}</ListItemText>
                </MenuItem>
              }
              <MenuItem onClick={() => { setOpenSharemodal(true); closeChatMenu(); }}>
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                <ListItemText>Compartir chat</ListItemText>

              </MenuItem>
              {
                selectedChat?.isGroup == "1" &&
                <MenuItem onClick={() => { setCurrGroupID(selectedChat.id); setOpenAddParticipants(true); closeChatMenu(); }}>
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText>Agregar participantes</ListItemText>

                </MenuItem>

              }

            </MenuList>
          </Menu>
          <Divider />
          {
            isLoading ?
              <Grid
                container
                direction={"row"}
                sx={{ padding: '20px', height: '100%' }}
                alignItems="center"
                justify="center"
              >

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  align="center"
                  sx={{ height: '100%' }}
                >
                  <div className='containerLoading'>
                    <div className='dot1'></div>
                    <div className='dot2'></div>
                    <div className='dot3'></div>
                  </div>
                </Grid>
              </Grid>
              :
              <List onScroll={handleScroll} style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '83%' }}>
                {
                  chats?.map((chat, key) => {


                    if (chat.pinned != '1') { return ''; }
                    return <ListItem selected={currentChat?.id == chat.id} onMouseLeave={hideChatMenu} onMouseEnter={() => showChatMenu(chat.id)} button={currentWindow != 0} onClick={() => { if (currentWindow != 0 && !showCheckBox) { onClick(chat, key) } }} disabled={isLoadingChats}>
                      <Grid container>

                        <Grid style={{ borderLeft: `0.3em solid`, borderLeftColor: chat.Client?.Status?.color ? chat.Client.Status.color : '', padding: '0.5em' }} item sx={3} md={3} lg={3}>
                          {
                            showCheckBox ?
                              <Checkbox onChange={(e) => { addRemoveChatFromSelect(chat.id, e.target.checked, chat) }} checked={isChatSelected(chat.id)} /> :
                              <ListItemIcon >
                                <Online
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                >
                                  <Avatar
                                    alt="Lucy Lavender"
                                    src="/static/img/avatars/avatar-1.jpg"
                                  />
                                </Online>

                              </ListItemIcon>

                          }


                        </Grid>
                        <Grid item sx={7} md={7} lg={7}>

                          <Badge sx={{ maxWidth: '100%', minWidth: '100%' }} badgeContent={chat.unreadCount} color="success">
                            <ListItemText className="texto-contenedor" primary={chat.Client?.firstName ? chat.Client.firstName : chat.name} secondary={chat.lastMessage} />
                          </Badge>
                          {currentWindow == 0 &&
                            <Button onClick={() => setAdviserToClient(chat)} fullWidth variant={"contained"}>Atender</Button>}



                        </Grid>
                        {
                          (chat.id == idShowChatMenu || chat.id == idKeepChatMenu) ?
                            <Grid item sx={2} md={2} lg={2}>
                              <IconButton onClick={(e) => { e.stopPropagation(); openChatMenu(chat, e); }} fullWidth sx={{ height: '100%' }}>
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                            :
                            <Grid item sx={2} md={2} lg={2}>
                              <IconButton fullWidth sx={{ height: '100%' }}>
                                <PushPin fontSize="small" />
                              </IconButton>
                            </Grid>
                        }

                        <Grid item sx={12} md={12} lg={12}>
                          <div style={{ overflowY: 'hidden', overflowX: 'hidden', maxHeight: '30px', }}>
                            <Button
                              startIcon={<WhatsApp style={{ color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF' }} fontSize="small" />}
                              variant="outlined"
                              size="small"
                              sx={{
                                // color: getContrastColor(chat.WpSession?.color ? chat.WpSession.color : '#000000'),
                                borderColor: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                              }} >
                              {chat.WpSession.name}
                            </Button>
                            {
                              chat?.Client?.Status?.name &&
                              <Button

                                variant="outlined"
                                size="small"
                                sx={{

                                  borderColor: chat.Client.Status.color ? chat.Client.Status.color : '#FFFFFF',
                                  color: chat.Client.Status?.color ? chat.Client.Status.color : '#FFFFFF',
                                }} >
                                {chat.Client.Status.name}
                              </Button>
                            }
                            {
                              chat.Client?.Tags.map((tag) => {
                                return <Chip key={tag.name} label={tag.name} sx={{ color: 'white', background: tag?.color ? tag.color : 'black' }} />
                              })
                            }
                          </div>
                        </Grid>

                      </Grid>
                    </ListItem>
                  })
                }
                {
                  chats?.map((chat, key) => {


                    if (chat.pinned == '1') { return ''; }
                    return <ListItem selected={currentChat?.id == chat.id} onMouseLeave={hideChatMenu} onMouseEnter={() => showChatMenu(chat.id)} button={currentWindow != 0} onClick={() => { if (currentWindow != 0 && !showCheckBox) { onClick(chat, key) } }} disabled={isLoadingChats}>
                      <Grid container>

                        <Grid style={{ borderLeft: `0.3em solid`, borderLeftColor: chat.Client?.Status?.color ? chat.Client.Status.color : '', padding: '0.5em' }} item sx={3} md={3} lg={3}>
                          {
                            showCheckBox ?
                              <Checkbox onChange={(e) => { addRemoveChatFromSelect(chat.id, e.target.checked, chat) }} checked={isChatSelected(chat.id)} /> :
                              <ListItemIcon >
                                <Online
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                >
                                  <Avatar
                                    alt="Lucy Lavender"
                                    src="/static/img/avatars/avatar-1.jpg"
                                  />
                                </Online>
                              </ListItemIcon>

                          }


                        </Grid>
                        <Grid item xs={7} md={7} lg={7}>

                          <Badge sx={{ maxWidth: '100%', minWidth: '100%' }} badgeContent={chat.unreadCount} color="success">
                            <ListItemText className="texto-contenedor" primary={chat.Client?.firstName ? chat.Client.firstName : chat.name} secondary={chat.lastMessage} />
                          </Badge>
                          {currentWindow == 0 &&
                            <Button onClick={() => setAdviserToClient(chat)} fullWidth variant={"contained"}>Atender</Button>}



                        </Grid>
                        {
                          (chat.id == idShowChatMenu || chat.id == idKeepChatMenu) && <Grid item sx={2} md={2} lg={2}>
                            <IconButton onClick={(e) => { e.stopPropagation(); openChatMenu(chat, e); }} fullWidth sx={{ height: '100%' }}>
                              <ArrowDropDown />
                            </IconButton>
                          </Grid>
                        }


                        <Grid item sx={12} md={12} lg={12}>
                          <div style={{ overflowY: 'hidden', overflowX: 'hidden', maxHeight: '30px', }}>
                            <Button
                              startIcon={<WhatsApp style={{ color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF' }} fontSize="small" />}
                              variant="outlined"
                              size="small"
                              sx={{
                                // color: getContrastColor(chat.WpSession?.color ? chat.WpSession.color : '#000000'),
                                borderColor: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                              }} >
                              {chat.WpSession.name}
                            </Button>
                            {
                              chat?.Client?.Status?.name &&
                              <Button

                                variant="outlined"
                                size="small"
                                sx={{

                                  borderColor: chat.Client.Status.color ? chat.Client.Status.color : '#FFFFFF',
                                  color: chat.Client.Status?.color ? chat.Client.Status.color : '#FFFFFF',
                                }} >
                                {chat.Client.Status.name}
                              </Button>
                            }

                            {
                              chat.Client?.Tags.map((tag) => {
                                return <Chip label={tag.name} sx={{ color: getContrastColor(tag?.color ? tag.color : '#000000'), background: tag?.color ? tag.color : 'black' }} />
                              })
                            }
                          </div>
                        </Grid>



                      </Grid>
                    </ListItem>
                  })
                }

              </List>
          }

        </Grid>

        {
          (isLoading || isLoadingChats || currentWindow == 0) && !filters.phone ?
            <Grid item sx={{ height: '100%' }} xs={12} md={9} lg={9}>
              <Grid
                container


                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >

                {
                  currentWindow == 0 ?
                    <span>
                      Selecciona un cliente para poder chatear con él
                    </span>
                    :
                    <span>
                      Cargando{isLoadingChats ? ' mensajes. Si es la primera vez que abre este chat puede tardar unos minutos' : ''}, por favor espere
                    </span>
                }


              </Grid>
            </Grid>
            :
            <Grid item sx={{ height: '100%' }} xs={12} md={currentChat && !showNewMessageModal ? 7 : 8} lg={currentChat && !showNewMessageModal ? 7 : 9}>

              {showNewMessageModal ?
                <ChatMessages ref={bottomEl}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%' }}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid sx={{ minHeight: '150px' }} item xs={3} md={3} lg={3}>
                          <ThreeP className="new-chat-icon" />
                        </Grid>
                        <Grid sx={{ minWidth: '25%' }} item xs={3} md={3} lg={3}>

                          <TextField
                            value={currentChat?.WpSession?.sessionAuth}
                            select
                            fullWidth
                            label="Selecciona un whatsapp"
                            name="type"
                            variant="outlined"
                            onChange={(event) => { handleChangeCurrChat(null, event.target.value) }}

                          >
                            {
                              sessions?.map((session) => (
                                <MenuItem key={session.id} value={session.sessionAuth}>
                                  {session.name}
                                </MenuItem>
                              ))
                            }
                          </TextField>

                        </Grid>
                        <Grid item sx={{ minWidth: '25%' }} xs={3} md={3} lg={3}>

                          <PhoneInput
                            country={navigator.language || navigator.userLanguage}
                            enableSearch={true}
                            placeholder="Ingresa el número"
                            value={currentChat?.phone}
                            onChange={(phone) => { handleChangeCurrChat(phone, null) }} />


                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
                </ChatMessages>
                :
                <ChatMessages ref={bottomEl}>
                  {

                    currentMessages.map((m, key) => {

                      return (
                        <ChatMessageComponent
                          messageObj={m}
                          name={m.name}
                          avatar="/static/img/avatars/avatar-2.jpg"
                          message={m.body}
                          isGroup={currentChat.isGroup == "1"}
                          //time={m.timestamp.split(' ')[1]}
                          time={m.timestamp.replace('T', ' ').substring(0, 16)}
                          time2={`${m.timestamp.replace(' ', 'T')}-05:00`.substring(0, 25).replace('000Z-', '000Z')}
                          position={m.fromMe != 1 ? "left" : "right"}
                          author={m.author}
                        />
                      )

                    })

                  }

                  <p>{scrollToBottomDelay()}</p>
                </ChatMessages>
              }
              <Divider />
              {
                (currentMessages?.length > 0 || showNewMessageModal) &&
                <ChatInputMessage

                  templates={templates}
                  currentMessage={currentMessage}
                  filterNameValue={filterNameValue}
                  setFilterNameValue={setFilterNameValue}
                  sendMessage={sendMessage}
                  currentChat={currentChat}
                  attachedFiles={attachedFiles}
                  setAttachedFiles={setAttachedFiles}

                />
              }


            </Grid>
        }

        {currentChat && !showNewMessageModal &&
          <ClientInfo
            products={products}
            status={status}
            chat={currentChat}
            onSave={saveChatClientInfo}
          />
        }
      </Grid >


      <AddTagmodal
        title={"Asignar etiquetas"}
        open={openTags}
        setOpen={setOpenTags}
        selectedChats={selectedInfoChats}
        selectedChatIDs={selectedChats}
        relaod={() => { setReload(!reload); closeActionMenu() }}
      //  onSave={addTagToClients}
      />


      <AddProductModal
        title={"Asignar productos"}
        open={openProducts}
        setOpen={setOpenProducts}

        onSave={addProductToClients}
      />

      <AddStatusModal
        title={"Asignar estatus"}
        open={openStatus}
        setOpen={setOpenStatus}

        onSave={addStatusToClient}
      />



      <MassiveMsgModal

        forwardingId={0}
        open={openMassiveMessage}
        setOpen={setOpenMassiveMessage}
        title={"Envio masivo"}
        whatsapp={false}
        contactsExternal={getPhones()}
        reloadMain={() => { setOpenMassiveMessage(false); setReload(!reload); }}
        scheduled={scheduleMsg}
        isSequence={isSequence}
      />

      <ShareModal
        open={openShareModal}
        setOpen={setOpenSharemodal}
        theme={null}
        title={"Compartir chat"}
        users={advisers}
        onSave={shareChat}
      />

      <AddparticipantToGroupModal
        open={openAddParticipants}
        setOpen={setOpenAddParticipants}
        idGroup={currGroupID}
        title={"agregar participantes"}
      />
    </>
  );
}

export default Chat2;
