import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { Autocomplete, Box, Button, Fab, Grid, TextField } from '@mui/material';
import { AttachFile, Delete, Send, SentimentSatisfied } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import api from '../../services/api';
import AddFilesModal from '../AddFIlesModal';

import EmojiPicker from 'emoji-picker-react';
import { AudioRecorder as AudioRecorder2 } from 'react-audio-voice-recorder';

import { useAudioRecorder } from 'react-audio-voice-recorder';

import './index.css';

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;


const ChatInputMessage = React.memo(({ sendMessage, isFromFB = false, templates, currentMessage, newMessage, vertical = false }) => {

    const [filterNameValue, setFilterNameValue] = useState('');
    const [message, setMessage] = useState('');
    const [clearAutomcomplete, setClearAutocomplete] = useState(false)

    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [attachedFiles, setAttachedFiles] = useState([]);

    const [openFiles, setOpenFiles] = useState(false);

    const [showEmoji, setShowEmojji] = useState(false);

    const recorderControls = useAudioRecorder()



    const obtenerValorDespuesDeBarra = (value) => {
        const lastIndex = value.lastIndexOf('/');
        if (lastIndex !== -1) {
            return value.slice(lastIndex + 1);
        }
        return '';
    };

    const clearValue = () => {
        sendMessage(message, filePath, attachedFiles);
        setMessage('');
        setFile(null);
        setFilePath(null);
        setAttachedFiles([]);
        setClearAutocomplete(!clearAutomcomplete);
    }

    const handleClick = () => {
        setOpenFiles(true);
        //  const fileInput = document.getElementById('fileInput');
        //fileInput.click();
    };

    /**
      * Sube un archivo 
      * @param {*} file 
      */
    const addAudioFile = async (fileToUpload, name) => {

        setIsLoading(true)
        /**
         * Envia el archivo para subirlo
         */
        const formData = new FormData();

        if (name) {
            formData.append('audio', fileToUpload, name)
        } else {
            formData.append('audio', fileToUpload)
        }


        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            const { data } = await api.patch('/api/upload/audio', formData, config);

            /**
             * Recupera la ruta del archivo subido
             */
            setFilePath(data.fielName);
        } catch (err) {
            console.log(err);
            alert(`Ocurrió un error al subir el archivo de audio`)

        }
        setIsLoading(false)
    }

    /**
      * Elimina un archivo 
      * @param {*} file 
      */
    const removeAudioFile = async (fileName) => {

        setIsLoading(true)
        /**
         * Envia el archivo para eliminarlo
         */

        try {
            const { data } = await api.patch('/api/deleteFile/audio', { fileName: fileName });

            /**
             * Recupera la ruta del archivo subido
             */
            setFilePath(null);
        } catch (err) {
            console.log(err);
            alert(`Ocurrió un error al eliminar el archivo de audio`)

        }
        setIsLoading(false)
    }


    /**
     * Agrega los archivos adjuntos
     * @param {*} products 
     */
    const attachFiles = (files) => {
        setOpenFiles(false);
        setAttachedFiles(files);
    }

    const detachFiles = () => {
        setAttachedFiles([]);
    }



    const addAudioElement = async (blob) => {
        await addAudioFile(blob, "Whatsapp audio.mp3")


    };
    return (
        <ChatInput container>

            {attachedFiles.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition="start"

                    size="small"
                    variant="outlined"
                    endIcon={<Delete onClick={
                        (e) => {
                            detachFiles();
                            e.stopPropagation();

                        }
                    } />}
                    fullWidth
                >
                    {`Hay ${attachedFiles.length} archivo${attachedFiles.length != 1 ? 's' : ''} adjunto${attachedFiles.length != 1 ? 's' : ''}`}
                </LoadingButton>
            </Grid>}

            {filePath && <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition="start"

                    size="small"
                    variant="outlined"
                    endIcon={<Delete onClick={
                        async (e) => {
                            await removeAudioFile(filePath)
                            e.stopPropagation();

                        }
                    } />}
                    fullWidth
                >
                    {`Audio adjunto ${filePath}`}
                </LoadingButton>
            </Grid>}
            < Grid item style={{ flexGrow: 1 }} >

                <Autocomplete
                    key={clearAutomcomplete}
                    filterOptions={(options, state) => options}
                    freeSolo

                    open={filterNameValue !== ''}
                    options={templates.filter((template) =>
                        template?.name.toLowerCase().indexOf(filterNameValue.toLocaleLowerCase()) >= 0
                    )}
                    renderInput={(params) => {
                        if (params?.inputProps) {

                            params.inputProps.value = message;
                        }

                        if (!message) {
                            params.inputProps.value = '';
                        }
                        return (



                            <TextField


                                value={message}
                                ref={currentMessage}
                                onChange={(e) => {

                                    const filterV = obtenerValorDespuesDeBarra(e.target.value);
                                    setFilterNameValue(filterV);
                                    setMessage(e.currentTarget.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.stopPropagation();
                                    }
                                }}
                                maxRows={3}
                                InputProps={{
                                    style: {
                                        maxHeight: '60px',
                                        overflow: 'auto',
                                    },
                                }}
                                multiline
                                {...params}
                                label="Escribe tu mensaje"
                            />




                        )
                    }}
                    renderOption={(props, option) => {
                        if (option?.name) {
                            return <li {...props}>{option.name}</li>;
                        }
                        return null;
                    }}
                    selectOnFocus={false}
                    getOptionLabel={(option) => option?.message || ''}
                    fullWidth
                    onChange={(event, value) => {
                        setFilterNameValue('');
                        setMessage(value.message)
                    }}

                />

            </Grid>
            {
                vertical && <Grid item style={{ minHeight: '10px' }} xs={12} sm={12} md={12} lg={12}>  </Grid>
            }


            {!isFromFB && !recorderControls.isRecording && <Grid item>
                <Box ml={2}>
                    <Fab color="primary" aria-label="add" size="medium" onClick={() => setShowEmojji(!showEmoji)}>
                        <SentimentSatisfied />
                    </Fab>

                </Box>

            </Grid>}

            {!isFromFB && !recorderControls.isRecording &&
                <Grid item>
                    <Box ml={2}>
                        <Fab color="primary" aria-label="add" size="medium" onClick={handleClick}>
                            <AttachFile />
                        </Fab>

                    </Box>
                </Grid>
            }
            {!isFromFB &&!filePath && <Grid item>
                <Box ml={2}>
                    <Fab color="primary" aria-label="add" size="medium" style={{ width: "fit-content", borderRadius: '20px' }}>
                        <AudioRecorder2
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                                noiseSuppression: false,
                                echoCancellation: false,
                            }}
                            style={{ height: '50px' }}
                            //downloadOnSavePress={true}
                            //downloadFileExtension="mp3"
                            classes={{
                                AudioRecorderStartSaveClass: 'audio-recorder-svg-color',
                                AudioRecorderPauseResumeClass: 'audio-recorder-svg-color',
                                AudioRecorderDiscardClass: 'audio-recorder-svg-color',
                            }}
                        //recorderControls={recorderControls}
                        //showVisualizer={false}
                        />
                    </Fab>
                </Box>
                        </Grid>}

            {!recorderControls.isRecording && <Grid item>
                <Box ml={2}>
                    <Fab disabled={isLoading} color="primary" aria-label="add" size="medium" onClick={() => { clearValue(); }}>
                        <Send />
                    </Fab>
                </Box>
            </Grid>}


            <AddFilesModal
                title={"Adjuntar archivos"}
                open={openFiles}
                setOpen={setOpenFiles}
                onSave={attachFiles}
            />
            {
                showEmoji && !recorderControls.isRecording &&
                <div
                    style={{
                        position: 'absolute',
                        bottom: '9%',
                        left: '53%'
                    }}
                >
                    <EmojiPicker

                        onEmojiClick={(emoji) => {

                            const newMessage = message + emoji.emoji;
                            setMessage(newMessage);
                            setShowEmojji(false)

                            // setClearAutocomplete(!clearAutomcomplete);

                        }}
                    />
                </div>
            }

        </ChatInput >
    )
}, (prevProps, nextProps) => {

    // Comparación personalizada para la propiedad específica
    return prevProps.currentChat === nextProps.currentChat;
})

export default ChatInputMessage;