function contrastingColor(color)
{
    let newColor = color.replace('#','');
    let resp = (luma(newColor) >= 165) ? '#000000' : '#ffffff';
    return resp;
}
function luma(color) // color can be a hx string or an array of RGB values 0-255
{
    var rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
    return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}
function hexToRGBArray(color)
{
  
    if (color.length === 3)
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    else if (color.length !== 6)
        throw('Invalid hex color: ' + color);
    var rgb = [];
    for (var i = 0; i <= 2; i++)
        rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}
function formatDateLocal(dateString) {
    
    // Crear una instancia de Date a partir del string
    const date = new Date(dateString);

    // Extraer los componentes de la fecha
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Meses son 0-11
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Formatear la fecha
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}
export const getContrastColor= contrastingColor;
export const formatDate= formatDateLocal;