import * as React from 'react';


import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import api from '../../services/api';
import { Breadcrumbs, Button, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import TemplateModal from '../../components/TemplateModal'
import { Helmet } from 'react-helmet-async';
import AddSequenceModal from '../../components/AddSequenceModal';





/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Sequences({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [sequences, setSequences] = useState([]);

  /**
   * Guarda las secuencias sleccionadas
   */
  const [selectedSequences,setSelectedSequences] = useState([]);

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);


  /**
   * Guarda el tepmlate seleccionado
   */
  const [current, setCurrent] = useState({});

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();




  const [filterName, setFilterName] = useState(null);
  /**
   * Carga la info necesaria
   */
  React.useEffect(() => {

    setOpen(false);
    setFilterName(null);

    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");
        setPermissionsUser(userInfo.data.userInfo.permissions)

      } catch (err) {
        console.log(err)
      }
    }

    async function getSequences() {

      try {

        const { data } = await api.post("/api/sequence/get");
        setSequences(data.scheduleSequenceList);

      } catch (err) {
        console.log(err)
      }
    }

    getSequences();
    getSessions();

  }, [reload]);


  /**
   * Carga los templates filtrados
   */
  React.useEffect(() => {


    async function getSequences() {

      try {


        const { data } = await api.post("/api/sequence/get", { name: filterName });
        setSequences(data.scheduleSequenceList);

      } catch (err) {
        console.log(err)
      }
    }

    getSequences();


  }, [filterName]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }

    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      headerName: 'Editar', field: 'edit', flex: 1, hide: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => { setCurrent(params.row); setOpen(true) }}>Editar</Button>

        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'status',
      headerName: 'Estatus',
      flex: 1,
      editable: false,
    },

  
  ];

 
  /**
   * Crea una nueva plantilla
   * @param {*} data 
   */
  const addTemplate = async (newTemplate, files, words) => {


    try {


      const request = {
        id: newTemplate.id && newTemplate.id != 0 ? newTemplate.id : 0,
        templateObj: newTemplate,
        words: words

      }


      const { data } = await api.post("/api/createTemplate", request);


      if (data.status == "Success") {

        let idTemplateTemp = data?.template?.id;

        if (!newTemplate.id || newTemplate.id == 0) {
          try {

            //  console.log({ id:idTemplateTemp, files: files});
            const { data } = await api.post("/api/addTempFile", { id: idTemplateTemp, files: files });

            if (data.status == "Success") {
              // console.log(data);

            } else {
              enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
                variant: 'error',
              });
              console.log(data);
            }
          } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
              variant: 'error',
            });

          }
        }
        enqueueSnackbar('Se creó la plantilla', {
          variant: 'success',
        });


      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(`Ocurrió un error al crear la plantilla`, {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear la plantilla', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }


  return (
    <>

      <Helmet title="Plantillas" />

      <Typography variant="h3" gutterBottom display="inline">
        Plantillas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Secuencias de envio</Link>
        {
          revisaPermisos(['Agregar Plantilla']) &&
          <Button variant='outlined' onClick={() => { setOpen(!open); setCurrent({}) }} >Nueva secuencua</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          align="center"
          sx={{ height: '10%' }}
        >
          <TextField
            label="Buscar secuencia de envio"
            fullWidth
            onChange={(e) => { setFilterName(e.target.value) }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          align="center"
          sx={{ height: '10%' }}
        >
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '90%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={sequences}
            checkboxSelection
            showDelete={false}
            onDelete={(data) => { }}
            onsSelectionChange={(data) => { setSelectedSequences(data) }}
            showShareButton={false}
            onShare={() => {}}
          />
          {
            revisaPermisos(['Agregar Plantilla']) &&
            <AddSequenceModal 
              open={open}
              relaod={()=>setReload(!reload)}
              setOpen={setOpen}
              theme={null}
              title={"Nueva secuencia"}
              selectedSequence={current}
            />
          }

        </Grid>
      </Grid>
    </>
  );
}
