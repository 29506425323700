import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import Papa from "papaparse";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import api from "../../services/api";
import MultiSelect from "../MultiSelect";

const AddGoogleContactsModal = ({ open, setOpen, title, theme }) => {

    const [id, setId] = useState(0);

    const [file, setFile] = useState(null);

    const [phoneList, setPhoneList] = useState([]);

    const [stringPhoneList, setStringPhoneList] = useState("550000000\n550000001");
    const [stringNameList, setStringNameList] = useState("Contacto1\nContacto2");

    const [isLoading, setIsLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const [isPlainText, setIsPlainText] = useState(false);

    const [currentTags, setCurrentTags] = useState([]);
    const [tags, setTags] = useState([]);

    
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);


    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        setPhoneList([])
    }, [isPlainText])

    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }
        async function getTags() {
            /**
                   * Consulta llas etiquetas
                   */
            const dataTags = await api.post("/api/v2/getUserTags", {});

            if (dataTags.data.status == "Success") {
                setTags(dataTags.data.tags)
                //console.log(dataTags.data.tags);
            }
        }
        getTags()
        getaccounts();

        async function getProducts() {
            try {

                const { data } = await api.post("/api/getProducts");



                if (data.status == "Success") {

                    setProducts(data.products);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los productos", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });

            }
        }
        getProducts();
        async function getstatus() {
            try {
                const { data } = await api.post("/api/getStatus");


                if (data.status == "Success") {

                    setStatus(data.statusList);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los estatus", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });
            }
        }
        getstatus()
    }, [])

    useEffect(() => {
        setIsLoading(false)
        setPhoneList([]);
        setFile(null);
        setId(null)
    }, [open])


    const handleChangeFile = (fileTemp) => {

        const reader = new FileReader();


        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            let phoneObjects = [];
            csv?.data.map((obj, index) => {
                let newContact = {};

                if (obj.phone) {
                    newContact.phone = obj.phone;
                }
                if (obj.name) {
                    newContact.name = obj.name;
                }
                obj.id = index;

                if (obj.phone && obj.name) {
                    phoneObjects.push(newContact)
                }

            })

            if (phoneObjects.length == 0) {
                enqueueSnackbar("No se encontraron contactos para agregar", {
                    variant: 'warning',
                });
                setFile(null)
            }

            setPhoneList(phoneObjects);
        };
        setFile(fileTemp)
        reader.readAsText(fileTemp);


    }

    const addContacts = async () => {

        if (!id || id == 0) {
            enqueueSnackbar("Debe seleccionar una cuentar", {
                variant: 'warning',
            });
            return false;
        }

        setIsLoading(true);
        try {

            const { data } = await api.post('/google/addContacts', {
                id: id, contacts: phoneList, product: selectedProducts,
                status: selectedStatus,
            })


            if (data.status == "Success") {
                enqueueSnackbar("Se agregaron los contactos correctamente", {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar("No se encontraron contactos para agregar", {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al agregar los contactos", {
                variant: 'error',
            });
        }
        setIsLoading(false);
        handleClose();
    }


    const handleClose = () => {
        setOpen(false);

    }

    const getButtonColorByStatus = (status) => {

        if (status == "Pendiente") {
            return 'secondary';
        }
        if (status == "Error") {
            return 'error';
        }
        if (status == "Exitoso") {
            return 'success';
        }
    }

    const handleChangeContacts = (contacsString, nameStrings) => {
        const data = contacsString?.split("\n");
        const names = nameStrings?.split("\n");
        let contacstList = [];
        data?.map((contacInfo, index) => {
            contacstList.push({
                phone: contacInfo,
                name: names.length >= index + 1 ? names[index] : "",
            })


        })
        setPhoneList(contacstList)
        setStringPhoneList(contacsString);
        setStringNameList(nameStrings);
    }
    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}  <FormControlLabel control={<Checkbox
                    checked={isPlainText}
                    onChange={(e) => setIsPlainText(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }} />} label="Pegar contactos" />

            </DialogTitle >

            <DialogContent dividers>

                <Grid container spacing={2}>


                    {
                        ((phoneList.length > 0) || isLoading) &&
                        <Grid
                            sx={{ maxHeight: '250px', minHeight: '250px', overflowY: 'scroll' }}
                            item xs={12} sm={12} md={12} lg={12}>

                            {
                                phoneList.map((phone, index) => (
                                    <Grid key={index} container >
                                        <Grid xs={6} sm={6} md={6} lg={6}>
                                            <p>{phone.name}</p>

                                        </Grid>
                                        <Grid xs={phone.comment ? 3 : 6} sm={phone.comment ? 3 : 6} md={phone.comment ? 3 : 6} lg={phone.comment ? 3 : 6}>
                                            <Button variant='' fullWidth> {phone.phone}</Button>

                                        </Grid>
                                        <Grid xs={phone.comment ? 3 : 6} sm={phone.comment ? 3 : 6} md={phone.comment ? 3 : 6} lg={phone.comment ? 3 : 6}>
                                            <Button color={getButtonColorByStatus(phone.status)} fullWidth>{phone.status}</Button>

                                        </Grid>


                                    </Grid>
                                ))
                            }

                        </Grid>
                    }

                    {
                        !isLoading &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Cuenta de Google"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth

                            // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                            >
                                {accounts?.map((account) => (
                                    <MenuItem key={account.id} value={account.id}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }
                    {<Grid item xs={12} sm={12} md={12} lg={12}>
                        <MultiSelect

                            title="Etiquetas"

                            list={tags}
                            onChange={(data) => { setCurrentTags(data) }}
                            initSelect={currentTags}

                        />
                    </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField

                            select
                            label="Producto"
                            value={selectedProducts}
                            onChange={(e) => setSelectedProducts(e.target.value)}
                            fullWidth
                            color="primary"
                           
                        >
                            {products?.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField

                            select
                            label="Estatus"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}

                            color="primary"
                            fullWidth
                        >
                            {status?.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    {
                        !isLoading && !isPlainText &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                fullWidth
                                variant="outlined"
                                component="label"
                                sx={{ minHeight: phoneList.length > 0 ? '10px' : '300px' }}
                            >
                                {
                                    file?.name ? file.name : "Subir archivo"
                                }
                                <input
                                    type="file"
                                    multiple={false}
                                    hidden
                                    onChange={(e) => { handleChangeFile(e.target.files[0]) }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                            </Button>
                        </Grid>
                    }
                    {
                        !isLoading && isPlainText &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField
                                fullWidth
                                value={stringPhoneList}
                                label="Teléfonos"
                                name="phones"
                                variant="outlined"
                                onChange={(e) => { handleChangeContacts(e.target.value, stringNameList) }}
                                multiline
                                rows={6}

                            />

                        </Grid>
                    }

                    {
                        !isLoading && isPlainText &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField
                                fullWidth
                                value={stringNameList}
                                label="Nombres"
                                name="names"
                                variant="outlined"
                                onChange={(e) => { handleChangeContacts(stringPhoneList, e.target.value) }}
                                multiline
                                rows={6}

                            />

                        </Grid>
                    }

                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <div style={{ textAlign: "center" }}>
                            <a href={`${process.env.REACT_APP_BACK_URL}/media/contacts.csv`}>Descargar ejemplo</a>
                        </div>

                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                    Cancelar
                </Button>
                <LoadingButton theme={theme} loading={isLoading} variant="contained" color="primary" onClick={() => addContacts()}>

                    Agregar contactos

                </LoadingButton>
            </DialogActions>
        </Dialog>

    )

}

export default AddGoogleContactsModal;