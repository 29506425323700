import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';



export default function FilesModal({ title, open, setOpen, theme, files, addFileLocal,removeFileLocal }) {


    useEffect(() => {

        setFilesLocal(files)
    }, [files]);

    const [filesLocal,setFilesLocal] = useState([])

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {
                            filesLocal?.map((fileT, key) => {

                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} align="center" >

                                        <Button
                                            key={key}
                                            variant="contained"

                                            style={{ width: '50%' }}

                                        >
                                            {fileT.name ? fileT.name : ''}
                                            {fileT.path ? fileT.path : ''}
                                        </Button>
                                        <Button style={{ width: '5%' }} onClick={() => { removeFileLocal(key) }} variant="outlined" startIcon={<Delete />}></Button>


                                    </Grid>
                                )

                            })
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        variant="outlined"
                        component="label"
                    >
                        {
                            "Subir archivo"
                        }
                        <input
                            type="file"
                            multiple={false}
                            hidden
                            onChange={(e) => { addFileLocal(e.target.files[0]) }}
                        />
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
