import { useEffect, useState } from "react";
import api from "../../../services/api";
import { Alert, Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";

export default function PageSelect({ index, extSelectedBook, extSelectedPage, changeData }) {

    const [books, setBooks] = useState([]);
    const [pages, setPages] = useState([]);


    const [selectedBook, setSelectedBook] = useState(extSelectedBook);
    const [selectedPage, setSelectedpage] = useState(extSelectedPage);

    const [isNewBook, setIsNewBook] = useState(false);
    const [isNewPage, setIsNewPage] = useState(false);

    useEffect(() => {
        changeData(index, selectedBook, selectedPage, isNewBook, isNewPage)
    }, [selectedBook, selectedPage])


    useEffect(() => {
        async function getBooks() {
            try {

                const { data } = await api.get("/api/books");

                if (data.status == "Success") {
                    setBooks(data.books);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }
        getBooks()
    }, [])
    useEffect(() => {

        async function getPages() {
            try {

                const { data } = await api.post('/api/getPages', { bookID: selectedBook.id });
                setPages(data.pages);
            } catch (err) {

            }
        }

        if (selectedBook && !isNewBook) {
            getPages();
        }

    }, [selectedBook])

    useEffect(() => {
        if (isNewBook) {
            setIsNewPage(isNewBook)
        }
        setSelectedBook('')
    }, [isNewBook])

    useEffect(() => {
        setSelectedpage('')
    }, [isNewPage])
    return (
        <Grid contanier item spacing={2} xs={12}>
            {
                books?.length > 0 &&
                <Grid container item xs={12}>
                    <Grid item xs={10}>
                        {
                            isNewBook ?
                                <TextField fullWidth onChange={(e) => setSelectedBook(e.target.value)} label="Escribe el nombre del libro" variant="standard" />
                                :
                                <Autocomplete
                                    options={books}
                                    getOptionLabel={(option) => option.name}
                                    id="forms"
                                    clearOnEscape
                                    onChange={(event, newValue) => {

                                        if (newValue) {
                                            setSelectedBook(newValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Selecciona un libro" variant="standard" />
                                    )}
                                />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox checked={isNewBook} onChange={(e) => setIsNewBook(e.target.checked)} />}
                            label="Nuevo"
                        />
                    </Grid>
                </Grid>

            }
            {
                (pages?.length > 0 || isNewPage) &&
                <Grid container item>
                    <Grid item xs={10}>
                        {
                            isNewPage ?
                                <TextField fullWidth onChange={(e) => setSelectedpage(e.target.value)} label="Escribe el nombre de la hoja" variant="standard" />
                                :
                                <Autocomplete
                                    options={pages}
                                    getOptionLabel={(option) => option.name}
                                    id="forms"
                                    clearOnEscape
                                    onChange={(event, newValue) => {

                                        if (newValue) {
                                            setSelectedpage(newValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Selecciona una hoja" variant="standard" />
                                    )}
                                />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox checked={isNewPage} onChange={(e) => setIsNewPage(e.target.checked)} />}
                            label="Nueva"
                        />
                    </Grid>
                </Grid>
            }
            {

                books?.length == 0 &&
                <Grid item>
                    <Alert severity="warning">
                        No tienes libros creados
                    </Alert>
                </Grid>
            }

        </Grid>
    )
}