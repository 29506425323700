import { Helmet } from "react-helmet-async";
import TablaUsuarios from "../../components/TablaUsuarios/TablaUsuarios";
import { Breadcrumbs, Button, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { CopyAll } from "@mui/icons-material";
import QuotePreview from "../../components/NewQuote/QuotePreview";
import QuoteModal from "../../components/NewQuote/QuoteModal";



const getQuoteUrl = (id) => {
    const urlBase = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    return `${urlBase}/quotes/${id}`;

}

export default function QuotesList() {
    const [quotes, setQuotes] = useState([])
    const [selectedQuote, setSelectedQuote] = useState(null)


    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(false);

    const [searchParam, setSearchParam] = useState(null);
    async function getQuotes(param) {
        try {
            if(param){
                const { data } = await api.get(`/api/quotes?name=${param}`)
                setQuotes(data.quotes)
            }else{
                const { data } = await api.get(`/api/quotes`)
                setQuotes(data.quotes)
            }
           
        } catch (err) {

        }
    }

    useEffect(() => {
        getQuotes(searchParam);
    }, [reload, searchParam])
    const columns = [
        {
            field: 'edit', headerName: 'Editar', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    <Button onClick={() => {
                        setSelectedQuote(params.row);
                        setOpen(true);
                    }}>Editar</Button>
                )
            }
        },
        {
            field: 'name', headerName: 'Nombre', flex: 1, editable: false,
        },
        {
            field: 'company', headerName: 'Empresa', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    params.row?.Company?.name || 'Sin compañia'
                )
            }
        },
        {
            field: 'address', headerName: 'Dirección', flex: 1, editable: false,
        },
        {
            field: 'url', headerName: 'Link', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    <Button onClick={
                        () => {
                            navigator.clipboard.writeText(getQuoteUrl(params.row.id)).then(() => {
                                alert("Se copió la url")
                            }).catch(err => {
                                console.error('Error al copiar el texto: ', err);
                            });
                        }
                    } style={{ height: '100%' }} variant="outlined" startIcon={<CopyAll />}></Button>
                );
            }
        },

    ]

    return (
        <>
            <Helmet title="Facturas" />

            <Typography variant="h3" gutterBottom display="inline">
                Facturas
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Lista
                </Link>
            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Grid
                container
                sx={{ padding: '20px', height: '80vh' }}
                alignItems="center"
                justify="center"
            >
                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    align="center"
                    sx={{ height: '10%' }}
                >
                    <TextField
                        label="Buscar cotización"
                        fullWidth
                        onChange={(e) => { setSearchParam(e.target.value) }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '90%' }}
                >


                    <TablaUsuarios
                        columns={columns}
                        rows={quotes}
                        showDelete={false}
                        onDelete={(data) => { }}

                    />

                </Grid>
            </Grid>
            {
                selectedQuote &&
                <QuoteModal
                    id={selectedQuote.id}
                    client={{}}
                    externalCompany={selectedQuote.Company}
                    externalProducts={selectedQuote.QuoteItems}
                    externalQuote={selectedQuote}
                    open={open}
                    externalTemplate={1}
                    setOpen={setOpen}
                    callBack={() => setReload(!reload)}
                />
            }

        </>
    )
}