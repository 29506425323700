import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';

export default function MoveModal({ title, open, setOpen, onSave, theme, currentDatabaseID }) {


    const [currentDatabase, setCurrentDatabase] = useState(null);
    const [dataBasesList, setDataBasesList] = useState([]);


    useEffect(() => {
        setCurrentDatabase(null);

        async function getDatabases() {
            try {
                const { data } = await api.post("/api/groupList");
                setDataBasesList(data.groups);
            } catch (err) {
                console.log(err);
            }
        }

        getDatabases();
    }, [])

    const handleClose = () => {
        setOpen(false);
    }



    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                select
                                value={currentDatabase}
                                label="Selecciona una base de datos"
                                name="type"
                                variant="outlined"
                                onChange={(event) => { setCurrentDatabase(event.target.value); }}

                            >
                                {dataBasesList?.map((option) => {

                                    if (option.id != currentDatabaseID) {
                                        return (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    }

                                })}
                            </TextField>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { onSave(currentDatabase); }}>

                        Mover datos

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
