import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';
import MultiSelect from '../MultiSelect';

export default function AddProductModal({ title, open, setOpen, onSave, theme }) {


    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);

    useEffect(() => {
        setProducts(null);

        async function getProducts() {
            try {
                const { data } = await api.post("/api/getProducts");
                setProducts(data.products);
            } catch (err) {
                console.log(err);
            }
        }

        getProducts();
    }, [])

    const handleClose = () => {
        setOpen(false);
    }




    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Productos"
                                value={selectedProducts}
                                onChange={(e) => setSelectedProducts(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth
                                sx={{ width: '80%' }}
                            // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                            >
                                {products?.map((product) => (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { onSave(selectedProducts); }}>

                        Asignar productos

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
