import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import './styles.css'
import api from '../../services/api';
import { useSnackbar } from 'notistack';

const CalendarEventBox = ({ externalEventID, externalAccountID, event, name, description, startDate, endDate, link, onCancel, allowCancel }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [eventID, setEventID] = useState(null);
    const [accountID, setAccountID] = useState(null);


    const [open, setOpen] = useState(false);
    useEffect(() => {
        console.log(event)
    }, [])

    useEffect(() => {
        setEventID(externalEventID);
    }, [externalEventID])

    useEffect(() => {
        setAccountID(externalAccountID);
    }, [externalAccountID])

    const cancelEvent = async () => {
        if (!window.confirm(`¿Desea cancelar permanentemente la cita ${name}?`)) {
            return;
        }
        try {
            const { data } = await api.post('/api/googleCalendar/delete', { eventID, accountID })
            enqueueSnackbar("Se cancel+o el evento", {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar("Ocurrio un error al cancelar el evento", {
                variant: 'error',
            });
            return;
        }
        if (onCancel) {
            onCancel();
        }
    }

    const obtenerFechaYHoraEnEspañol = (date) => {
        const fecha = new Date(date);

        const opciones = {
            weekday: 'long',    // Nombre del día de la semana
            year: 'numeric',    // Año
            month: 'long',      // Nombre del mes
            day: 'numeric',     // Día del mes
            hour: 'numeric',    // Hora
            minute: 'numeric',  // Minutos
            second: 'numeric',  // Segundos
            hour12: false       // Formato de 24 horas
        };

        const fechaEnEspañol = new Intl.DateTimeFormat('es-ES', opciones).format(fecha);
        return fechaEnEspañol;
    };


    function openClientChat() {
        window.location.href = '/multiagente/chat?chatID=' + event.event.Client.Chat.id;
    }
    return (
        <>
            <div onClick={() => setOpen(true)}>
                <Grid container spacing={2} alignItems="center" >
                    <Grid item xs={2}>
                        <EventIcon className="icon" />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h6">{name}</Typography>
                    </Grid>

                </Grid>
            </div>
            <Dialog
                disableEnforceFocus
                onClose={() => setOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    Detalles del evento
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6">Titulo:</Typography>
                            <Typography variant="body1">{name}</Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Typography variant="h6">Descripción:</Typography>
                            <Typography variant="body1">{description}</Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Typography variant="h6">Fecha:</Typography>
                            {obtenerFechaYHoraEnEspañol(event.event.start)} -  {obtenerFechaYHoraEnEspañol(event.event.end)}
                        </Grid>

                        <Grid alignItems="center" item xs={12} sm={12} md={12} lg={12}>

                            <a href={link} target="_blank">Ir al meet</a>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography variant="h6">Correo:</Typography>
                            <Typography variant="body1">{event.event.Client.email}</Typography>

                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography variant="h6">Teléfono:</Typography>
                            <Typography variant="body1">{event.event.Client.phone}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} alignContent="center">

                            <Button disabled={!event.event.Client.Chat} onClick={() => { window.open('/multiagente/chat?chatID=' + event.event.Client.Chat.id, '_blank').focus(); }}>Ir al chat</Button>
                            
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} alignContent="center">

                            <Button disabled={event.event.Client.Chat!= null} onClick={() => { window.open('/multiagente/chat?chatID=' + event.event.Client.id + '&isClient=1', '_blank').focus(); }}>Crear nuevo chat</Button>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpen(false) }}>
                        Cerrar
                    </Button>
                    {allowCancel && <Button color="error" variant="contained" onClick={cancelEvent}>Cancelar cita</Button>}
                </DialogActions>
            </Dialog>
        </>

    );
};

export default CalendarEventBox;
