import * as React from 'react';


import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';

import { useState } from 'react';

import styled from 'styled-components';
import api from '../../services/api';

import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';

import { useSnackbar } from "notistack";
import { Edit, FileCopy, RestaurantRounded } from '@mui/icons-material';

import SpeechModal from '../../components/SpeechModal';
import { Helmet } from 'react-helmet-async';

/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Speech({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
  * Variable que recibirá el objeto de spechs
  */
  const [speechs, setSpeechs] = useState([]);

  /**
    * Variable que guarda el objeto seleccionado
    */
  const [currentSpeech, setCurrentSpeech] = useState({});

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);

    async function getSessions() {
      // console.log();
      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);


        const { data } = await api.post("api/getSpeech");

        console.log(data)
        setSpeechs(data.words);

      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [reload]);


  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'test', headerName: 'Editar', flex: 1, editable: false, renderCell: (params) => {
        // console.log(params.row.qr); 
        return (
          <Button
            onClick={() => {
              setCurrentSpeech(params.row);
              setOpen(!open)
            }}
          >
            Editar
          </Button>
        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1
    },
    {
      field: 'word', headerName: 'Palabra a sustituir', flex: 1, editable: false
    },
    //{ field: 'opc', headerName: 'Opciones', flex: 1, editable: false, renderCell: (params) => { return (<DeleteIcon></DeleteIcon>) } },
    {
      field: 'replace', headerName: 'Opciones', flex: 1, editable: false,
    },
  ];
  /**
   * Elimina un Speech por el id
   * @param {*} id 
   */
  const deleteSpeech = async (dataSpeechs) => {

    dataSpeechs.map(async (ss, key) => {
      try {

        const { data } = await api.put("api/deleteSpeech", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Speech eliminada', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar el Speech', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar el Speech', {
          variant: 'error',
        });
      }
    });


  }
  /**
   * Crea un nuevo Speech
   * @param {*} data 
   */
  const addSpeech = async (id, newSpeech) => {

    console.log(newSpeech);

    const request = {

      wordObj: newSpeech

    }

    /**
     * Si trae id actualiza
     */
    if (id) {
      request.id = id;
    }


    try {

      const { data } = await api.post("/api/createSpeech", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar(`Se  ${id ? 'actualizó' : 'creó'} el Speech`, {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar(`Ocurrió un error al ${id ? 'actualizar' : 'crear'} el Speech`, {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar(`Ocurrió un error al ${id ? 'actualizar' : 'crear'} el Speech`, {
        variant: 'error',
      });
    }

    setReload(!reload)
  }


  return (
    <>
      <Helmet title="Speach" />
      <Typography variant="h3" gutterBottom display="inline">
        Speach
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Speach</Link>
        {
           revisaPermisos(['Agregar Speech']) &&
          <Button variant='outlined' onClick={() => { setCurrentSpeech({}); setOpen(!open) }} >Nuevo Speach</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={speechs}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar Speech'])}
            onDelete={(data) => { deleteSpeech(data); }}
          />
          {
            revisaPermisos(['Agregar Speech']) &&
            <>

          


              <SpeechModal
                title={"Webhook"}
                open={open}
                setOpen={setOpen}
                wpsessions={sessionsUser}
                theme={theme}
                onSave={addSpeech}
                speech={currentSpeech}
              />
            </>
          }

        </Grid>

      </Grid>


    </>
  );
}



const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;