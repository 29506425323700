import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import SidebarNavList from "./SidebarNavList";

import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";

const Title = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const SidebarNavSection = (props) => {


  const [profile, setProfile] = useState([]);

  const [showSection, setShowSection] = useState(false);

  const {
    title,
    pages,
    className,
    component: Component = "nav",
    reload,
    ...rest
  } = props;

  useEffect(() => {
    async function getPremissions() {
      try {

        const userInfo = await api.post("/api/getPermissions");
        setProfile(userInfo.data.userInfo.permissions)

      } catch (err) {
        console.log(err)
      }
    }

    getPremissions();

  }, [reload]);


  return (
    <Component {...rest}>
      {title && showSection && <Title variant="subtitle2">{title}</Title>}
      <SidebarNavList pages={pages} depth={0} profile={profile} setShowSection={setShowSection} />
    </Component>
  );
};

export default SidebarNavSection;
