import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Chip, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TagsTableModal from '../../components/TagsTableModal';
import { Helmet } from 'react-helmet-async';
import { getContrastColor } from '../../utils/generalFunctions';
import TagGroupAccordion from '../../components/TagGroupAccordion';
import AddTagGroupModal from '../../components/AddTagGroupModal';
import { DragDropContext } from 'react-beautiful-dnd';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function WebHooks({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);

  /**
 *  Variables para activar el modal para crear un grupo
 */
  const [openGroupModal, setOpenGroupModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [webhooks, setWebhooks] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentSessionName, setCurrentSessionName] = useState('');
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();


  const [currentTag, setCurrentTag] = useState(null);

  const [tagGroups, setTagGroups] = useState([]);

  const [currentTagGroup, setCurrentTagGroup] = useState(null);

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    setOpenGroupModal(false)
    setCurrentTag(null);
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getTags", { wpSessions: userInfo.data.userInfo.wpsessions });
        //console.log(data)
        setWebhooks(data.tags);
      } catch (err) {
        console.log(err)
      }
      try {
        const { data } = await api.post("api/getSessions");
        // console.log(data)
        setSessionsUser(data.sessions);
      } catch (error) {
        console.log(error)
      }

      try {
        const { data } = await api.post("/api/tagGroup/get");
        // console.log(data)
        setTagGroups(data.tagGroups);
      } catch (error) {
        console.log(error)
      }
    }
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'color', headerName: 'Color', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {

              <Button
                sx={{
                  color: getContrastColor(params.row?.color ? params.row.color : '#000000'),
                  background: params.row?.color ? params.row.color : 'black'
                }}
              >
                Color
              </Button>
            }

          </>
        )
      }
    },
    {
      field: 'Wpsessions',
      headerName: 'Whatsapps',
      flex: 1,
      renderCell: (params) => {
        if (params.row?.WpSessions?.length > 0) {
          return params.row?.WpSessions.map((session) => {
            return <Chip sx={{ backgroundColor: session?.color? session.color: '#000000', color: getContrastColor(session?.color? session.color: '#000000') }} label={session.name}></Chip>
          })
        } else {
          return <Chip sx={{ backgroundColor: '#000000', color: getContrastColor('#000000') }} label="Sin whatsapps"></Chip>
        }

      }

    },
    {
      field: 'test', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {
              revisaPermisos(['Modificar categorias']) ?
                <Button onClick={() => { setCurrentTag(params.row); setEstadoModal(true); }}>
                  Editar
                </Button> :
                "Sin acciones"
            }

          </>
        )
      }
    },


  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteTag", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Etiqueta eliminada', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar Etiqueta', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar Etiqueta', {
          variant: 'error',
        });
      }
    });


  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addWebhook = async (newWebhook, showMsg = true, oldIndex) => {


    let request = {

      tagObj: newWebhook,
      updateWP: showMsg
    }

    if (newWebhook.id) {
      request.id = newWebhook.id;
    }


    request.oldIndex = oldIndex;


    try {

      const { data } = await api.post("/api/createTag", request);

      //console.log(data);

      if (data.status == "Success") {

        if (showMsg) {
          enqueueSnackbar('Se creó la etiqueta', {
            variant: 'success',
          });
          setReload(true);

        }

      } else {
        if (showMsg) {
          enqueueSnackbar('Ocurrió un error al crear la etiqueta', {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err)
      if (showMsg) {
        enqueueSnackbar('Ocurrió un error al crear la etiqueta', {
          variant: 'error',
        });
      }
    }
    if (showMsg) {
      setReload(!reload)
    }
  }


  /**
   * Crea un nuevo grupo para etiquetas
   * @param {*} data 
   */
  const addTagGroup = async (newTagGroup) => {


    let request = {

      tagGroupObj: newTagGroup,
    }

    if (newTagGroup.id) {
      request.id = newTagGroup.id;
    }

    try {

      const { data } = await api.post("/api/tagGroup/add", request);

      //console.log(data);

      if (data.status == "Success") {


        enqueueSnackbar('Se creó el grupo de etiquetas', {
          variant: 'success',
        });
        setReload(true);



      } else {

        enqueueSnackbar('Ocurrió un error al crear el grupo de etiquetas', {
          variant: 'error',
        });

      }

    } catch (err) {
      console.log(err)

      enqueueSnackbar('Ocurrió un error al crear el grupo de etiquetas', {
        variant: 'error',
      });

    }

    setReload(!reload)

  }

  // Handler para reordenar los elementos cuando se completa el arrastre
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;

    }

    const destinationGroupIndex = result.destination.droppableId;
    const tagID = parseInt(result.draggableId);
    const sourceGroupIndex = result.source.droppableId;

    let updatedTagGroups = Array.from(tagGroups);


    const movedTag = updatedTagGroups[sourceGroupIndex].Tags[result.source.index];
    /** Se elimina del grupo anterior */
    updatedTagGroups[sourceGroupIndex].Tags.splice(result.source.index, 1);
    updatedTagGroups[destinationGroupIndex].Tags.push(movedTag);

    try {
      const request = {
        id: tagID,
        tagObj: {
          id: tagID,
          tagGroupID: updatedTagGroups[destinationGroupIndex].id
        },
      }
      const { data } = await api.post("/api/createTag", request);
    } catch (err) {
      console.log(err);
    }
    setTagGroups(updatedTagGroups);
    /* const updatedTags = Array.from(tags);
     const [movedTag] = updatedTags.splice(result.source.index, 1);
     updatedTags.splice(result.destination.index, 0, movedTag);*/


  }
  return (
    <>
      <Helmet title="Etiquetas" />

      <Typography variant="h3" gutterBottom display="inline">
        Etiquetas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Etiquetas</Link>
        {
          revisaPermisos(['Agregar categorias']) &&
          <Button variant='outlined' onClick={() => setEstadoModal(!estadoModal)} >Nueva etiqueta</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          {<TablaUsuarios
            columns={columns}
            rows={webhooks}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar categorias'])}
            onDelete={(data) => { deleteSession(data); }}
            rowReordering
            handleRowOrderChange={async ({ row, targetIndex,oldIndex }) => {
              const tagObj = {
                id: row.id,
                tagIndex: targetIndex
              }

              await addWebhook(tagObj, false,oldIndex);
            }}
          />
          }
          {
            revisaPermisos(['Agregar categorias']) &&
            <>
              <TagsTableModal
                title={"Nueva etiqueta"}
                currentTag={currentTag}
                open={estadoModal}
                setOpen={setEstadoModal}
                wpsessions={sessionsUser}
                theme={theme}
                wpsessionsId={sessionsUserNum}
                onSave={addWebhook}
              />
            </>
          }

          {
            revisaPermisos(['Agregar categorias']) &&
            <>
              <AddTagGroupModal
                title={"Nueva etiqueta"}
                open={openGroupModal}
                setOpen={setOpenGroupModal}
                theme={theme}
                currentTagGroup={currentTagGroup}
                onSave={addTagGroup}
              />
            </>
          }
          {/*<DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              {

                tagGroups.map((tagGroup, index) => {

                  return (
                    <Grid item xs={6}>
                      <TagGroupAccordion
                        onEdit={(e) => { e.preventDefault(); setCurrentTagGroup(tagGroup); setOpenGroupModal(true) }}
                        name={tagGroup.name}
                        tags={tagGroup.Tags}
                        showEditButton={revisaPermisos(['Modificar categorias'])}
                        id={index.toString()}
                        setCurrentTag={setCurrentTag}
                        setEstadoModal={setEstadoModal}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
            </DragDropContext>*/}

        </Grid>
      </Grid>


    </>
  );
}