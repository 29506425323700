import { Add, Done, Navigation, WhatsApp } from "@mui/icons-material";
import { Button, Chip, Fab, Grid, IconButton, Link, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import api from "../../services/api";
import CallModal from "../CallModal";
import CustomFilter from "../CustomFilter";
import MassiveMsgModal from "../MassiveMsgModal";
import MultiSelect from "../MultiSelect";
import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";

import { SocketContext } from "../../services/socket";
import DrivePicker from "../DrivePicker";
import GoogleLoginView from "../GoogleLogin";
import MoveModal from "../MoveModal";
import BookFooter from "../BookFooter";
import { GridFooterContainer } from "@mui/x-data-grid";
import { GridFooter } from "@mui/x-data-grid";
import AddTagmodal from "../AddTagModal";
import AddProductModal from "../AddProductModal";
import { getContrastColor } from "../../utils/generalFunctions";


const ClientListTable = ({ theme, advisers, loadGoogleContacts, templateList, currentAdviserID, showAdviserButton, groupID, books, addClientToDB, setAddClientToDB, reloadSheet, setCurrentSheet, loadAllClients = false, loadAsContacts = false }) => {


    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [tags, setTags] = useState([]);

    const [userId, setUserId] = useState(0);

    const [filters, setFilters] = useState({});


    const [open, setOpen] = useState(false);
    const [openCallModal, setOpenCallModal] = useState(false);
    const [openMove, setOpenMove] = useState(false);
    const [openTags, setOpenTags] = useState(false);
    const [openProducts, setOpenProducts] = useState(false);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [maxCount, setMaxCount] = useState(0);

    const [lastPage, setLastPage] = useState(0);
    const [lastPageSize, setLastPageSize] = useState(20);

    /**
      * Notifiaciones
      */
    const { enqueueSnackbar } = useSnackbar();


    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);
    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [reload, setReload] = useState(false);

    const [scheduleMsg, setScheduleMsg] = useState(false);

    const [isSequence, setIsSequence] = useState(false);

    const [scheduleCall, setScheduleCall] = useState(false);

    const [currentPermissions, setCurrentPermissions] = useState([]);

    const [currentDatabaseID, setCurrentDatabaseID] = useState(null);

    const [status, setStatus] = useState([]);

    const [products, setProducts] = useState([]);

    const [resetClients, setResetClients] = useState(false);
    /**
  * Compara la lista de permisos con los permisos asignados
  */
    const revisaPermisos = (allow, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < currentPermissions.length; j++) {

                if (allow[i] == currentPermissions[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }
    useEffect(() => {
        console.log(lastPage)
    }, [lastPage]);

    useEffect(() => {



        async function getStatus() {


            const { data } = await api.post("api/getStatus");

            if (data.statusList.length > 0) {

                setStatus(data.statusList);

            }

        }
        async function getProducts() {


            const { data } = await api.post("api/getProducts");

            if (data?.products) {

                setProducts(data.products);

            }

        }
        async function getTagsService() {

            try {


                try {

                    const { data } = await api.post("api/getTags");

                    if (data.tags) {
                        setTags(data.tags);
                    }

                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }

        async function getUserId() {

            try {
                const userInfo = await api.post("/api/getPermissions");
                setUserId(userInfo.data.userInfo.id);
                setCurrentPermissions(userInfo.data.userInfo.permissions);
            } catch (err) {
                console.log(err);
            }

        }
        getProducts()
        getUserId();
        getTagsService();
        getStatus();
        setClients([]);
    }, [currentAdviserID, reload, groupID])


    useEffect(() => {
        async function getClients() {
            let clientsTemp = clients;
            if (lastPageSize != pageSize) {
                clientsTemp = [];
                setLastPageSize(pageSize);
            }

            let filter = currentAdviserID ? { adviser: currentAdviserID } : {};
            filter.page = lastPage;
            filter.pageSize = pageSize;
            filter.filters = filters;

            if (resetClients) {
                clientsTemp = [];
                setResetClients(false);
            }

            if (loadGoogleContacts) {



                filter.filters.isFromGoogle = 1;
                const { data } = await api.post("api/getAllClients", filter);
                setClients([...clientsTemp, ...data.clients]);
                setMaxCount(data.count);
            } else {
                const { data } = await api.post("api/getAllClients", filter);
                setClients([...clientsTemp, ...data.clients]);
                setMaxCount(data.count);

            }

        }

        async function getChats() {

            let filter = currentAdviserID ? { adviser: currentAdviserID } : {};
            const { data } = await api.post("/api/getAllContacs", filter);
            setClients(data.clients);

        }
        if (loadAllClients && !loadAsContacts) {
            getClients();
            setCurrentSheet(null)
        }

        if (loadAsContacts) {
            getChats();
            setCurrentSheet(null)
        }

    }, [loadAllClients, loadAsContacts, reload, lastPage, pageSize, filters])



    /**
     *  Función para filtrar la información
     */
    const filterData = async (field, value) => {
        let tempFilters = { ...filters, [field]: value };


        if (currentAdviserID) {
            tempFilters.adviser = currentAdviserID;
        }

        if (currentDatabaseID) {
            tempFilters.groupId = currentDatabaseID;
        }

        try {



        } catch (err) {
            alert("Ocurrio un error al filtrar la información");
        }
        setResetClients(true);
        setPage(0);
        setLastPage(0);
        handlePageSizeChange();
        setFilters(tempFilters);

    }
    /**
     * Recupera un arreglo con lso telefonos
     * @returns 
     */
    const getPhones = () => {
        let phones = [];

        selectedClients.map((cl) => {
            phones.push(cl.phone);
        })

        return phones;
    }

    const handlePageChange = (newPage) => {
        if (newPage > lastPage) {
            setLastPage(lastPage + 1)
        }

        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setLastPageSize(pageSize);

        if (newPageSize) {
            setPageSize(newPageSize);
        }

        setPage(0); // Reinicia la página al cambiar el tamaño de página
        setLastPage(0)
    };


    const callMassive = async (currentTemplate, name, phone) => {

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...selectedClients];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
            kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            twilioPhone: phone,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);

            //  console.log(data);
            if (data.status == "Success") {

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} llamadas`, {
                    variant: 'success',
                });

                const socketObj = {
                    id: data.msgObj.id,
                    from: phone,
                    contacts: constactsArr,
                    delay: 1000,
                }


                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                setOpenCallModal(false);

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpen(false)
    }

    const setAdviserToClients = async () => {

        let clientList = [...selectedClients];

        clientList.map((cl) => {
            delete cl.Tags;
            delete cl.Comments;
            delete cl.User;
        })


        try {
            await api.post('api/createPage', {
                userId: userId,
                clients: clientList,
                advisers: selectedAdvisers
            });

            enqueueSnackbar(`La asignación fue exitosa`, {
                variant: 'success',
            });

            setReload(!reload);
            setSelectedClients([]);
            setSelectedAdvisers([])
            setShowAddAdvisers(false);

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al asignar los clientes`, {
                variant: 'error',
            });
        }


    }


    /**
   * Programa las llamadas masivas
   */
    const scheduleCallMassive = async (currentTemplate, name, phone, sendAt) => {

        /**
            * Array de contactos para enviar los mensajes
            * Integrantes del grupo que se creará
            */
        const constactsArr = [...selectedClients];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }


        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
            kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            userSend: userId,
            templateId: currentTemplate.id,
            twilioPhone: phone,
            delay: 1 * 1000,
            status: 'Programado',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {

                /**
                 * Id del envio masivo
                 */
                let idMsgEnv = data.msgObj.id;

                try {

                    const request = {
                        msgObject: {
                            name: name,
                            delay: 1 * 1000,
                            status: 'Programado',
                            type: 'Llamada',
                            user: userId,
                            idMassive: idMsgEnv,
                            idTemplate: currentTemplate.id,
                            sendAt: sendAt
                        },
                        contacts: constactsArr
                    }

                    const response = await api.post('/api/createSchedule', request);

                    if (response.data.status == "Success") {
                        enqueueSnackbar(`Se programaron los mesajes para la fecha ${sendAt}`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`Error al programar el mensaje`, {
                            variant: 'error',
                        });
                    }
                } catch (err) {
                    enqueueSnackbar(`Error al programar el mensaje`, {
                        variant: 'error',
                    });
                }


                setOpenCallModal(false)
            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }


    }

    /**
     * Revisa el tipo de envio que se debe hacer
     */
    const checkSendType = (currentTemplate, name, phone, sendAt) => {

        if (scheduleCall) {
            scheduleCallMassive(currentTemplate, name, phone, sendAt);
        } else {
            callMassive(currentTemplate, name, phone)
        }
    }

    /**
     * Mueve los datos seleccionados a una nueva base de datos
     * @param {*} newDatabaseId 
     */
    const moveDataToDatabase = async (newDatabaseId) => {

        try {
            const { data } = await api.post('/api/moveGroupData', { newID: newDatabaseId, oldID: groupID, clients: selectedClients })

            if (data.status == "Success") {
                enqueueSnackbar(`Se movieron ${selectedClients.length} clientes`, {
                    variant: 'success',
                });

                setReload(!reload);
            } else {
                throw data;
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al mover los clientes`, {
                variant: 'error',
            });
        }

        setOpenMove(false);
    }


    const addTagToClients = async (tags) => {

        let clientList = [...selectedClients];
        try {
            await api.post('/api/addTagToClients', {

                clients: clientList,
                idTags: tags
            });

            enqueueSnackbar(`Se agregaron las etiquetas de forma correcta`, {
                variant: 'success',
            });

            setReload(!reload);
            setOpenTags(false);

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al agregar las etiquetas`, {
                variant: 'error',
            });
        }


    }


    const addProductToClients = async (products) => {

        let clientList = [...selectedClients];


        try {
            await api.post('/api/addProductToClients', {

                clients: clientList,
                products: products
            });

            enqueueSnackbar(`Se agregaron los productos de forma correcta`, {
                variant: 'success',
            });

            setReload(!reload);
            setOpenTags(false);

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al agregar los productos`, {
                variant: 'error',
            });
        }


    }

    const CustomFooter = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <BookFooter />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GridFooterContainer>
                        {/* Add what you want here */}
                        <GridFooter sx={{
                            border: 'none', // To delete double border.
                        }} />
                    </GridFooterContainer>
                </Grid>

            </Grid>
        );
    }


    const updateClient = async (id, client) => {
        try {
            const { data } = await api.post('/api/updateClient', { id: id, client: client });


            if (data.status == "Success") {
                enqueueSnackbar(`Se actualizó el cliente`, {
                    variant: 'success',
                });


            } else {
                console.log(data);
                throw data;
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al actualziar el cliente`, {
                variant: 'error',
            });
        }
    }
    /**
    * Vista para la edición de un campo simple
    * @param {*} props 
    * @returns 
    */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        const [newValue, setNewValue] = useState(value)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            setNewValue(target.value)
        }

        /**
         * Cuando presiona el botón se guarda el nuevo valor
         */
        const handleCommit = async (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();



            /**
             * Para los objetos dentro de la membresía excluyendo el id
             * se actualiza sin problema
             */

            props.row[props.field] = newValue;

            let request = { [props.field]: newValue };

            await updateClient(props.row.id, request);


        };



        return (
            <Grid container sx={{ minWidth: '200px;' }} >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        defaultValue={value}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} >
                    <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                        <Done color="success" />
                    </IconButton>
                </Grid>
            </Grid>
        )

    }

    const getBookName = (id) => {

        let bookName = 'Sin libro';



        for (let i = 0; i < books.length; i++) {
            if (books[i].id == id) {
                bookName = books[i].name;
                break;
            }
        }
    }
    const getCLientColumns = () => {


        if (loadAsContacts) {
            return [
                {
                    headerName: 'Estatus', field: 'status', hide: false, minWidth: 250,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} width={props.colDef.minWidth} />
                    },
                    renderCell: (params) => {
                        return <Chip label={params.row?.Status?.name ? params.row.Status.name : 'Sin estatus'} sx={{ color: getContrastColor(params.row.Status?.color ? params.row.Status.color : '#000000'), background: params.row?.Status?.color ? params.row.Status.color : 'black' }} />
                    }

                },
                {
                    headerName: 'Libro', field: 'groupId', hide: false,
                    /** Filtro personalizado 
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },*/
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.Book?.name} />
                    }

                },
                {
                    headerName: 'Hoja', field: 'groupId2', hide: false,
                    /** Filtro personalizado 
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },*/
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.name} />
                    }

                },
                {
                    headerName: 'Nombre', field: 'firstName', hide: false, editable: true,
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },

                {
                    headerName: 'Whatsapp', field: 'test', hide: false,
                    renderCell: (params) => {
                        return <Link target="_blank" rel="noopener" href={"https://wa.me/" + params.row.phone}><WhatsApp color="success" /></Link>
                    },
                },
                {
                    headerName: 'Teléfono', field: 'phone', hide: false, editable: true,

                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },
                {
                    headerName: 'Producto', field: 'product', hide: false, editable: true, minWidth: 250,
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={products} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <Chip label={params.row.Product?.name} />

                    </Grid>
                },
                {
                    headerName: 'Etiquetas', field: 'tags', hide: false,
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={products} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => {

                        return (
                            <Grid md={12} sx={{ height: '100%' }}>
                                {params.row.Tags?.map((value) => (
                                    <Chip label={value.name} sx={{ color: getContrastColor(value?.color ? value.color : '#000000'), background: value?.color ? value.color : 'black' }} />
                                ))}
                            </Grid>
                        )
                    }

                },
                {
                    headerName: 'Asesor', field: 'adviser', hide: false, editable: true,
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    // renderEditCell: (props) => <SimpleEditField {...props} />,
                    renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <Button>{params.row?.User?.email ? params.row.User.email : 'Sin asesor'}</Button>

                    </Grid>
                },
            ]
        } else {


            return [

                {
                    headerName: 'Nombre', field: 'firstName', hide: false, editable: true, flex: 1,
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => {
                        return params.row.firstName ? params.row.firstName : params.row?.Chat?.name ? params.row.Chat.name : ''
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },

                {
                    headerName: 'Teléfono', field: 'phone', hide: false, editable: true, flex: 1,

                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },
                {
                    headerName: '', field: 'test', hide: false,
                    renderCell: (params) => {
                        return <Link target="_blank" rel="noopener" href={"https://wa.me/" + params.row.phone}><WhatsApp color="success" /></Link>
                    },
                },
                {
                    headerName: 'Whatsapp', field: 'wpSession', hide: false,
                    renderCell: (params) => {
                        const whatsapp = params.row?.Chat?.WpSession;

                        if (whatsapp) {
                            return  <Chip label={whatsapp.name} sx={{ color: getContrastColor(whatsapp?.color ? whatsapp.color : '#000000'), background: whatsapp?.color ? whatsapp.color : 'black' }} />
                        }else{
                            return  <Chip label={"Sin whatsapp"} sx={{ color: getContrastColor(whatsapp?.color ? whatsapp.color : '#000000'), background: whatsapp?.color ? whatsapp.color : 'black' }} />
                        }
                       

                    },
                },
                {
                    headerName: 'Producto', field: 'product', hide: false, editable: true, flex: 1,
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={products} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <Chip label={params.row.Product?.name} />

                    </Grid>
                },
                {
                    headerName: 'Etiquetas', field: 'tags', hide: false, flex: 1,
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={tags} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => {

                        return (
                            <Grid md={12} sx={{ height: '100%' }}>
                                {params.row.Tags?.map((value) => (
                                    <Chip label={value.name} sx={{ color: getContrastColor(value?.color ? value.color : '#000000'), background: value?.color ? value.color : 'black' }} />
                                ))}
                            </Grid>
                        )
                    }

                },
                {
                    headerName: 'Libro', field: 'bookId', hide: false, flex: 1,

                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={books} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.Book?.name} />
                    }

                },
                {
                    headerName: 'Hoja', field: 'sheetId', hide: false, flex: 1,
                    /** Filtro personalizado 
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },*/
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.name} />
                    }

                },

            ]

            return [
                {
                    headerName: 'Fecha de subida', field: 'createdAt', hide: false,
                    renderCell: (params) => {

                        return (
                            <Grid container>
                                <Grid item md={10}>
                                    {params.row?.createdAt.split(" ")[0]}
                                </Grid>

                            </Grid>
                        )
                    }

                },
                {
                    headerName: 'Última actualización', field: 'updatedAt', hide: false, flex: 1,
                    renderCell: (params) => {

                        return (
                            <Grid container>
                                <Grid item md={10}>
                                    {params.row?.updatedAt.split(" ")[0]}
                                </Grid>

                            </Grid>
                        )
                    }

                },

                {
                    headerName: 'Estatus', field: 'status', hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => {
                        return <Chip label={params.row?.Status?.name ? params.row.Status.name : 'Sin estatus'} sx={{ color: getContrastColor(params.row.Status?.color ? params.row.Status.color : '#000000'), background: params.row?.Status?.color ? params.row.Status.color : 'black' }} />
                    }

                },
                {
                    headerName: 'Libro', field: 'groupId', hide: false,
                    /** Filtro personalizado 
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },*/
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.Book?.name} />
                    }

                },
                {
                    headerName: 'Hoja', field: 'groupId2', hide: false,
                    /** Filtro personalizado 
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },*/
                    renderCell: (params) => {
                        return <Chip label={params.row?.Group?.name} />
                    }

                },
                {
                    headerName: 'Nombre', field: 'firstName', hide: false, editable: true,
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },
                {
                    headerName: 'Apellido', field: 'lastName', hide: false, editable: true,
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },

                {
                    headerName: 'Teléfono', field: 'phone', hide: false, editable: true,

                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },
                {
                    headerName: 'Whatsapp', field: 'test', hide: false,
                    renderCell: (params) => {
                        return <Link target="_blank" rel="noopener" href={"https://wa.me/" + params.row.phone}><WhatsApp color="success" /></Link>
                    },
                },
                {
                    headerName: 'Correo', field: 'email', hide: false, editable: true,

                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderEditCell: (props) => <SimpleEditField {...props} />
                },
                {
                    headerName: 'Producto', field: 'product', hide: false, editable: true,
                    renderHeader: (props) => {
                        return <CustomFilter isSelect keys={["id", "name"]} list={products} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                    },
                    renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <Chip label={params.row.Product?.name} />

                    </Grid>
                },
                {
                    headerName: 'Etiquetas', field: 'tags', hide: false,
                    renderCell: (params) => {

                        return (
                            <Grid md={12} sx={{ height: '100%' }}>
                                {params.row.Tags?.map((value) => (
                                    <Chip label={value.name} sx={{ color: getContrastColor(value?.color ? value.color : '#000000'), background: value?.color ? value.color : 'black' }} />
                                ))}
                            </Grid>
                        )
                    }

                },
                {
                    headerName: 'Comentarios', field: 'comments', hide: false,
                    renderCell: (params) => {

                        return (
                            <Grid container>
                                <Grid item md={10}>
                                    {params.row.Comments?.length > 0 ? params.row.Comments[0].comments : 'Sin comentarios'}
                                </Grid>

                            </Grid>
                        )
                    }

                },

              
            ]
        }

    }

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >

                <Grid
                    container
                    sx={{ height: '80vh', minWidth: '100%' }}
                    alignItems="center"
                    justifyContent="center"
                    justify="center">

                    <Grid item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        {!loadAllClients && <BookFooter relaod={reloadSheet} bookID={groupID} onPageChange={(id) => { setCurrentDatabaseID(id) }} addClientToDB={addClientToDB} setAddClientToDB={setAddClientToDB} setReload={setReload} />}
                    </Grid>
                    <Grid item
                        xs={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        sm={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        md={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        lg={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: '100%' }}>
                        <TablaUsuarios
                            page={page}
                            pageSize={pageSize}
                            totalRows={maxCount}
                            showPages
                            handlePageSizeChange={handlePageSizeChange}
                            handlePageChange={handlePageChange}
                            bookID={groupID}
                            onPageChange={(id) => { setCurrentDatabaseID(id); }}
                            rows={clients}
                            columns={getCLientColumns()}
                            checkboxSelection
                            onDelete={(rowsSelected) => {

                                let rs = [...clients]
                                rowsSelected.map((r) => {
                                    clients.map((r2, index) => {
                                        if (r.id == r2.id) {
                                            rs.splice(index, 1);
                                        }
                                    })

                                })

                            }}
                            onsSelectionChange={(rowsSelected) => {


                                let selectedTemp = [];
                                rowsSelected.map((r) => {
                                    clients.map((r2, index) => {
                                        if (r.id == r2.id) {
                                            selectedTemp.push(r2);
                                        }
                                    })

                                })
                                console.log(selectedTemp);
                                setSelectedClients(selectedTemp);

                                if (selectedTemp.length == 0) {
                                    setSelectedClients([]);
                                    setSelectedAdvisers([])
                                    setShowAddAdvisers(false);
                                }
                            }}

                            components={{ Footer: CustomFooter }}
                        />
                    </Grid>
                    {showAddAdvisers && selectedClients.length > 0 &&
                        <Grid item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            align="center"
                            alignItems="center"
                            justifyContent="center"

                            sx={{ height: '100%' }}>
                            <TablaUsuarios

                                rows={advisers}
                                columns={[

                                    {
                                        headerName: 'Correo', field: 'email', hide: false,

                                    },
                                    {
                                        headerName: 'Nombre', field: 'firstName', hide: false,

                                    },
                                    {
                                        headerName: 'Estatus', field: 'status', hide: false,

                                    },


                                ]}
                                checkboxSelection
                                onDelete={(rowsSelected) => {


                                }}
                                onsSelectionChange={(newUsers) => {

                                    setSelectedAdvisers(newUsers);


                                }}


                            />
                        </Grid>}
                </Grid>
                {
                    selectedClients.length > 0 && !showAddAdvisers && <div style={{ textAlign: 'center', position: 'fixed', bottom: 16, width: '100%' }}>
                        {(loadAsContacts || showAdviserButton) && revisaPermisos(['Asignar clientes']) && <Fab variant="extended" onClick={() => setShowAddAdvisers(true)}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar asesor
                        </Fab>}
                        {!loadAsContacts && revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setScheduleMsg(false); setIsSequence(false); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />s
                            Enviar Mensaje
                        </Fab>}
                        {!loadAsContacts && revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setScheduleMsg(true); setIsSequence(false); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Programar Mensaje
                        </Fab>}
                        {!loadAsContacts && revisaPermisos(['Enviar llamada masiva']) && <Fab variant="extended" onClick={() => { setScheduleCall(true); setOpenCallModal(true) }}>
                            <Navigation sx={{ mr: 1 }} />
                            Programar Llamada
                        </Fab>}
                        {!loadAsContacts && revisaPermisos(['Enviar llamada masiva']) && <Fab variant="extended" onClick={() => { setScheduleCall(false); setOpenCallModal(true) }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar Llamada
                        </Fab>}
                        {!loadAsContacts && revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setIsSequence(true); setScheduleMsg(false); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar secuencia
                        </Fab>}
                        {
                            !loadAsContacts && revisaPermisos(['Mover clientes']) &&
                            <Fab variant="extended" onClick={() => { setScheduleCall(false); setOpenCallModal(false); setOpenMove(true); }}>
                                <Navigation sx={{ mr: 1 }} />
                                Mover
                            </Fab>
                        }
                        {loadAsContacts && <Fab variant="extended" onClick={() => { setOpenTags(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar Etiquetas
                        </Fab>}
                        {loadAsContacts && <Fab variant="extended" onClick={() => { setOpenProducts(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar Productos
                        </Fab>}

                    </div>
                }{
                    showAddAdvisers && selectedClients.length > 0 && <div style={{ textAlign: 'center', position: 'fixed', bottom: 16, width: '100%' }}>
                        <Fab color="success" variant="extended" onClick={() => setAdviserToClients()} disabled={selectedAdvisers.length == 0}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar
                        </Fab>

                        <Fab color="error" variant="extended" onClick={() => {

                            setSelectedAdvisers([])
                            setShowAddAdvisers(false);
                        }}>
                            <Navigation sx={{ mr: 1 }} />
                            Cancelar
                        </Fab>

                    </div>
                }
            </Grid>


            <MassiveMsgModal
                theme={theme}
                forwardingId={0}
                open={open}
                setOpen={setOpen}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={() => { setOpen(false); }}
                scheduled={scheduleMsg}
                isSequence={isSequence}
            />

            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templateList}
                onSave={(currentTemplate, name, phone, sendAt) => { checkSendType(currentTemplate, name, phone, sendAt) }}
                scheduled={scheduleCall}
            />

            <MoveModal
                title={"Mover datos a una nueva base"}
                currentDatabaseID={groupID}
                open={openMove}
                setOpen={setOpenMove}
                theme={theme}
                onSave={moveDataToDatabase}
            />

            <AddTagmodal
                title={"Asignar etiquetas"}
                open={openTags}
                setOpen={setOpenTags}
                theme={theme}
                onSave={addTagToClients}
            />

            <AddProductModal
                title={"Asignar productos"}
                open={openProducts}
                setOpen={setOpenProducts}
                theme={theme}
                onSave={addProductToClients}
            />

        </>
    )
}

export default ClientListTable;