import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import Database from '../../pages/Database';


export default function AddBookModal({ title, open, setOpen, onSave }) {



    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={()=>setOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="md"
            >
                <DialogTitle id="customized-dialog-title" onClose={()=>setOpen(false)}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Database onFinish={onSave}/>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
