import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, IconButton } from "@mui/material"
import Delete from "@mui/icons-material/Delete";
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';

export default function Detail({ columns, rows, id = 'id', onDelete, checkboxSelection, showDelete, onsSelectionChange, showResend, onResend, onResendAll, detail, height }) {

  /**
   *  Activa la lisencia de uso para datagrid pro
   */
  LicenseInfo.setLicenseKey(
    'bfc6e20fb3bd4afcc42e60d5cffc5096T1JERVI6NDA0OTQsRVhQSVJZPTE2Nzk4ODAxOTQwMDAsS0VZVkVSU0lPTj0x',
  );

  const [selection, setSelection] = React.useState([]);

  const [pageSize, setPageSize] = React.useState(20);

  const getDetailPanelContent = React.useCallback(
    detail,
    [],
  );

  const getDetailPanelHeight = React.useCallback(() => height, []);

  return (
    <div style={{ height: '100%' }}>
      <Grid container justifyContent="flex-end">
        {
          selection.length > 0 && (<>

            {showDelete &&
              <Grid item>
                <IconButton onClick={() => { onDelete(selection) }}><Delete color="error"></Delete></IconButton>
              </Grid>}
            {showResend &&
              <Grid item>
                <Button color="primary" onClick={() => { onResend(selection) }}>Reenviar Seleccionados</Button>
              </Grid>
            }

          </>)
        }
        {
          showResend &&
          <Grid item>
            <Button color="primary" onClick={() => { onResendAll(selection) }}>Reenviar Errores</Button>
          </Grid>
        }

      </Grid>


      <DataGridPro
        pageSize={pageSize}
        pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowId={(row) => { return row[id] }}
        rows={rows}
        columns={columns}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        rowsPerPageOptions={[5, 10, 20, 30, 100]}
        disableSelectionOnClick
        rowThreshold={0}
        checkboxSelection={checkboxSelection ? true : false}
        /**
         * Sirve para guardar la seleccion en algun lugar
         */
        onSelectionModelChange={(newSelectionModel) => {

          /**
           * Crea un arreglo con todos los objetos seleccionados
           */
          const clientsEdit = [];


          rows.map((value, key) => {

            if (newSelectionModel.indexOf(value[id]) > -1) {
              clientsEdit.push(value)
            }
          })
          setSelection(clientsEdit);

          if (onsSelectionChange) {
            onsSelectionChange(clientsEdit)
          }
          // console.log(clientsEdit)
        }}



      />
    </div>
  );
}

