import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';

import Modal from '../../components/Modal/Modal';
import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Chip, Divider, FormControlLabel, Grid, Link, MenuItem, Switch, TextField, Typography } from '@mui/material';
import QRCode from "react-qr-code";
import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet-async';
import WhatsappModal from '../../components/WhatsappModal';
import ChatBotMessageModal from '../../components/ChatBotMessageModal';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function ChatbotTradicional() {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [sessions, _setSessions] = useState([]);

  /**
   * 
   */
  const sessionsRef = React.useRef(sessions);

  const setSessions = data => {
    sessionsRef.current = data;
    _setSessions(data);
  }

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentSessionName, setCurrentSessionName] = useState('');

  /**
 * 
 */
  const [currentSession, setCurrentSession] = useState(null);


  const [currentBotMessage, setCurrentBotMessage] = useState(null);

  const [currentBotProducts, setCurrentBotProducts] = useState([]);

  const [invetoryID, setInventoryID] = useState(0);

  const [currentID, setCurrentID] = useState(null);

  const [currentToken, setCurrentToken] = useState(null);

  const [productList, setProductList] = useState([]);

  const [chatbotTemplates, setChatbotTemplates] = useState([]);

  const [selectedChatbotTemplate, setSelectedChatbotTemplate] = useState(0);
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);


        const { data } = await api.post("api/getBotSessions", { wpSessions: userInfo.data.userInfo.wpsessions });
        // console.log(data)
        setSessions(data.sessions);
      } catch (err) {
        console.log(err)
      }
    }

    async function getChabotTemplates() {

      try {
        const { data } = await api.post("/api/chatbotTemplate");
        setChatbotTemplates(data.chabotTemplates);
      } catch (err) {
        console.log(err);
      }
    }
    getChabotTemplates();
    getSessions();

  }, [reload]);




  /**
  * Funcion para actualizar las sesiones con el socket
  */
  const onGetUpdate = React.useCallback((sessionAuth, qr) => {

    let selectedMSession = {};

    for (let i = 0; i < sessionsRef.current.length; i++) {

      if (sessionsRef.current[i].sessionAuth == sessionAuth) {
        selectedMSession = sessionsRef.current[i];

        selectedMSession.qr = qr;
        selectedMSession.status = "QR";


        let newArr = [...sessionsRef.current];
        newArr[i] = selectedMSession

        setSessions([
          ...newArr,
        ]);
        if (sessionAuth == currentSessionName) {

          setCurrentQr(qr)
        }
        break;
      }
    }
  }, [sessionsRef, currentSessionName])

  React.useEffect(() => {

    if (socket && sessions.length) {

      if (sessions.length > 0) {


        sessions.map((session, key) => {
          //console.log("escuchando"+session);
          socket.on('qr' + session.sessionAuth, (data) => {

            onGetUpdate(data.data.wp, data.data.qr);
          })
          socket.on('waiting' + session.sessionAuth, (data) => {

            setReload(!reload)
          })
          socket.on('fail' + session.sessionAuth, (data) => {

            setReload(!reload)
          })
          socket.on('diconnected' + session.sessionAuth, (data) => {

            setReload(!reload)
          })
          socket.on('chatAdded' + session.sessionAuth, (data) => {

            setReload(!reload)
          })
          socket.on('authenticated' + session.sessionAuth, (data) => {

            setReload(!reload)
          })
        })
      }

    }
  }, [socket, sessions, reload, onGetUpdate])

  /**
   * Columnas de la tabla
   */
  const columns = [
    { field: 'id', headerName: 'ID' },

    {
      field: 'qr', headerName: 'Estatus whatsapp', flex: 1, editable: false, renderCell: (params) => {

        return (
          <BotonQr
            disabled
            setCurrentSessionName={setCurrentSessionName}
            sessionName={params.row.sessionAuth}
            qr={params.row.qr}
            setCurrentQr={setCurrentQr}
            text={params.row.status}
            estadoModal={estadoModal}
            reconnectSession={reconnectSession}
            setEstadoModal={setEstadoModal} />
        )
      }
    },
    {
      field: 'name',
      headerName: 'Nombre Sistema',
      flex: 1,
      editable: true,

    },

    {
      field: 'status', headerName: 'Chatbot', flex: 1, renderCell: (params) => {

        return (
          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <Switch
                checked={params.row.isTradicionalBotActive == 1}
                disabled={params.row.status != 'Conectado'}
                onChange={(e) => { activeBotSession(params.row.id, params.row.isTradicionalBotActive == 1 ? 0 : 1, params.row.tradicionalBot) }}
                name="loading"
                color="primary"
              />
            }
            label={params.row.status != 'Conectado' ? 'Ese necesario escanear el QR' : params.row.isTradicionalBotActive == 1 ? 'Desactivar bot' : 'Activar bot'}
          />

        )
      }
    },
    {
      field: 'systemMessage',
      headerName: 'Plantilla',
      flex: 1,
      editable: true,
      renderCell: (params) => {

        return (
          <TextField
            select
            fullWidth
            value={params.row.tradicionalBot}
            label="Plantilla"
            variant="outlined"
            onChange={(e) => addBotTemplate(params.row.id, e.target.value)}
          >
            {
              chatbotTemplates?.map((cTemplate) => {
                return (
                  <MenuItem key={cTemplate.id} value={cTemplate.id}>
                    {cTemplate.name}
                  </MenuItem>
                )
              })
            }
          </TextField>
        )
      }

    },
    {
      field: 'isTradicionalBotActive', headerName: 'Estatus chatbot', flex: 1, renderCell: (params) => {

        return (
          <Button variant="contained" color={params.row.isTradicionalBotActive == 1 ? 'success' : 'error'} onClick={() => {

          }}>{params.row.isTradicionalBotActive == 1 ? 'Activo' : 'Desactivado'}</Button>
        )
      }
    },


  ];

  /**
   * Reconecta una sesion
   * @param {*} sessionName 
   * @returns 
   */
  const reconnectSession = async (sessionName) => {


    try {

      const { data } = await api.post('/api/reconectSession', { sessionAuth: sessionName });

      // console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Reconectando sesion', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Error al reconectar', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al reconectar el whatsapp', {
        variant: 'error',
      });
    }
  }


  /**
   * Activa el bot para una sesión de whats
   * @param {*} data 
   */
  const activeBotSession = async (id, activeChat, tradicionalBot) => {


    if (!tradicionalBot) {
      enqueueSnackbar('Debe seleccionar una plantilla para el bot', {
        variant: 'warning',
      });
      return;
    }
    let request = {
      id: id,
      wpsessionObj: {
        isTradicionalBotActive: activeChat ? 1 : 0,
  
      },

    }


    try {

      const { data } = await api.post("/api/updateBot/tradicional", request);

      if (data.status == "Success") {

        enqueueSnackbar('Se activó el bot correctamente, prueba enviar un mensaje', {
          variant: 'success',
        });
        setReload(true);

      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al activar el bot', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al activar el bot', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }

  const addBotTemplate = async (id, templateID) => {


    if (!templateID) {
      enqueueSnackbar('Debe seleccionar una plantilla para el bot', {
        variant: 'warning',
      });
      return;
    }

    let request = {
      id: id,
      wpsessionObj: {
        tradicionalBot: templateID
      },

    }


    try {

      const { data } = await api.post("/api/updateBot/tradicional", request);

      if (data.status == "Success") {

 
      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al agregar la plantilla', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al agregar la plantilla', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }




  return (
    <>
      <Helmet title="Whatsapp" />

      <Typography variant="h3" gutterBottom display="inline">
        Chatbot Tradicional
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          chatbot
        </Link>
        <Link >tradicional</Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          {
            sessions.length == 0 ?
              <div>No existen sesiones de whatsapp, agrega una nueva en el menú de Redes sociales</div>
              :
              <TablaUsuarios
                columns={columns}
                rows={sessions}
                checkboxSelection
                showDelete={false}
                onDelete={(data) => { }}
                columnVisibilityModel={{
                  actions: false
                }}
              />
          }


        </Grid>
      </Grid>

      <Modal
        estado={estadoModal}
        cambiarEstado={setEstadoModal}
      >
        <Contenido>
          <QRCode value={currentQr} />
        </Contenido>
      </Modal>
    </>
  );
}



const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;