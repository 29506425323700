import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import MultiSelect from '../MultiSelect';
import api from '../../services/api';


export default function ChatbotTemplateModal({ title, open, setOpen, onSave, theme, chatbotT, }) {


    useEffect(() => {
        async function getTemplates() {
            try {
                const { data } = await api.post("/api/getTemplate");
                setTemplates(data.messageTemplates);

            } catch (err) {
                console.log(err)
            }
        }

        getTemplates();
    }, []);
    /**
     * 
     */
    useEffect(() => {


        if (chatbotT?.ChatbotMessages) {
            setchatbotTemplate(chatbotT);
        } else {

            setchatbotTemplate({
                name: '',
                description: '',
                ChatbotMessages: []
            });

        }

        setIsLoading(false);

    }, [chatbotT]);


    /**
     * Variable para el nuevo chatbotTemplate
     */
    const [chatbotTemplate, setchatbotTemplate] = useState({
        name: '',
        description: '',
        ChatbotMessages: []
    });

    const [isLoading, setIsLoading] = useState(false);

    const [templates, setTemplates] = useState([]);
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setchatbotTemplate({
            ...chatbotTemplate,
            [event.name]: event.value,
        })
    }

    const handleChangeMessage = (e, index) => {
        const event = e.target;
        let tempChatbotTemplate = { ...chatbotTemplate }

        tempChatbotTemplate.ChatbotMessages[index] = {
            ...tempChatbotTemplate.ChatbotMessages[index],
            [event.name]: event.value,
        }
        console.log(tempChatbotTemplate)
        setchatbotTemplate({
            ...tempChatbotTemplate,
        })
    }


    const addWord = () => {
        let tempChatbotTemplate = { ...chatbotTemplate }
        tempChatbotTemplate.ChatbotMessages.push({
            word: 'Palabra',
            templateID: 0,
            type: 0
        })
        console.log(tempChatbotTemplate)
        setchatbotTemplate({
            ...tempChatbotTemplate,
        })

    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={chatbotTemplate.name}
                                label="Nombre de la chatbot"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={chatbotTemplate.description}
                                label="Descripción"
                                name="description"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button fullWidth onClick={addWord}>Agregar palabras</Button>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            {
                                chatbotTemplate?.ChatbotMessages.map((message, index) => {


                                    return (
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    value={message?.word ? message.word : ''}
                                                    label="Palabra clave"
                                                    name="word"
                                                    variant="outlined"
                                                    onChange={(e) => handleChangeMessage(e, index)}

                                                />

                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    value={message?.templateID ? message.templateID : 0}
                                                    label="Plantilla"
                                                    name="templateID"
                                                    variant="outlined"
                                                    onChange={(e) => handleChangeMessage(e, index)}

                                                >
                                                    {templates.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </Grid>
                                            <Grid item xs={4}>
                                            <TextField
                                                    fullWidth
                                                    select
                                                    value={message.type}
                                                    label="Tipo"
                                                    name="type"
                                                    variant="outlined"
                                                    onChange={(e) => handleChangeMessage(e, index)}

                                                >
                                                    {[{label: "Exacta", value: 1},{label: "Contener frase o palabra", value: 0}].map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </Grid>
                                        </Grid>)
                                })
                            }

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button disabled={isLoading} theme={theme} variant="contained" color="primary" onClick={() => onSave(chatbotTemplate)}>
                        {
                            chatbotTemplate ? "Guardar" : "Agregar"
                        }
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
