import { useEffect, useState } from "react";
import api from "../../services/api";
import { Button, Grid } from "@mui/material";
import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import { CopyAll } from "@mui/icons-material";
import { width } from "@mui/system";

export default function AdviserForms({ callBack }) {

    const [forms, setForms] = useState([]);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        async function getForms() {
            try {
                const { data } = await api.get('/api/calendarform');
                setForms(data.calendarForms)
            } catch (err) {

            }
        }
        async function getUserData() {

            try {

                const { data } = await api.get("/api/v2/me");

                setUserData(data);


            } catch (err) {
                console.log(err)
            }
        }
        getUserData();
        getForms();
    }, [])


    const getCalendarFormUrl = (formID) => {
        const urlBase = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

        return `${urlBase}/calendarios/${userData.id}/${formID}`;

    }

    const columns = [
        {
            field: 'name', headerName: 'Nombre', width: 200, editable: false,
        },
        {
            field: 'id', headerName: 'URL', flex: 4, editable: false,
            renderCell: (params) => {

                return (
                    <Grid container item md={12} xs={12}>

                        <Grid item >
                            <Button onClick={
                                () => {
                                    navigator.clipboard.writeText(getCalendarFormUrl(params.row.id)).then(() => {
                                        alert("Se copió la url")
                                    }).catch(err => {
                                        console.error('Error al copiar el texto: ', err);
                                    });
                                }
                            } style={{ height: '100%' }} variant="outlined" startIcon={<CopyAll />}></Button>
                        </Grid>
                     

                    </Grid>
                )
            }
        },
    ]
    return (
        <div style={{minHeight: '25vh'}}>
            <TablaUsuarios
                columns={columns}
                rows={forms}
                showDelete={false}
                onDelete={(data) => { }}
            />
        </div>
    )
}