import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import MultiSelect from '../MultiSelect';
import api from '../../services/api';


export default function ChatBotMessageModal({ title, open, setOpen, onSave, theme, wpID, currSystemMessage, currToken, productsList, products, inventoryId }) {

    /**
     * 
     */

    useEffect(() => {
        async function getSheets() {

            try {

                const { data } = await api.post("/api/google/sheets");

                setInvetoryList(data.sheets);
            } catch (err) {
                console.log(err)
            }
        }

        getSheets();
    }, []);

    useEffect(() => {
        setSystemMessage(currSystemMessage);
    }, [currSystemMessage]);

    useEffect(() => {

        if(currToken){
            setToken(currToken);
        }else{
            setToken('');
        }
        
    }, [currToken]);

    useEffect(() => {
        setId(wpID);
    }, [wpID]);

    useEffect(() => {

        let productIDs = products.map((product) => { return product.id })
        setBotProducts(productIDs);
    }, [products]);

    useEffect(() => {
        setBotProducts(inventoryId);
    }, [inventoryId]);

    /**
     * Variable para asignar el mensaje para el comportamiento del bot
     */
    const [systemMessage, setSystemMessage] = useState(currSystemMessage);

    /**
    * Variable para asignar los productos
    */
    const [botProducts, setBotProducts] = useState(products);

    /*
    * Variable para asignar el id del whatsap
    */
    const [id, setId] = useState(wpID);

    /*
    * Variable para asignar el tipo de inventario que lee
    */
    const [productListType, setProductListType] = useState(1);

    /**
    * Variable para asignar el inventario
    */
    const [invetoryID, setInventoryID] = useState(inventoryId);

    /**
   * Variable para asignar el inventario
   */
    const [invetoryList, setInvetoryList] = useState([]);

    /**
* Variable para asignar el inventario
*/
    const [token, setToken] = useState(null);


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeProducts = (list) => {
        setBotProducts(list)

    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={systemMessage}
                                label="Ingresa un mensaje para definir el comportamiento del chatbo, por ejemplo: Eres un vendedor dentro de la organización"
                                name="name"
                                variant="outlined"
                                onChange={(e) => { setSystemMessage(e.target.value) }}
                                multiline
                                rows={6}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={token}
                                label="Token de chatGPT"
                                name="name"
                                variant="outlined"
                                onChange={(e) => { setToken(e.target.value) }}


                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Tipo de producto"
                                value={productListType}
                                onChange={(e) => setProductListType(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth

                            // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                            >
                                {[{ name: "Inventario", value: 1 }, { name: "Productos del sistema", value: 2 }]?.map((account) => (
                                    <MenuItem key={account.value} value={account.value}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {
                            productListType == 2 ?
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <MultiSelect
                                        title="Productos asignados"
                                        theme={theme}
                                        list={productsList}
                                        onChange={handleChangeProducts}
                                        initSelect={botProducts}
                                        fullWidth
                                    />

                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Inventario"
                                        value={invetoryID}
                                        onChange={(e) => setInventoryID(e.target.value)}
                                        variant="filled"
                                        color="primary"
                                        fullWidth
                                    >
                                        {invetoryList?.map((inventory) => (
                                            <MenuItem key={inventory.id} value={inventory.id}>
                                                {inventory.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                        }

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(id, systemMessage, botProducts, [invetoryID], productListType, token)}>
                        {currSystemMessage ? "Actualizar" : "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
