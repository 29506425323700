import { Breadcrumbs, Button, Divider, Fab, Grid, Link, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import ProfileModal from "../../components/ProfileModal";
import ProfileTable from "../../components/ProfileTable";
import api from "../../services/api";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";




/**
 * Vista para la modificación de permisos
 * @returns 
 */
const Permissions = ({ theme }) => {
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Variable que recibirá el objeto de perfiles
     * incluye sus permisos
     */
    const [profiles, setProfiles] = useState([]);
    /**
     * Variable que contiene la lista de permisos
     */
    const [permissions, setPermissions] = useState([]);
    /**
     * Variable que contiene la lista de telefonos de twilio
     */
    const [twilioPhones, setTwilioPhones] = useState([]);
    /**
     * Variable que contiene la lista de estatus
     */
    const [status, setStatus] = useState([]);
    /**
    * Variable que contiene la lista de etiquetas
    */
    const [tags, setTags] = useState([]);

    /**
    * Variable que contiene la lista de las paginas
    */
    const [fbPages, setFbPages] = useState([]);
    /**
     * Variable que contiene la lista de perfiles
     */
    const [systemNames, setSystemNames] = useState([]);
    /**
     * Variable para mostrar y coultar el modal
     */
    const [openModal, setOpenModal] = useState(false);
    /**
     * Variable para guardar el perfil seleccionado
     */
    const [currentProfile, setCurrentProfile] = useState(null);
    /**
     * Variable pararecargar la tabla sin recargar la página
     */
    // const [reload, setReload] = useState(false);
    /**
     * Variable para mostrar la página de carga
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
    * Variable que contiene la lista de permisos del usuario
    */
    const [permissionsUser, setPermissionsUser] = useState([]);

    const [reload, setReload] = useOutletContext();

    const [duplicate, setDuplicate] = useState(false);

    const [isOwner, setIsOwner] = useState(0);
    /**
     * Se ejecuta al inicio
     */
    useEffect(() => {


        /**
         * Consulta los perfiles 
         * El objeto contiene los permisos asignados a cada uno
         */
        async function getProfiles() {
            try {
                const { data } = await api.get("/api/profile/all");

                /**
                 * Valida el estatus Success
                 * sino manda un error al usuario
                 */

                //console.log(data);
                if (data.status == 'Success') {

                    setProfiles(data.profiles);
                    setIsLoading(false)
                } else {
                    console.log(data)
                    enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                        variant: 'error',
                    });
                }

            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                    variant: 'error',
                });
                console.log(err);
            }

        }
        /**
         * Consulta los permisos 
         * Son necesarios en caso de querer asignar nuevos permisos
         */
        async function getPermissions() {
            try {
                const { data } = await api.get("/api/perm/list");

                //console.log(data);
                if (data.status == 'Success') {
                    setPermissions(data.permissions);
                } else {
                    console.log(data);
                    enqueueSnackbar('Ocurrió un error al consultar los permisos', {
                        variant: 'error',
                    });
                }
            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los permisos', {
                    variant: 'error',
                });
                console.log(err);
            }
        }
        /**
         * Consulta los sistemas 
         * Son necesarios en caso de querer asignar un sistema
         */
        async function getSystemNames() {
            try {
                const { data } = await api.post("/api/getSessions");
                //console.log(data);
                if (data.status == 'Success') {
                    setSystemNames(data.sessions)
                } else {
                    enqueueSnackbar('Ocurrió un error al consultar los sistemas', {
                        variant: 'error',
                    });
                }
            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los sistemas', {
                    variant: 'error',
                });
                console.log(err);
            }

        }
        /**
        * Consulta los telefonos de twilio 
        * Son necesarios en caso de querer asignar nuevos
        */
        async function getTwilioPhones() {
            try {
                const { data } = await api.post("/api/getTwilioPhones");

                //console.log(data);
                if (data.status == 'Success') {
                    setTwilioPhones(data.twilioPhones);
                } else {
                    enqueueSnackbar('Ocurrió un error al consultar los telefonos', {
                        variant: 'error',
                    });
                }
            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los telefonos', {
                    variant: 'error',
                });
                console.log(err);
            }
        }

        async function getTags() {

            try {
                const { data } = await api.post("/api/getTags");

                setTags(data.tags);
            } catch (err) {
                console.log(err)
            }
        }
        async function getStatus() {

            try {
                const { data } = await api.post("/api/getStatus");
                setStatus(data.statusList);
            } catch (err) {
                console.log(err)
            }
        }

        async function getFbPages() {

            try {
                const { data } = await api.post("/api/userpages/profile/lite");
                setFbPages(data.pages);
            } catch (err) {
                console.log(err)
            }
        }
        getFbPages();
        getTwilioPhones();
        getProfiles();
        getPermissions();
        getSystemNames();
        getPermissionsUser();
        getTags();
        getStatus();

    }, [reload])


    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < permissionsUser.length; j++) {
                // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
                if (allow[i] == permissionsUser[j]) {
                    count++;
                }
            }
        }
        //  console.log(`${count} >= ${minMatch}`);
        // console.log(count >= minMatch);
        return count >= minMatch;
    }
    /**
     * Recupera todos los permisos y sesiones
     */
    const getPermissionsUser = async () => {

        try {

            const userInfo = await api.post("/api/getPermissions");


            setPermissionsUser(userInfo.data.userInfo.permissions)
            setIsOwner(userInfo.data.userInfo.isOwner);
            // setSessions(userInfo.data.userInfo.wpsessions);

        } catch (err) {
            console.log(err);
        }


    }

    /**
     * Elimina el perfil por id
     * @param {*} profileData 
     */
    const deleteProfile = async (profileData) => {
        setIsLoading(true)
        setOpenModal(false);
        if (window.confirm(`El perfil ${profileData.name} se elminiará ¿Deseas continuar?`)) {
            const { data } = await api.put("/api/profile/del", { id: profileData.id });

            if (data.status == 'Success') {
                setReload(!reload);
                enqueueSnackbar('Perfil eliminado con exito', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('Ocurrió un error al eliminar el perfil', {
                    variant: 'error',
                });
            }
        }

        setIsLoading(false)
    }
    /**
     * Guarda el perfil seleccionado para su edición
     * @param {*} profileData 
     */
    const onEditProfile = (profileData, duplicate = false) => {
        setOpenModal(true);
        setDuplicate(duplicate);
        if (duplicate) {
            let newProfile = { ...profileData };
            delete profileData.id;
            setCurrentProfile(newProfile);
            return;
        }
        setCurrentProfile(profileData);
    }

    /**
     * Agrega los datos como un nuevo perfil
     * 
     */
    const addProfile = async (newProfile, perm, sysNames, twilioPhones, pages) => {
        setIsLoading(true)
        setOpenModal(false);
        /**
         * Prepara el objeto de perfil con los permisos
         */
        let request = {
            profileObj: newProfile,
            permissions: perm,
            wpsessions: sysNames,
            twilioPhones: twilioPhones,
            pages,
            tags: newProfile.tags,
            status: newProfile.status,
            tagsWithoutChat: newProfile.tagsWithoutChat

        }
        /**
         * Si contiene un id entonces se envia para editar
         * en lugar de agregar
         */
        if (newProfile.id) {
            request.id = newProfile.id;
        }


        const { data } = await api.post("/api/profile/add", request);

        //console.log(data);
        if (data.status == 'Success') {
            setReload(!reload);
            enqueueSnackbar('Perfil agregado con exito', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('Ocurrió un error al agregar el perfil', {
                variant: 'error',
            });
        }
        setIsLoading(false)
    }
    return (
        <>
            {/** barra navegacion debajo del titulo */}
            <Helmet title="Roles" />

            <Typography variant="h3" gutterBottom display="inline">
                Roles y Permisos
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Administracion
                </Link>
                <Link >Roles</Link>
                {revisaPermisos(['Agregar Perfil']) &&

                    <Button
                        variant="outlined"
                        onClick={() => { setOpenModal(true); setCurrentProfile(null); }}
                    >
                        Nuevo perfil
                    </Button>


                }
            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Loading open={isLoading} />
            <ProfileModal
                title="Agregar perfil"
                open={openModal}
                setOpen={setOpenModal}
                onSave={addProfile}
                permissions={permissions}
                systemNames={systemNames}
                twilioPhones={twilioPhones}
                pages={fbPages}
                theme={theme}
                newProfile={currentProfile}
                status={status}
                tags={tags}
                duplicate={duplicate}
                isOwner={isOwner}
            />

            <Grid
                container

                sx={{ padding: '20px', height: '80vh' }}
                alignItems="center"
                justify="center"
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '100%' }}
                >
                    <ProfileTable
                        profiles={profiles}
                        onDelete={deleteProfile}
                        onEdit={onEditProfile}
                        showEdit={revisaPermisos(['Modificar Perfil'])}
                        showDelete={revisaPermisos(['Eliminar Perfil'])}
                    />
                </Grid>

            </Grid>

            {/*
                revisaPermisos(['Agregar Perfil']) && (

                    <Fab
                        theme={theme}
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed', bottom: 16, right: 'calc(50% - 20px)' }}
                        onClick={() => { setOpenModal(true); setCurrentProfile(null); }}
                    >
                        <Add />
                    </Fab>

                )*/
            }

        </>
    )
}

export default Permissions;