import { Box, Breadcrumbs, Button, Divider, Grid, Link, MenuItem, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import EmailItem from "../../components/EmailItem";
import api from "../../services/api";
import LoadingCircles from "../../components/LoadingCircles";
import { SocketContext } from "../../services/socket";
import MultiSelect from "../../components/MultiSelect";
import NoDataFound from "../../components/NoDataFound";

export default function Emails() {

    const [emails, setEmails] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [reload, setReload] = useState(false);
    const [accounts, setAccounts] = useState([]);

    const [name, setName] = useState('');
    const [fromMe, setFromMe] = useState(null);
    const [ids, setIds] = useState([]);
    /**
     * Socket para lo refrebte al chat
     */
    const socket = useContext(SocketContext);

    async function getEmails(name, fromMe, ids) {
        setIsloading(true)
        try {
            let query = '?';

            if (name) {
                query += `name=${name}&`
            }
            if (fromMe) {
                query += `fromMe=${fromMe}&`
            }
            if (ids.length > 0) {
                for (let i = 0; i < ids.length; i++) {
                    query += `ids=${ids[i]}&`
                }
            }
            const { data } = await api.get(`api/emails${query}`)
            setEmails(data.messages || [])
        } catch (err) {
            console.log(err)
        }
        setIsloading(false)
    }

    async function getAccounts() {

        try {
            const { data } = await api.post('api/getAccounts')
            setAccounts(data.googleAccounts || [])
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        getAccounts()
    }, [])

    useEffect(() => {

        const handleNewEmail = (email) => {
            setEmails(oldEmails => [{ ...email, isNew: true }, ...oldEmails])
        };

        for (let i = 0; i < accounts.length; i++) {

            socket?.on(`NEW_EMAIL_${accounts[i].id}${accounts[i].name}`, handleNewEmail);
        }

        return () => {
            for (let i = 0; i < accounts.length; i++) {
                socket?.off(`NEW_EMAIL_${accounts[i].id}${accounts[i].name}`, handleNewEmail);
            }
        };

    }, [accounts, socket]);

    useEffect(() => {
        getEmails(name, fromMe, ids);
    }, [reload, fromMe, ids])

    function handleChangeText(text) {
        setName(text)
        if (!text) {
            setReload(!reload)
        }
    }
    return (
        <>
            <Helmet title="Bandeja de entrada" />

            <Typography variant="h3" gutterBottom display="inline">
                Bandeja de entrada
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Chat
                </Link>
                <Link >Bandeja de entrada</Link>
            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Grid
                container
                alignItems="center"
                justifyContent="center"

            >
                <Grid item xs={4}>
                    <TextField fullWidth label="Buscar" variant="outlined" onChange={(e) => handleChangeText(e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={() => setReload(!reload)}>Buscar</Button>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        select
                        label="Tipo"
                        value={fromMe}
                        onChange={(e) => setFromMe(e.target.value)}
                        variant="outlined"
                        color="primary"
                        fullWidth
                    >
                        {[{ id: null, name: 'Todos' }, { id: '0', name: 'Recibidos' }, { id: '1', name: 'Enviados' }].map((kind, key) => (
                            <MenuItem key={key} value={kind.id}>
                                {kind.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <MultiSelect
                        style={{ width: '100%' }}
                        title="Correos"
                        theme={null}
                        list={accounts}
                        onChange={(data) => { setIds(data) }}
                        initSelect={ids}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={5}
                sx={{
                    height: '80vh',
                    overflowY: 'auto',
                }}
            >


                {
                    isLoading

                        ?
                        <LoadingCircles />
                        :

                        emails.length === 0 ?
                            <NoDataFound
                                mainText={"No se encontraron correos"}
                                secondaryText={"Por favor, revisa tu configuración o intenta nuevamente más tarde."}
                            />
                            :
                            emails.map((email, key) => (
                                <EmailItem key={key} email={email} />
                            ))
                }


            </Grid>

        </>
    )
}