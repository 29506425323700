import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle } from '@mui/material';

import MassiveEmail from '../../pages/MassiveEmail';


export default function SendEmailModal({  open, setOpen, emails = [], account, subject }) {

    /**
     * Carga los nuevos emails
     */
    useEffect(() => {
        console.log(account)
        console.log(emails)
        setEmailList(emails)
    }, [emails]);



    /**
     * Variable para guardar los mensajes
     */
    const [emailList, setEmailList] = useState([]);

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"
            >
             
                <DialogContent dividers>
                    <MassiveEmail
                        isInModal
                        externalClientList={emailList}
                        callBack={()=>{setOpen(false)}}
                        account={account}
                        externalSubject={subject}
                    />
                </DialogContent>
                <DialogActions>
                    <Button  variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
}
