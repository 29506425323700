import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';
import MultiSelect from '../MultiSelect';

export default function AddFilesModal({ title, open, setOpen, onSave, theme,selectedF }) {


    const [files, setFiles] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        setFiles(null);

        async function getFiles() {
            try {
                const { data } = await api.post("/api/getLogFile");
                setFiles(data.files);
            } catch (err) {
                console.log(err);
            }
        }

        getFiles();
    }, [])

    useEffect(()=>{

        if(selectedF){
            setSelectedFiles(selectedF);
        }else{
            setSelectedFiles([]);
        }
    },[selectedF])
    

    const handleClose = () => {
        setOpen(false);
    }


    const handleChange = (selectedT) =>{

        console.log(selectedT);
        setSelectedFiles(selectedT);
    }


    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                title="Archivos"
                                theme={theme}
                                list={files}
                                onChange={handleChange}
                                initSelect={selectedFiles}
                            />

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { onSave(selectedFiles); }}>

                        Adjuntar archivos

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
