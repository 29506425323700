import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import {  Dialog, DialogTitle, FormControlLabel, FormGroup, Grid, MenuItem, TextField } from '@mui/material';



export default function EstateModal({ title, open, setOpen, onSave, theme, currentEstate, templates,whatsapps}) {


    useEffect(() => {

        if (currentEstate) {
            setEstatenInfo(currentEstate);
        } else {
            
        }
    }, [currentEstate]);

    /**
     * Variable para el nuevo perfil
     */
    const [estateInfo, setEstatenInfo] = useState({});

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setEstatenInfo({
            ...estateInfo,
            [event.name]: event.value,
        })

    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                       
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={estateInfo.wpSessionID}
                                label="Whatsapp"
                                name="wpSesionID"
                                variant="outlined"
                                onChange={handleChange}
                                select

                            >
                                {whatsapps.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={estateInfo.tempalteID}
                                label="Plantilla"
                                name="templateID"
                                variant="outlined"
                                onChange={handleChange}
                                select

                            >
                                {templates.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                       

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(estateInfo)}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
