import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';



import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TemplateModal from '../../components/TemplateModal'
import { Helmet } from 'react-helmet-async';
import ShareModal from '../../components/ShareModal';





/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Templates({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [templates, setTemplates] = useState([]);

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);

  /**
   * Guarda el tepmlate seleccionado
   */
  const [current, setCurrent] = useState({});

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**
  * Speech para corregir a la hora de enviar
  */
  const [speechs, setSpeechs] = useState([]);


  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const [openShareModal, setOpenShareModal] = useState(false);

  const [advisers, setAdvisers] = useState([]);


  const [filterName, setFilterName] = useState(null);
  /**
   * Carga la info necesaria
   */
  React.useEffect(() => {

    setOpen(false);
    setOpenShareModal(false);
  

    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);




      } catch (err) {
        console.log(err)
      }
    }

    async function getSpeechs() {
      const { data } = await api.post("api/getSpeech");

      setSpeechs(data.words);
    }

    async function getAdvisers() {

      try {

        const { data } = await api.get("api/users/all");
        setAdvisers(data);


      } catch (err) {
        console.log(err);
      }


    }
    async function getFilteredTempaltes() {

      try {


        const { data } = await api.post("/api/getTemplate");
        setTemplates(data.messageTemplates);

      } catch (err) {
        console.log(err)
      }
    }



    getAdvisers();

    getSpeechs();

    getSessions();

  }, [reload]);


  /**
   * Carga los templates filtrados
   */
  React.useEffect(() => {


    async function getFilteredTempaltes() {

      try {
        let request = {};
        if (filterName) {
          request.name = filterName
        }


        const { data } = await api.post("/api/getTemplate", request);
        setTemplates(data.messageTemplates);

      } catch (err) {
        console.log(err)
      }
    }


    getFilteredTempaltes();



  }, [filterName, reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      headerName: 'Editar', field: 'bitacora', flex: 1, hide: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => { if (params.row.type == "Correo") { window.location.replace('/plantillas/email?templateID=' + params.row.id); return; }if (params.row.type == "Cotizacion") { window.location.replace('/plantillas/cotizacion?templateID=' + params.row.id); return; } setCurrent(params.row); setOpen(true) }}>Editar</Button>

        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
      
    },
    {
      field: 'owner', headerName: 'Propietario', flex: 1, editable: false,
      renderCell: (params) => {

        const user = advisers.filter((adviser)=>adviser.id == params.row.owner)

        return (
          (user.length > 0 &&  user[0].firstName) || 'Sin dueño'
        )
      }
    },
   
    {
      field: 'userModified', headerName: 'Modificó', flex: 1, editable: false,
      renderCell: (params) => {

        const user = advisers.filter((adviser)=>adviser.id == params.row.userModified)

        return (
          (user.length > 0 &&  user[0].firstName) || ''
        )
      }

    },
    {
      field: 'updatedAt', headerName: 'Ultima modificación', flex: 1, editable: false,
    },
    {
      field: 'message',
      headerName: 'Plantilla mensaje',
      flex: 1,
      editable: false,
    },
    {
      field: 'filePath', headerName: 'Archivo', flex: 1, editable: false,
    },
    {
      field: 'type', headerName: 'Tipo de plantilla', flex: 1, editable: false,
    },
  ];

  /**
   * Elimina un template
   * @param {*} id 
   */
  const deleteTemplate = async (dataTemplate) => {

    dataTemplate.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteTemplate", { id: ss.id });

        if (data.status == "Success") {
          enqueueSnackbar(`Plantilla eliminada`, {
            variant: 'success',
          });
        } else {
          if (data?.code) {
            enqueueSnackbar(data.userMsg, {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(`Ocurrió un error al eliminar la plantilla ${ss.name}`, {
              variant: 'error',
            });
          }

        }


      } catch (err) {
        console.log(err)
        enqueueSnackbar(`Ocurrió un error al eliminar la plantilla ${ss.name}`, {
          variant: 'error',
        });
      }
    });

    setReload(!reload);


  }
  /**
   * Crea una nueva plantilla
   * @param {*} data 
   */
  const addTemplate = async (newTemplate, files, words) => {


    try {


      const request = {
        id: newTemplate.id && newTemplate.id != 0 ? newTemplate.id : 0,
        templateObj: newTemplate,
        words: words

      }


      const { data } = await api.post("/api/createTemplate", request);


      if (data.status == "Success") {

        let idTemplateTemp = data?.template?.id;

        if (!newTemplate.id || newTemplate.id == 0) {
          try {

            //  console.log({ id:idTemplateTemp, files: files});
            const { data } = await api.post("/api/addTempFile", { id: idTemplateTemp, files: files });

            if (data.status == "Success") {
              // console.log(data);

            } else {
              enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
                variant: 'error',
              });
              console.log(data);
            }
          } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
              variant: 'error',
            });

          }
        }
        enqueueSnackbar('Se creó la plantilla', {
          variant: 'success',
        });


      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(`Ocurrió un error al crear la plantilla`, {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear la plantilla', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }

  /**
   * Comparte una plantilla con varios usuarios
   */
  const shareTemplate = async (users) => {

    try {

      const { data } = await api.post('/api/massive/share', { userIDs: users, templates: selectedTemplates });
      if (data.status == "Success") {
        enqueueSnackbar(`Se compartió la plantilla de forma correcta`, {
          variant: 'success',
        });
        setReload(!reload)
      } else {
        enqueueSnackbar(`Ocurrió un error al compartir la plantilla`, {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al compartir la plantilla', {
        variant: 'error',
      });
    }
  }
  /**
   * Sube un archivo y lo guarda en files
   * @param {*} file 
   */
  const addFile = async (file, templateId) => {

    /**
     * Envia el archivo para subirlo
     */
    const formData = new FormData();
    formData.append('file', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    let filePath = '';

    try {
      const { data } = await api.patch('/api/upload', formData, config);

      /**
       * Recupera la ruta del archivo subido
       */
      filePath = data.fielName;
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }

    if (!filePath) {

      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }

    /**
     * Registra el archivo den la tabla FILES
     */

    try {
      const { data } = await api.post("/api/addFiles", { files: [filePath] });

      /**
       * Si ya esta creado el webhook
       * Se agrega el archivo
       */

      let fileIds = data.files;

      if (templateId) {

        try {
          const { data } = await api.post("/api/addTempFile", { id: templateId, files: fileIds });

          if (data.status == "Success") {
            return true;

          } else {
            enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
              variant: 'error',
            });
            return null;
          }
        } catch (err) {
          console.log(err);
          enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
            variant: 'error',
          });
          return null;
        }

      }
      return { path: filePath, id: data.files[0] };

    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }
  }

  /**
   * Elimina la relación de un archivo
   * @param {*} file 
   */
  const deleteFileRel = async (fileId, templateId) => {


    /**
     * Si no hay webhook no tiene sentido eliminar la relacion
     */
    if (!templateId || templateId == 0) {
      return;
    }

    /**
     * Registra el archivo den la tabla FILES
     */

    try {

      /**
       * Si ya esta creado el webhook
       * Se elimina el archivo
       */


      try {
        const { data } = await api.post("/api/removeTempFile", { id: templateId, files: [fileId] });

        if (data.status == "Success") {
          return true;

        } else {
          enqueueSnackbar(`Ocurrió un error al eliminar el archivo`, {
            variant: 'error',
          });
          return null;
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar(`Ocurrió un error al eliminar el archivo`, {
          variant: 'error',
        });
        return null;
      }

    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al eliminar el archivo`, {
        variant: 'error',
      });
      return null;
    }
  }

  /**
   * Regresa los usuarios con los que se ha compartido una plantilla
   * Solo se usa cuando se selecciona una plantilla y no varias
   */
  const getSharedUsers = () => {

    if (selectedTemplates.length == 1) {
      const userIDs = selectedTemplates[0].Users.map(u => u.id)
      return userIDs
    } else {
      return [];
    }

  }


  return (
    <>

      <Helmet title="Plantillas" />

      <Typography variant="h3" gutterBottom display="inline">
        Plantillas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Plantillas</Link>
        {
          revisaPermisos(['Agregar Plantilla']) &&
          <Button variant='outlined' onClick={() => { setOpen(!open); setCurrent({}) }} >Nueva plantilla</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          align="center"
          sx={{ height: '10%' }}
        >
          <TextField
            label="Buscar plantilla"
            fullWidth
            onChange={(e) => { setFilterName(e.target.value) }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          align="center"
          sx={{ height: '10%' }}
        >
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '90%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={templates}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar Plantilla'])}
            onDelete={(data) => { deleteTemplate(data); }}
            onsSelectionChange={(data) => { setSelectedTemplates(data) }}
            showShareButton={revisaPermisos(['Compartir plantillas'])}
            onShare={() => { setOpenShareModal(true) }}
          />
          {
            revisaPermisos(['Agregar Plantilla']) &&
            <>


              <TemplateModal
                title={"Nueva plantilla"}
                open={open}
                setOpen={setOpen}
                template={current}
                theme={theme}
                onSave={addTemplate}
                addFile={addFile}
                removeFile={deleteFileRel}
                wordList={speechs}
              />
            </>
          }

        </Grid>
      </Grid>
      {
        revisaPermisos(['Compartir plantillas']) &&
        <ShareModal
          open={openShareModal}
          setOpen={setOpenShareModal}
          theme={null}
          title={"Compartir plantilla"}
          users={advisers}
          onSave={shareTemplate}
          selectedUsers={getSharedUsers()}
        />

      }

    </>
  );
}
