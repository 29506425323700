import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";


import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import api from "../../services/api";
import { SocketContext } from "../../services/socket";

const SendMassiveMailModal = ({ open, setOpen, title, theme, emailList }) => {

    const [id, setId] = useState(0);

    const [clientList, setClientList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [groupName, setGroupName] = useState('');

    const [subject, setSubject] = useState('');

    const [idTemplate, setIdTemplate] = useState(null);

    const [selectedAccount, setSelectedAccount] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    /**
    * Socket para el envio masivo
    */
    const socket = useContext(SocketContext);

    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }

        async function getTemplates() {
            try {
                const { data } = await api.post("/api/getTemplate");
                setTemplates(data.messageTemplates);

            } catch (err) {
                console.log(err);
            }
        }

        getTemplates();
        getaccounts();
    }, [])

    useEffect(() => {
        setIsLoading(false)
        setId(null)
        setIdTemplate(null);
        setGroupName('');
        setSubject('');
        setSelectedAccount(null);
    }, [open])


    useEffect(() => {
        setClientList(emailList)
    }, [emailList])


    const sendMassiveMail = async () => {

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...clientList];

        if (!groupName) {
            enqueueSnackbar(`El nombre del grupo es requerido`, {
                variant: 'warning',
            });
            return false;
        }

        if (!subject) {
            enqueueSnackbar(`El asunto del correo es requerido`, {
                variant: 'warning',
            });
            return false;
        }

        if (!idTemplate) {
            enqueueSnackbar(`La plantilla es requerida`, {
                variant: 'warning',
            });
            return false;
        }

        if (!selectedAccount) {
            enqueueSnackbar(`La cuenta de google es requerida`, {
                variant: 'warning',
            });
            return false;
        }
        setIsLoading(true);
        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: groupName,
            forwardingId: 0,
            wpId: selectedAccount.name,
            isMedia: true,
            templateId: idTemplate,
            body: '',
            kind: 'Email',
            subject: subject,
            accountID: selectedAccount.id,
            mediaUrl: '',
            twilioPhone: '',
            delay: 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);

            //  console.log(data);
            if (data.status == "Success") {

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} correos`, {
                    variant: 'success',
                });

                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    delay: 1000,
                }

                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveMail", socketObj);


            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar los correos`, {
                variant: 'error',
            });
        }

        setIsLoading(false);
        handleClose();
    }



    const handleClose = () => {
        setOpen(false);

    }

    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle >

            <DialogContent dividers>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            label="Nombre del grupo de envio"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                            disabled={isLoading}
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            label="Asunto del correo"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                            disabled={isLoading}
                        />

                    </Grid>



                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Cuenta de Google"
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                            disabled={isLoading}
                        >
                            {accounts?.map((account) => (
                                <MenuItem key={account.id} value={account}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField

                            select
                            label="Plantilla"
                            value={idTemplate}
                            onChange={(e) => setIdTemplate(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                            disabled={isLoading}
                        >
                            {templates?.map((template) => {

                                if (template.type != "Llamada") {
                                    return (
                                        <MenuItem key={template.id} value={template.id}>
                                            {template.name}
                                        </MenuItem>
                                    )
                                }

                            })}
                        </TextField>
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                    Cancelar
                </Button>
                <LoadingButton theme={theme} loading={isLoading} variant="contained" color="primary" onClick={() => sendMassiveMail()}>

                    Enviar correos

                </LoadingButton>
            </DialogActions>
        </Dialog>

    )

}

export default SendMassiveMailModal;