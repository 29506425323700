
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ContactsTable from '../ContactsTable';
import MassiveTable from '../MassiveTable';


/**
 * Estilos para ventana modal
 */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#2e3b55;',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,

};

/**
 * Componente principal
 * @returns 
 */
export default function ContactsListModal({ open, setOpen, contacts, setContacts,tagsList }) {


  /**
   * Cierra el modal
   */
  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid
          container

          sx={{ padding: '20px', height: '90vh' }}
          alignItems="center"
          justify="center"
        >

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            align="center"
            sx={{ height: '100%' }}
          >
            <ContactsTable
              massiveMsg={contacts}
              onDelete={() => { }}
              setCurrent={setContacts}
              tagsList={tagsList}
            />
          </Grid>
        </Grid>


      </Modal>
    </div>
  );
}