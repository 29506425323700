
import { useEffect, useState } from 'react';
import api from "../../services/api";
import { TextField, Button, Fab, Grid, IconButton, Typography, Breadcrumbs, Link, Divider, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material'
import { MenuItem } from '@mui/material';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import CustomFilter from '../../components/CustomFilter';

import useTheme from "../../hooks/useTheme";
import { Helmet } from 'react-helmet-async';
import UserEditComplement from '../../components/UserEditComplement';

/**
 * Compara la lista de permisos con los permisos asignados
 */
const revisaPermisos = (allow, list, minMatch = 1) => {

  let count = 0;

  for (let i = 0; i < allow.length; i++) {
    for (let j = 0; j < list.length; j++) {

      if (allow[i] == list[j]) {
        count++;
      }
    }
  }

  return count >= minMatch;
}




function Users() {

  const { theme } = useTheme();

  const styles = {
    modal: {
      position: 'absolute',
      width: 500,
      backgroundColor: 'white',
      border: '2 px solid #00',
      //  boxshadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3, 4),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },


  } //* Estilo de la ventana modal Agregar Usuarios 

  const [data, setData] = useState([])
  const [modalAgregar, setModalAgregar] = useState(false); // Abrir cerrar modal agregar 
  const [modalEditar, setModalEditar] = useState(false); // Abrir cerrar modal editar 
  const [perfilseleccionado, setPerfilSeleccionado] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    profile: 1,
    isOwner: 0
  })

  /**
      * Variable pararecargar la tabla sin recargar la página
      */
  const [reload, setReload] = useState(false);
  /**
   * Variable para mostrar la página de carga
   */
  const [isLoading, setIsLoading] = useState(true);
  /**
   * Notifiaciones
   */
  const { enqueueSnackbar } = useSnackbar();

  /**
 * Pasa los permisos a todas las vistas
 */
  const [permissions, setPermissions] = useState([]);

  /**
   * Pasa las sesiones a todas vistas
   */
  const [sessions, setSessions] = useState([]);

  const [listaPerfiles, setListaPerfiles] = useState([])

  /**
     * Cada que cambien  los datos filtra de nuevo
     */
  useEffect(() => {
    setFiltered(data);
  }, [data])

  /**
   * Texto de búsqueda
   */
  const [searchText, setSearchText] = useState('');

  /**
  * Guarda los datos filtrados
  */
  const [filtered, setFiltered] = useState(data);

  /**
  * Guarda los datos de lso filtros
  */
  const [latsFilters, setLatsFilters] = useState({});

  /**
   * Variable que contiene la lista de etiquetas
   */
  const [tags, setTags] = useState([]);

  /**
  * Variable que contiene la lista de las paginas
  */
  const [fbPages, setFbPages] = useState([]);
  /**
   * Variable que contiene la lista de perfiles
   */
  const [systemNames, setSystemNames] = useState([]);

  const [userData, setUserData] = useState({});

  const [isOwner, setIsOwner] = useState(0);

  /**
   * Busca el valor que conincida con lo seleccionado en los filtros
   * @param {*} searchValue 
   */
  const secondFilter = (coumnName, value) => {

    const filterValues = {
      ...latsFilters,
      [coumnName]: value
    }

    let finalResults = null;


    setLatsFilters(filterValues);
    finalResults = data.filter(function (item) {

      let isInFilter = true;

      for (var key in filterValues) {


        const newKey = key.split('.');


        if (newKey.length > 1) {

          if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

            isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

          } else {
            if (!item[newKey[0]][newKey[1]]) {

              isInFilter = isInFilter && filterValues[key] == '';
              continue;
            }
            if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

              isInFilter = isInFilter && false;
            }
          }


        } else {

          if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

            isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

          } else {

            if (!item[key]) {
              isInFilter = isInFilter && filterValues[key] == '';
              continue;
            }
            if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

              isInFilter = isInFilter && false;
            }
          }
        }


      }
      return isInFilter;
    });

    setFiltered(finalResults);
  };
  //* Guardar datos al agregar perfil

  const handleChange = e => {
    const { name, value } = e.target; // recibe el name y value 
    setPerfilSeleccionado(prevState => ({
      ...prevState,// llena el objeto con los valores pasados 
      [name]: value
    }))
    //console.log(perfilseleccionado)
  }// capturar los datos que el usuario ingresa al agregar perfil 

  /**
   * Recupera todos los permisos y sesiones
   */
  const getPermissions = async () => {

    try {

      const userInfo = await api.post("/api/getPermissions");
      //console.log(userInfo.data);

      setPermissions(userInfo.data.userInfo.permissions)
      setSessions(userInfo.data.userInfo.wpsessions);
      setIsOwner(userInfo.data.userInfo.isOwner);
    } catch (err) {
      console.log(err);
    }


  }

  useEffect(() => {
    /**
         * Consulta los sistemas 
         * Son necesarios en caso de querer asignar un sistema
         */
    async function getSystemNames() {
      try {
        const { data } = await api.post("/api/getSessions");
        //console.log(data);
        if (data.status == 'Success') {
          setSystemNames(data.sessions)
        } else {
          enqueueSnackbar('Ocurrió un error al consultar los sistemas', {
            variant: 'error',
          });
        }
      } catch (err) {
        enqueueSnackbar('Ocurrió un error al consultar los sistemas', {
          variant: 'error',
        });
        console.log(err);
      }

    }


    async function getTags() {

      try {
        const { data } = await api.post("/api/getTags");

        setTags(data.tags);
      } catch (err) {
        console.log(err)
      }
    }

    async function getFbPages() {

      try {
        const { data } = await api.post("/api/userpages/profile/lite");
        setFbPages(data.pages);
      } catch (err) {
        console.log(err)
      }
    }
    getFbPages();
    getSystemNames();
    getTags();



    api.get(`/api/v2/users/all`)
      .then(res => {
        setData(res.data);
        // console.log(res.data)
        setIsLoading(false);
      })
    api.get(`/api/profile/list`)
      .then(res => {
        //console.log(res.data)
        setListaPerfiles(res.data.profiles);
      }

      )

    getPermissions();

  }, [reload]) //* Conectar con el Backend 


  /**
   * Manda peticion para agregar usuario
   */
  const agregarUsuario = async (edit) => {
    /**
     * Valida todos los campos
     */

    if (!perfilseleccionado.firstName || perfilseleccionado.firstName == '') {
      enqueueSnackbar('El nombre es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.lastName || perfilseleccionado.lastName == '') {
      enqueueSnackbar('El apellido es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.email || perfilseleccionado.email == '') {
      enqueueSnackbar('El correo es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.profile || perfilseleccionado.profile == 0) {
      enqueueSnackbar('El perfil es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.password && !edit) {
      enqueueSnackbar('La contraseña es requerida', {
        variant: 'warning',
      });
      return false;
    }
    setModalAgregar(false);
    setModalEditar(false);
    setIsLoading(true);
    /**
     * Eliminamos las propiedades que no son necesarias para crear un usuario
     */

    let newUser = {
      email: perfilseleccionado.email,
      firstName: perfilseleccionado.firstName,
      lastName: perfilseleccionado.lastName,
      profile: perfilseleccionado.profile,
      isOwner: perfilseleccionado.isOwner,
      showAllWhatsapps: userData.showAllWhatsapps,
      showAllFbPages: userData.showAllFbPages,
      showAllTags:  userData.showAllTags,
      defautlAccountId: userData.defautlAccountId
    }
    /**
     * Agrega la contraseña solo si se cambió
     */
    if (perfilseleccionado.password) {
      newUser.password = perfilseleccionado.password;
    }
    let requestData = {
      userObj: newUser,
      wpsessions: userData.wpsersystems,
      tags: userData.tags,
      tagsWithoutChat: userData.tagsWithoutChat,
      pages: userData.userpages,
      googleAccounts: userData.googleAccounts,
    }

    if (edit) {
      requestData.id = perfilseleccionado.id;

    }




    try {
      const { data } = await api.post(`/api/v2/users/add`, requestData);
      /**
       * Si responde correctamente 
       * Manda un mensaje al usuario
       */


      if (data.status == 'Success') {
        setReload(!reload);
        enqueueSnackbar(`Usuario ${edit ? 'editado' : 'agregado'} correctamente`, {
          variant: 'success',
        });
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrio un error al agregar el usuario', {
            variant: 'error',
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Ocurrio un error inesperado, intenta más tarde', {
        variant: 'error',
      });
      console.log(err);
    }

    setIsLoading(false);

  } //enviar los datos que el usuario escribio al backend al agregar un nuevo perfil



  /**
   * Elimina el usuario seleccionado
   * @param {*} id 
   */
  const eliminarUsuario = async (user) => {

    const confirm = window.confirm(`¿Desea eliminar el usuario ${user.firstName}?`);

    /**
     * Si cancela la opreción
     * temianmos aqui
     */
    if (!confirm) {
      return false;
    }
    setIsLoading(true);
    try {
      const { data } = await api.put(`/api/users/del`, { id: user.id });
      /**
       * Si responde correctamente 
       * Manda un mensaje al usuario
       */
      if (data.status == 'Success') {
        setReload(!reload);
        enqueueSnackbar('Usuario eliminado correctamente', {
          variant: 'success',
        });
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrio un error al eliminar ¿ el usuario', {
            variant: 'error',
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Ocurrio un error inesperado, intenta más tarde', {
        variant: 'error',
      });
      console.log(err);
    }

    setIsLoading(false);
  }

  const seleccionarPerfil = (firstName, caso) => {

    delete firstName.password;
    setPerfilSeleccionado(firstName);
    (caso === "Editar") && abrirCerrarModalEditar();

  }



  const abrirCerrarModalinsertar = () => {
    setPerfilSeleccionado({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      profile: 1
    })
    setModalAgregar(!modalAgregar);
  } // abrir o cerrar el modal agregar usuario 

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  } // abrir o cerrar el modal agregar usuario 



  return (
    <>
      <Helmet title="Usuarios" />

      <Typography variant="h3" gutterBottom display="inline">
        Usuarios
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Administracion
        </Link>
        <Link >Usuarios</Link>
        {revisaPermisos(['Agregar Usuarios'], permissions) &&

          <Button
            variant="outlined"
            onClick={() => abrirCerrarModalinsertar()}
          >
            Nuevo usuario
          </Button>


        }
      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Loading open={isLoading} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            id="id"
            rows={filtered}
            columns={[
              {
                headerName: 'Editar', field: 'test', flex: 1, hide: true, permissions,
                renderCell: (params) => {

                  return (
                    <IconButton onClick={() => { seleccionarPerfil(params.row, 'Editar') }}><EditIcon color="primary"></EditIcon></IconButton>
                  )
                }
              },
              {
                headerName: 'Eliminar', field: 'test1', flex: 1, hide: false,
                renderCell: (params) => {

                  return (
                    <IconButton onClick={() => { eliminarUsuario(params.row); }}><Delete color="error"></Delete></IconButton>
                  )
                }
              },
              {
                headerName: 'Nombre', field: 'firstName', flex: 1, hide: false,
                renderHeader: (props) => {
                  return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                },
              },
              {
                headerName: 'Apellido', field: 'lastName', flex: 1, hide: false,
                renderHeader: (props) => {
                  return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                },
              },
              {
                headerName: 'Perfil', field: 'Profile.name', flex: 1, hide: false, valueGetter: (params) => {

                  for (let profile in listaPerfiles) {

                    if (listaPerfiles[profile].id === params.row.profile) {
                      return listaPerfiles[profile].name;
                    }
                  }
                },
                renderHeader: (props) => {
                  return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                },
              },
              {
                headerName: 'Correo', field: 'email', flex: 1, hide: false,
                renderHeader: (props) => {
                  return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                },
              },

            ]}
            columnVisibilityModel={{ test: revisaPermisos(['Modificar Usuarios'], permissions), test1: revisaPermisos(['Eliminar Usuarios'], permissions) }}

          />


        </Grid>
      </Grid>


      <Dialog
        disableEnforceFocus
        onClose={abrirCerrarModalinsertar}
        aria-labelledby="customized-dialog-title"
        open={modalAgregar}
      >
        <DialogTitle id="customized-dialog-title" onClose={abrirCerrarModalinsertar}>
          <Grid container>
            <Grid item xs={6}>
              Agregar Usuario
            </Grid>
            <Grid item xs={6}>
              {isOwner && <FormControlLabel
                control={
                  <Checkbox
                    checked={perfilseleccionado.isOwner == 1}
                    onChange={(e) => {
                      setPerfilSeleccionado(prevState => ({
                        ...prevState,
                        isOwner: e.target.checked ? 1 : 0
                      }))
                    }}
                    inputProps={{ 'aria-label': 'controlled' }} />
                }
                label="administrador" />}
            </Grid>
          </Grid>
        </DialogTitle >

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField fullWidth label='Nombre de Usuario' name="firstName" onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>

              <TextField fullWidth label='Apellidos' name="lastName" onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth label='Correo' name="email" onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth id="outlined-select-currency"
                select
                label="Select"
                value={perfilseleccionado.profile}
                name="profile"

                onChange={handleChange}
              >
                {listaPerfiles.map((perfil, index) => (
                  <MenuItem key={perfil.id} value={perfil.id}>
                    {perfil.name}
                  </MenuItem>
                ))}</TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth label='Contraseña' name="password" onChange={handleChange} type="password" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <UserEditComplement
              newProfile={perfilseleccionado}
              pages={fbPages}
              systemNames={systemNames}
              tags={tags}
              onChange={(userData) => setUserData(userData)}
              showTags={revisaPermisos(['Asignar etiquetas'], permissions)}
              showWhatsapps={revisaPermisos(['Asignar whatsapps'], permissions)}
              showFbPages={revisaPermisos(['Asignar páginas de FB'], permissions)}
              showAccounts={revisaPermisos(['Asignar cuentas de google'], permissions)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>

          <Button color='error' variant="outlined" onClick={() => abrirCerrarModalinsertar()}>Cancelar</Button>
          <Button color='primary' variant="outlined" onClick={() => agregarUsuario(false)}>Agregar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableEnforceFocus
        onClose={abrirCerrarModalEditar}
        aria-labelledby="customized-dialog-title"
        open={modalEditar}
      >
        <DialogTitle id="customized-dialog-title" onClose={abrirCerrarModalEditar}>
          <Grid container>
            <Grid item xs={6}>
              Editar Usuario
            </Grid>
            <Grid item xs={6}>
              {isOwner && <FormControlLabel
                control={
                  <Checkbox
                    checked={perfilseleccionado.isOwner == 1}
                    onChange={(e) => {
                      setPerfilSeleccionado(prevState => ({
                        ...prevState,
                        isOwner: e.target.checked ? 1 : 0
                      }))
                    }}
                    inputProps={{ 'aria-label': 'controlled' }} />
                }
                label="administrador" />}
            </Grid>
          </Grid>
        </DialogTitle >

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} fullWidth label='Nombre de Usuario' name="firstName" onChange={handleChange} value={perfilseleccionado && perfilseleccionado.firstName} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>

              <TextField disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} fullWidth label='Apellidos' name="lastName" onChange={handleChange} value={perfilseleccionado && perfilseleccionado.lastName} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} fullWidth label='Correo' name="email" onChange={handleChange} value={perfilseleccionado && perfilseleccionado.email} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} fullWidth id="outlined-select-currency"
                select
                label="Select"
                name="profile"

                value={perfilseleccionado.profile}
                onChange={handleChange}
              >
                {listaPerfiles.map((perfil, index) => (
                  <MenuItem key={perfil.id} value={perfil.id}>
                    {perfil.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} autoComplete="new-password" fullWidth label='Contraseña' name="password" onChange={handleChange} value={perfilseleccionado.password} type="password" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <UserEditComplement
              newProfile={perfilseleccionado}
              pages={fbPages}
              systemNames={systemNames}
              tags={tags}
              onChange={(userData) => setUserData(userData)}
              showTags={revisaPermisos(['Asignar etiquetas'], permissions) && !(perfilseleccionado.isOwner == 1 && isOwner != 1)}
              showWhatsapps={revisaPermisos(['Asignar whatsapps'], permissions) && !(perfilseleccionado.isOwner == 1 && isOwner != 1)}
              showFbPages={revisaPermisos(['Asignar páginas de FB'], permissions) && !(perfilseleccionado.isOwner == 1 && isOwner != 1)}
              showAccounts={revisaPermisos(['Asignar cuentas de google'], permissions) && !(perfilseleccionado.isOwner == 1 && isOwner != 1)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>

          <Button color='error' variant="outlined" onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
          <Button disabled={perfilseleccionado.isOwner == 1 && isOwner != 1} color='primary' variant="outlined" onClick={() => agregarUsuario(true)}>Guardar</Button>
        </DialogActions>
      </Dialog>

      {
        /*revisaPermisos(['Agregar Usuarios'], permissions) && (
    
          <Fab
    
            color="primary"
            aria-label="add"
            sx={{ position: 'fixed', bottom: 16, right: 'calc(50% - 20px)' }}
            onClick={() => abrirCerrarModalinsertar()}
          >
            <Add />
          </Fab>
    
        )*/
      }


    </>



  );
}
export default Users;