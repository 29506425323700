import './styles.css'

export default function LoadingCircles({loadingText}) {
    return (
        <div className='containerLoading'>
            <div className='dot1'></div>
            <div className='dot2'></div>
            <div className='dot3'></div>
            <div className="text">{loadingText || 'Cargando...'}</div>
        </div>
    )
}