import { MoreVert } from "@mui/icons-material";
import { Avatar, Card, CardHeader, IconButton, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../services/api";

export default function ChatMessagesHeader({ client, setEmailMessages}) {

    const [emails, setEmails] = useState([])
    const [accountID, setAccountID] = useState(0)

    async function getUserEmails() {
        try {
            const { data } = await api.post('/api/getAccounts')
            setEmails(data.googleAccounts)
        } catch (err) {

        }
    }
    useEffect(() => {
        getUserEmails()

    }, [])

    useEffect(() => {
        if (accountID == 0) {
            setEmailMessages([]);
        } else {
            getEmails(accountID);
        }

    }, [accountID])

    async function getEmails(id) {
        try {
           
            const { data } = await api.get(`/api/google/mail?id=${id}&clientID=${client.Client.id}&maxResults=10`)
            setEmailMessages(data.messagesList || []);
            
        } catch (err) {

        }

    }
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: client.WpSession.color }} aria-label="recipe">
                        {client?.Client?.firstName && client?.Client?.firstName[0] || client?.name && client.name[0] || 'A'}
                    </Avatar>
                }
                action={
                    <TextField
                        disabled={!client?.Client?.email}
                        select
                        label="Cuenta de Google"
                        value={accountID}
                        onChange={(e) => setAccountID(e.target.value)}
                        variant="filled"
                        color="primary"
                        fullWidth

                    // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                    >
                        <MenuItem value={0}>
                            Whatsapp
                        </MenuItem>
                        {emails?.map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                                {account.name}
                            </MenuItem>
                        ))}
                    </TextField>
                }
                title={client?.name}

            />

        </Card>
    )
}