
/**MUI */
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';


import api from '../../services/api';
import Papa from "papaparse";
import { Add, AddToDrive, ConstructionOutlined, ContentPaste, Download, Event, FeaturedPlayList, FileUpload, HighlightOff, Storage } from '@mui/icons-material';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { Breadcrumbs, Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Link, TextField, Typography, withStyles } from '@mui/material';
import DatabaseTable from '../../components/DatabaseTable';
import CallModal from '../../components/CallModal';
import { useSnackbar } from 'notistack';
import { SocketContext } from '../../services/socket';
import MassiveMsgModal from '../../components/MassiveMsgModal';
import DatabaseCard from '../../components/DatabaseCard';
import { IconButton } from '@mui/material';
import GoogleLoginView from '../../components/GoogleLogin';
import { AddCircle } from 'react-ionicons';
import { Helmet } from 'react-helmet-async';
import { read, writeFileXLSX, utils } from "xlsx";
/**
 * Carga la vista para subir contactos a la hoja
 * @param {*} param0 
 */
const PageModal = ({ theme, groupID, databaseID, addClientToDB, setAddClientToDB, onClose }) => {

    const [columns, setColumns] = useState([]);

    const [rows, setRows] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [groups, setGroups] = useState([])


    const [db, setDb] = useState(true)

    const [advisers, _setAdvisers] = useState([]);

    /**
     * 
     */
    const advisersRef = useRef(advisers);

    const setAdvisers = data => {
        advisersRef.current = data;
        _setAdvisers(data);
    }

    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [openCallModal, setOpenCallModal] = useState(false);

    const [openMsgModal, setOpenMsgModal] = useState(false);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);

    const [userId, setUserId] = useState();
    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [currentGroupId, setCurrentGroupId] = useState(null);
    /**
  * Notifiaciones
  */
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {



        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");
                setAdvisers(data);


            } catch (err) {
                console.log(err);
            }


        }


        getAdvisers();



    }, [])

    const onGetUpdate = useCallback((email, status) => {

        let tempAdvisersList = [...advisersRef.current];

        for (let i = 0; i < tempAdvisersList.length; i++) {
            if (tempAdvisersList[i].email == email) {
                tempAdvisersList[i].status = status;
            }
        }

        setAdvisers(tempAdvisersList)
    }, [advisersRef])

    useEffect(() => {

        if (socket) {


            socket.on(`adviserChange`, (adviserObj) => {
                console.log("aSe conectó un asesor");
                //console.log(messageObj)
                onGetUpdate(adviserObj.email, adviserObj.status);
            });


        }

    }, [socket, onGetUpdate])


    const [groupName, setGroupName] = useState('');

    const [currentInputValue, setCurrentInputvalue] = useState(`Nombre 1\tApellido 1\t5500000001\tcorreo1@test.com\tProducto\tCategoria
Nombre 2\tApellido 2\t5500000002\tcorreo2@test.com\tProducto\tCategoria
Nombre 3\tApellido 3\t5500000003\tcorreo3@test.com\tProducto\tCategoria`);

    const [excelRows, setExcelRows] = useState([]);

    const [excelSheets, setExcelSheets] = useState([]);

    const [showInput, setShowInput] = useState(false);


    const [newEmptyBase, setNewEmptyBase] = useState(false);

    const convertToTable = (rows) => {

        let newRows = [];


        rows.map((row, key) => {
            console.log(row)
            row.id = key;
            row.Product = { name: row.Product };
            row.Tag = row?.Tag?.split(',')
            newRows.push(row);
        })

        return newRows;
    }

    const handleChangeFile = (event, isExcel) => {
        // If user clicks the parse button without
        // a file we show a error

        if (isExcel) {
            const reader = new FileReader();
            reader.onload = async (e) => {

                const data = e.target.result;

                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                setExcelSheets(workbook.SheetNames);

                const json = utils.sheet_to_json(worksheet);
                const newRows = convertToTable(json);
                const columns = Object.keys(json[0]);


                setColumns(columns);
                setRows(newRows);

                let sheetDataList = [];

                workbook?.SheetNames?.map((sheetNameTemp) => {
                    let tempRows = convertToTable(utils.sheet_to_json(workbook.Sheets[sheetNameTemp]));
                    sheetDataList.push(tempRows);
                })

                setExcelRows(sheetDataList);
            };
            reader.readAsArrayBuffer(event.target.files[0]);

        } else {

            setExcelRows([]);
            setExcelSheets([]);
            // Initialize a reader which allows user
            // to read any file or blob.
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });

                const parsedData = csv?.data;
                const columns = Object.keys(parsedData[0]);

                csv?.data.map((obj, index) => {
                    if (Object.keys(obj).length == 1) {


                        obj.firstName = "Por Definir"
                        obj.lastName = "Por Definir"
                        obj.status = "Por Definir"
                        obj.country = "Por Definir"
                        obj.city = "Por Definir"
                        obj.gender = "Por Definir"
                        obj.level = "Por Definir"
                    }
                    obj.id = index;
                })

                setColumns(columns);
                setRows(parsedData);

            };
            reader.readAsText(event.target.files[0]);
        }

    }

    const handleShowInput = () => {

        setShowInput(!showInput);

    }

    const validaInput = () => {
        try {

            let arr = currentInputValue.split('\n');



            if (arr[0].split("\t").length == 1) {

                let tempRows = [];

                arr.map((value) => {

                    let tempArrObj = value.split("\t");

                    let newObj = {
                        "id": tempArrObj[0],
                        "phone": tempArrObj[0],
                        "firstName": "Por Definir",
                        "lastName": "Por Definir",
                        "status": "Por Definir",
                        "country": "Por Definir",
                        "city": "Por Definir",
                        "gender": "Por Definir",
                        "level": "Por Definir",
                    }


                    tempRows.push(newObj);
                })

                setRows(tempRows);
                return;

            } else {
                if (arr[0].split("\t").length < 6) {
                    alert("El formato de los datos no es correcto");
                    return;
                }
            }



            let tempRows = [];
            let count = 0;
            arr.map((value) => {

                let tempArrObj = value.split("\t");

                /*let newObj = {
                    "id": tempArrObj[0],
                    "phone": tempArrObj[1],
                    "firstName": tempArrObj[2],
                    "lastName": tempArrObj[3],
                    "status": tempArrObj[4],
                    "country": tempArrObj[5],
                    "city": tempArrObj[6],
                    "gender": tempArrObj[7],
                    "level": tempArrObj[8]
                }*/
                console.log(tempArrObj)
                let newObj = {
                    "id": count,
                    "firstName": tempArrObj[0],
                    "lastName": tempArrObj[1],
                    "phone": tempArrObj[2],
                    "email": tempArrObj[3],
                    "Product": tempArrObj[4],
                    // "Tags":tempArrObj[5].split(","),
                    "Tags": tempArrObj[5],
                    "country": "Por Definir",
                    "city": "Por Definir",
                    "gender": "Por Definir",
                    "level": "Por Definir",
                }
                count++;
                tempRows.push(newObj);
            })

            setRows(tempRows);


        } catch (err) {
            console.log(err);
            alert("El formato de los datos no es correcto");
            return;
        }
    }

    const createGroup = async (name) => {

        if (!name && !addClientToDB) {
            if (!groupName) {
                enqueueSnackbar(`El nombre es requerido`, {
                    variant: 'error',
                });
                return false;
            }

        }


        let clientList = [...rows];

        clientList.map((cl) => {
            delete cl.id;
        })


        try {

            if (addClientToDB) {

                await api.post('api/createPage', {
                    id: databaseID,
                    clients: clientList,
                    advisers: selectedAdvisers
                });
            } else {
                await api.post('api/createPage', {
                    group: {
                        name: name ? name : groupName,
                        owner: userId,
                        bookId: groupID
                    },

                    clients: clientList,
                    advisers: selectedAdvisers
                });
            }


            enqueueSnackbar(`${addClientToDB ? 'Se agregaron los clientes de forma exitosa' : 'Se creó la hoja exitosamente'}`, {
                variant: 'success',
            });

            setRows([]);
            setShowInput(false);
            setNewEmptyBase(false);
            //  setDb(false);
            setAddClientToDB(false);

            if (onClose) {
                onClose();
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al crear el grupo`, {
                variant: 'error',
            });
        }


    }


    return (
        <>
            {
                db && (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"

                    >

                        <Grid
                            container
                            sx={{ height: '80vh', minWidth: '100%' }}

                            alignItems="center"
                            justifyContent="center"
                            justify="center">


                            {rows.length > 0 && !addClientToDB &&
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField
                                        id="name"
                                        name="name"

                                        label="Nombre de la hoja"
                                        onChange={(e) => { setGroupName(e.target.value) }}
                                        value={groupName}
                                        fullWidth
                                    >
                                    </TextField>


                                </Grid>}



                            {/*rows.length > 0 &&
                                <Grid item xs={2} sm={2} md={2} lg={2} >
                                    <Grid container
                                        alignItems="center"
                                        justifyContent="center">
                                        <Grid item xs={2} sm={2} md={2} lg={2} >
                                            <Checkbox
                                                checked={showAddAdvisers}
                                                onChange={(e) => { setShowAddAdvisers(e.target.checked) }}
                                                sx={{
                                                    color: 'white',
                                                    '&.Mui-checked': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={10} lg={10} >
                                            <p style={{ color: 'white' }}>Asginar asesor</p>
                                        </Grid>
                                    </Grid>



                                            </Grid>*/}
                            <Grid
                                item
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                align="center"
                                alignItems="center"
                                justifyContent="center"
                                container
                                spacing={2}
                                sx={{ height: '90%' }}
                            >
                                {newEmptyBase &&
                                    <Grid item xs={12} sm={12} md={8} lg={8}>

                                        <Card sx={{ maxWidth: '100%', minWidth: '100%', minHeight: 320, position: 'relative' }}>
                                            <CardContent>
                                                <TextField
                                                    id="name"
                                                    name="name"

                                                    label="Nombre de la hoja"
                                                    onChange={(e) => { setGroupName(e.target.value) }}
                                                    value={groupName}
                                                    fullWidth
                                                >
                                                </TextField>
                                                <Storage
                                                    color={"primary"}
                                                    sx={{ fontSize: 200 }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    bottom: '18px',
                                                    width: '100%',
                                                }}>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"

                                                        onClick={() => {
                                                            createGroup();
                                                        }}
                                                    >
                                                        {addClientToDB ? "Agregar clientes" : "Crear hoja"}
                                                    </Button>
                                                </div>
                                            </CardContent>
                                        </Card>



                                    </Grid>}

                                {rows.length > 0 &&
                                    <>
                                        <Grid item
                                            xs={showAddAdvisers ? 6 : 12}
                                            sm={showAddAdvisers ? 6 : 12}
                                            md={showAddAdvisers ? 6 : 12}
                                            lg={showAddAdvisers ? 6 : 12}
                                            align="center"
                                            alignItems="center"
                                            justifyContent="center"

                                            sx={{ height: '100%' }}>
                                            <TablaUsuarios

                                                rows={rows}
                                                columns={[

                                                    {
                                                        headerName: 'Nombre', field: 'firstName', hide: false, flex: 1

                                                    },
                                                    {
                                                        headerName: 'Apellido', field: 'lastName', hide: false, flex: 1

                                                    },
                                                    /*   {
                                                           headerName: 'Pais', field: 'country', hide: false,
   
                                                       },
                                                       {
                                                           headerName: 'Ciudad', field: 'city', hide: false,
   
                                                       },
                                                       {
                                                           headerName: 'Género', field: 'gender', hide: false,
   
                                                       },
                                                       {
                                                           headerName: 'Nivel educativo', field: 'level', hide: false,
   
                                                       },
                                                       {
                                                           headerName: 'Asesor', field: 'adviser', hide: false,
   
                                                       },*/
                                                    {
                                                        headerName: 'Teléfono', field: 'phone', hide: false, flex: 1

                                                    },
                                                    {
                                                        headerName: 'Correo', field: 'email', hide: false, flex: 1

                                                    },
                                                    {
                                                        headerName: 'Estatus', field: 'status', hide: false, flex: 1

                                                    },



                                                ]}
                                                checkboxSelection
                                                onDelete={(rowsSelected) => {

                                                    let rs = [...rows]
                                                    rowsSelected.map((r) => {
                                                        rows.map((r2, index) => {
                                                            if (r.id == r2.id) {
                                                                rs.splice(index, 1);
                                                            }
                                                        })

                                                    })
                                                    console.log(rs);
                                                    setRows(rs);
                                                }}
                                                showDelete

                                            />
                                        </Grid>
                                        {showAddAdvisers &&
                                            <Grid item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                align="center"
                                                alignItems="center"
                                                justifyContent="center"

                                                sx={{ height: '100%' }}>
                                                <TablaUsuarios

                                                    rows={advisers}
                                                    columns={[

                                                        {
                                                            headerName: 'Correo', field: 'email', hide: false,

                                                        },
                                                        {
                                                            headerName: 'Nombre', field: 'forstName', hide: false,

                                                        },
                                                        {
                                                            headerName: 'Estatus', field: 'status', hide: false,

                                                        },


                                                    ]}
                                                    checkboxSelection
                                                    onDelete={(rowsSelected) => {


                                                    }}
                                                    onsSelectionChange={(newUsers) => {

                                                        setSelectedAdvisers(newUsers);


                                                    }}


                                                />
                                            </Grid>}
                                    </>}
                                {
                                    rows.length > 0 ?
                                        <>
                                            <Grid item md={3}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    fullWidth
                                                    onClick={() => {
                                                        createGroup();
                                                    }}
                                                >
                                                    {addClientToDB ? "Agregar clientes" : "Crear hoja"}
                                                </Button>
                                            </Grid>
                                            {/*
                                                !newEmptyBase &&
                                                <Grid item md={3}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                        onClick={() => {
                                                            setOpenCallModal(true);
                                                        }}
                                                    >
                                                        Llamada masiva
                                                    </Button>
                                                </Grid>
                                            }
                                            {
                                                !newEmptyBase &&
                                                <Grid item md={3}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                        onClick={() => {
                                                            setOpenMsgModal(true);
                                                        }}
                                                    >
                                                        Mensaje masivo
                                                    </Button>
                                                </Grid>
                                                    */}



                                        </>

                                        :
                                        <>{!showInput && !newEmptyBase && !addClientToDB &&
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    icon={() => {
                                                        return (

                                                            <Add
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"

                                                                onClick={(event) => setNewEmptyBase(true)}
                                                            >
                                                                Nueva hoja

                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Nueva"}
                                                    title={"Nueva"}
                                                    description={"Crea una nueva hoja vacia para poder sincronizar con un formulario"}
                                                />
                                            </Grid>}
                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    icon={() => {
                                                        return (

                                                            <FileUpload
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                            >
                                                                {addClientToDB ? "Subir clientes" : "Subir hoja"}
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    onChange={(event) => handleChangeFile(event)}
                                                                />
                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Subir"}
                                                    title={"Subir"}
                                                    description={"Sube un archivo .csv para ingresar los datos en una nueva hoja"}

                                                />



                                            </Grid>
                                            }
                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    aditionalInfo={() => <Button
                                                        variant="outlined"
                                                        component="label"
                                                    >
                                                        <a target="_blank" href={`${process.env.REACT_APP_BACK_URL}/media/FormatoEjemplo.xlsx`}>Descargar Ejemplo</a>
                                                    </Button>}
                                                    icon={() => {
                                                        return (

                                                            <FileUpload
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                            >
                                                                Subir formato de libro excel
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    onChange={(event) => handleChangeFile(event, true)}
                                                                />
                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Subir"}
                                                    title={"Subir Excel"}
                                                    description={"Sube un archivo de excel para ingresar los datos en una nueva base"}

                                                />
                                            </Grid>
                                            }
                                            {/*!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>



                                                {userId && <DatabaseCard
                                                    button={() => {
                                                        return (
                                                            <GoogleLoginView userId={userId} />
                                                        )
                                                    }}
                                                    icon={() => {
                                                        return (

                                                            <AddToDrive
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    actionName={"Goole"}
                                                    title={"Sincronizar"}
                                                    description={"Trae archivos desde una hoja de google de tu cuenta"}
                                                />}
                                            </Grid>
                                            */}
                                            {showInput &&
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <TextField
                                                        value={currentInputValue}
                                                        fullWidth

                                                        rows={10}
                                                        multiline
                                                        onChange={(event) => {
                                                            setCurrentInputvalue(event.target.value);
                                                        }}
                                                    >

                                                    </TextField>

                                                </Grid>
                                            }

                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    icon={() => {
                                                        return (

                                                            <ContentPaste
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"

                                                                onClick={(event) => handleShowInput(event)}
                                                            >
                                                                {addClientToDB ? "Pegar clientes" : "Pegar hoja"}

                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Pegar"}
                                                    title={"Pegar"}
                                                    description={"Pega los datos divididos por comas para crear una nueva base"}
                                                />
                                            </Grid>}

                                            {
                                                showInput && <Grid item xs={12} sm={12} md={12} lg={12}>

                                                    <Grid container spacing={2} xs={12} sm={8} md={4} lg={4}>

                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Button
                                                                color="success"
                                                                variant="contained"
                                                                component="label"
                                                                onClick={() => validaInput()}
                                                                fullWidth
                                                            >
                                                                Subir
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                fullWidth
                                                                onClick={(event) => handleShowInput(event)}
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {!showInput && !newEmptyBase && !addClientToDB && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    icon={() => {
                                                        return (

                                                            <Download
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                            >
                                                                <a target="_blank" href={`${process.env.REACT_APP_BACK_URL}/media/pruebacsv.csv`}>Descargar Ejemplo</a>
                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Descargar"}
                                                    title={"Descargar"}
                                                    description={"Descarga un ejemplo de archivo .csv para poder subirlo posteriormente"}

                                                />



                                            </Grid>
                                            }
                                        </>

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }

            {
                !db &&
                <>
                    <br />
                    {currentGroupId &&
                        <div style={{ position: 'relative', width: '100%' }}>
                            <IconButton style={{
                                position: 'absolute',
                                right: '2%'
                            }} onClick={() => { setCurrentGroupId(null) }}>
                                <HighlightOff
                                    style={{ fill: "white", transform: 'scale(1.8)' }}
                                />
                            </IconButton>
                        </div>
                    }
                    <Grid
                        align="center"
                        justifyContent="center"
                        container
                        sx={{ height: '80%', width: '100wv', margin: 0 }}

                    >

                        {currentGroupId &&
                            <Grid
                                item
                                sm={10}

                            >
                                <DatabaseTable
                                    theme={theme}
                                    templateList={templates}
                                    advisers={advisers}
                                    showAdviserButton
                                    groupID={currentGroupId}
                                />
                            </Grid>}
                    </Grid>

                </>
            }




        </>
    )
}

export default PageModal;