import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';



import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TemplateModal from '../../components/TemplateModal'
import { Helmet } from 'react-helmet-async';
import ShareModal from '../../components/ShareModal';
import ChatbotTemplateModal from '../../components/ChatbotTemplateModal';





/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function ChatbotTemplates({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [templates, setTemplates] = useState([]);

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);

  /**
   * Guarda el tepmlate seleccionado
   */
  const [current, setCurrent] = useState({});

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**
  * Speech para corregir a la hora de enviar
  */
  const [speechs, setSpeechs] = useState([]);


  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const [openShareModal, setOpenShareModal] = useState(false);

  const [advisers, setAdvisers] = useState([]);


  const [filterName, setFilterName] = useState(null);
  /**
   * Carga la info necesaria
   */
  React.useEffect(() => {

    setOpen(false);
    setOpenShareModal(false);
  

    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);




      } catch (err) {
        console.log(err)
      }
    }

   

    getSessions();

  }, [reload]);


  /**
   * Carga los templates filtrados
   */
  React.useEffect(() => {


    async function getFilteredTempaltes(filterName) {

      try {
        let request = {};
        if (filterName) {
          request.name = filterName
        }


        const { data } = await api.post("/api/chatbotTemplate", request);
        setTemplates(data.chabotTemplates);

      } catch (err) {
        console.log(err)
      }
    }


    getFilteredTempaltes(filterName);



  }, [filterName, reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      headerName: 'Editar', field: 'bitacora', flex: 1, hide: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => { setCurrent(params.row); setOpen(true) }}>Editar</Button>

        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'description', headerName: 'Descripción', flex: 1, editable: false,
    },
  ];

  /**
   * Elimina un template
   * @param {*} id 
   */
  const deleteTemplate = async (dataTemplate) => {

    dataTemplate.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteTemplate", { id: ss.id });

        if (data.status == "Success") {
          enqueueSnackbar(`Plantilla eliminada`, {
            variant: 'success',
          });
        } else {
          if (data?.code) {
            enqueueSnackbar(data.userMsg, {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(`Ocurrió un error al eliminar la plantilla ${ss.name}`, {
              variant: 'error',
            });
          }

        }


      } catch (err) {
        console.log(err)
        enqueueSnackbar(`Ocurrió un error al eliminar la plantilla ${ss.name}`, {
          variant: 'error',
        });
      }
    });

    setReload(!reload);


  }
  /**
   * Crea una nueva plantilla
   * @param {*} data 
   */
  const addTemplate = async (newTemplate) => {


    try {


      const request = {
        id: newTemplate.id && newTemplate.id != 0 ? newTemplate.id : 0,
        templateObj: newTemplate,

      }


      const { data } = await api.post("/api/chatbotTemplate/add", request);


      if (data.status == "Success") {

        enqueueSnackbar('Se creó la plantilla', {
          variant: 'success',
        });


      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(`Ocurrió un error al crear la plantilla`, {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear la plantilla', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }


  


  return (
    <>

      <Helmet title="Plantillas" />

      <Typography variant="h3" gutterBottom display="inline">
        Palabras para Chatbot
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Chatbot
        </Link>
        <Link >Plantillas</Link>
        {
          revisaPermisos(['Agregar Plantilla']) &&
          <Button variant='outlined' onClick={() => { setOpen(!open); setCurrent({}) }} >Nueva plantilla</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          align="center"
          sx={{ height: '10%' }}
        >
          <TextField
            label="Buscar plantilla"
            fullWidth
            onChange={(e) => { setFilterName(e.target.value) }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          align="center"
          sx={{ height: '10%' }}
        >
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '90%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={templates}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar Plantilla'])}
            onDelete={(data) => { deleteTemplate(data); }}
            onsSelectionChange={(data) => { setSelectedTemplates(data) }}
            showShareButton={revisaPermisos(['Compartir plantillas'])}
            onShare={() => { setOpenShareModal(true) }}
          />
          {
            revisaPermisos(['Agregar Plantilla']) &&
            <>


              <ChatbotTemplateModal
                title={"Nueva plantilla"}
                open={open}
                setOpen={setOpen}
                chatbotT={current}
                theme={theme}
                onSave={addTemplate}
              />
            </>
          }

        </Grid>
      </Grid>
     

    </>
  );
}
