import * as React from 'react';

import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
//import SheetsTableModal from '../../components/SheetsTableModal';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import AddGoogleSheetModal from '../../components/AddGoogleSheetModal';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function GoogleInventory({ theme }) {


  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [sheets, setSheets] = useState([]);

  const [products, setProducts] = useState([]);

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentSessionName, setCurrentSessionName] = useState('');


  const [currentSheet, setCurrentSheet] = useState(null)
  const [currentSheetID, setCurrentSheetID] = useState(null)
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();


  const [filterName, setFilterName] = useState(null);


  const [idView, setIdView] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {


    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);


      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [reload]);

  React.useEffect(() => {
    async function getSheets() {

      try {

        const { data } = await api.post("/api/google/sheets", { name: filterName });

        setSheets(data.sheets);
      } catch (err) {
        console.log(err)
      }
    }

    async function getProducts() {

      try {

        const { data } = await api.post("/api/google/products", { name: filterName, sheetID: currentSheetID });

        setProducts(data.products);
      } catch (err) {
        console.log(err)
      }
    }

    if (idView == 0) {

      getSheets();
    } else {

      if (currentSheetID) {
        getProducts();
      }

    }



  }, [reload, filterName, idView, currentSheetID])




  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'googleID', headerName: 'ID', flex: 1, editable: false,
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'sheetName', headerName: 'Hoja sincronizada', flex: 1, editable: false,
    },
    {
      field: 'show', headerName: 'Inventario', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <Button variant="contained" onClick={() => {
            setCurrentSheet(params.row);
            setCurrentSheetID(params.row.id);
            setFilterName('');
            setIdView(1);
          }}>Ver inventario</Button>
        )
      }
    },

  ];


  /**
   * Columnas de la tabla de productos
   */
  const productColumns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'description', headerName: 'Descripción', flex: 1, editable: false,
    },
    {
      field: 'stock', headerName: 'Cantidad', flex: 1, editable: false,
    },


  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.post("/api/google/sheets/delete", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Hoja de google eliminada', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar la hoja de google', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar la hoja de google', {
          variant: 'error',
        });
      }
    });


  }

  /**
   * Sincroniza los productos de un inventario
   *
   */
  const syncInventory = async () => {


    try {
      setIsLoading(true)
      const { data } = await api.post("/api/google/products/sync", { id: currentSheet.googleAccountID, sheetName: currentSheet.sheetName, spreadsheetId: currentSheet.googleID, sheetID: currentSheet.id });

      // console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Terminó la sincronización', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al sincronizar la hoja de google', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al sincronizar la hoja de google', {
        variant: 'error',
      });
    }

    setIsLoading(false)
  }
  /**
   * Crea un nuevo producto
   * @param {*} data 
   */
  const addWebhook = async (newProduct) => {


    let request = {

      productObj: newProduct

    }

    if (newProduct.id) {
      request.id = newProduct.id;
    }

    try {

      const { data } = await api.post("/api/createProduct", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se creó el producto correctamente', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al crear el producto', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear el producto', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Productos" />

      <Typography variant="h3" gutterBottom display="inline">
        Inventario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >{`${idView == 0 ? 'Inventario' : 'Productos'}`}</Link>
        {
          idView == 1 &&
          <LoadingButton loading={isLoading} variant='outlined' color="success" onClick={() => { syncInventory() }} >Sincronizar inventario</LoadingButton>
        }
        {
          revisaPermisos(['Agregar productos']) && idView == 0 &&
          <Button variant='outlined' onClick={() => { setCurrentSheetID(null); setCurrentSheet(null); setEstadoModal(true); }} >Agregar hoja de google</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          align="center"
          sx={{ height: '10%' }}
        >
          <TextField
            label={`Buscar ${idView == 0 ? 'hoja' : 'producto'}`}
            fullWidth
            onChange={(e) => { setFilterName(e.target.value) }}
            disabled={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          align="center"
          sx={{ height: '10%' }}
        >

        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          align="center"
          sx={{ height: '10%' }}
        >
          {
            idView == 1 &&
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setCurrentSheet(null);
                setCurrentSheetID(null);
                setFilterName('');
                setIdView(0);
              }}
              disabled={isLoading}
            >
              Regresar
            </Button>
          }

        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          {
            idView == 0 ?
              <TablaUsuarios
                columns={columns}
                rows={sheets}
                checkboxSelection
                showDelete={revisaPermisos(['Eliminar productos'])}
                onDelete={(data) => { deleteSession(data); }}
                columnVisibilityModel={{
                  edit: revisaPermisos(['Modificar productos'])
                }}
              />
              :
              <TablaUsuarios
                columns={productColumns}
                rows={products}
                checkboxSelection
                showDelete={revisaPermisos(['Eliminar productos'])}
                onDelete={(data) => { deleteSession(data); }}
                columnVisibilityModel={{
                  edit: revisaPermisos(['Modificar productos'])
                }}
              />
          }
          {
            revisaPermisos(['Agregar productos', 'Modificar productos']) &&
            <>




              <AddGoogleSheetModal
                title={"Sincronizar hoja de google"}
                open={estadoModal}
                setOpen={setEstadoModal}
                theme={theme}
                relaod={()=>{setReload(!reload)}}
              />
            </>
          }

        </Grid>
      </Grid>


    </>
  );
}



const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;