import * as React from 'react';


import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';

import { useState } from 'react';
import api from '../../services/api';
import { Breadcrumbs, Button, Chip, Divider, Grid, Link, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, Delete } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

/**
 * Contenedor para gestionar los elementos eliminados
 * @returns 
 */
export default function Trash() {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   * Variable que contiene los elementos eliminados
  */
  const [objectList, setObjectList] = useState([]);


  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);


  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);

    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)


        const { data } = await api.post("/api/trashList");

        if (data.status == "Success") {
          setObjectList(data.objectList)
        }

      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {

        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }

    return count >= minMatch;
  }

  /**
   * Regresa la descripción par alos tipos de objetos
   * @param {*} type 
   * @returns 
   */
  const getObjectType = (type) => {

    if (type == 'book') {
      return { name: 'Libro', color: 'primary' }
    }
    if (type == 'sheet') {
      return { name: 'Hoja', color: 'secondary' }
    }

    return { name: 'Desconocido', color: 'error' }
  }
  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'objectType', headerName: 'Tipo', flex: 1, editable: false,
      renderCell: (params) => {
        const objDesc = getObjectType(params.row.objectType);
        return (
          <Chip label={objDesc.name} color={objDesc.color} />

        )
      }
    },
    {
      field: 'actions', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        const canRestore = revisaPermisos(['Vaciar papelera']);

        if (canRestore) {
          return (
            <Button onClick={() => restoreObject(params.row)}>
              Restaurar
            </Button>

          )
        }

        return (
          <Button>
            Sin acciones
          </Button>

        )

      }
    },

  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (selectedObjectList) => {


    const resp = window.confirm(`¿Desea eliminar permanentemente ${selectedObjectList.length > 1 ? 'los '+selectedObjectList.length+' objetos seleccionados':'el objeto seleccionado'} ?`);

    if(!resp){
        return;
    }

    selectedObjectList.map(async (currObj, key) => {
      try {

        let request = {
          id: currObj.id,
          objectType: currObj.objectType
        }

        const { data } = await api.post("/api/trashRemove", request);
  
        if (data.status == 'Success') {
          setReload(!reload);
          enqueueSnackbar(data.userMsg, {
            variant: 'success',
          });
        } else {
          if (data?.code) {
            enqueueSnackbar(`Se eliminó el ${getObjectType(currObj.objectType).name} ${currObj.objectType.name}`, {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(`Ocurrio un error al eliminar el ${getObjectType(currObj.objectType).name} ${currObj.objectType.name}`, {
              variant: 'error',
            });
          }
        }
      } catch (err) {
        enqueueSnackbar('Ocurrio un error inesperado, intenta más tarde', {
          variant: 'error',
        });
        console.log(err);
      }
    })
  }
  /**
   * Restaura un objeto
   * @param {*} object 
   */
  const restoreObject = async (object) => {
    try {

      let request = {
        id: object.id,
        objectType: object.objectType
      }
      const { data } = await api.post("/api/trashRestore", request);

      if (data.status == 'Success') {
        setReload(!reload);
        enqueueSnackbar(data.userMsg, {
          variant: 'success',
        });
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrio un error al restaurar el objeto', {
            variant: 'error',
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Ocurrio un error inesperado, intenta más tarde', {
        variant: 'error',
      });
      console.log(err);
    }
  }

  return (
    <>
      <Helmet title="Papelera" />

      <Typography variant="h3" gutterBottom display="inline">
        Papelera
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Clientes
        </Link>
        <Link >Papelera</Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={objectList}
            checkboxSelection
            showDelete={revisaPermisos(['Vaciar papelera'])}
            onDelete={(data) => { deleteSession(data); }}
          />

        </Grid>
      </Grid>


    </>
  );
}