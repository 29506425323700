import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const checkPermission = (permissions, profile) => {

  for(let index2  in permissions){

      if(profile.indexOf(permissions[index2])>= 0){
        return true;
      }
      
  }
return false;
}


const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute, profile,setShowSection } = props;
  if(!items?.push){
   
    return
  }
 

  if (page.children ) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;
   if(checkPermission(page.permission, profile)){
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );}
  } else {
    if(checkPermission(page.permission, profile)){
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />
    );}
  }
  
  if(items.length > 0 ){
    setShowSection(true);
  }
  return items;
};

export default reduceChildRoutes;
