import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Box, Button, Chip, Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  
 
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${[700]};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 1%;
  height: 1px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const Sidebar = ({ items, showFooter = true, reload, ...rest }) => {
  return (
    <>
    
      <Drawer 
      variant="permanent"
       {...rest}>
        <Brand component={NavLink} to="/private/inicio">
          <BrandIcon />{" "}
          <Box ml={1}>
            PulpoTicket
          </Box>
          
        </Brand>
        <SidebarNav items={items} reload={reload} />
        {!!showFooter && <Footer />}
      </Drawer>
      
    </>
  );
};

export default Sidebar;
