import styled from 'styled-components';

function Button({content,onC}) {
  return (
    <StyledButton onClick={() => onC()}>{content}</StyledButton>
  )
}

const StyledButton = styled.button`
background: linear-gradient(to right,  #02253d 0%, #365b77 79%);
text-transform: uppercase;
letter-spacing: 0.2rem;
width: 65%;
height: 3rem;
border: none;
color: white;
border-radius: 2rem;
cursor: pointer;
transition: all .3s ease;
&:hover {
  background: linear-gradient(to right,  #021f32 0%, #2a4458 79%);
  }
`

export default Button;