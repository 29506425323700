import * as React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DetailRegister from '../DetailRegister';


export default function DetailPanel(props) {
  return (
    <Stack
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h6">{`Reenvios`}</Typography>
         
          <DetailRegister theme={{}} {...props}/>
        </Stack>
      </Paper>
    </Stack>
  );
}

