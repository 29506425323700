import * as React from 'react';
import { DataGridPro, GridFooter, GridFooterContainer, GridToolbar, LicenseInfo } from '@mui/x-data-grid-pro';
import { Button, Grid, IconButton } from "@mui/material"
import Delete from "@mui/icons-material/Delete";
import BookFooter from '../BookFooter';
import { Share } from '@mui/icons-material';

export default function TablaUsuarios({ editCellPropsChange = () => { }, cellEditCommit = () => { }, totalRows, handlePageSizeChange, handlePageChange, page, columns, rows, id = 'id', onDelete, checkboxSelection, showDelete, onsSelectionChange, showResend, showResendButton, onResend, onResendAll, columnVisibilityModel = {}, showPages = false, bookID, onPageChange, showShareButton, onShare, rowReordering, handleRowOrderChange }) {


  const [selection, setSelection] = React.useState([]);

  const [pageSize, setPageSize] = React.useState(20);

  /**
   *  Activa la licencia de uso para datagrid pro
   */
  LicenseInfo.setLicenseKey(
    'bfc6e20fb3bd4afcc42e60d5cffc5096T1JERVI6NDA0OTQsRVhQSVJZPTE2Nzk4ODAxOTQwMDAsS0VZVkVSU0lPTj0x',
  );

  return (
    <div style={{ height: '100%' }}>
      <Grid container justifyContent="flex-end">
        {
          selection.length > 0 && (<>

            {showDelete &&
              <Grid item>
                <Button color="error" variant="outlined" startIcon={<Delete />} onClick={() => { onDelete(selection) }}>Eliminar</Button>
              </Grid>}
            {showResend && showResendButton &&
              <Grid item>
                <Button color="primary" onClick={() => { onResend(selection) }}>Reenviar Seleccionados</Button>
              </Grid>
            }
            {
              showShareButton &&
              <Grid item>
                <Button variant="outlined" startIcon={<Share />} onClick={() => { onShare() }}>Compartir</Button>
              </Grid>
            }

          </>)
        }
        {
          showResend && showResendButton &&
          <Grid item>
            <Button color="primary" onClick={() => { onResendAll(selection) }}>Reenviar Errores</Button>
          </Grid>
        }

      </Grid>
      <DataGridPro
        onCellEditStart={editCellPropsChange}
        cellEditCommit={editCellPropsChange}
        onCellEditCommit={cellEditCommit}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        pagination
        onPageSizeChange={(newPageSize) => { setPageSize(newPageSize); if (handlePageSizeChange) { handlePageSizeChange(newPageSize) } }}
        getRowId={(row) => { return row[id] }}
        rows={rows}
        rowCount={totalRows ? totalRows : rows.length}
        columns={columns}
        keepNonExistentRowsSelected
        rowsPerPageOptions={[5, 10, 20, 30, 100]}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection ? true : false}

        /**
         * Sirve para guardar la seleccion en algun lugar
         */
        onSelectionModelChange={(newSelectionModel) => {

          /**
           * Crea un arreglo con todos los objetos seleccionados
           */
          const clientsEdit = [];


          rows.map((value, key) => {

            if (newSelectionModel.indexOf(value[id]) > -1) {
              clientsEdit.push(value)
            }
          })
          setSelection(clientsEdit);

          if (onsSelectionChange) {
            onsSelectionChange(clientsEdit)
          }
          // console.log(clientsEdit)
        }}
        columnVisibilityModel={columnVisibilityModel}
        // components={{ Footer: CustomFooter }}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
    </div>
  );
}

