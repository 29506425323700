import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";

/**Estilo Logo */
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  width: 130px;
  height: 130px;
  margin-bottom: 15px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
/**Estilo Imagen avatar */
const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  return (
    <React.Fragment>
      <Brand></Brand>
      <Wrapper>
        {/** Helmet texto que aparece en la pestaña del navegador*/}
        <Helmet title="Ingresar" />
        {/** Imagen avatar*/}
        {/*<BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" />*/}

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          ¡Bienvenido de nuevo!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          inicia sesion con tu cuenta para continuar
        </Typography>
        {/**SignInComponent continene los inputs y las validaciones*/}
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
