import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, TextField } from '@mui/material';


export default function ProductsTableModal({ title, open, setOpen, onSave, theme, product }) {

    /**
     * Actualiza el prodcuto para poder editarlo
     */
    useEffect(() => {
        console.log("entrando")
        if (product != null) {
            setProductInfo(product)
        } else {
            setProductInfo({
                id: null,
                name: '',
                description: '',
                price: 0
            })
        }

    }, [product]);


    /**
     * Variable para el nuevo producto
     */
    const [productInfo, setProductInfo] = useState({
        id: null,
        name: '',
        description: '',
        price: 0
    });



    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setProductInfo({
            ...productInfo,
            [event.name]: event.value,
        })

    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={productInfo.name}
                                label="Nombre del producto"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={productInfo.description}
                                label="Descripcion del producto"
                                name="description"
                                variant="outlined"
                                onChange={handleChange}
                                multiline
                                rows={6}

                            />

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => {
                        setProductInfo({
                            id: null,
                            name: '',
                            description: '',
                            price: 0
                        }); 
                        onSave(productInfo);
                    }}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
