import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../../services/api';
import { useEffect } from 'react';



export default function DayCard({ id, letter, name, dayNumber, start, end, selected }) {


    const [enabled, setEnabled] = useState(selected);
    const [day, setDay] = useState({
        id,
        day: dayNumber,
        start,
        end
    })

    useEffect(()=>{
        setDay({
            id,
            day: dayNumber,
            start,
            end
        })
        setEnabled(selected)
    },[selected])
    
    async function updateDay() {
        try {
            const { data } = await api.post('/api/calendar', { id: day.id, day })
            alert("Se actualizó el calendario")
            
        } catch (err) {
            alert("Ocurrio un error al actualizar el calendario")
        }
    }

    async function unableDay() {
        try {
            const { data } = await api.put('/api/calendar', { id: day.id })
            setEnabled(false)
            alert("Se actualizó el calendario")
        } catch (err) {
            alert("Ocurrio un error al actualizar el calendario")
        }
    }
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {letter}
                    </Avatar>
                }
                action={
                    <FormControlLabel control={<Checkbox
                        checked={enabled}
                        onChange={(e) => setEnabled(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }} />} label="Habilitar día" />
                }
                title={name}
            />

            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                sx={{ width: '80%' }}
                                label="Inicio"
                                value={day.start}
                                inputFormat={"HH:mm"}
                                fullWidth
                                onChange={(newDate) => setDay({ ...day, start: newDate })}
                                renderInput={(params) => <TextField {...params} disabled={!enabled} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                sx={{ width: '80%' }}
                                label="Fin"
                                value={day.end}
                                inputFormat={"HH:mm"}
                                fullWidth
                                onChange={(newDate) => setDay({ ...day, end: newDate })}
                                renderInput={(params) => <TextField {...params} disabled={!enabled} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
                <Button color="error" onClick={unableDay} disabled={!enabled}>Deshabilitar</Button>
                <Button onClick={updateDay} disabled={!enabled}>Guardar</Button>
            </CardActions>

        </Card >
    );
}
