
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
/**
 * Componente principal
 * @returns 
 */
export default function ClientsModal({ title = "Usuarios", open, setOpen, onChange, clientsExternalRawData = {
  firstName: { value: [], raw: '' },
  lastName: { value: [], raw: '' },
  email: { value: [], raw: '' },
  phone: { value: [], raw: '' },
  address: { value: [], raw: '' },
  status: { value: [], raw: '' },
  product: { value: [], raw: '' },
  tags: { value: [], raw: '' },
}, selected = [], required = ['phone'], ignored = ['tags'] }) {

  const clientStructure = [
    { name: "Nombre", value: 'firstName' },
    { name: "Apellido", value: 'lastName' },
    { name: "Correo", value: 'email' },
    { name: "Teléfono", value: 'phone' },
    { name: "Dirección", value: 'address' },
    { name: "Estatus", value: 'status' },
    { name: "Product", value: 'product' },
    { name: "Etiquetas", value: 'tags' }
  ]

  const getFields = () => {
    let fields = [...new Set([...selected, ...required])]
    const filterFields = fields.filter(item => !ignored.includes(item));
    return filterFields;
  }
  const [clients, setClients] = useState();
  const [clientsRawData, setClientsRawData] = useState(clientsExternalRawData);
  const [selectedFields, setSelectedFields] = useState(getFields());



  useEffect(() => {
    const keys = Object.keys(clientsRawData);
    console.log(keys)
    if (keys.length > 0) {

      const length = clientsRawData[keys[3]].value.length;
      const newClients = [];

      for (let i = 0; i < length; i++) {
        const client = {};
        keys.forEach(key => {
          console.log(key)
          client[key] = clientsRawData[key].value[i];
        });
        newClients.push(client);
      }
      console.log(newClients)
      if (onChange) {
        onChange(newClients)
      }
      setClients(newClients);
    }

  }, [clientsRawData])
  /**
   * Cierra el modal
   */
  const handleClose = () => {
    setOpen(false);
  };


  const onChangeSelection = (selected, attribute) => {

    if (selected) {

      let clientList = { ...clientsRawData }
      clientList[attribute] = { value: [], raw: '' }
      setClientsRawData(clientList);
      setSelectedFields(prev => [...prev, attribute])
    } else {
      if (required.indexOf(attribute) == -1) {
        const index = selectedFields.indexOf(attribute)

        let selectedTempList = [...selectedFields];
        selectedTempList.splice(index, 1)


        let clientList = { ...clientsRawData }
        delete clientList[attribute]

        setSelectedFields(selectedTempList);
        setClientsRawData(clientList);
      }

    }

  }

  const handleChange = (attribte, value) => {
    let clientsRawDataTemp = { ...clientsRawData }
    clientsRawDataTemp[attribte].raw = value;
    clientsRawDataTemp[attribte].value = value.split(/\r?\n/);
    console.log(clientsRawDataTemp)
    setClientsRawData(clientsRawDataTemp);
  }



  const getRowLength = () => {
    const selecteds = selectedFields.length;

    if (selecteds === 0) {
      return 12
    }

    return 12 / selecteds;
  }
  return (
    <Dialog
      disableEnforceFocus
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle >

      <DialogContent dividers>
        <Grid container>
          <Grid item container >
            {
              clientStructure.map((structure, index) => {

                if (ignored.indexOf(structure.value) == -1) {
                  return (<Grid item key={index}>
                    <FormControlLabel
                      control={<Checkbox checked={selectedFields.indexOf(structure.value) != -1} onChange={(e) => onChangeSelection(e.target.checked, structure.value)} />}
                      label={structure.name}
                    />
                  </Grid>
                  )
                }


              })
            }
          </Grid>
          <Grid item container >
            {
              clientStructure.map((structure, index) => {

                if (selectedFields.indexOf(structure.value) != -1) {
                  return (
                    <Grid item xs={getRowLength()} key={index}>
                      <Typography>{structure.name}</Typography>
                      <TextField
                        value={clientsRawData[structure.value].raw}
                        fullWidth
                        onChange={(e) => { handleChange(structure.value, e.target.value) }}
                        name={structure.value}
                        cols="65"
                        rows="24"
                        multiline
                      />

                    </Grid>
                  )
                }
                return '';
              })
            }
          </Grid>

        </Grid>
      </DialogContent>


      <DialogActions>
        <Button variant="outlined" color="success" onClick={handleClose}>
          Guardar
        </Button>

      </DialogActions>
    </Dialog>

  );
}