import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, TextField } from '@mui/material';


export default function AddTagGroupModal({ title, open, setOpen, onSave, theme, currentTagGroup }) {

    /**
     * 
     */
    useEffect(() => {

        if (currentTagGroup) {
            settagGroup(currentTagGroup);
        } else {
            settagGroup({
                name: ''
            });
        }
    }, [currentTagGroup]);

   
    /**
     * Variable para el nuevo grupo
     */
    const [tagGroup, settagGroup] = useState({
        name: '',
    });


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        settagGroup({
            ...tagGroup,
            [event.name]: event.value,
        })
    }
    
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={tagGroup.name}
                                label="Nombre del grupo"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(tagGroup)}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
