import { useEffect, useState } from "react";
import api from "../../services/api";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

export default function RemembersView({ clientCalendarID, currentRemembers, onChange }) {
    const [calendarID, setCalendarID] = useState(null)
    const [remembers, setRemembers] = useState([])

    async function getRemmebers(calendar) {
        try {
            const { data } = await api.get("/api/remembers/" + calendar)
            if (data.remembers) {
                setRemembers(data.remembers)
            }

        } catch (err) {

        }
    }
    useEffect(() => {
        if (clientCalendarID) {
            setCalendarID(calendarID)
            getRemmebers();
        }
    }, [clientCalendarID])

    useEffect(() => {
        if (currentRemembers && currentRemembers.length != remembers.length) {
            setRemembers(currentRemembers)
        }
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(remembers)
        }
    }, [remembers])

    function removeRemember(index) {
        let tempRemembers = [...remembers]
        tempRemembers.splice(index, 1)
        setRemembers(tempRemembers);
    }

    function addRemember() {
        let tempRemembers = [...remembers]
        tempRemembers.push({
            recurrent: 0,
            days: '',
            hour: 10,
        })
        setRemembers(tempRemembers);
    }

    function handleChangeRemmeber(index, field, value) {
        let tempRemembers = [...remembers]
        let rememberObj = tempRemembers[index];
        rememberObj = {
            ...rememberObj,
            [field]: value
        }
        tempRemembers[index] = rememberObj;
        setRemembers(tempRemembers);

    }
    const getHours = () => {
        let hours = [];

        for (let i = 7; i < 23; i++) {
            hours.push(i)
        }

        return hours;
    }

    const getminutes = () => {
        let minutes = [];

        for (let i = 5; i < 60; i += 5) {
            minutes.push(i)
        }

        return minutes;
    }
    return (
        <>
            <Button color="success" fullWidth onClick={addRemember} variant="contained" startIcon={<Add />}>Agrear recordatorio</Button>

            <Grid container spacing={4}>
                {
                    remembers.map((remember, index) => (
                        <Grid container spacing={4} item xs={12} key={index}>

                            <Grid item xs={10}>
                                <Typography fontSize={18}>Recordatorio {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button color="error" fullWidth onClick={()=>removeRemember(index)} variant="contained" startIcon={<Delete />}></Button>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Tipo de recordatorio"
                                    value={remember.recurrent}
                                    onChange={(e) => handleChangeRemmeber(index, 'recurrent', e.target.value)}
                                    variant="filled"
                                    color="primary"
                                    fullWidth
                                >
                                    {[{ id: 0, name: 'Una vez' }, { id: 1, name: 'Recurrente' }].map((type) => (
                                        <MenuItem key={`recurrent-${type.id}`} value={type.id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label={remember.recurrent == 0 ? 'Minutos antes del evento' : 'Hora del recordatorio'}
                                    value={remember.hour}
                                    onChange={(e) => handleChangeRemmeber(index, 'hour', e.target.value)}
                                    variant="filled"
                                    color="primary"
                                    fullWidth
                                >
                                    {

                                        remember.recurrent == 0 ?
                                            getminutes()?.map((hour) => (
                                                <MenuItem key={`hour-${hour}`} value={hour}>
                                                    {hour}
                                                </MenuItem>
                                            ))
                                            :
                                            getHours()?.map((hour) => (
                                                <MenuItem key={`hour-${hour}`} value={hour}>
                                                    {hour}
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}