import { Button, Box, Typography } from "@mui/material";

export default function GetChatMessagesError({onNewChat}) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="70vh"
            textAlign="center"
            p={3}
        >
            <Typography variant="h6" gutterBottom>
                El chat que estas consultando no se puede cargar debido a que el WhatsApp no está conectado.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Para crear un nuevo chat da click en el siguiente botón:
            </Typography>
            <Button onClick={onNewChat} variant="contained" color="primary">
                Nuevo chat
            </Button>
        </Box>
    );
}
