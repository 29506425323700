import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Alert, Autocomplete, Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import { CompactPicker, SketchPicker, SliderPicker } from 'react-color';
import MultiSelect from '../MultiSelect';
import api from '../../services/api';


export default function WhatsappModal({ title, open, setOpen, onSave, currentSession, tags, status, books = [], sessions }) {

    /**
  * Variable para el nuevo whatsapp
  */
    const [sessionInfo, setSessionInfo] = useState({
        name: '',
        color: '',
        Tags: [],
        tags: [],
        Status: [],
        status: [],
        book: null,
        sheet: null,
        bookName: "",
        sheetName: ''
    });

    const [newSheet, setNewSheet] = useState(false)

    useEffect(() => {

        if (currentSession) {
            setSessionInfo({
                ...currentSession,
                tags: currentSession.Tags.map((tag) => tag.id),
                status: currentSession.Statuses?.map((status) => status.id),
                book: currentSession?.Group?.Book?.id,
                sheet: currentSession?.Group?.id
            });
        } else {
            setSessionInfo({
                name: '',
                color: '',
                Tags: [],
                tags: [],
                Status: [],
                status: [],
                book: null,
                sheet: null,
                bookName: "",
                sheetName: ''
            });
        }
    }, [currentSession]);

    const [sheets, setSheets] = useState([])
    const [onlySheet, setOnlySheet] = useState(false);
    useEffect(() => {
        async function getSheets() {
            if (sessionInfo.book && !onlySheet) {
                try {
                    const { data } = await api.post('/api/getPages', { bookID: sessionInfo.book });
                    setSheets(data.pages)
                } catch (err) {

                }
            }
        }
        getSheets();
    }, [sessionInfo.book])

    useEffect(() => {
        async function getSheets() {

            try {
                const { data } = await api.post('/api/sheets');
                setSheets(data.sheets)
            } catch (err) {

            }

        }

        if (onlySheet) {

            getSheets();
        }

    }, [onlySheet])


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };


    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;
        setSessionInfo({
            ...sessionInfo,
            [event.name]: event.value,
        })
    }


    const handleChangeBookAndSheet = (book, sheet) => {

        setSessionInfo({
            ...sessionInfo,
            book, sheet
        })
    }
    const handleChangeObject = (key, value) => {

        setSessionInfo({
            ...sessionInfo,
            [key]: value,
        })
    }

    const handleChangeTags = (tagList) => {

        setSessionInfo({
            ...sessionInfo,
            tags: tagList
        })

    }

    const handleChangeStatus = (statusList) => {

        setSessionInfo({
            ...sessionInfo,
            status: statusList
        })

    }


    const onClearData = (checked) => {
        if (checked) {
            setOnlySheet(false)
        }
        setNewSheet(checked);
        setSessionInfo({
            ...sessionInfo,
            book: null,
            sheet: null,
            bookName: "",
            sheetName: ''
        })
    }

    const onClearDataForSheet = (checked) => {
        if (checked) {
            setNewSheet(false)
        }

        setOnlySheet(checked);
        setSessionInfo({
            ...sessionInfo,
            book: null,
            sheet: null,
            bookName: "",
            sheetName: ''
        })
    }


    const checkSheetUsed = () => {
        let sessionUsed = false;
        sessions?.map((session) => {

            if (sessionInfo.sheet != null && session?.Group?.id == sessionInfo.sheet && session.id != sessionInfo.id) {
                sessionUsed = true;
            }
        })
        return sessionUsed;
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={sessionInfo.name}
                                label="Nombre del whatsapp"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ backgroundColor: sessionInfo.color ? sessionInfo.color : 'primary' }}
                            >
                                Color
                            </Button>

                            <SliderPicker
                                color={sessionInfo.color}
                                onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: 'color',
                                            value: e.hex
                                        }
                                    })
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                id="tagsId"
                                name="tags"
                                title="Etiquetas"

                                list={tags}
                                onChange={handleChangeTags}
                                initSelect={sessionInfo.tags}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                id="statusId"
                                name="status"
                                title="Estatus"
                                list={status}
                                onChange={handleChangeStatus}
                                initSelect={sessionInfo.status}
                            />
                        </Grid>
                        {!onlySheet && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newSheet}
                                        onChange={(e) => onClearData(e.target.checked)}
                                    />
                                }
                                label="Nuevo libro"
                            />
                        </Grid>
                        }
                        {!newSheet && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={onlySheet}
                                        onChange={(e) => onClearDataForSheet(e.target.checked)}
                                    />
                                }
                                label="Busqueda por hoja"
                            />
                        </Grid>
                        }
                        {
                            !newSheet && checkSheetUsed() &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Alert mt={2} mb={1} severity="warning">
                                    {`Esta hoja ya pertenece a un whatsapp, si la utiliza los contactos nuevos del anterior whatsapp irán a una hoja por default`}
                                </Alert>
                            </Grid>
                        }
                        {(!newSheet && !onlySheet) && <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Autocomplete
                                options={books}
                                getOptionLabel={(option) => option.name}
                                value={books.find(book => book.id === sessionInfo.book) || null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Libro"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(e, value) => handleChangeObject('book', value.id)}
                            />

                        </Grid>}

                        {!newSheet &&

                            onlySheet ?
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                    options={sheets}
                                    disabled={sessionInfo == null}
                                    getOptionLabel={(option) => `${option.name} - Libro: ${option?.Book?.name}`}
                                    value={sheets.find(sheet => sheet.id === sessionInfo.sheet) || null}
                                    renderInput={(params) => {

                                        return (<TextField
                                            {...params}
                                            label="Hoja"
                                            variant="outlined"
                                        />)
                                    }}
                                    onChange={(e, value) => {
                                        if (value?.Book?.id) {
                                            handleChangeBookAndSheet(value.Book.id, value.id)
                                        }


                                    }}
                                />


                            </Grid>
                            :
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                    options={sheets}
                                    disabled={sessionInfo == null}
                                    getOptionLabel={(option) => option.name}
                                    value={sheets.find(sheet => sheet.id === sessionInfo.sheet) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Hoja"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(e, value) => handleChangeObject('sheet', value.id)}
                                />


                            </Grid>}

                        {newSheet && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={sessionInfo.bookName}
                                label="Nombre del libro"
                                name="bookName"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>}
                        {newSheet && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={sessionInfo.sheetName}
                                label="Nombre de la hoja"
                                name="sheetName"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => { onSave(sessionInfo.name, sessionInfo.color, sessionInfo.id, sessionInfo.tags, sessionInfo.status, sessionInfo.sheet, newSheet, sessionInfo.bookName, sessionInfo.sheetName) }}>
                        {currentSession ? "Guardar" : "Agregar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
