
import Delete from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import { IconButton } from '@mui/material';
import CustomFilter from "../CustomFilter"
import { useEffect, useState } from "react";
import { CopyAll } from "@mui/icons-material";
/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const ProfileTable = ({ profiles, onDelete, onEdit, showEdit, showDelete }) => {
    /**
     * Cada que cambien  los datos filtra de nuevo
     */
    useEffect(() => {
        setFiltered(profiles);
    }, [profiles])

    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] =useState('');

    /**
    * Guarda los datos filtrados
    */
    const [filtered, setFiltered] = useState(profiles);

    /**
    * Guarda los datos de lso filtros
    */
    const [latsFilters, setLatsFilters] = useState({});

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {

        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }

        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = profiles.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

                    } else {

                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }
                }


            }
            return isInFilter;
        });
        
        setFiltered(finalResults);
    };

    return (

        <TablaUsuarios

            rows={filtered}
            columns={[
                {
                    headerName: 'Editar', field: 'test', flex: 1, hide: !showEdit,

                    renderCell: (params) => {

                        return (
                            <IconButton onClick={() => { onEdit(params.row) }}><EditIcon color="primary"></EditIcon></IconButton>
                        )
                    }
                },
                {
                    headerName: 'Eliminar', field: 'test1', flex: 1, hide: !showDelete,
                    renderCell: (params) => {

                        return (
                            <IconButton onClick={() => { onDelete(params.row) }}><Delete color="error"></Delete></IconButton>
                        )
                    }
                },
                {
                    headerName: 'Perfil', field: 'name', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Duplicar', field: 'duplicate', flex: 1, hide: !showEdit,

                    renderCell: (params) => {

                        return (
                            <IconButton onClick={() => { onEdit(params.row,true) }}><CopyAll color="primary">Duplicar</CopyAll></IconButton>
                        )
                    }
                },
                {
                    headerName: 'Descripción', field: 'description', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                
            ]}
            columnVisibilityModel={{ test: showEdit, test1: showDelete}}
        />

    )
}

export default ProfileTable; 