import { AccountCircle, CalendarMonth, Check, Close, Edit, Email } from "@mui/icons-material";
import { Button, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";


import './index.css';
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SendEmailModal from "../SendEmailModal";
import CalendarEventModal from "../CalendarEventModal";
import api from "../../services/api";
import CalendarEventCard from "../CalendarEventCard";
import MultiSelect from "../MultiSelect";
import { useSnackbar } from "notistack";
import AdviserForms from "../AdviserForms";
import QuotesModal from "../QuotesModal/Index";
import NewQuote from "../NewQuote";
const ClientInfo = ({ status, products, tags = [], chat, onSave, md = 2, lg = 2, newClient = false }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [currentChat, setCurrentChat] = useState(chat)
    const [isLoading, setIsLoading] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);

    const [showEvents, setShowEvents] = useState(false);
    const [clientEvents, setClientEvents] = useState([]);
    const [reloadEvents, setReloadEvents] = useState(false);
    const [permissionsUser, setPermissionsUser] = useState([]);

    const [selectedTags, setSelectedTags] = useState([]);


    /**
  * Compara la lista de permisos con los permisos asignados
  */
    const revisaPermisos = (allow, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < permissionsUser.length; j++) {
                // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
                if (allow[i] == permissionsUser[j]) {
                    count++;
                }
            }
        }
        //  console.log(`${count} >= ${minMatch}`);
        // console.log(count >= minMatch);
        return count >= minMatch;
    }

    useEffect(() => {
        setIsEditing(newClient);
    }, [newClient])
    useEffect(() => {
        setCurrentChat(chat);
        setSelectedTags(chat?.Client?.Tags?.map((tag) => tag.id) || []);
        getClientEvents();
        setShowEvents(false);
        getSessions();

    }, [chat])

    useEffect(() => {

        if (chat.Client?.id) {
            getClientEvents();
        }


    }, [showEvents, reloadEvents])

    const getClientEvents = async () => {
        try {
            const { data } = await api.post('/api/googleCalendar/events', { clientID: chat.Client?.id })
            setClientEvents(data.events);
        } catch (err) {

        }
    }
    async function getSessions() {

        try {

            const userInfo = await api.post("/api/getPermissions");


            setPermissionsUser(userInfo.data.userInfo.permissions)



        } catch (err) {
            console.log(err)
        }
    }
    //getSessions();
    const handleClientDataChange = (event) => {
        const target = event.target;


        let newChatInfo = {
            ...currentChat,
            Client: {
                ...currentChat.Client,
                [target.name]: target.value
            }
        }
        setCurrentChat(newChatInfo);
    }

    const saveChatClientInfo = async () => {
        setIsLoading(true)
        let chatToSave = { ...currentChat }
        chatToSave.Client.tags = selectedTags;
        await onSave(chatToSave);
        setIsLoading(false)
        if (!newClient) {
            setIsEditing(false)
        }
    }

    return (
        <>
            <Grid item sx={{ height: '100%' }} xs={12} md={md} lg={lg}>

                <Grid spacing={3} sx={{ padgding: '20px' }} container item xs={12}>

                    <Grid item xs={12} md={12} lg={12} sx={{ height: '20vh' }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: '100%' }}
                        >
                            <AccountCircle className="profile-icon" />
                        </Grid>

                    </Grid>

                    {
                        revisaPermisos(["Programar eventos en calendario", "Cancelar eventos en calendario"])
                        && !newClient &&
                        <Grid item xs={6} md={6} lg={6}>
                            <Button variant={showEvents ? "outlined" : "contained"} fullWidth onClick={() => setShowEvents(false)}>Información</Button>
                        </Grid>
                    }
                    {
                        revisaPermisos(["Programar eventos en calendario", "Cancelar eventos en calendario"])
                        && !newClient &&
                        <Grid item xs={6} md={6} lg={6}>
                            <Button variant={showEvents ? "contained" : "outlined"} fullWidth onClick={() => setShowEvents(true)}> Eventos</Button>
                        </Grid>


                    }


                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="firstName" disabled={!isEditing} label="Nombre" value={currentChat.Client?.firstName ? currentChat.Client.firstName : ''} />
                        </Grid>
                    }
                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="lastName" disabled={!isEditing} label="Apellido" value={currentChat.Client?.lastName ? currentChat.Client.lastName : ''} />
                        </Grid>
                    }


                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="phone" disabled={!newClient} label="Teléfono" value={currentChat.phone} />
                        </Grid>
                    }

                    {
                        !showEvents && <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="email" disabled={!isEditing} label="Correo" value={currentChat.Client?.email ? currentChat.Client.email : ''} />
                        </Grid>
                    }
                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="address" disabled={!isEditing} label="Dirección" value={currentChat.Client?.address} />
                        </Grid>

                    }
                    {
                        showEvents &&
                        <Grid
                            container item xs={12} alignItems="center" justifyContent="center"
                        >
                            <Typography fontSize={15}>Formularios</Typography>
                            <Divider />
                        </Grid>
                    }
                    {
                        showEvents && <AdviserForms />
                    }
                    {
                        showEvents && !newClient && <Grid
                            container item xs={12} alignItems="center" justifyContent="center"
                        >
                            <Typography fontSize={15}>Eventos</Typography>
                            <Divider />
                        </Grid>
                    }
                    {
                        showEvents && <Grid item xs={12} md={12} lg={12}>
                            {
                                clientEvents.length === 0 ?
                                    <Grid
                                        container item xs={12} alignItems="center" justifyContent="center"
                                    >
                                        <Typography> No hay eventos agendados</Typography>
                                    </Grid>
                                    :
                                    `Hay ${clientEvents.length} evento${clientEvents.length > 1 ? "s" : ""} agendado${clientEvents.length > 1 ? "s" : ""}`
                            }

                        </Grid>
                    }
                    {
                        showEvents && !newClient && <Grid item xs={12} md={12} lg={12}>
                            <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                                {
                                    clientEvents.map((event) => (
                                        <CalendarEventCard
                                            description={event.description}
                                            name={event.summary}
                                            startDate={event.startDateTime}
                                            endDate={event.endDateTime}
                                            link={event.link}
                                            onCancel={() => { setReloadEvents(!reloadEvents) }}
                                            externalAccountID={event.googleAccountID}
                                            externalEventID={event.eventID}
                                            allowCancel={revisaPermisos(["Cancelar eventos en calendario"])}
                                        />
                                    ))
                                }
                            </div>
                        </Grid>
                    }
                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="status" select disabled={!isEditing} label="Estatus" value={currentChat.Client?.status}>
                                {status?.map((st) =>
                                    <MenuItem key={st.id} value={st.id}>
                                        {st.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                    }

                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField onChange={handleClientDataChange} fullWidth name="product" select disabled={!isEditing} label="Producto" value={currentChat.Client?.product}>
                                {products?.map((st) => (
                                    <MenuItem key={st.id} value={st.id}>
                                        {st.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }

                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <MultiSelect
                                title="Etiquetas"
                                theme={{}}
                                list={tags}
                                onChange={(ids) => setSelectedTags(ids)}
                                initSelect={selectedTags}
                                disabled={!isEditing}
                            />

                        </Grid>
                    }

                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <MultiSelect
                                title="Etiquetas"
                                theme={{}}
                                list={tags}
                                onChange={(ids) => setSelectedTags(ids)}
                                initSelect={selectedTags}
                                disabled={!isEditing}
                            />

                        </Grid>
                    }

                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                multiline
                                rows={3}
                                value={currentChat.Client?.comment}
                                fullWidth
                                name="comment"
                                disabled={!isEditing}
                                label="Comentarios"
                                onChange={handleClientDataChange}
                            />

                        </Grid>
                    }



                    {!newClient
                        && <Grid item xs={12} md={12} lg={12}>

                           <QuotesModal variant="contained" clientID={currentChat.Client?.id}/>

                        </Grid>
                    }
                     {!newClient
                        && <Grid item xs={12} md={12} lg={12}>

                           <NewQuote variant="contained" client={{...currentChat.Client, Chat: currentChat}}/>

                        </Grid>
                    }
                    {revisaPermisos(["Programar eventos en calendario"]) && !newClient
                        && <Grid item xs={12} md={12} lg={12}>

                            <Button fullWidth size="large" onClick={() => {
                                if (currentChat.Client?.email) {
                                    setOpenCalendar(true);
                                } else {
                                    alert("El cliente no cuenta con un email")
                                }

                            }
                            } variant="contained" endIcon={<CalendarMonth />}>
                                Agendar cita
                            </Button>

                        </Grid>
                    }

                    {
                        !showEvents && revisaPermisos(["Enviar correos"]) && !newClient
                        &&
                        <Grid item xs={12} md={12} lg={12}>

                            <Button fullWidth size="large" onClick={() => {
                                if (currentChat.Client?.email) {
                                    setOpenEmail(true);
                                } else {
                                    alert("El cliente no cuenta con un email")
                                }

                            }
                            } variant="contained" endIcon={<Email />}>
                                Enviar correo
                            </Button>

                        </Grid>
                    }
                    {
                        !showEvents &&
                        <Grid item xs={12} md={12} lg={12}>
                            {isEditing ?
                                newClient ?
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <LoadingButton loading={isLoading}
                                                loadingIndicator="Cargando..." fullWidth size="large" color="success" onClick={() => { saveChatClientInfo(); }} variant="contained" endIcon={<Check />}>
                                                <span>Guardar</span>
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <LoadingButton loading={isLoading}
                                                loadingIndicator="Cargando..." fullWidth size="large" color="success" onClick={() => { saveChatClientInfo(); }} variant="contained" endIcon={<Check />}>
                                                <span>Guardar</span>
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <LoadingButton loading={isLoading}
                                                loadingIndicator="Cargando..." fullWidth size="large" color="error" onClick={() => { setIsEditing(!isEditing) }} variant="contained" endIcon={<Close />}>
                                                <span>Cancelar</span>
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                :
                                <Button fullWidth size="large" onClick={() => { setIsEditing(!isEditing) }} variant="contained" endIcon={<Edit />}>
                                    Editar
                                </Button>
                            }
                        </Grid>
                    }

                </Grid>

            </Grid>
            <SendEmailModal
                open={openEmail}
                setOpen={setOpenEmail}
                emails={[currentChat.Client?.email]}
            />

            <CalendarEventModal
                clientEmail={currentChat.Client?.email}
                externalClientID={currentChat.Client?.id}
                open={openCalendar}
                setOpen={setOpenCalendar}
            />
        </>
    )
}

export default ClientInfo;