import { ThreeP } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import MultiSelect from "../MultiSelect"
import Cookies from "universal-cookie"
import { useSnackbar } from "notistack"
import { SocketContext } from "../../services/socket"
import api from "../../services/api"




function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default function NewMessageModal({ phone }) {

    const { enqueueSnackbar } = useSnackbar();
    const [whatsapp, setWhatsapp] = useState('')
    const [message, setMessage] = useState('')
    const [tags, setTags] = useState([])

    const cookies = new Cookies();
    const [open, setOpen] = useState(false);

    const socket = useContext(SocketContext);
    const [sessions, setSessions] = useState([])
    function handleClose() {
        setOpen(false)
    }

    useEffect(() => {
        async function getSessions() {
            try {
                const { data } = await api.post("api/v2/getUserSessions");
                setSessions(data.sessions);
            } catch (err) {

            }
        }

        getSessions();
    }, [])


    async function sendMessage() {
        try {
           
            let objToSend = {
                message,
                idChat: `${phone}@c.us`,
                wpsession: whatsapp,
                audioMedia: null,
                attached: null,
                token: cookies.get(process.env.REACT_APP_COOKIE),
                tags
            }
            console.log(objToSend)
            if (!objToSend.idChat) {
                enqueueSnackbar("Es necesario agregar un teléfono", {
                    variant: 'warning',
                });
                return;
            }
            if (!objToSend.wpsession) {
                enqueueSnackbar("Es necesario seleccionar un whatsapp", {
                    variant: 'warning',
                });
                return;
            }


            if (!objToSend.message) {
                enqueueSnackbar("Es necesario escribir un mensaje", {
                    variant: 'warning',
                });
                return;
            }

            socket.emit('sendMessage', objToSend);


            enqueueSnackbar("Se envio el mensaje de forma correcta", {
                variant: 'success',
            });

            await timeout(2000);

            window.location.href = '/multiagente/chat';
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al enviar el mensaje", {
                variant: 'error',
            });
        }
    }
    return (
        <>
            <Button fullWidth onClick={() => setOpen(true)} >Enviar mensaje</Button>
            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                maxWidth="lg"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Envio de mensaje
                </DialogTitle >

                <DialogContent dividers>

                    <Grid
                        container
                      
                    >
                        <Grid item xs={12} md={12} lg={12}>

                            <TextField
                                value={whatsapp}
                                select
                                fullWidth
                                label="Selecciona un whatsapp"
                                name="type"
                                variant="outlined"
                                onChange={(event) => { setWhatsapp(event.target.value) }}

                            >
                                {
                                    sessions?.map((session) => (
                                        <MenuItem key={session.id} value={session.sessionAuth}>
                                            {session.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>

                            <PhoneInput
                                disabled={true}
                                country={navigator.language || navigator.userLanguage}
                                enableSearch={true}
                                placeholder="Ingresa el número"
                                value={phone}
                            //onChange={(phone) => { setPhone(phone) }} 
                            />


                        </Grid>
                        {/*<Grid item xs={12} md={12} lg={12}>

                            <MultiSelect
                                style={{ width: '100%' }}
                                title="Etiquetas"
                                theme={null}
                                list={tags}
                                onChange={(data) => { setTags(data) }}
                                initSelect={[]}

                            />
                        </Grid>*/}

                        <Grid item xs={12} md={12} lg={12}>

                            <TextField
                                fullWidth
                                value={message}
                                label="Mensaje"
                                name="message"
                                variant="outlined"
                                onChange={(e) => { setMessage(e.target.value) }}
                                multiline
                                rows={1}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="outlined" color="success" onClick={sendMessage}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}