import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';


export default function AddStatusModal({ title, open, setOpen, onSave, theme }) {


    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        setSelectedStatus(null);

        async function getstatus() {
            try {
                const { data } = await api.post("/api/getStatus");
                setStatus(data.statusList);
            } catch (err) {
                console.log(err);
            }
        }

        getstatus();
    }, [])

    const handleClose = () => {
        setOpen(false);
    }




    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Estatus"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth
                                sx={{ width: '80%' }}
                            // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                            >
                                {status?.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { onSave(selectedStatus); }}>

                        Asignar estatus

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
