import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

export default function GroupStructureModal({ open, setOpen, structure,onSave }) {


    const [currentStructure, setCurrentStructure] = React.useState({});

    const [currentFields, setCurrentField] = React.useState([
        { localColName: "firstName", simpleName: "Nombre" },
        { localColName: "lastName", simpleName: "Apellidos" },
        { localColName: "country", simpleName: "País" },
        { localColName: "city", simpleName: "Ciudad" },
        { localColName: "gender", simpleName: "Género" },
        { localColName: "level", simpleName: "Nivel educativo" },
        { localColName: "phone", simpleName: "Teléfono" }
    ]);

    React.useEffect(() => {
       
       
        setCurrentStructure({...structure});
    }, [structure])

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (field, event) => {
        const newVal = event.target.value;

        let newStrucutre = { ...currentStructure }
        newStrucutre[field].localColName = newVal;
        setCurrentStructure(newStrucutre);
    }

    return (
        <div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Asignar columnas</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Para la correcta sincronización de datos es necesario asignar una columna a cada valor para los clientes
                    </DialogContentText>
                    <Grid
                        container
                    >
                        {
                            Object.keys(currentStructure).map((field) => {

                                return <Grid item key={field} sm={12} md={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{`Columna ${field}`}</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentStructure[field].localColName}
                                            label="Valor"
                                            onChange={(e) => { handleChange(field, e) }}
                                        >
                                            {
                                                currentFields.map((fieldValues,key) => {
                                                    return <MenuItem key={key} value={fieldValues.localColName}>{fieldValues.simpleName}</MenuItem>
                                                })
                                            }


                                        </Select>
                                    </FormControl>
                                </Grid>
                            })
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={() => { onSave(JSON.stringify(currentStructure))}}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
