import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Modal from './../Modal/Modal'
import styled from 'styled-components'
import Button from './../Button/Button'
import { Formik } from 'formik';
import { BlockPicker } from 'react-color';
import { useEffect } from 'react';
import { useState } from 'react';



export default function BotonMas({ newtext= 'Nuevo Whatsapp',estadoModal, setEstadoModal, showAdd, addSession, currentSession, setCurrentSession }) {

  const [color, setColor] = useState({})

  const [id, setId] = useState(null)

  useEffect(() => {

    if (currentSession) {

      setCurSession(currentSession);
      setId(currentSession.id);
    } else {
      setCurSession({
        name: "",
        welcomeMessage: ""
      })
      setId(null)
    }
  }, [currentSession])



  const [curSession, setCurSession] = useState({
    name: "",
    welcomeMessage: ""
  });

  return (
    <>

      {showAdd && <SpeedDial
        FabProps={{ style: { backgroundColor: "#144985" } }}
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: 16, right: 'calc(50% - 20px)' }}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      >
        <SpeedDialAction
          icon={<FileCopyIcon></FileCopyIcon>}
          tooltipTitle={newtext}
          onClick={() => {
            setEstadoModal(!estadoModal); setCurSession({
              name: "",
              welcomeMessage: ""
            })
            setCurrentSession(null);
          }

          }
        />
      </SpeedDial>}

      <Modal
        estado={estadoModal}
        cambiarEstado={setEstadoModal}>
        <Formik
          initialValues={currentSession ? currentSession : curSession}
          validate={(valores) => {
            let errores = {};

            if (!valores.sistema) {
              errores.sistema = "El sistema no puede estar vacio"
            }
            if (!valores.mensaje) {
              errores.mensaje = "El mensaje no puede estar  vacio"
            }

            return errores;
          }}
        >

          {({ errors, handleBlur, touched, handleChange, values }) => (
            <form onSubmit={(event) => addSession(event, color, id)}>
              <Contenido1>
                <h1>Nuevo Sistema</h1>
                <br />
                <label>Sistema</label>
                <Input2
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Sistema"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <br />
                {touched.sistema && errors.sistema && <Error>{errors.sistema}</Error>}
                <br />
                <label>Mensaje</label>
                <Input3
                  type="text"
                  id="welcomeMessage"
                  name="welcomeMessage"
                  placeholder="Mensaje"
                  value={values.welcomeMessage}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />
                <br />
                {touched.mensaje && errors.mensaje && <Error>{errors.mensaje}</Error>}
                <br />

                {/*  <BlockPicker 
                  name="color"
                  color={color}
                  onChangeComplete={(color)=>{setColor(color);}}
          />¨*/}
                <Button type="submit" content="Aceptar"></Button>

              </Contenido1>
            </form>
          )}
        </Formik>
      </Modal>


    </>
  );
}



const Error = styled.div`
  color: red;
`;


const Contenido1 = styled.div`
	display: flex;
	flex-direction: column;
  align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 5px;
	}
  label {
		font-weight: 500;
		margin-bottom: 5px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;

const Input2 = styled.input`
  width: 100%;
  border-radius: 5px;
  height: 2rem;
  padding: 1rem;
  border: none;
  background-color: rgb(211, 211, 211);
`;

const Input3 = styled.input`
  width: 100%;
  border-radius: 5px;
  height: 10rem;
  padding: 1rem;
  border: none;
  background-color: rgb(211, 211, 211);
`;




