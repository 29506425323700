import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';



import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TemplateModal from '../../components/TemplateModal'
import { Helmet } from 'react-helmet-async';
import ShareModal from '../../components/ShareModal';
import { LoadingButton } from '@mui/lab';




/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Files({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [files, setFiles] = useState([]);

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);

  /**
   * Guarda el tepmlate seleccionado
   */
  const [current, setCurrent] = useState({
    id: null,
    name: '',
    path: '',

  });

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**
  * El nuevo archivo a subir
  */
  const [file, setFile] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [openShareModal, setOpenShareModal] = useState(false);

  const [advisers, setAdvisers] = useState([]);

  const [uploadingFile, setUploadingFile] = useState(false);

  /**
   * Carga la info necesaria
   */
  React.useEffect(() => {

    setOpen(false);
    setOpenShareModal(false);
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getLogFile", {});


        setFiles(data.files);


      } catch (err) {
        console.log(err)
      }
    }

    async function getAdvisers() {

      try {

        const { data } = await api.get("api/users/all");
        setAdvisers(data);


      } catch (err) {
        console.log(err);
      }


    }
    getSessions();
    getAdvisers()
  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      headerName: 'Editar', field: 'bitacora', flex: 1, hide: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => { setCurrent(params.row); setOpen(true) }}>Editar</Button>

        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'path', headerName: 'Archivo', flex: 1, editable: false,
    },
    {
      headerName: 'Vista', field: 'test', flex: 1, hide: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => { window.open(process.env.REACT_APP_BACK_URL + '/media/' + params.row.path, '_blank').focus(); console.log(params.row.path) }}>Ver</Button>
        )
      }
    },
  ];

  /**
   * Elimina un template
   * @param {*} id 
   */
  const deleteTemplate = async (dataTemplate) => {

    dataTemplate.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteLogFile", { id: ss.id });

        if (data.status == "Success") {

          enqueueSnackbar('Archivo eliminado', {
            variant: 'success',
          });


        } else {

          if (data?.code) {
            enqueueSnackbar(data.userMsg, {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(`Ocurrió un error al eliminar el Archivo ${ss.name}`, {
              variant: 'error',
            });
          }

        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar el Archivo', {
          variant: 'error',
        });
      }
    });

    setReload(!reload);


  }


  /**
   * Sube un archivo y lo guarda en files
   * @param {*} file 
   */
  const addFile = async () => {


    if (!current.name || current.name == '') {
      enqueueSnackbar(`Es necesario agregar un nombre`, {
        variant: 'warning',
      });
    }

    if (!file && !current.id) {
      enqueueSnackbar(`Es necesario adjuntar un archivo`, {
        variant: 'warning',
      });
    }
    let filePath = '';

    setUploadingFile(true)
    if (!current.id || file) {
      /**
           * Envia el archivo para subirlo
           */
      const formData = new FormData();
      formData.append('file', file)

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }



      try {
        const { data } = await api.patch('/api/upload', formData, config);

        /**
         * Recupera la ruta del archivo subido
         */
        filePath = data.fielName;
      } catch (err) {
        console.log(err);
        enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
          variant: 'error',
        });
        return null;
      }

      if (!filePath) {

        enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
          variant: 'error',
        });
        return null;
      }
    }


    /**
     * Registra el archivo en la tabla FILES
     */

    try {

      let request = {};

      let newFile = { ...current };

      if (file) {
        newFile.path = filePath;
      }
      request.fileObj = newFile;

      if (current.id) {
        request.id = current.id;
      }

      const { data } = await api.post("/api/addLogFile", request);

      if (data.status == "Success") {
        enqueueSnackbar(`El archivo se ${current.id ? 'modificó' : 'agregó'} de forma correcta`, {
          variant: 'success',
        });
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(`Ocurrió un error al ${current.id ? 'modificar' : 'agregar'} el Archivo`, {
            variant: 'error',
          });
        }

      }

      setReload(!reload)
      setUploadingFile(false)
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al ${current.id ? 'modificar' : 'agregar'} el archivo ${file.name}`, {
        variant: 'error',
      });
      setUploadingFile(false)
      return null;
    }


  }
  /**
   * Regresa los usuarios con los que se ha compartido una plantilla
   * Solo se usa cuando se selecciona una plantilla y no varias
   */
  const getSharedUsers = () => {

    if (selectedFiles.length == 1) {
      const userIDs = selectedFiles[0].Users.map(u => u.id)
      return userIDs
    } else {
      return [];
    }

  }

  /**
   * Comparte un archivo con varios usuarios
   */
  const shareFile = async (users) => {

    try {

      const { data } = await api.post('/api/shareFile', { userIDs: users, files: selectedFiles });
      if (data.status == "Success") {
        enqueueSnackbar(`Se compartió el archivo de forma correcta`, {
          variant: 'success',
        });

        setReload(!reload)
      } else {
        enqueueSnackbar(`Ocurrió un error al compartir el archivo`, {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al compartir el archivo', {
        variant: 'error',
      });
    }
  }


  return (
    <>

      <Helmet title="Plantillas" />

      <Typography variant="h3" gutterBottom display="inline">
        Almacenamiento
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Archivos
        </Link>

        {
          revisaPermisos(['Agregar Plantilla']) &&
          <Button variant='outlined' onClick={() => { setOpen(!open); setCurrent({}) }} >Nuevo archivo</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={files}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar Plantilla'])}
            onDelete={(data) => { deleteTemplate(data); }}
            onsSelectionChange={(data) => { setSelectedFiles(data) }}
            showShareButton={revisaPermisos(['Compartir archivos'])}
            onShare={() => { setOpenShareModal(true) }}
          />

        </Grid>
      </Grid>


      <Dialog
        disableEnforceFocus
        onClose={() => { setOpen(false) }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { setOpen(false) }}>
          Archivos
        </DialogTitle >

        <DialogContent dividers>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth

                value={current.name}
                label="Nombre del archivo"
                name="name"
                variant="outlined"
                onChange={(event) => { setCurrent({ ...current, name: event.target.value }); }}

              >
              </TextField>

            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>

              <LoadingButton
                loading={uploadingFile}
                variant="contained"
                component="label"

                fullWidth

              >
                {
                  current.id && !file ?
                    current.path
                    :
                    !file ?
                      "Subir archivo"
                      :
                      file.name
                }
                <input
                  type="file"
                  multiple={false}
                  hidden
                  onChange={(e) => { setFile(e.target.files[0]) }}
                />
              </LoadingButton>

            </Grid>



          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={uploadingFile}theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
            Cancelar
          </LoadingButton>
          <LoadingButton loading={uploadingFile}theme={theme} variant="contained" color="primary" onClick={() => { addFile() }}>
            {
              current.id ? "Actualizar" : "Agregar"
            }
          </LoadingButton>
        </DialogActions>
      </Dialog>


      {
        revisaPermisos(['Compartir archivos']) &&
        <ShareModal
          open={openShareModal}
          setOpen={setOpenShareModal}
          theme={null}
          title={"Compartir plantilla"}
          users={advisers}
          onSave={shareFile}
          selectedUsers={getSharedUsers()}
        />

      }

    </>
  );
}

