import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import RemembersView from '../RemembersView';


export default function CalendarEventModal({ open, setOpen, clientEmail, externalClientID }) {
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Carga los nuevos emails
     */
    useEffect(() => {
        setEmail(clientEmail)
    }, [clientEmail]);

    useEffect(() => {
        setCLientID(externalClientID)
    }, [externalClientID]);


    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }
        getaccounts();
    }, [])
    /**
     * Variable para guardar los datos del usuario
     */
    const [email, setEmail] = useState(null);
    const [clientID, setCLientID] = useState(null);
    const [startDateTime, setStartDateTime] = useState(new Date());
    const [endDateTime, setEndDateTime] = useState(new Date());
    const [summary, setSummary] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [accountID, setAccountID] = useState(null);

    const [view, setView] = useState(0)
    const [remembers, setRemembers] = useState([])
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };



    const createEvent = async () => {
        try {

            if (!accountID) {
                enqueueSnackbar("Debe seleccionar una cuenta de google", {
                    variant: 'warning',
                });
                return;
            }
            if (!summary) {
                enqueueSnackbar("Debe agregar un titulo", {
                    variant: 'warning',
                });
                return;
            }
            if (!description) {
                enqueueSnackbar("Debe agregar una descripción", {
                    variant: 'warning',
                });
                return;
            }
            const request = {
                accountID,
                summary,
                location,
                description,
                startDateTime,
                endDateTime,
                clientID,
                clientEmail: email,
                remembers
            }

            const { data } = await api.post('/api/googleCalendar/add', request);
            enqueueSnackbar("Cita programada", {
                variant: 'success',
            });
            setOpen(false)
        } catch (err) {
            enqueueSnackbar("Ocurrió un error al agendar la cita", {
                variant: 'error',
            });
        }
    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <Button variant={view == 0 ? 'contained' : 'outlined'} onClick={() => setView(0)}> Programar cita</Button>
                    <Button variant={view == 1 ? 'contained' : 'outlined'} onClick={() => setView(1)}> Recordatorios</Button>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        view == 1 && <RemembersView currentRemembers={remembers} onChange={(value) => setRemembers(value)} />
                    }

                    {view == 0 && <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth name="summary" label="Titulo"
                                value={summary} onChange={(e) => setSummary(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth name="location" label="Lugar"
                                value={location} onChange={(e) => setLocation(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth name="description" label="Descripción"
                                value={description} onChange={(e) => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Cuenta de Google"
                                value={accountID}
                                onChange={(e) => setAccountID(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth

                            // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                            >
                                {accounts?.map((account) => (
                                    <MenuItem key={account.id} value={account.id}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    sx={{ width: '80%' }}
                                    label="Inicio"
                                    value={startDateTime}
                                    inputFormat={"YYYY-MM-DD HH:mm"}
                                    fullWidth
                                    onChange={(newDate) => setStartDateTime(newDate)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    sx={{ width: '80%' }}
                                    label="Fin"
                                    value={endDateTime}
                                    inputFormat={"YYYY-MM-DD HH:mm"}
                                    fullWidth
                                    onChange={(newDate) => setEndDateTime(newDate)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    }
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>
                    {
                        view == 0 &&
                        < Button variant="outlined" color="success" onClick={createEvent}>
                            Guardar
                        </Button>

                    }

                </DialogActions>
            </Dialog >
        </>
    );
}
