import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { Send } from 'react-ionicons';

import { useSnackbar } from 'notistack';
import api from '../../services/api';



const urlExpresion = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const urlRegex = new RegExp(urlExpresion);

export default function FormWebhookModal({ title, open, setOpen, onSave, webhookUrl }) {

    /**
     * Asigna la url del webhook
     */
    useEffect(() => {

        setCurrentWebhookUrl(webhookUrl);

    }, [webhookUrl]);


    /**
    * Cierra la vista de pruebas
    */
    useEffect(() => {

        setTestMode(false)

    }, [open]);


    /**
     * Guarda la url en caso de haberla
     */
    const [currentWebhookUrl, setCurrentWebhookUrl] = useState(webhookUrl);

    /**
     * Activa la vista de pruebas
     */
    const [testMode, setTestMode] = useState(false);

    /**
     * Número para pruebas
     */
    const [currentPhone, setCurrentPhone] = useState(null);

    const [urlFormatError, setUrlFormatErro] = useState(false);

    useEffect(() => {
        setUrlFormatErro(urlRegex.test(currentWebhookUrl));

    }, [currentWebhookUrl])


    const [loading, setLoading] = useState(true);

    /**
    * Notifiaciones
    */
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Prueba el webhook asignado
     */
    const sendWebhookTest = async () => {

        setLoading(true);
        try {
            const { data } = await api.post('/api/testWebhook', { phone: currentPhone,url: currentWebhookUrl });

            if(data.status == "Success"){
                enqueueSnackbar(`Prueba enviada exitosamente`, {
                    variant: 'success',
                });
            }else{
                
                enqueueSnackbar(`Ocurrio un error al enviar la prueba ${data.msg}`, {
                    variant: 'error',
                });
            }
         
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al enviar la prueba ${err.message}`, {
                variant: 'error',
            });
        }
        setLoading(false);
    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>

                        {
                            !testMode &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    error={urlFormatError}
                                    value={currentWebhookUrl}
                                    label="Url del webhook"
                                    name="name"
                                    variant="outlined"
                                    onChange={(event) => { setCurrentWebhookUrl(event.target.value); }}
                                    helperText={urlFormatError ? "La url no tiene el formato correcta" : ''}
                                >
                                </TextField>
                            </Grid>


                        }
                        {
                            !testMode && !urlFormatError &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button onClick={() => { setTestMode(true) }}>Probar webhook</Button>
                            </Grid>
                        }

                        {
                            testMode &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth

                                    value={currentPhone}
                                    label="Numero de prueba"
                                    name="phone"
                                    variant="outlined"
                                    onChange={(event) => { setCurrentPhone(event.target.value); }}

                                >
                                </TextField>
                            </Grid>
                        }



                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !testMode &&
                        <>
                            <Button  variant="outlined" color="error" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button disabled={urlFormatError}  variant="contained" color="primary" onClick={() => onSave(currentWebhookUrl)}>
                                Guardar
                            </Button>
                        </>
                    }
                    {
                        testMode &&
                        <>
                            <Button  variant="outlined" color="error" onClick={() => { setTestMode(false) }}>
                                Regresar
                            </Button>
                            <LoadingButton

                                onClick={sendWebhookTest}
                                endIcon={<Send />}
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Probar
                            </LoadingButton>
                        </>
                    }

                </DialogActions>
            </Dialog>
        </>
    );
}
