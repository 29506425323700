import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import { SnackbarProvider } from "notistack";
import { Button } from "@mui/material";
import { FacebookProvider } from "react-facebook";
import { createRef } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SocketContext,socket } from "./services/socket";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  /**
   * Referencia para poder usar las notificaciones
   */
  const notistackRef = createRef();

  /**
   * Cierra la notificacion
   * @param {*} key 
   * @returns 
   */
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        {/*Texto que aparece en la pestaña del navegador*/}
        <Helmet
          titleTemplate="%s | PulpoTicket"
          defaultTitle="PulpoTicket  -  Inicio"
        />
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={5}
            ref={notistackRef}
            /**
             * Agrega el botón de cerrar para todas las notificaciones
             */
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>
                Cerrar
              </Button>
            )}
          >
            <FacebookProvider appId="708484500834063">
            <SocketContext.Provider value={socket}>
            <GoogleOAuthProvider clientId="334994534128-j8mkdgjarctl3qihetaif5guqhhu272f.apps.googleusercontent.com">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </MuiThemeProvider>

              </LocalizationProvider>
              </GoogleOAuthProvider>
              </SocketContext.Provider>
            </FacebookProvider>
          </SnackbarProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
