import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import api from "../../services/api";
import { Box } from "react-feather";
import { GridOn } from "@mui/icons-material";

const AddGoogleSheetModal = ({ open, setOpen, title, theme, reload }) => {

    const [accountID, setAccountID] = useState(0);

    const [sheetID, setSheetID] = useState(0);

    const [invName, setInvName] = useState('');

    const [sheetName, setSheetName] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const [sheets, setSheets] = useState([]);

    const [sheetNames, setSheetNames] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }
        getaccounts();
    }, [])


    useEffect(() => {
        async function getSheets() {

            try {

                const { data } = await api.post("/google/getGoogleSheets", { id: accountID });

                setSheets(data.sheets);

            } catch (err) {
                console.log(err)
            }
        }

        if (accountID && accountID != 0) {
            getSheets();
        }

    }, [accountID])

    useEffect(() => {
        async function getSheetNames() {

            try {

                const { data } = await api.post("/google/getSheetNames", { id: accountID, spreadsheetId: sheetID });

                setSheetNames(data.sheetNames);

            } catch (err) {
                console.log(err)
            }
        }

        if (sheetID && sheetID != 0) {
            getSheetNames();
        }

    }, [sheetID])

    useEffect(() => {
        setIsLoading(false)
        setAccountID(null)
        setSheetID(null);
    }, [open])




    const syncSheet = async () => {

        if (!accountID || accountID == 0) {
            enqueueSnackbar("Debe seleccionar una cuenta de google", {
                variant: 'warning',
            });
            return false;
        }

        if (!sheetID || sheetID == 0) {
            enqueueSnackbar("Debe seleccionar una hoja", {
                variant: 'warning',
            });
            return false;
        }

        if (!sheetName || sheetName == 0) {
            enqueueSnackbar("Debe seleccionar una página", {
                variant: 'warning',
            });
            return false;
        }

        if (!invName || invName == '') {
            enqueueSnackbar("Debe agregar un nombre", {
                variant: 'warning',
            });
            return false;
        }
        

        setIsLoading(true);
        try {
            const { data } = await api.post('/google/syncSheet', { 
                id: accountID,
                spreadsheetId: sheetID,
                sheetName: sheetName,
                name: invName
            })


            if (data.status == "Success") {
                enqueueSnackbar("Se sincronizó el inventario de forma correcta", {
                    variant: 'success',
                });
                reload();
            } else {
                enqueueSnackbar("No se logró sincronizar el forumlario", {
                    variant: 'error',
                });
            }
          
        } catch (err) {
            console.log(err)
            enqueueSnackbar("No se logró sincronizar el forumlario", {
                variant: 'error',
            });
        }
        setIsLoading(false);
        handleClose();
    }


    const handleClose = () => {
        setOpen(false);

    }


    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle >

            <DialogContent dividers>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="outlined-select-currency"
                            label="Nombre"
                            value={invName}
                            onChange={(e) => setInvName(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Cuenta de Google"
                            value={accountID}
                            onChange={(e) => setAccountID(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth

                        // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                        >
                            {accounts?.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Autocomplete
                            options={sheets}
                            getOptionLabel={(option) => option.name}
                            id="sheets"
                            clearOnEscape
                            onChange={(event, newValue) => {

                                if (newValue) {
                                    setSheetID(newValue.id);
                                }

                            }}
                           
                            renderInput={(params) => (

                                <TextField {...params} label="Selecciona una hoja" variant="standard" />

                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Autocomplete
                            options={sheetNames}
                            getOptionLabel={(option) => option}
                            id="sheetNames"

                            clearOnEscape
                            onChange={(event, newValue) => {

                                if (newValue) {
                                    setSheetName(newValue);
                                }

                            }}
                           
                            renderInput={(params) => (

                                <TextField {...params} label="Selecciona una página" variant="standard" />

                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                    Cancelar
                </Button>
                <LoadingButton theme={theme} loading={isLoading} variant="contained" color="primary" onClick={() => syncSheet()}>

                    Sincronizar

                </LoadingButton>
            </DialogActions>
        </Dialog>

    )

}

export default AddGoogleSheetModal;