import { Breadcrumbs, Divider, Grid, Link, Typography } from "@mui/material";

import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading";

import api from "../../services/api";

import MassiveTable from "../../components/MassiveTable";

import { SocketContext } from '../../services/socket';
import { useNavigate } from "react-router-dom";
import WebhookMsgModal from "../../components/WebhookMsgModal";
import MassiveMsgModal from "../../components/MassiveMsgModal";
import { Helmet } from "react-helmet-async";

/**
 * Vista para la consulta de envios masivos
 * @returns 
 */
const EmailHistory = ({ theme }) => {
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Variable que recibirá el objeto de mensajes masivos
     * incluye su lista de mensajes
     */
    const [messages, _setMessages] = useState([]);

    /**
     * 
     */
    const messagesRef = useRef(messages);

    const setMessages = data => {
        messagesRef.current = data;
        _setMessages(data);
    }
    /**
     * Variable para mostrar y coultar el modal
     */
    const [open, setOpen] = useState(false);
    /**
     * Variable para mostrar y coultar el modal de reenvio
     */
    const [openResent, setOpenResent] = useState(false);
    /**
     * Variable pararecargar la tabla sin recargar la página
     */
    const [reload, setReload] = useState(false);
    /**
     * Variable para mostrar la página de carga
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
    * Variable que contiene la lista de permisos del usuario
    */
    const [permissionsUser, setPermissionsUser] = useState([]);

    /**
    * Variable que contiene la lista sistemas sobre los que tiene permiso
    */
    const [sessions, setSessions] = useState([]);

    /**
    *  id del objeto seleccionado dentro del array
    */
    const [curentMsgId, setCurrentMsgId] = useState(0);

    /**
     * Socket para lo refrebte al chat
     */
    const socket = useContext(SocketContext);
    /**
     * Constante para navegar entre las vistas
     */
    const navigate = useNavigate();

    /**
     * Se ejecuta al inicio
     */
    useEffect(() => {


        /**
         * Consulta los permisos del usuario
         * Con los sistemas consulta los mensajes masivos que puede ver
         */
        async function getMassiveMsg() {
            try {
                try {

                    const userInfo = await api.post("/api/getPermissions");


                    setPermissionsUser(userInfo.data.userInfo.permissions)
                    setSessions(userInfo.data.userInfo.wpsessions);

                    /**
                     * Si no tiene los permisos entonces lo manda a la página de no permitidos
                     */
                    if (!revisaPermisos(['Bitacora Envio Masivo'], userInfo.data.userInfo.permissions)) {
                        navigate('/notAllowed')
                    }

                    // console.log(userInfo.data.userInfo.wpsessions)
                    const { data } = await api.post("/api/massive/listV2", { kind: "Email" });

                    /**
                     * Valida el estatus Success
                     * sino manda un error al usuario
                     */


                    if (data.status == 'Success') {


                        setMessages(data.messages);
                        setIsLoading(false)
                    } else {

                        console.log(data);
                        enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                            variant: 'error',
                        });
                    }

                } catch (err) {
                    enqueueSnackbar('Ocurrió un error al consultar los permisos del usuario', {
                        variant: 'error',
                    });
                    console.log(err);
                }


            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                    variant: 'error',
                });
                console.log(err);
            }

        }

        setOpenResent(false);
        getMassiveMsg();
    }, [reload])


    /**
     * Funcion para obtener los mensajes y agregarlos
     */
    const onGetUpdate = useCallback((id, messageObj) => {

        let selectedMsgMassive = {};

        let found = false;

        for (let i = 0; i < messagesRef.current.length; i++) {

            if (messagesRef?.current[i]?.id === id) {


                selectedMsgMassive = messagesRef.current[i];
                selectedMsgMassive.totalMessagesSend = messageObj.count;
                selectedMsgMassive.status = messageObj.groupStatus ? messageObj.groupStatus : selectedMsgMassive.status;




                for (let msgIndex in selectedMsgMassive.MassiveMessageLists) {

                    if (selectedMsgMassive?.MassiveMessageLists[msgIndex]?.contact == messageObj.contact) {
                        found = true;
                        selectedMsgMassive.MassiveMessageLists[msgIndex].status = messageObj.status;
                        break
                    }
                }

                if (!found && messageObj?.newContact) {


                    let tempMassiveSelect = { ...selectedMsgMassive };
                    tempMassiveSelect.MassiveMessageLists = [...tempMassiveSelect.MassiveMessageLists, messageObj.newContact]


                    let newArr = [...messagesRef.current];
                    newArr[i] = tempMassiveSelect

                    setMessages([
                        ...newArr,
                    ]);

                    break;

                } else {

                    let newArr = [...messagesRef.current];
                    newArr[i] = selectedMsgMassive

                    setMessages([
                        ...newArr,
                    ]);

                    break;
                }

            }
        }


    }, [messagesRef])

    /** 
     * Inicializa el sokcet para recibir los mensajes
     */
    useEffect(() => {

        if (socket && sessions.length > 0) {

            messages.map((msg, key) => {
                socket.on(`MSG_SUCCESS${msg.id}`, (messageObj) => {

                    onGetUpdate(msg.id, messageObj);
                });
            })

        }

    }, [socket, sessions, messages, onGetUpdate])




    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, list = permissionsUser, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }

    const getCurrentMsgs = (id) => {
        for (let i = 0; i < messages.length; i++) {

            if (messages[i].id == id) {

                return messages[i].MassiveMessageLists;
            }

        }
    }

    const getWhatsapp = (id) => {
        for (let i = 0; i < messages.length; i++) {
            // console.log(id)

            if (messages[i].id == id) {

                return messages[i].wpId;
            }

        }

        return null;
    }

    const getCurrentMsgsArr = (id) => {

        let arr = [];
        for (let i = 0; i < messages.length; i++) {
            // console.log(id)

            if (messages[i].id == id) {

                for (let j = 0; j < messages[i].MassiveMessageLists.length; j++) {

                    if (messages[i]?.MassiveMessageLists[j]?.contact) {
                        arr.push(messages[i].MassiveMessageLists[j].contact);
                    }

                }
            }

        }

        return arr;
    }


    /**
     * Actualiza el número de teléfono de la bitacora
     * @param {*} id 
     * @param {*} phone 
     */
    const onEditPhone = async (id, phone) => {

        // console.log(phone);
        try {
            const { data } = await api.post('/api/msgListUpdate', { id: id, contact: phone, status: "Modificado" });

            if (data.status == "Success") {
                enqueueSnackbar(`Número actualizado`, {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                variant: 'error',
            });
        }

        reloadMain();

    }

    const reloadMain = () => {
        setReload(!reload)
    }

    /**
     * Metodo para el envio del cambio del nombre
     */
    const editGroupName = async (requestObj) => {

        try {
            const { data } = await api.post('/api/massivemessage/udpate', requestObj);

            if (data.status == 'Success') {
                enqueueSnackbar(`Nombre del grupo actualizado`, {
                    variant: 'success',
                });
            } else {
                throw 'Error al actualizar el grupo';
            }
            reloadMain();
        } catch (err) {
            enqueueSnackbar(`Error al actualizar el grupo`, {
                variant: 'error',
            });
        }

    }
    return (
        <>
            <Helmet title="Mensajes" />

            <Typography variant="h3" gutterBottom display="inline">
                Correos
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Historial
                </Link>
                <Link >Correos</Link>

            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Loading open={isLoading} />
            <WebhookMsgModal
                title={"Bitacora de correos"}
                isEmail={true}
                showResendButton={revisaPermisos(['Enviar mensaje masivo'])}
                //theme={props}
                forwardingId={curentMsgId}
                open={open}
                setOpen={setOpen}
                messages={messages.length > 0 ? getCurrentMsgs(curentMsgId) : []}
                onEdit={onEditPhone}
                onResednAll={(resendMsgs) => {
                    /*
                    if (resendMsgs.length > 0) {

                        socket.emit('resendMassiveMsg', { idMassiveMg: resendMsgs[0].msgMassiveId });
                        enqueueSnackbar(`Reenviando mensajes`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`No hay mensajes en la bitacora`, {
                            variant: 'error',
                        });
                    }*/
                }}
                onResend={(resendMsgs) => {
                    /*console.log("Inicia envio");

                    if (resendMsgs.length > 0) {

                        let arrMsg = [];


                        for (let i = 0; i < resendMsgs.length; i++) {
                            arrMsg.push(resendMsgs[i].id)
                        }

                        // console.log("Enviando");
                        // console.log(resendMsgs);

                        let socketObjTest = { idMassiveMg: resendMsgs[0].msgMassiveId, pengindIds: arrMsg };
                        // console.log(socketObjTest);


                        socket.emit('resendMassiveMsg', socketObjTest);
                        enqueueSnackbar(`Reenviando ${resendMsgs.length} mensajes seleccionados`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`No hay mensajes en la bitacora`, {
                            variant: 'error',
                        });
                    }*/
                }}
            />


            <MassiveMsgModal
                title={"Reenvio"}
                theme={theme}
                setOpen={setOpenResent}
                open={openResent}
                forwardingId={curentMsgId}
                whatsapp={getWhatsapp(curentMsgId)}
                contactsExternal={getCurrentMsgsArr(curentMsgId)}
                reloadMain={reloadMain}
                disableNav
            />
            <Grid
                container

                sx={{ padding: '20px', height: '80vh' }}
                alignItems="center"
                justify="center"
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '100%' }}
                >
                    <MassiveTable
                        isEmail={true}
                        massiable
                        massiveMsg={messages}
                        forwardingId={curentMsgId}
                        setCurrent={(id) => {
                            setCurrentMsgId(id);
                            setOpen(true);

                        }}
                        showResent={(id) => {
                            setCurrentMsgId(id);
                            setOpenResent(true);

                        }}

                        onEdit={editGroupName}
                        onDelete={async (ids) => {

                            await Promise.all(ids.map(async (group) => {
                                try {
                                    const { data } = await api.post('/api/massive/delete', { id: group.id });

                                    if (data.status == "Success") {
                                        enqueueSnackbar(`Grupo eliminado ${group.name} exitosamente`, {
                                            variant: 'success',
                                        });
                                    } else {
                                        throw "Error, el id es requerido";
                                    }
                                } catch (err) {
                                    enqueueSnackbar(`Ocurrio un error al eliminar el grupo ${group.name}`, {
                                        variant: 'error',
                                    });
                                }

                                return true;
                            }))

                            reloadMain();
                        }}
                        showDelete={revisaPermisos(['Eliminar bitacora mensajes masivos'])}
                        showResendButton={false}

                        showResendAllButton={revisaPermisos(['Enviar mensaje masivo'])}
                    />
                </Grid>

            </Grid>



        </>
    )
}

export default EmailHistory;