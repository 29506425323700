import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';


export default function TwilioModal({ title, open, setOpen, onSave, theme, wpsessions, wpsessionsId, currentTwilioPhone }) {

    /**
     * 
     */
    useEffect(() => {
        setCurrentSessions(wpsessions);
    }, [wpsessions]);

    /**
    * 
    */
    useEffect(() => {
        if(currentTwilioPhone){
            console.log(currentTwilioPhone)
            setTwilioPhone(currentTwilioPhone);
        }else{
            setTwilioPhone({
                name: '',
                phone: ''
            });
        }
       
    }, [currentTwilioPhone]);


    /**
     * Variable para el nuevo perfil
     */
    const [twilioPhone, setTwilioPhone] = useState({
        id: null,
        name: '',
        phone: ''
    });

    /**
     * Variable para la lista de sistemas asignaods
     */
    const [currentSessions, setCurrentSessions] = useState(wpsessions);


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setTwilioPhone({
            ...twilioPhone,
            [event.name]: event.value,
        })

    }

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={twilioPhone.name}
                                label="Nombre del teléfono"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={twilioPhone.phone}
                                label="Teléfono"
                                name="phone"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(twilioPhone)}>
                        {twilioPhone.id ? "Actualizar": "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
