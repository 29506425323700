import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

const NoDataFound = ({mainText, secondaryText}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Ocupa toda la altura de la pantalla
      }}
    >
      <Card
        sx={{
          maxWidth: 600,
          padding: 2,
          textAlign: 'center',
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <WarningIcon
            sx={{ fontSize: 50, color: 'orange', mb: 2 }} // Tamaño grande del icono
          />
          <Typography variant="h4" component="div" sx={{ mb: 2 }}>
            {mainText}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {secondaryText}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NoDataFound;
