import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid } from '@mui/material';
import WebhookMsgTable from '../WebhookMsgTable';
import Modal from '@mui/material/Modal';

export default function WebhookMsgModal({ isEmail,title, open, setOpen, theme, messages,showResendButton, onResednAll, onResend,onEdit }) {

    /**
     * Carga los nuevos mensajes
     */
    useEffect(() => {

        setMessagesLocal(messages)
    }, [messages]);

    /**
     * Variable para guardar los mensajes
     */
    const [messagesLocal, setMessagesLocal] = useState([]);

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"
            

            >


                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >


                <DialogContent dividers>
                    <Grid
                        container

                        sx={{ height: '70vh', minWidth: '100%' }}
                        alignItems="center"
                        justify="center">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            align="center"
                            sx={{ height: '90%' }}
                        >
                            <WebhookMsgTable
                                showResendButton={showResendButton}
                                massiveMsg={messages}
                                onSave={() => { }}
                                onResendAll={() => {
                                    onResednAll(messages)



                                }}
                                onEdit={onEdit}
                                onResend={(phones) => {onResend(phones) }}
                                isEmail={isEmail}
                            />

                        </Grid>


                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>

                </DialogActions>
            </Dialog>

        </>
    );
}
