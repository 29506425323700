import { Badge, Box, Card, CardActions, CardContent, CardHeader, Chip, Grid, IconButton, Typography } from "@mui/material";

import EmailViewModal from "../EmailViewModal";
import { formatDate } from "../../utils/generalFunctions";
import { Mail } from "@mui/icons-material";
import ReplyButton from "../ReplyButon";
function extractEmail(str) {
    // Define la expresión regular para extraer el correo electrónico
    const emailRegex = /<([^>]+)>/;
    // Ejecuta la expresión regular en la cadena proporcionada
    const match = str?.match(emailRegex);
    // Devuelve el correo electrónico si se encuentra, de lo contrario devuelve null
    return match ? match[1] : null;
  }


export default function EmailItem({ email }) {
    const { subject, timestamp, snippet, sender, body, GoogleAccount, isNew } = email;
    return (
        <Grid item xs={10}>

            <Card sx={{ width: '100%', position: 'relative' }}>
                <CardHeader
                    title={subject}
                    subheader={` ${sender}`}
                />
                <IconButton aria-label="new"
                    sx={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem'
                    }}
                >
                    {isNew && <Badge
                        badgeContent="New"
                        color="error"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Mail />
                    </Badge>
                    }
                </IconButton>
                <Chip
                    variant="outlined"
                    sx={{
                        position: 'absolute',
                        top: '1rem',
                        right: isNew ? '3rem':'1rem',
                    }} label={GoogleAccount.email} color="success" />
                <CardContent>
                    <Typography variant="body1">
                        {snippet}
                    </Typography>

                </CardContent>
                <CardActions disableSpacing>
                    {formatDate(timestamp)}

                    <Box sx={{ flexGrow: 1 }} />
                    <EmailViewModal title={subject} emailBody={body} />
                    <ReplyButton account={GoogleAccount} subject={subject} email={extractEmail(sender)}/>

                </CardActions>
            </Card>
        </Grid>
    )
}