import { Add, Delete } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function QuoteItems({ setProducts, externalProducts }) {

    const [items, setItems] = useState([]);

    useEffect(()=>{
        if(externalProducts){
            setItems(externalProducts);
        }
    },[])
    const handleChange = (e, index) => {
        const event = e.target;

        let itemsList = [...items]
        itemsList[index] = {
            ...itemsList[index],
            [event.name]: event.value,
        }
        setItems(itemsList);
        setProducts(itemsList)
    }

    function addProduct(){
        let itemsList = [...items]
        itemsList.push({
            name: 'Producto',
            description: 'Descripción',
            unitPrice: 10,
            quantity: 2
        })
        setItems(itemsList);
        setProducts(itemsList)
    }

    function deleteProduct(index){
        let itemsList = [...items]
        itemsList.splice(index,1)
        setItems(itemsList);
        setProducts(itemsList)
    }
    return (
        <Grid container spacing={4}>
            <Grid item xs={8}>
                <Typography>Productos</Typography>
            </Grid>
            <Grid item xs={4}>
               <Button onClick={addProduct} startIcon={<Add/>}>Agregar producto</Button>
            </Grid>
            {
                items.map((item, index) =>
                    <Grid key={index} item container>
                        <Grid item xs={6}>
                            <TextField fullWidth value={item.name} label="Nombre" name="name" onChange={e => handleChange(e, index)} />
                        </Grid>
                       
                        <Grid item xs={3}>
                            <TextField fullWidth value={item.unitPrice} label="Pecio" name="unitPrice" onChange={e => handleChange(e, index)} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth value={item.quantity} label="Cantidad" name="quantity" onChange={e => handleChange(e, index)} />
                        </Grid>
                        <Grid item xs={1}>
                            <Button color="error" startIcon={<Delete/>} onClick={()=>deleteProduct(index)}></Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth value={item.description} label="Descripción" name="description" onChange={e => handleChange(e, index)} />
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}