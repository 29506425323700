
import React from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Helmet } from 'react-helmet-async';
import { Breadcrumbs, Button, Divider, Grid, Link, Typography } from '@mui/material';
import './styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import ClientCalendarEventModal from '../../components/ClientCalendarEventModal';
import esLocale from '@fullcalendar/core/locales/es';
const localizer = momentLocalizer(moment);


const ClientCalendar = () => {


  const [calendarDays, setCalendarDays] = useState([]);
  const { userId, formId } = useParams();


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false)
  const [formText, setFormText] = useState("");
  const [formName, setFormName] = useState("");
  const [image, setImage] = useState("");
  useEffect(() => {
    async function getCalendarDays() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/client/calendar/${userId}/${formId}`)
        setCalendarDays(data.days)
        setFormText(data.text);
        setFormName(data.name)
        setImage(data.filePath)
      } catch (err) {
        alert("Ocurrió un error al consultar los días")
      }
    }

    getCalendarDays();
  }, [])

  const deshabilitarLunes = date => {
    const enableDay = calendarDays.find((day) => day.day == date.getDay())
    if (!enableDay) {
      return {
        style: {
          // backgroundColor: 'lightgray',
          //color: 'gray', 
          cursor: 'not-allowed'
        },
        onClick: () => { }
      };
    }
    return {
      style: {
        backgroundColor: 'lightgreen',
        cursor: 'pointer'

      },
    };
  };

  const handleSelect = ({ start, end }) => {
    setSelectedDate(start)
    setOpen(true)

  };

  return (
    <>
      <Helmet title="Programar cita" />

      <Typography variant="h3" gutterBottom display="inline">
        Calendario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Citas
        </Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <Calendar
            localizer={localizer}
            events={[]}
            selectable={true}
            onSelectSlot={handleSelect}
            dayPropGetter={deshabilitarLunes}
            locales={[esLocale]}
            locale="es" // Set the locale to Spanish
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
          />
        </Grid>
      </Grid>

      <ClientCalendarEventModal
        name={formName}
        text={formText}
        adviserID={userId}
        formId={formId}
        date={selectedDate}
        dayNumber={selectedDate.getDay()}
        open={open}
        setOpen={setOpen}
        image={image}
      />
    </>
  );
};

export default ClientCalendar;
