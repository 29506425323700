
/**MUI */
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';


import api from '../../services/api';
import Papa from "papaparse";
import { Add, AddToDrive, ConstructionOutlined, ContentPaste, Download, Event, FeaturedPlayList, FileUpload, HighlightOff, Storage } from '@mui/icons-material';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { Breadcrumbs, Card, CardContent, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Link, TextField, Typography, withStyles } from '@mui/material';
import DatabaseTable from '../../components/DatabaseTable';
import CallModal from '../../components/CallModal';
import { useSnackbar } from 'notistack';
import { SocketContext } from '../../services/socket';
import MassiveMsgModal from '../../components/MassiveMsgModal';
import DatabaseCard from '../../components/DatabaseCard';
import { IconButton } from '@mui/material';
import GoogleLoginView from '../../components/GoogleLogin';
import { AddCircle } from 'react-ionicons';
import { Helmet } from 'react-helmet-async';
import { read, writeFileXLSX, utils } from "xlsx";

/**
 * Carga la vista para subir contactos a la base de datos
 * @param {*} param0 
 */
const Database = ({ theme,onFinish }) => {

    const [columns, setColumns] = useState([]);

    const [rows, setRows] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [groups, setGroups] = useState([])


    const [db, setDb] = useState(true)

    const [advisers, _setAdvisers] = useState([]);

    /**
     * 
     */
    const advisersRef = useRef(advisers);

    const setAdvisers = data => {
        advisersRef.current = data;
        _setAdvisers(data);
    }

    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [openCallModal, setOpenCallModal] = useState(false);

    const [openMsgModal, setOpenMsgModal] = useState(false);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);

    const [userId, setUserId] = useState();
    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [currentGroupId, setCurrentGroupId] = useState(null);
    /**
  * Notifiaciones
  */
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        async function getGroups() {
            try {

                const { data } = await api.post("/api/getGroups");

                if (data.status == "Success") {
                    setGroups(data.books);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }
        async function getPermissions() {

            try {

                const userInfo = await api.post("/api/getPermissions");
                //console.log(userInfo.data);

                setPermissions(userInfo.data.userInfo.permissions)
                setUserId(userInfo.data.userInfo.id);

            } catch (err) {
                console.log(err);
            }


        }
        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");
                setAdvisers(data);


            } catch (err) {
                console.log(err);
            }


        }

        async function getTemplates() {

            try {

                const { data } = await api.post("api/getTemplate");
                //console.log(userInfo.data);
                console.log(data);
                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err);
            }


        }

        getPermissions();
        getAdvisers();
        getTemplates();
        getGroups();

    }, [])

    const onGetUpdate = useCallback((email, status) => {

        let tempAdvisersList = [...advisersRef.current];

        for (let i = 0; i < tempAdvisersList.length; i++) {
            if (tempAdvisersList[i].email == email) {
                tempAdvisersList[i].status = status;
            }
        }

        setAdvisers(tempAdvisersList)
    }, [advisersRef])

    useEffect(() => {

        if (socket) {


            socket.on(`adviserChange`, (adviserObj) => {
                
                onGetUpdate(adviserObj.email, adviserObj.status);
            });


        }

    }, [socket, onGetUpdate])

    /**
     * Pasa los permisos a todas las vistas
     */
    const [permissions, setPermissions] = useState([]);

    const [groupName, setGroupName] = useState('');

    const [currentInputValue, setCurrentInputvalue] = useState(`1,5500000001,Nombre 1,Apellido 1,Pendiente,México,CDMX,Hombre,Profesional
2,5500000002,Nombre 2,Apellido 2,Pendiente,México,CDMX,Hombre,Profesional
3,5500000003,Nombre 3,Apellido 3,Pendiente,México,CDMX,Hombre,Profesional`);

    const [showInput, setShowInput] = useState(false);

    const [newEmptyBase, setNewEmptyBase] = useState(false);

    const [excelRows, setExcelRows] = useState([]);

    const [excelSheets, setExcelSheets] = useState([]);


    const convertToTable = (rows) => {

        let newRows = [];


        rows.map((row, key) => {
            console.log(row)
            row.id = key;
            row.Product = { name: row.Product };
            row.Tag = row?.Tag?.split(',')
            newRows.push(row);
        })

        return newRows;
    }
    const handleChangeFile = (event, isExcel) => {
        // If user clicks the parse button without
        // a file we show a error

        if (isExcel) {
            const reader = new FileReader();
            reader.onload = async (e) => {

                const data = e.target.result;

                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                setExcelSheets(workbook.SheetNames);

                const json = utils.sheet_to_json(worksheet);
                const newRows = convertToTable(json);
                const columns = Object.keys(json[0]);


                setColumns(columns);
                setRows(newRows);

                let sheetDataList = [];

                workbook?.SheetNames?.map((sheetNameTemp) => {
                    let tempRows = convertToTable(utils.sheet_to_json(workbook.Sheets[sheetNameTemp]));
                    sheetDataList.push(tempRows);
                })

                setExcelRows(sheetDataList);
            };
            reader.readAsArrayBuffer(event.target.files[0]);

        } else {

            setExcelRows([]);
            setExcelSheets([]);
            // Initialize a reader which allows user
            // to read any file or blob.
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });

                const parsedData = csv?.data;
                const columns = Object.keys(parsedData[0]);

                csv?.data.map((obj, index) => {
                    if (Object.keys(obj).length == 1) {


                        obj.firstName = "Por Definir"
                        obj.lastName = "Por Definir"
                        obj.status = "Por Definir"
                        obj.country = "Por Definir"
                        obj.city = "Por Definir"
                        obj.gender = "Por Definir"
                        obj.level = "Por Definir"
                    }
                    obj.id = index;
                })

                setColumns(columns);
                setRows(parsedData);

            };
            reader.readAsText(event.target.files[0]);
        }

    }

    const handleShowInput = () => {

        setShowInput(!showInput);

    }

    const validaInput = () => {
        try {

            let arr = currentInputValue.split('\n');

            if (arr.length < 2) {
                alert("Es necesario ingresar más de un registro");
                return;
            }

            if (arr[0].split(",").length == 1) {

                let tempRows = [];

                arr.map((value) => {

                    let tempArrObj = value.split(",");

                    let newObj = {
                        "id": tempArrObj[0],
                        "phone": tempArrObj[0],
                        "firstName": "Por Definir",
                        "lastName": "Por Definir",
                        "status": "Por Definir",
                        "country": "Por Definir",
                        "city": "Por Definir",
                        "gender": "Por Definir",
                        "level": "Por Definir",
                    }

                    tempRows.push(newObj);
                })

                setRows(tempRows);
                return;

            } else {
                if (arr[0].split(",").length < 9) {
                    alert("El formato de los datos no es correcto");
                    return;
                }
            }



            let tempRows = [];

            arr.map((value) => {

                let tempArrObj = value.split(",");
               
                let newObj = {
                    "id": tempArrObj[0],
                    "phone": tempArrObj[1],
                    "firstName": tempArrObj[2],
                    "lastName": tempArrObj[3],
                    "status": tempArrObj[4],
                    "country": tempArrObj[5],
                    "city": tempArrObj[6],
                    "gender": tempArrObj[7],
                    "level": tempArrObj[8]
                }

                tempRows.push(newObj);
            })

            setRows(tempRows);


        } catch (err) {
            console.log(err);
            alert("El formato de los datos no es correcto");
            return;
        }
    }


    const getExcelSheets = () =>{
        let sheets = [];

        excelSheets.map((sh,key)=>{
            sheets.push({
                name: sh,
                clients: [...excelRows[key]]
            })
        })  
        console.log(excelSheets);
        console.log(excelRows);
        console.log(sheets);
        return sheets;
    }
    const createGroup = async (name) => {

        if (!name) {
            if (!groupName) {
                enqueueSnackbar(`El nombre es requerido`, {
                    variant: 'error',
                });
                return false;
            }

        }


        let clientList = [...rows];

        clientList.map((cl) => {
            delete cl.id;
        })

       
        try {
            const { data } = await api.post('api/Creategroup', {
                book: {
                    name: name ? name : groupName,
                    owner: userId,
                },
                sheets: excelSheets.length > 0 ? getExcelSheets() : [],
                clients: clientList,
                advisers: selectedAdvisers
            });

            if (data.status == "Success") {
                enqueueSnackbar(`Se creó el libro exitosamente`, {
                    variant: 'success',
                });

                if(onFinish){
                    onFinish();
                }
                
            } else {
                if (data?.code) {
                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('Ocurrio un error al crear el libro', {
                        variant: 'error',
                    });
                }
            }

            setRows([]);
            setShowInput(false);
            setNewEmptyBase(false);
            setExcelRows([]);
            setExcelSheets([]);
            //  setDb(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al crear el libro`, {
                variant: 'error',
            });
        }


    }

    const callMassive = async (currentTemplate, name, phone) => {

        let clientList = [...rows];
        setGroupName(name);

        clientList.map((cl) => {
            delete cl.id;
        })

        console.log(clientList);
        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...clientList];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {



                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    delay: 1000,
                    from: phone
                }

                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} mensajes`, {
                    variant: 'success',
                });

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpenCallModal(false);

        createGroup(name);
    }

    /**
     * Recupera un arreglo con lso telefonos
     * @returns 
     */
    const getPhones = () => {
        let phones = [];

        rows.map((cl) => {
            phones.push(cl.phone);
        })

        return phones;
    }

    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, list = permissions, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }
    return (
        <>
           
            {
                db && (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"

                    >

                        <Grid
                            container
                            sx={{ height: '80vh', minWidth: '100%' }}

                            alignItems="center"
                            justifyContent="center"
                            justify="center">


                            {rows.length > 0 &&
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField
                                        id="name"
                                        name="name"

                                        label="Nombre del libro"
                                        onChange={(e) => { setGroupName(e.target.value) }}
                                        value={groupName}
                                        fullWidth
                                    >
                                    </TextField>


                                </Grid>}



                            {rows.length > 0 && revisaPermisos(['Asignar Clientes']) &&
                                <Grid item xs={2} sm={2} md={2} lg={2} >
                                    <Grid container
                                        alignItems="center"
                                        justifyContent="center">
                                        <Grid item xs={2} sm={2} md={2} lg={2} >
                                            <Checkbox
                                                checked={showAddAdvisers}
                                                onChange={(e) => { setShowAddAdvisers(e.target.checked) }}
                                                sx={{
                                                    color: 'white',
                                                    '&.Mui-checked': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={10} lg={10} >
                                            <p style={{ color: 'white' }}>Asginar asesor</p>
                                        </Grid>
                                    </Grid>



                                </Grid>}
                            <Grid
                                item
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                align="center"
                                alignItems="center"
                                justifyContent="center"
                                container
                                spacing={2}
                                sx={{ height: '90%' }}
                            >
                                {newEmptyBase &&
                                    <Grid item xs={12} sm={12} md={8} lg={8}>

                                        <Card sx={{ maxWidth: '100%', minWidth: '100%', minHeight: 300, position: 'relative' }}>
                                            <CardContent>
                                                <TextField
                                                    id="name"
                                                    name="name"

                                                    label="Nombre del libro"
                                                    onChange={(e) => { setGroupName(e.target.value) }}
                                                    value={groupName}
                                                    fullWidth
                                                >
                                                </TextField>
                                                <Storage
                                                    color={"primary"}
                                                    sx={{ fontSize: 200 }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    bottom: '18px',
                                                    width: '100%',
                                                }}>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"

                                                        onClick={() => {
                                                            createGroup();
                                                        }}
                                                    >
                                                        Crear libro
                                                    </Button>
                                                </div>
                                            </CardContent>
                                        </Card>



                                    </Grid>}

                                {rows.length > 0 &&
                                    <>
                                        <Grid item
                                            xs={showAddAdvisers ? 6 : 12}
                                            sm={showAddAdvisers ? 6 : 12}
                                            md={showAddAdvisers ? 6 : 12}
                                            lg={showAddAdvisers ? 6 : 12}
                                            align="center"
                                            alignItems="center"
                                            justifyContent="center"

                                            sx={{ height: '100%' }}>
                                            <TablaUsuarios

                                                rows={rows}
                                                columns={[

                                                    {
                                                        headerName: 'Estatus', field: 'status', hide: false,

                                                        renderCell: (params) => {
                                                            return <Chip label={params.row?.Status?.name ? params.row.Status.name : 'Sin estatus'} sx={{ color: 'white', background: params.row?.Status?.color ? params.row.Status.color : 'black' }} />
                                                        }

                                                    },
                                                    {
                                                        headerName: 'Nombre', field: 'firstName', hide: false, editable: true,


                                                    },
                                                    {
                                                        headerName: 'Apellido', field: 'lastName', hide: false, editable: true,

                                                    },


                                                    {
                                                        headerName: 'Teléfono', field: 'phone', hide: false, editable: true,


                                                    },

                                                    {
                                                        headerName: 'Correo', field: 'email', hide: false, editable: true,


                                                    },
                                                    {
                                                        headerName: 'Producto', field: 'product', hide: false, editable: true,

                                                        renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                                                            alignItems="center"
                                                            justifyContent="center">

                                                            <Chip label={params.row.Product?.name} />

                                                        </Grid>
                                                    },
                                                    {
                                                        headerName: 'Etiquetas', field: 'tags', hide: false,
                                                        renderCell: (params) => {

                                                            return (
                                                                <Grid md={12} sx={{ height: '100%' }} direction="column"
                                                                    alignItems="center"
                                                                    justifyContent="center">
                                                                    {params.row.Tags?.map((value) => (
                                                                        <Chip key={value.id} label={value.name} />
                                                                    ))}
                                                                </Grid>
                                                            )
                                                        }

                                                    },



                                                ]}
                                                checkboxSelection
                                                onDelete={(rowsSelected) => {

                                                    let rs = [...rows]
                                                    rowsSelected.map((r) => {
                                                        rows.map((r2, index) => {
                                                            if (r.id == r2.id) {
                                                                rs.splice(index, 1);
                                                            }
                                                        })

                                                    })
                                                    console.log(rs);
                                                    setRows(rs);
                                                }}
                                                showDelete

                                            />
                                        </Grid>
                                        {showAddAdvisers &&
                                            <Grid item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                align="center"
                                                alignItems="center"
                                                justifyContent="center"

                                                sx={{ height: '100%' }}>
                                                <TablaUsuarios

                                                    rows={advisers}
                                                    columns={[

                                                        {
                                                            headerName: 'Correo', field: 'email', hide: false,

                                                        },
                                                        {
                                                            headerName: 'Nombre', field: 'forstName', hide: false,

                                                        },
                                                        {
                                                            headerName: 'Estatus', field: 'status', hide: false,

                                                        },


                                                    ]}
                                                    checkboxSelection
                                                    onDelete={(rowsSelected) => {


                                                    }}
                                                    onsSelectionChange={(newUsers) => {

                                                        setSelectedAdvisers(newUsers);


                                                    }}


                                                />
                                            </Grid>}
                                    </>}
                                {
                                    rows.length > 0 ?
                                        <>
                                            <Grid item md={3}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    fullWidth
                                                    onClick={() => {
                                                        createGroup();
                                                    }}
                                                >
                                                    Crear libro
                                                </Button>
                                            </Grid>
                                            {
                                                !newEmptyBase && revisaPermisos(['Enviar llamada masiva']) &&
                                                <Grid item md={3}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                        onClick={() => {
                                                            setOpenCallModal(true);
                                                        }}
                                                    >
                                                        Llamada masiva
                                                    </Button>
                                                </Grid>
                                            }
                                            {
                                                !newEmptyBase && revisaPermisos(['Enviar mensaje masivo']) &&
                                                <Grid item md={3}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                        onClick={() => {
                                                            setOpenMsgModal(true);
                                                        }}
                                                    >
                                                        Mensaje masivo
                                                    </Button>
                                                </Grid>
                                            }



                                        </>

                                        :
                                        <>{!showInput && !newEmptyBase &&
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    icon={() => {
                                                        return (

                                                            <Add
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"

                                                                onClick={(event) => setNewEmptyBase(true)}
                                                            >
                                                                Nuevo libro

                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Nuevo"}
                                                    title={"Nuevo"}
                                                    description={"Crea un nuevo libro vacio para poder agregar hojas"}
                                                />
                                            </Grid>}
                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    aditionalInfo={() => <Button
                                                        variant="outlined"
                                                        component="label"
                                                    >
                                                        <a target="_blank" href={`${process.env.REACT_APP_BACK_URL}/media/pruebacsv.csv`}>Descargar Ejemplo</a>
                                                    </Button>}
                                                    icon={() => {
                                                        return (

                                                            <FileUpload
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                            >
                                                                Subir formato de libro
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    onChange={(event) => handleChangeFile(event)}
                                                                />
                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Subir"}
                                                    title={"Subir CSV"}
                                                    description={"Sube un archivo .csv para ingresar los datos en una nueva base"}

                                                />



                                            </Grid>
                                            }
                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <DatabaseCard
                                                    aditionalInfo={() => <Button
                                                        variant="outlined"
                                                        component="label"
                                                    >
                                                        <a target="_blank" href={`${process.env.REACT_APP_BACK_URL}/media/FormatoEjemplo.xlsx`}>Descargar Ejemplo</a>
                                                    </Button>}
                                                    icon={() => {
                                                        return (

                                                            <FileUpload
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    button={() => {
                                                        return (
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                            >
                                                                Subir formato de libro excel
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    onChange={(event) => handleChangeFile(event, true)}
                                                                />
                                                            </Button>
                                                        )
                                                    }}
                                                    actionName={"Subir"}
                                                    title={"Subir Excel"}
                                                    description={"Sube un archivo de excel para ingresar los datos en una nueva base"}

                                                />



                                            </Grid>
                                            }
                                            {!showInput && !newEmptyBase && <Grid item xs={12} sm={6} md={4} lg={4}>



                                                {userId && <DatabaseCard
                                                    button={() => {
                                                        return (
                                                            <>
                                                                {/* <GoogleLoginView userId={userId} />*/}
                                                                <Button color="success"
                                                                disabled
                                                                    variant="contained" >Sincronizar cuenta de google 🚀{' '}</Button>
                                                            </>


                                                        )
                                                    }}
                                                    icon={() => {
                                                        return (

                                                            <AddToDrive
                                                                color={"primary"}
                                                                sx={{ fontSize: 78 }}
                                                            />
                                                        )
                                                    }}
                                                    actionName={"Goole"}
                                                    title={"Sincronizar"}
                                                    description={"Trae archivos desde una hoja de google de tu cuenta - PROXIMAMENTE"}
                                                />}
                                            </Grid>
                                            }
                                            {showInput &&
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <TextField
                                                        value={currentInputValue}
                                                        fullWidth

                                                        rows={10}
                                                        multiline
                                                        onChange={(event) => {
                                                            setCurrentInputvalue(event.target.value);
                                                        }}
                                                    >

                                                    </TextField>

                                                </Grid>
                                            }



                                            {
                                                showInput && <Grid item xs={12} sm={12} md={12} lg={12}>

                                                    <Grid container spacing={2} xs={12} sm={8} md={4} lg={4}>

                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Button
                                                                color="success"
                                                                variant="contained"
                                                                component="label"
                                                                onClick={() => validaInput()}
                                                                fullWidth
                                                            >
                                                                Subir
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                fullWidth
                                                                onClick={(event) => handleShowInput(event)}
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }

                                        </>

                                }



                            </Grid>


                        </Grid>


                    </Grid>
                )
            }

            {!db &&
                <>
                    <br />
                    {currentGroupId &&
                        <div style={{ position: 'relative', width: '100%' }}>
                            <IconButton
                                color="primary"
                                style={{
                                    position: 'absolute',
                                    right: '2%'
                                }} onClick={() => { setCurrentGroupId(null) }}>
                                <HighlightOff
                                    style={{ fill: "white", transform: 'scale(1.8)' }}
                                />
                            </IconButton>
                        </div>
                    }
                    <Grid
                        align="center"
                        justifyContent="center"
                        container
                        sx={{ height: '80%', width: '100wv', margin: 0 }}

                    >
                        {!currentGroupId &&
                            <Grid
                                item
                                sm={currentGroupId ? 3 : 10}
                                align="center"


                                container
                                sx={{ height: '100%', margin: 0 }}
                                spacing={4}
                            >
                                {
                                    groups.map((g) => (
                                        <Grid item sx={currentGroupId ? 12 : 6} md={currentGroupId ? 12 : 3} lg={currentGroupId ? 12 : 3}>
                                            <DatabaseCard name={g.name} count={g.membersCount} onOpenDatabase={() => { setCurrentGroupId(g.id); }} />
                                        </Grid>

                                    ))
                                }
                            </Grid>
                        }
                        {currentGroupId &&
                            <Grid
                                item
                                sm={10}

                            >
                                <DatabaseTable
                                    theme={theme}
                                    templateList={templates}
                                    advisers={advisers}
                                    showAdviserButton
                                    groupID={currentGroupId}
                                />
                            </Grid>}
                    </Grid>

                </>}


            <MassiveMsgModal
                theme={theme}
                forwardingId={0}
                open={openMsgModal}
                setOpen={setOpenMsgModal}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={createGroup}
            />
            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templates}
                onSave={(currentTemplate, name, phone) => { callMassive(currentTemplate, name, phone) }}
            />

        </>
    )
}

export default Database;