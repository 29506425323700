import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import {
    Badge,
    Box,
    Grid,
    Card,
    TextField as MuiTextField,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Fab,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Link,
    IconButton,
    Chip,
    Button,
    MenuItem,
    Checkbox,
    Menu,
    MenuList,
    Autocomplete,
    ListSubheader,
    ListItemButton,
    Collapse,
    CircularProgress,
    CardHeader,
    CardContent,
    CardActions,
} from "@mui/material";
import { spacing, width } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { AccountCircle, AccountTree, ArrowDropDown, Assignment, AttachFile, CalendarToday, Check, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, Close, Cloud, CoPresentOutlined, ConstructionOutlined, ContentCopy, ContentCut, ContentPaste, Delete, Description, Edit, ExpandLess, ExpandMore, FireHydrantAltRounded, ImageNotSupported, InsertDriveFile, Label, LabelOff, LinearScale, MarkChatRead, MarkChatUnread, Markunread, Message, Person, PersonAdd, PushPin, Save, Schedule, Share, ShoppingCart, StarBorder, ThreeP, VolumeOff, VolumeUp, WhatsApp } from "@mui/icons-material";
import api from "../../services/api";

import { SocketContext } from '../../services/socket';
import './Chat2.css';
import { LoadingButton } from "@mui/lab";
import FilterListIcon from '@mui/icons-material/FilterList';
import AddTagmodal from "../../components/AddTagModal";
import AddProductModal from "../../components/AddProductModal";
import MassiveMsgModal from "../../components/MassiveMsgModal";
import { getContrastColor } from "../../utils/generalFunctions";
import notification from '../../assets/notification.mp3';
import ChatInputMessage from "../../components/ChatInputMessage";
import ShareModal from "../../components/ShareModal";
import { useSnackbar } from "notistack";
import AddStatusModal from "../../components/AddStatusModal";
import ClientInfo from "../../components/ClientInfo";


import Cookies from 'universal-cookie';
import AddparticipantToGroupModal from "../../components/AddParticipantToGroupModal";


import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";
import MultiSelect from "../../components/MultiSelect";
import NotificationComponent from "../../components/NotificationComponent";
import WhatsappSessionAlert from "../../components/WhatsappSessionAlert";
import ChatMessagesHeader from "../../components/ChatMessagesHeader";
import EmailViewModal from "../../components/EmailViewModal";
import GetChatMessagesError from "../../components/GetChatMessagesError/Index";


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);


const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 90%;
  width: 100%;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
 
`;

const ChatMessageInner = styled.div`
  display: inline-block;
  max-width: 100%;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
        props.highlighted
            ? props.theme.palette.secondary.main
            : props.theme.palette.action.hover};
  color: ${(props) =>
        props.highlighted
            ? props.theme.palette.common.white
            : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
        props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const getMessageBytLines = (message) => {

    const resp = message.split("\n").map((i, key) => {
        if (i != '') {
            return <div key={key}>{i}</div >;
        }

    })

    return resp;
}


const downloadFile = async (chatID, messageID, wpMessageID) => {
    try {
        const { data } = await api.post('/api/getMessage/media', {
            chatID: chatID,
            id: messageID,
            messageID: wpMessageID
        })

        return data;
    } catch (err) {
        console.log("Ocurrió un error al cargar el mensaje")
    }
}



function ChatMessageComponent({
    name,
    message,
    time,
    time2,
    avatar,
    author,
    position = "left",
    messageObj,
    showAuthor,
    isGroup,
    updateMessage,
    indexMessage

}) {

    const [localMessageObj, setLocalMessageObj] = useState(messageObj);
    const [localMessage, setLocalMessage] = useState(message);
    const [isLoading, setIsLoading] = useState(false);

    async function updateChatFile() {
        setIsLoading(true)
        const { path, body } = await downloadFile(messageObj.chatId, messageObj.id, messageObj.wpId);

        const newMessage = {
            ...localMessageObj,
            mediaUrl: path
        }
        setLocalMessageObj(newMessage)


        updateMessage(indexMessage, newMessage);


        setLocalMessage(body);
        setIsLoading(false)
    }

    return (
        <ChatMessage position={position}>
            <ChatMessageInner style={{ maxWidth: '50%' }}>

                <ChatMessageBubble style={{ maxWidth: '100%' }} highlighted={position === "right"}>
                    <Box>
                        <ChatMessageBubbleName variant="body1">
                            {localMessageObj.sendBy ? localMessageObj.sendBy : isGroup ? author : ''}
                        </ChatMessageBubbleName>
                    </Box>
                    {
                        localMessageObj.hasMedia == 1 ?

                            localMessageObj?.mediaUrl?.includes('.oga') || localMessageObj?.mediaUrl?.includes('.mpga') ?


                                <audio controls>
                                    <source src={`${process.env.REACT_APP_BACK_URL}/media/${localMessageObj.mediaUrl}`} type={localMessageObj?.mediaUrl?.includes('.mpga') ? "audio/mpeg" : "audio/ogg"} />

                                    Su navegador no soporta el audio
                                </audio>
                                :
                                localMessageObj?.mediaUrl?.includes('.jpeg') || localMessageObj?.mediaUrl?.includes('.webp') ?
                                    <>
                                        <img onClick={() => { window.open(`${process.env.REACT_APP_BACK_URL}/media/${localMessageObj.mediaUrl}`, '_blank').focus(); }} style={{ maxWidth: '100%', maxHeight: '200px' }} src={`${process.env.REACT_APP_BACK_URL}/media/${localMessageObj.mediaUrl}`} alt="img" />
                                        <Typography className="chat-long-text" variant="body2">{getMessageBytLines(message)}</Typography>
                                    </>
                                    :
                                    !localMessageObj.mediaUrl ?
                                        <div >
                                            <IconButton onClick={updateChatFile} className="file_container">
                                                {
                                                    isLoading ?
                                                        <CircularProgress color="success" />
                                                        :
                                                        <ImageNotSupported className="file_not_found" />
                                                }

                                            </IconButton>
                                            <Typography className="chat-long-text" variant="body2">Descargar archivo</Typography>
                                        </div>
                                        :
                                        <>
                                            <IconButton className="file_container" onClick={() => { window.open(`${process.env.REACT_APP_BACK_URL}/media/${localMessageObj.mediaUrl}`, '_blank').focus(); }}>

                                                <InsertDriveFile className="file_not_found" />

                                            </IconButton>
                                            <Typography className="chat-long-text" variant="body2">{getMessageBytLines(localMessage)}</Typography>
                                        </>
                            :

                            <Typography className="chat-long-text" variant="body2">{getMessageBytLines(localMessage)}</Typography>
                    }

                </ChatMessageBubble>
                <ChatMessageTime style={{ display: 'none' }} variant="body2">{new Date(time).toString()}</ChatMessageTime>
                <ChatMessageTime variant="body2">{getDate(time2)}</ChatMessageTime>
            </ChatMessageInner>
        </ChatMessage>
    );
}


function EmailMessageComponent({
    name,
    subject,
    message,
    time,
    time2,
    avatar,
    author,
    position = "left",
    messageObj,
    indexMessage

}) {



    return (
        <ChatMessage position={position}>
            <ChatMessageInner style={{ maxWidth: '50%' }}>

                <ChatMessageBubble style={{ maxWidth: '100%' }} highlighted={position === "right"}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'background.default' }}>

                        <Card sx={{ maxWidth: 600, width: '100%' }}>
                            <CardHeader
                                title={subject}
                                subheader={` ${time}`}
                            />
                            <CardContent>
                                <Typography variant="body1">
                                    {message?.substring(0, 100)}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <Box sx={{ flexGrow: 1 }} />
                                <EmailViewModal title={subject} emailBody={message} />

                            </CardActions>
                        </Card>
                    </Box>


                </ChatMessageBubble>

            </ChatMessageInner>
        </ChatMessage>
    );
}
function getDate(time2) {
    var utc = new Date(time2);
    var offset = utc.getTimezoneOffset();

    var local = '';

    if (time2.indexOf('T') > -1) {
        var local = utc;
    } else {
        var local = new Date(utc.getTime() + offset * 60000);
    }

    const zonaHorariaNavegador = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const opciones = { timeZone: zonaHorariaNavegador, hour: '2-digit', minute: '2-digit', hour12: false };




    const horaLocal = utc.toLocaleTimeString([], { ...opciones });

    var year = local.getFullYear();
    var month = ("0" + (local.getMonth() + 1)).slice(-2);
    var day = ("0" + local.getDate()).slice(-2);

    var fechaFormateada = year + "-" + month + "-" + day + " " + horaLocal;
    return fechaFormateada;


}



function ChatV2() {
    let navigate = useNavigate();
    const cookies = new Cookies();
    const currentMessage = useRef();

    /**
     * Objetos para el manejo de los chats
     */
    const [chats, _setChats] = useState([]);

    const chatsRef = React.useRef(chats);

    const setChats = data => {
        chatsRef.current = data;
        _setChats(data);
    }

    /**
     * Objetos para le manejos del chat actual
     */
    const [currentChat, _setCurrentChat] = useState(null);

    const currentChatRef = React.useRef(currentChat);

    const setCurrentChat = data => {
        currentChatRef.current = data;
        _setCurrentChat(data);
    }
    /**
     * Objetos para le manejo de los chats que llegan desde el socket
     */
    const [currentMessages, _setCurrentMessages] = useState([]);

    const currentMessagesRef = React.useRef(currentMessages);

    const setCurrentMessages = data => {
        currentMessagesRef.current = data;
        _setCurrentMessages(data);
    }

    /**
     * Socket para lo refrebte al chat
     */
    const socket = React.useContext(SocketContext);




    const [status, setStatus] = useState([]);
    const [status2, setStatus2] = useState([]);

    const [products, setProducts] = useState([]);

    const [currentChatIndex, setCurrentChatIndex] = useState(null);


    const [currentWindow, _setCurrentWindow] = useState(1);

    const currentWindowRef = React.useRef(currentWindow);

    const setCurrentWindow = data => {
        currentWindowRef.current = data;
        _setCurrentWindow(data);
    }

    const [isLoading, setIsLoading] = useState(false);

    const [isLoadingChats, setIsloadingChats] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [blockButtons, setBlockButtons] = useState(false);



    const [showCheckBox, setShowCheckBox] = useState(false);

    const [selectAll, setSelectAll] = useState(false);


    const [selectedChats, setSelectedChats] = useState([]);
    const [selectedInfoChats, setSelectedInfoChats] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);


    const [reload, _setReload] = useState(false);

    const reloadRef = React.useRef(reload);

    const setReload = data => {
        reloadRef.current = data;
        _setReload(data);
    }
    const open = Boolean(anchorEl);


    const [filters, setFilters] = useState({
        unread: false,
        replyed: false,
        tags: [],
        whatsapp: [],
        status: [],
        phone: '',
        quotes: false
    })


    const [tags, setTags] = useState([]);


    const [anchorChatMenu, setAnchorChatMenu] = useState(null);

    const [anchorActionMenu, setAnchorActionMenu] = useState(null);

    const [idShowChatMenu, setIdShowChatMenu] = useState(null);
    const [idKeepChatMenu, setIdKeepChatMenu] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);


    const [openMove, setOpenMove] = useState(false);
    const [openTags, setOpenTags] = useState(false);
    const [openProducts, setOpenProducts] = useState(false);

    const [openStatus, setOpenStatus] = useState(false);

    const [templates, setTemplates] = useState([]);
    const [sessions, setSessions] = useState([]);

    const [filterNameValue, setFilterNameValue] = useState('');


    const [openMassiveMessage, setOpenMassiveMessage] = useState(false);

    const [scheduleMsg, setScheduleMsg] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const [chatUrlParamID, setChatUrlParamID] = useState(null);
    const [isNewClient, setIsNewClient] = useState(null);

    const [openShareModal, setOpenSharemodal] = useState(false);

    const [advisers, setAdvisers] = useState([]);

    const [chatPage, setChatPage] = useState(1);
    const [nextChatPage, setNextChatPage] = useState(2);

    const [reloadNewChats, setReloadNewChats] = useState(false);

    const [attachedFiles, setAttachedFiles] = useState([]);
    /**
   * Notifiaciones
   */
    const { enqueueSnackbar } = useSnackbar();


    const [reloadNewMessage, _setReloadNewMessage] = useState(false);

    const reloadNewMessageRef = React.useRef(reloadNewMessage);

    const setReloadNewMessage = data => {
        reloadNewMessageRef.current = data;
        _setReloadNewMessage(data);
    }

    const [chatSound, _setChatSound] = useState(true);

    const chatSoundRef = React.useRef(chatSound);

    const setChatSound = data => {
        chatSoundRef.current = data;
        _setChatSound(data);
    }

    const [reloadSound, setReloadSound] = useState(true);


    const [openAddParticipants, setOpenAddParticipants] = useState(false);

    const [currGroupID, setCurrGroupID] = useState(null);

    const [isSequence, setIsSequence] = useState(false);

    const [showNewMessageModal, setShowNewMessageModal] = useState(false);

    /**Nuevo mensaje */
    const [newPhoneNumber, setNewPhoneNumber] = useState(null);
    const [newWpSession, setNewWpSession] = useState(null);

    /**Badgets conteo */
    const [groupCount, setCurrGroupCount] = useState(0);
    const [unreadCount, setUnreadCount] = useState(0);
    const [readCount, setReadCount] = useState(0);


    /**
    * Variable que contiene la lista de permisos del usuario
    */
    const [permissionsUser, setPermissionsUser] = useState([]);

    /**
     * Etiquetas seleccionadas
     */
    const [selectedTags, setSelectedTags] = useState([]);

    const [emailMessages, setEmailMessages] = useState([]);


    const [chatError, setChatError] = useState(false)
    /**
 * Compara la lista de permisos con los permisos asignados
 */
    const revisaPermisos = (allow, list = permissionsUser, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }


    useEffect(() => {

        async function getChatConfig() {
            try {
                const { data } = await api.get("/api/config/chatSound");


                if (data.status == "Success") {

                    setChatSound(data.chatSoundEnabled ? true : false);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar la configuración de audio del chat", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error al consultar la configuración de audio del chat", {
                    variant: 'error',
                });
            }
        }

        getChatConfig();
    }, [reloadSound])

    useEffect(() => {



        async function getstatus() {
            try {
                const { data } = await api.post("/api/getStatus");


                if (data.status == "Success") {

                    setStatus2(data.statusList);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los estatus", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });
            }
        }

        async function getProducts() {
            try {

                const { data } = await api.post("/api/getProducts");



                if (data.status == "Success") {

                    setProducts(data.products);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los productos", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });

            }
        }

        async function getTags() {
            try {

                const { data } = await api.post("/api/v2/getUserTags");



                if (data.status == "Success") {

                    setTags(data.tags);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar las etiquetas", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error al consultar las etiquetas", {
                    variant: 'error',
                });
            }
        }

        async function getTemplates() {

            try {


                const { data } = await api.post("/api/getTemplate");

                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err)
            }
        }


        async function getWhatsapps() {

            try {


                const { data } = await api.post("api/v2/getUserSessions");
                // console.log(data)setTags(data.tags);

                let tempTags = [];
                data.sessions.map((session) => {
                    tempTags = [...tempTags, ...session.Tags];
                })
                setTags(tempTags);

                let tempStatus = [];
                /*data.sessions.map((session) => {
                    tempStatus = [...tempStatus, ...session?.Statuses];
                })*/
                setStatus(tempStatus);

                setSessions(data.sessions);


            } catch (err) {
                console.log(err)
            }
        }

        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");
                setAdvisers(data);

                const userInfo = await api.post("/api/getPermissions");


                setPermissionsUser(userInfo.data.userInfo.permissions)
            } catch (err) {
                console.log(err);
            }


        }



        getstatus();
        getWhatsapps();
        getProducts();
        // getTags();
        getTemplates();
        getAdvisers();

        let chatUrlIdParam = searchParams.get("chatID");
        setChatUrlParamID(chatUrlIdParam);
        let clientUrlParam = searchParams.get("isClient");
        setIsNewClient(clientUrlParam);

    }, []);


    useEffect(() => {

        if (chatUrlParamID && chatUrlParamID != '' && !isLoadingChats && (isNewClient == 1 || isNewClient == null)) {
            setIsNewClient(2)
            searchChatAngGetMessages();
        }

    }, [chatUrlParamID, isNewClient, chats, isLoadingChats])


    /**
    * Funcion para actualizar las sesiones con el socket
    */
    const onMessage = React.useCallback((chatID, message, unreadCount, reloadMess, hasMedia, tags) => {

        if (reloadMess && currentWindowRef.current == 3) {

            setReloadNewMessage(true);
            return;
        }
        let chatsTemp = chatsRef.current;

        if (chatsTemp.length == 0) {
            if (Notification.permission === "granted") {


                let notification = new Notification("Hay un nuevo chat de whatsapp sin atender", {
                    body: `El chat dice ${message}`,
                });

                notification.onclick = () => {
                    navigate('/multiagente/fb-chat');
                };
            }

            return;
        }
        const updatedChatindex = chatsTemp.findIndex(x => x?.id === chatID);


        let updatedChat = chatsTemp[updatedChatindex];

        if (!updatedChat && message.to.indexOf('@g.us') == -1) {

            if (!message.fromMe && currentWindowRef.current == 0) {

                setReloadNewMessage(true);
            }

            if (message.fromMe && currentWindowRef.current == 1) {

                setReloadNewMessage(true);
            }
            return;
        }

        if (!updatedChat) {
            console.log("No se encontró el chat :(")
            return;
        }

        if (updatedChat?.lastMessage) {
            updatedChat.lastMessage = message.body;
        }


        if (hasMedia && !message.body) {
            if (updatedChat?.lastMessage) {
                updatedChat.lastMessage = message.fromMe ? 'Archivo enviado' : 'Archivo recibido';
            }

        }

        updatedChat.unreadCount = unreadCount;

        if (updatedChatindex > -1) {

            chatsTemp.splice(updatedChatindex, 1);
        }

        if (updatedChat.Client) {
            updatedChat.Client.Tags = tags;
        }


        if (currentWindowRef.current == 0 && !message.fromMe) {
            setChats([updatedChat, ...chatsTemp]);
        }

        if (currentWindowRef.current == 1 && message.fromMe) {
            setChats([updatedChat, ...chatsTemp]);
        }

        if (chatID == currentChatRef?.current?.id) {
            if (!message.fromMe) {
                setCurrentWindow(0);
            } else {
                setCurrentWindow(1);
            }

            if (currentMessagesRef.current?.id === message?.chatId?.id) {
                let newMessages = [...currentMessagesRef.current, message];
                setCurrentMessages(newMessages);
            }


        }

        if (!message.fromMe) {
            if (Notification.permission === "granted") {
                let notification = new Notification("Nuevo mensaje de whatsapp", {
                    body: `${updatedChat?.Client?.firstName ? updatedChat?.Client?.firstName : " Chat"} dice: ${updatedChat.lastMessage}`,
                });

                notification.onclick = () => {
                    navigate('/multiagente/fb-chat');
                };
            }

        }
        if (!message.fromMe && currentWindowRef.current != 3 && chatSoundRef.current) {
            const audio = new Audio(notification);
            audio.play();
        }

    }, [currentMessagesRef, currentChatRef, chatsRef])

    useEffect(() => {

        if (socket) {

            socket.on('NEW_MESSAGE', (data) => {
                onMessage(data.id, data.msg, data.unreadCount, data.reload, data.hasMedia, data.tags);
            })
        }

    }, [socket, onMessage]);


    /**
     * Cada que cambia el tipo de chats que consulta se refrescan
     */
    useEffect(() => {


        // setCurrentMessages([]);
        //setCurrentChat(null);
        setIsLoading(true);
        setBlockButtons(true);
        setShowCheckBox(false);
        setOpenSharemodal(false);
        setSelectedChats([]);
        setSelectedInfoChats([])
        setChatPage(1);
        setNextChatPage(2);
        setReloadNewChats(true);
        async function getChats(clientType) {
            try {
                const { data } = await api.post('/api/v2/getChatsV2', { onlyGroups: clientType == 2, onlyUnread: clientType == 0, onlyRead: clientType == 1, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone, quote: filters.quotes, events: filters.events });

                if (data.status == "Success") {
                    setCurrGroupCount(data.groupsCount);
                    setUnreadCount(data.unreadCount);
                    setReadCount(data.readCount);
                    setChats(data.chats);

                } else if (data?.code) {

                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });

                } else {

                    enqueueSnackbar("Ocurrio un error al consultar los chats", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error al consultar los chats", {
                    variant: 'error',
                });
            }

            setIsLoading(false);
            setBlockButtons(false);

        }
        getChats(currentWindow);

    }, [currentWindow, reload, filters])

    /**
    * Carga los nuevos mensajes sin recargar todo
    */
    useEffect(() => {

        async function getChats(clientType) {
            try {
                const { data } = await api.post('/api/v2/getChatsV2', { onlyGroups: clientType == 2, onlyUnread: clientType == 0, onlyRead: clientType == 1, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone, quote: filters.quotes, events: filters.events });

                if (data.status == "Success") {
                    setCurrGroupCount(data.groupsCount);
                    setUnreadCount(data.unreadCount);
                    setReadCount(data.readCount);
                    const newChat = data.chats[0];
                    const findChatIndex = findChatIndexByID(newChat?.id);

                    if (findChatIndex > -1) {
                        let tempChats = [...chats];
                        tempChats[findChatIndex] = newChat;
                        setChats([...tempChats]);
                    } else {
                        setChats([newChat, ...chats]);
                    }


                } else if (data?.code) {

                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });

                } else {

                    enqueueSnackbar("Ocurrio un error al consultar los chats nuevos", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error al consultar los chats nuevos", {
                    variant: 'error',
                });
            }

        }

        if (reloadNewMessage) {

            setReloadNewMessage(false);
            getChats(currentWindow);
        }


    }, [currentWindow, reloadNewMessage, filters])

    /**
     * Cada que cambia el tipo de chats que consulta se refrescan
     */
    useEffect(() => {

        async function getChats(clientType) {
            try {
                const { data } = await api.post('/api/v2/getChatsV2', { onlyGroups: clientType == 2, page: chatPage, onlyUnread: clientType == 0, onlyRead: clientType == 1, tags: filters.tags, sessions: filters.whatsapp, status: filters.status, phone: filters.phone, quote: filters.quotes, events: filters.events });

                if (data.status == "Success") {
                    setCurrGroupCount(data.groupsCount);
                    setUnreadCount(data.unreadCount);
                    setReadCount(data.readCount);
                    if (data.chats.length == 0) {
                        setReloadNewChats(false);
                    } else {
                        let newChats = [...chats, ...data.chats]
                        setChats(newChats);
                    }
                    setNextChatPage(chatPage + 1);

                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error al consultar los chats", {
                    variant: 'error',
                });
            }
        }

        if (chatPage > 1) {
            if (nextChatPage == chatPage) {

                getChats(currentWindow);
            }

        }


    }, [currentWindow, filters, chats, chatPage, nextChatPage])

    const findChatIndexByID = (chatID) => {

        const index = chats.findIndex(chat => chat.id == chatID);
        return index;
    }

    const searchChatAngGetMessages = async () => {

        try {

            if (!isNewClient) {
                const { data } = await api.post('/api/v2/chatByID', { chatID: chatUrlParamID })
                const chat = data.chat;
                getMessagesByID(chatUrlParamID);
                setCurrentChat(chat);
            } else {
                const { data } = await api.post('/api/v2/getFakeChat', { clientID: chatUrlParamID })
                const chat = data.chat;
                setShowNewMessageModal(true);
                setCurrentChat(chat);
                //setChats([chat,...chats])
            }

        } catch (err) {
            enqueueSnackbar("Ocurrio un error al consultar el chat", {
                variant: 'error',
            });
        }
    }
    const onClick = async (chat, index) => {
        setChatUrlParamID(null);
        setIsNewClient(null);
        setCurrentChat(chat);
        setCurrentChatIndex(index);
        setShowNewMessageModal(false)
        getMessages('', chat.id, chat.phone);
    }


    const getMessages = async (session, chatID, phone) => {
        setIsloadingChats(true);
        setChatUrlParamID(null);
        //setIsNewClient(null);
        // console.log("Cargando mensajes"+chatID)
        try {
            setChatError(false)
            const { data } = await api.post('/api/getMessages', { session: '', chatID: chatID, phone: phone });

            if (data.status == "Success") {

                setCurrentMessages(data.messages);
                // console.log(data.messages)

            } else {
                setChatError(true)
                enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
                variant: 'error',
            });
        }
        setIsloadingChats(false);
    }

    const getMessagesByID = async (chatID) => {
        setIsloadingChats(true);
        setChatUrlParamID(null);

        try {
            const { data } = await api.post('/api/getMessages', { session: '', chatID });
            setChatError(false)
            if (data.status == "Success") {

                setCurrentMessages(data.messages);
                // console.log(data.messages)

            } else {
                setChatError(true)
                enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al consultar los mensajes", {
                variant: 'error',
            });
        }
        setIsloadingChats(false);
    }

    const sendMessage = async (msg, filePath, attachedFiles) => {
        try {
            let objToSend = {
                message: currentMessage.current.firstChild.control.value,
                idChat: currentChat.whatsappId,
                wpsession: currentChat?.WpSession?.sessionAuth,
                audioMedia: filePath,
                attached: attachedFiles,
                token: cookies.get(process.env.REACT_APP_COOKIE),
                tags: currentChat?.tags ? currentChat.tags : [],
                clientID: !isNewClient ? currentChat?.Client?.id : null,
            }


            if (showNewMessageModal) {

                if (!objToSend.idChat) {
                    enqueueSnackbar("Es necesario agregar un teléfono", {
                        variant: 'warning',
                    });
                    return;
                }
                if (!objToSend.wpsession) {
                    enqueueSnackbar("Es necesario seleccionar un whatsapp", {
                        variant: 'warning',
                    });
                    return;
                }
            }

            if (!objToSend.message) {
                enqueueSnackbar("Es necesario escribir un mensaje", {
                    variant: 'warning',
                });
                return;
            }

            if (isNewClient && showNewMessageModal) {
                try {
                    await api.post('/api/v2/chat', { chat: { ...currentChat, wpSessionId: currentChat?.WpSession?.id, id: 0 } })
                } catch (err) {
                    enqueueSnackbar("Ocurrio un error al asociar el cliente. Se creo un chat nuevo", {
                        variant: 'warning',
                    });
                }

            }

            socket.emit('sendMessage', objToSend);
            setSelectedTags([]);

            if (showNewMessageModal) {
                setOpenMassiveMessage(false);
                enqueueSnackbar("Se envio el mensaje de forma correcta", {
                    variant: 'success',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al enviar el mensaje", {
                variant: 'error',
            });
        }
    }



    const saveChatClientInfo = async (updatedChat) => {
        try {

            const { data } = await api.post('/api/updateChatClient', { chat: updatedChat });

            if (data.status == "Success") {
                let tempChats = [...chats];
                let tempChat = { ...updatedChat }

                if (!tempChat.Client.Status?.name) {
                    tempChat.Client.Status = {
                        id: 0,
                        name: '',
                        color: '#0000000'
                    }
                }
                tempChat.Client.Status.id = updatedChat.Client.status;
                let tempStatus = status.filter(s => s.id == updatedChat.Client.status);

                if (tempStatus[0]?.name) {
                    tempChat.Client.Status.name = tempStatus[0].name;
                    tempChat.Client.Status.color = tempStatus[0].color;
                }


                tempChats[currentChatIndex] = updatedChat;

                setChats(tempChats);
                enqueueSnackbar("Cliente actualizado con exito", {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar("Ocurrio un error al guardar la información", {
                    variant: 'error',
                });

            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al guardar la información", {
                variant: 'error',
            });
        }

        return true;
    }

    const setAdviserToClient = async (chat) => {
        try {

            const { data } = await api.post('/api/setChatAdviser', { chat: chat });
            if (data.status == "Success") {

                if (chat.isGroup == "1") {
                    setCurrentWindow(2);
                } else {
                    setCurrentWindow(1);
                }

                setTimeout(setChatUrlParamID(chat.id), 1000)

            } else {
                console.log(data)
                enqueueSnackbar("Ocurrio un error al asignar el asesor", {
                    variant: 'error',
                });

            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al asignar el asesor", {
                variant: 'error',
            });
        }
    }

    const bottomEl = useRef(null);

    const scrollToBottomDelay = () => {
        setTimeout(scrollToBottom, 1000)

    };

    const scrollToBottom = () => {

        if (bottomEl.current) {
            const { scrollHeight, clientHeight } = bottomEl.current;
            bottomEl.current.scrollTop = scrollHeight - clientHeight;
        }
        bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
    };


    const isChatSelected = (id) => {
        return selectedChats.indexOf(id) != -1;
    }

    const selectAllChats = (newValue) => {

        let selectedTemp = [];
        if (newValue) {


            chats.map((c) => {
                selectedTemp.push(c.id);
            })

            setSelectedChats(selectedTemp)
            setSelectedInfoChats(chats);
        } else {

            setSelectedChats(selectedTemp)
            setSelectedInfoChats(selectedTemp)
        }
        setSelectAll(newValue);
    }

    /**
     * Agrega o elimina un chat de la selección
     * @param {*} id 
     * @param {*} selected 
     */
    const addRemoveChatFromSelect = (id, selected, chat) => {

        let selectedTemp = [...selectedChats];
        let selecetdInfoTemp = [...selectedInfoChats]
        if (selected) {

            selectedTemp.push(id);
            selecetdInfoTemp.push(chat)


        } else {
            const index = selectedTemp.indexOf(id);
            if (index > -1) { // only splice array when item is found
                selectedTemp.splice(index, 1); // 2nd parameter means remove one item only
                selecetdInfoTemp.splice(index, 1);
            }

            setSelectAll(false);
        }
        setSelectedChats(selectedTemp);
        setSelectedInfoChats(selecetdInfoTemp);
    }

    const pinnedChat = async (selectedChatId) => {


        if (selectedChats.length == 0 && selectedChatId == null) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });

            return;
        }
        try {

            let chatsToSend = [...selectedChats]
            if (selectedChatId) {
                chatsToSend.push(selectedChatId);
            }
            const { data } = await api.post('/api/pinnChat', { chats: chatsToSend, pinned: true });
            if (data.status == "Success") {

            } else {
                console.log(data)
                enqueueSnackbar("Debe seleccionar al anclar los chats", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Debe seleccionar al anclar los chats", {
                variant: 'error',
            });
        }
        handleClose();
        setReload(!reload);
    }

    const unpinnedChat = async (selectedChatId) => {


        if (selectedChats.length == 0 && selectedChatId == null) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });
            return;
        }
        try {
            let chatsToSend = [...selectedChats]
            if (selectedChatId) {
                chatsToSend.push(selectedChatId);
            }
            const { data } = await api.post('/api/pinnChat', { chats: chatsToSend, pinned: false });
            if (data.status == "Success") {

            } else {
                console.log(data)
                enqueueSnackbar("Debe seleccionar al desanclar los chats", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Debe seleccionar al desanclar los chats", {
                variant: 'error',
            });
        }

        handleClose();
        setReload(!reload);
    }


    const addFilter = (name, value) => {
        let filtersTemp = filters;
        if (name == "unread") {
            filtersTemp.unread = !filtersTemp.unread;
            if (filtersTemp.unread) {
                filtersTemp.replyed = false;
            }
            setFilters({ ...filtersTemp })
        } else if (name == "tags") {

            let tagsTemp = filtersTemp.tags;
            let index = tagsTemp.indexOf(value);
            if (index > -1) {
                tagsTemp.splice(index, 1);
            } else {
                tagsTemp.push(value)
            }

            setFilters({ ...filtersTemp, tags: tagsTemp })

        } else if (name == "replyed") {
            filtersTemp.replyed = !filtersTemp.replyed;

            if (filtersTemp.replyed) {
                filtersTemp.unread = false;
            }
            setFilters({ ...filtersTemp })
        } else if (name == "phone") {
            filtersTemp.phone = value;
            setFilters({ ...filtersTemp })
        } else if (name == "quotes") {
            filtersTemp.quotes = value;
            setFilters({ ...filtersTemp })
        } else if (name == "events") {
            filtersTemp.events = value;
            setFilters({ ...filtersTemp })
        } else {
            let wpTemps = filtersTemp[name];
            let index = wpTemps.indexOf(value);
            if (index > -1) {
                wpTemps.splice(index, 1);
            } else {
                wpTemps.push(value)
            }

            setFilters({ ...filtersTemp, [name]: wpTemps })
            //console.log({ ...filtersTemp, whatsapp: wpTemps })
        }



    }

    const isTagSelected = (id) => {
        let tagsTemp = filters.tags;
        let index = tagsTemp.indexOf(id);
        return index != -1
    }

    const isWhatsappSelected = (id) => {
        let wpTemp = filters.whatsapp;
        let index = wpTemp.indexOf(id);
        return index != -1
    }

    const isStatusSelected = (id) => {
        let wpTemp = filters.status;
        let index = wpTemp.indexOf(id);
        return index != -1
    }
    const showChatMenu = (id) => {
        setIdShowChatMenu(id);
        //setShowNewMessageModal(false);
    }

    const hideChatMenu = () => {
        setIdShowChatMenu(null);
    }


    const openChatMenu = (chat, button) => {
        // console.log(button.currentTarget)
        setIdKeepChatMenu(chat.id);
        setSelectedChat(chat);
        setAnchorChatMenu(button.currentTarget);
    }

    const closeChatMenu = () => {
        setIdKeepChatMenu(null);
        setAnchorChatMenu(null);
        setSelectedChat(null);
    }


    const closeActionMenu = () => {
        setAnchorActionMenu(null);
    }

    const openActionMenu = (event) => {
        setAnchorActionMenu(event.currentTarget);
    };


    const addTagToClients = async (tags) => {



        let clientList = [...selectedChats];


        try {
            await api.post('/api/addTagToClients', {

                clients: clientList,
                idTags: tags,
                isFromChat: true
            });


            enqueueSnackbar("Se agregaron las etiquetas de forma correcta", {
                variant: 'success',
            });
            setReload(!reload);
            setOpenTags(false);
            closeActionMenu()
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al agregar las etiquetas`, {
                variant: 'error',
            });

        }


    }


    const addProductToClients = async (products) => {

        let clientList = [...selectedChats];

        try {
            await api.post('/api/addProductToClients', {

                clients: clientList,
                products: products,
                isFromChat: true
            });
            enqueueSnackbar(`Se agregaron los productos de forma correcta`, {
                variant: 'success',
            });


            setReload(!reload);
            setOpenProducts(false);
            closeActionMenu()
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al agregar los productos`, {
                variant: 'error',
            });
        }


    }

    /**
     * Cambia el estatus de los clientes
     * @param {*} products 
     */
    const addStatusToClient = async (status) => {

        let clientList = [...selectedChats];

        try {
            await api.post('/api/addStatusToClients', {

                clients: clientList,
                status: status,
                isFromChat: true
            });
            enqueueSnackbar(`Se agregaron los productos de forma correcta`, {
                variant: 'success',
            });


            setReload(!reload);
            setOpenStatus(false);
            closeActionMenu()
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al agregar los productos`, {
                variant: 'error',
            });
        }
    }



    const shareChat = async (usersID) => {


        if (selectedChats.length == 0) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });
            return;
        }
        try {
            let chatsToShare = [...selectedChats]

            if (selectedChat) {
                chatsToShare.push(selectedChat);
            }
            const { data } = await api.post('/api/addChatToClient', { chats: chatsToShare, users: usersID });
            if (data.status == "Success") {

            } else {
                console.log(data)
                enqueueSnackbar("Ocurrio un error al compartir los chats", {
                    variant: 'error',
                });

            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al compartir los chats", {
                variant: 'error',
            });
        }

        setReload(!reload);
    }


    const getPhones = () => {
        let phones = [];

        selectedChats.map((id) => {
            chats.map((chat) => {
                if (chat.id == id) {
                    phones.push(chat.phone);
                }
            })
        })
        return phones;
    }


    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;

        if (bottom && reloadNewChats) {

            if (chatPage < nextChatPage) {
                setChatPage(chatPage + 1)
            }
            ;
        }
    }


    const changeSoundConfig = async () => {
        try {

            const { data } = await api.post('/api/config/chatSound', { enableChatSound: chatSound ? 0 : 1 });

            if (data.status == "Success") {
                setChatSound(!chatSound)
            } else {
                if (data?.code) {
                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('Ocurrio un error al cambiar la configuración de sonido', {
                        variant: 'error',
                    });
                }
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar('Ocurrio un error al cambiar la configuración de sonido', {
                variant: 'error',
            });
        }
    }

    /**
     * 
     */
    const initNewChat = () => {
        setShowNewMessageModal(!showNewMessageModal);
        setCurrentChat({
            whatsappId: null,
            WpSession: {
                sessionAuth: null
            }
        })
    }

    /**
     * 
     */
    const handleChangeCurrPhone = (phone) => {

        let newChat = { ...currentChat };
        newChat.whatsappId = `${phone}@c.us`;

        setCurrentChat(newChat)
    }


    /**
     * 
     */
    const handleChangeCurrSess = (wpSession) => {

        let newChat = { ...currentChat };
        newChat.WpSession = wpSession;

        setCurrentChat(newChat)
    }

    /**
     * 
     */
    const handleChangeCurrTags = (tags) => {

        let newChat = { ...currentChat };
        newChat.tags = tags;

        setCurrentChat(newChat)
    }


    const markAsUnread = async (selectedChatId) => {


        if (selectedChats.length == 0 && selectedChatId == null) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });

            return;
        }
        try {

            let chatsToSend = [...selectedChats]
            if (selectedChatId) {
                chatsToSend.push(selectedChatId);
            }
            const { data } = await api.post('/api/markUnread', { chats: chatsToSend, unread: true });
            if (data.status == "Success") {

                enqueueSnackbar("Se marcaron como no leidos los chats", {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar("Ocurrió un error al marcar como no leidos los chats", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrió un error al marcar como no leidos los chats", {
                variant: 'error',
            });
        }
        handleClose();
        setReload(!reload);
    }


    const markAsRead = async (selectedChatId) => {


        if (selectedChats.length == 0 && selectedChatId == null) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });

            return;
        }
        try {

            let chatsToSend = [...selectedChats]
            if (selectedChatId) {
                chatsToSend.push(selectedChatId);
            }
            const { data } = await api.post('/api/markUnread', { chats: chatsToSend, unread: false });
            if (data.status == "Success") {

                enqueueSnackbar("Se marcaron como leidos los chats", {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar("Ocurrió un error al marcar como leidos los chats", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrió un error al marcar como leidos los chats", {
                variant: 'error',
            });
        }
        handleClose();
        setReload(!reload);
    }

    const getSharedUsers = () => {
        let userIds = [];

        selectedInfoChats.map((chat) => {
            let tempUserIds = chat.Users.map((usr) => usr.id)
            userIds = [...userIds, ...tempUserIds];
        });
        userIds = userIds.filter(function (item, pos) {
            return userIds.indexOf(item) == pos;
        })
        return userIds;
    }

    const updateMessage = (index, newMessage) => {
        let tempArrMessages = [...currentMessages];
        tempArrMessages[index] = newMessage;
        setCurrentMessages(tempArrMessages);

    }


    const deleteChat = async (selectedChatId) => {


        if (selectedChats.length == 0 && selectedChatId == null) {
            enqueueSnackbar("Debe seleccionar al menos un chat", {
                variant: 'warning',
            });
            return;
        }
        const allowDelete = window.confirm('¿Deseas eliminar los chats seleccionados?');

        if (!allowDelete) {
            return;
        }

        try {
            let chatsToSend = [...selectedChats]
            if (selectedChatId) {
                chatsToSend.push(selectedChatId);
            }
            const { data } = await api.post('/api/deleteChat', { chats: chatsToSend });
            if (data.status == "Success") {

                const deleted = chatsToSend.indexOf(currentChat?.id);
                if (deleted > -1) {
                    setCurrentChat({
                        whatsappId: null,
                        WpSession: {
                            sessionAuth: null
                        }
                    });
                    setCurrentChatIndex(-1);
                    setCurrentMessages([]);
                }

            } else {
                console.log(data)
                enqueueSnackbar("Ocurrio un error al eliminar los chats", {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al eliminar los chats", {
                variant: 'error',
            });
        }

        handleClose();
        setReload(!reload);
    }

    function openNewChat(){
        setShowNewMessageModal(true)
    }
    return (
        <>
            <Helmet title="Chat" />


            <NotificationComponent />
            <Grid container sx={{ height: '85vh', maxWidth: '100vw' }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '10%' }}
                >
                    <div style={{ width: '80%' }}>
                        <WhatsappSessionAlert onChange={() => { }} />
                    </div>
                </Grid>
                <Grid item sx={{ height: '85vh' }} xs={12} md={4} lg={3}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid sx={{ minHeight: '50px' }} item xs={revisaPermisos(['Ver grupos']) ? 4 : 6}>
                                <Badge badgeContent={unreadCount} color={"primary"} style={{ width: '100%' }}>
                                    <LoadingButton
                                        fullWidth
                                        loading={isLoading && currentWindow == 0}
                                        loadingIndicator="Cargando..."
                                        disabled={blockButtons} onClick={() => setCurrentWindow(0)} sx={{ minHeight: '50px' }} variant={currentWindow == 0 ? "contained" : "outlined"}>Pendientes</LoadingButton>
                                </Badge>
                            </Grid>
                            <Grid sx={{ minHeight: '50px' }} item xs={revisaPermisos(['Ver grupos']) ? 4 : 6}>

                                <LoadingButton
                                    fullWidth
                                    loading={isLoading && currentWindow == 1}
                                    loadingIndicator="Cargando..."
                                    disabled={blockButtons} onClick={() => setCurrentWindow(1)} sx={{ minHeight: '50px' }} variant={currentWindow == 1 ? "contained" : "outlined"}>Atendidos</LoadingButton>

                            </Grid>
                            {
                                revisaPermisos(['Ver grupos']) &&
                                <Grid sx={{ minHeight: '50px' }} item xs={4}>
                                    <Badge badgeContent={groupCount} color={"primary"} style={{ width: '100%' }}>
                                        <LoadingButton
                                            fullWidth
                                            loading={isLoading && currentWindow == 2}
                                            loadingIndicator="Cargando..."
                                            disabled={blockButtons} onClick={() => setCurrentWindow(2)} sx={{ minHeight: '50px' }} variant={currentWindow == 2 ? "contained" : "outlined"}>Grupos</LoadingButton>
                                    </Badge>
                                </Grid>
                            }

                        </Grid>

                    </Grid>

                    <Grid item xs={12}>

                        <Box p={0}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextField onChange={(e) => addFilter('phone', e.target.value)} label="Buscar chat" variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        !showCheckBox ?
                                            <IconButton id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}

                                                variant="contained" sx={{ height: '100%' }} fullWidth>
                                                <FilterListIcon />
                                            </IconButton>
                                            :
                                            <IconButton
                                                onClick={openActionMenu}
                                                variant="contained" sx={{ height: '100%' }} fullWidth>
                                                <LinearScale />
                                            </IconButton>
                                    }
                                    <Menu
                                    >
                                        <Box p={2}>
                                            <Typography variant="body1" >
                                                Acciones
                                            </Typography>
                                        </Box>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                            component="nav"
                                        >

                                            <ListItemButton onClick={() => { changeSoundConfig(); handleClose() }} >
                                                <ListItemIcon>
                                                    {
                                                        chatSound ?
                                                            <VolumeOff fontSize="small" />
                                                            :
                                                            <VolumeUp fontSize="small" />
                                                    }

                                                </ListItemIcon>
                                                <ListItemText primary="Sent mail" />
                                            </ListItemButton>
                                            <ListItemButton>
                                                <ListItemIcon>

                                                </ListItemIcon>
                                                <ListItemText primary="Drafts" />
                                            </ListItemButton>

                                            {
                                                sessions.map((s) => {

                                                    const selected = isWhatsappSelected(s.id);
                                                    return (
                                                        <>
                                                            <ListItemButton onClick={() => addFilter('whatsapp', s.id)} selected={selected} key={s.id}>
                                                                <ListItemIcon>
                                                                    <WhatsApp style={{ color: s.color ? s.color : 'black' }} fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={s.name} />
                                                                {selected ? <ExpandLess /> : <ExpandMore />}
                                                            </ListItemButton>
                                                            <Collapse in={selected} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    <ListItemButton sx={{ pl: 4 }}>
                                                                        <ListItemIcon>
                                                                            <StarBorder />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary="Starred" />
                                                                    </ListItemButton>
                                                                    <ListItemButton sx={{ pl: 4 }}>
                                                                        <ListItemIcon>
                                                                            <StarBorder />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary="Starred" />
                                                                    </ListItemButton>
                                                                    <ListItemButton sx={{ pl: 4 }}>
                                                                        <ListItemIcon>
                                                                            <StarBorder />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary="Starred" />
                                                                    </ListItemButton>
                                                                </List>
                                                            </Collapse>
                                                        </>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Menu>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuList>
                                            <Box p={2}>
                                                <Typography variant="body1" >
                                                    Acciones
                                                </Typography>
                                            </Box>
                                            <MenuItem onClick={() => { changeSoundConfig(); handleClose() }}>
                                                <ListItemIcon>
                                                    {
                                                        chatSound ?
                                                            <VolumeOff fontSize="small" />
                                                            :
                                                            <VolumeUp fontSize="small" />
                                                    }


                                                </ListItemIcon>
                                                <ListItemText>{chatSound ? "Desactivar audio" : "Activar audio"}</ListItemText>

                                            </MenuItem>
                                            <MenuItem onClick={() => { setShowCheckBox(!showCheckBox); handleClose() }}>
                                                <ListItemIcon>
                                                    {showCheckBox ?
                                                        <CheckBoxOutlineBlank fontSize="small" />
                                                        :
                                                        <CheckBox fontSize="small" />
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

                                            </MenuItem>
                                            <MenuItem onClick={() => { initNewChat(); handleClose() }}>
                                                <ListItemIcon>

                                                    <WhatsApp fontSize="small" />

                                                </ListItemIcon>
                                                <ListItemText>Nuevo mensaje</ListItemText>

                                            </MenuItem>
                                            <Divider />

                                            <Divider />

                                            <Box p={2}>
                                                <Typography variant="body1" >
                                                    Whatsapps
                                                </Typography>
                                            </Box>
                                            <Divider />

                                            {
                                                sessions.map((s) => {

                                                    const selected = isWhatsappSelected(s.id);

                                                    return (
                                                        <>
                                                            <MenuItem onClick={() => addFilter('whatsapp', s.id)} selected={selected} key={s.id}>
                                                                <ListItemIcon >
                                                                    <WhatsApp style={{ color: s.color ? s.color : 'black' }} fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText>{s.name}</ListItemText>
                                                                {selected ? <ExpandLess /> : <ExpandMore />}
                                                            </MenuItem>
                                                            <Collapse in={selected} timeout="auto" unmountOnExit>
                                                                <Box sx={{ ml: 4 }}>
                                                                    <MenuList>
                                                                        <Box p={2}>
                                                                            <Typography variant="body1" >
                                                                                {s.Tags.length > 0 ? "Etiquetas" : "No hay etiquetas"}
                                                                            </Typography>
                                                                        </Box>
                                                                        {
                                                                            s.Tags.map((t) => {


                                                                                return (
                                                                                    <MenuItem onClick={() => addFilter('tags', t.id)} selected={isTagSelected(t.id)} key={t.id}>
                                                                                        <ListItemIcon >
                                                                                            <Label style={{ color: t.color ? t.color : 'black' }} fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText>{t.name}</ListItemText>
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                        <Box p={2}>
                                                                            <Typography variant="body1" >
                                                                                {s?.Statuses?.length > 0 ? "Estatus" : "No hay estatus"}
                                                                            </Typography>
                                                                        </Box>
                                                                        {


                                                                            s?.Statuses?.map((s) => {


                                                                                return (
                                                                                    <MenuItem onClick={() => addFilter('status', s.id)} selected={isStatusSelected(s.id)} key={s.id}>
                                                                                        <ListItemIcon >
                                                                                            <WhatsApp style={{ color: s.color ? s.color : 'black' }} fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText>{s.name}</ListItemText>
                                                                                    </MenuItem>
                                                                                )
                                                                            })

                                                                        }
                                                                    </MenuList>
                                                                </Box>
                                                            </Collapse>
                                                        </>
                                                    )
                                                })
                                            }

                                            <Divider />
                                            <MenuItem onClick={() => addFilter('tags', 0)} selected={isTagSelected(0)} key={0}>
                                                <ListItemIcon>
                                                    <LabelOff fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Sin etiquetas</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => addFilter('quotes', !filters.quotes)} selected={filters.quotes}>
                                                <ListItemIcon>
                                                    <Description fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Chats con cotización</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => addFilter('events', !filters.events)} selected={filters.events}>
                                                <ListItemIcon>
                                                    <CalendarToday fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Chats con eventos</ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>

                                    <Menu

                                        anchorEl={anchorActionMenu}
                                        open={Boolean(anchorActionMenu)}
                                        onClose={closeActionMenu}
                                    >
                                        <MenuList

                                        >
                                            <Box p={2}>
                                                <Typography variant="body1" >
                                                    Acciones
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <MenuItem onClick={() => { setShowCheckBox(!showCheckBox); closeActionMenu() }}>
                                                <ListItemIcon>
                                                    {showCheckBox ?
                                                        <CheckBoxOutlineBlank fontSize="small" />
                                                        :
                                                        <CheckBox fontSize="small" />
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

                                            </MenuItem>

                                            <MenuItem onClick={() => { pinnedChat(); closeActionMenu(); }}>
                                                <ListItemIcon>
                                                    <PushPin fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Anclar</ListItemText>
                                            </MenuItem>

                                            <MenuItem onClick={() => { markAsUnread(); closeActionMenu(); }}>
                                                <ListItemIcon>
                                                    <MarkChatUnread fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Marcar como no leido</ListItemText>
                                            </MenuItem>

                                            <MenuItem onClick={() => { markAsRead(); closeActionMenu(); }}>
                                                <ListItemIcon>
                                                    <MarkChatRead fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Marcar como leido</ListItemText>
                                            </MenuItem>
                                            {
                                                revisaPermisos(['Eliminar chat']) &&
                                                <MenuItem onClick={() => { deleteChat(); closeActionMenu(); }}>
                                                    <ListItemIcon>
                                                        <Delete color="error" fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Eliminar chats</ListItemText>
                                                </MenuItem>
                                            }

                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });

                                                    return;
                                                }

                                                setOpenTags(true);

                                            }}>
                                                <ListItemIcon>
                                                    <Label fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Modificar etiquetas</ListItemText>
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });
                                                    return;
                                                }

                                                setOpenStatus(true);

                                            }}>
                                                <ListItemIcon>
                                                    <Assignment fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Modificar estatus</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });
                                                    return;
                                                }

                                                setOpenProducts(true);

                                            }}>
                                                <ListItemIcon>
                                                    <ShoppingCart fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Modificar producto</ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <Box p={2}>
                                                <Typography variant="body1" >
                                                    Masivo
                                                </Typography>
                                            </Box>
                                            <MenuItem onClick={() => { setOpenSharemodal(true); closeChatMenu(); }}>
                                                <ListItemIcon>
                                                    <Share />
                                                </ListItemIcon>
                                                <ListItemText>Compartir chat</ListItemText>

                                            </MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });
                                                    return;
                                                }
                                                setScheduleMsg(false);
                                                setIsSequence(false);
                                                setOpenMassiveMessage(true);

                                            }}>

                                                <ListItemIcon>
                                                    <Message fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Enviar mensaje masivo</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });
                                                    return;
                                                }
                                                setScheduleMsg(true);
                                                setIsSequence(false);
                                                setOpenMassiveMessage(true);

                                            }}>

                                                <ListItemIcon>
                                                    <Schedule fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Programar mensaje</ListItemText>
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                if (selectedChats.length == 0) {
                                                    enqueueSnackbar("Debe seleccionar al menos un contacto", {
                                                        variant: 'warning',
                                                    });
                                                    return;
                                                }
                                                setIsSequence(true);
                                                setScheduleMsg(false);
                                                setOpenMassiveMessage(true);

                                            }}>

                                                <ListItemIcon>
                                                    <AccountTree fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Enviar secuencia</ListItemText>
                                            </MenuItem>
                                        </MenuList>

                                    </Menu>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={2}>
                            <Grid container>
                                {
                                    showCheckBox &&
                                    <Grid item xs={3} >
                                        <Grid container justifyContent="center">
                                            <Checkbox checked={selectAll} onChange={(e) => selectAllChats(e.target.checked)} />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>

                        </Box>
                    </Grid>

                    <Menu
                        anchorEl={anchorChatMenu}
                        open={Boolean(anchorChatMenu)}
                        onClose={closeChatMenu}>
                        <MenuList

                        >

                            <MenuItem selected={filters.unread} onClick={() => { setShowCheckBox(!showCheckBox); closeChatMenu() }}>
                                <ListItemIcon>
                                    {showCheckBox ?
                                        <CheckBoxOutlineBlank fontSize="small" />
                                        :
                                        <CheckBox fontSize="small" />
                                    }
                                </ListItemIcon>
                                <ListItemText>{showCheckBox ? "Desactivar seleccion" : "Activar seleccion"}</ListItemText>

                            </MenuItem>
                            {
                                !showCheckBox &&
                                <MenuItem selected={filters.unread} onClick={() => {
                                    if (selectedChat?.pinned == 0) {
                                        pinnedChat(selectedChat?.id);
                                    } else {
                                        unpinnedChat(selectedChat?.id);
                                    }
                                    closeChatMenu();
                                }}>
                                    <ListItemIcon>
                                        <PushPin fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{selectedChat?.pinned == 0 ? "Anclar" : "Desanclar"}</ListItemText>
                                </MenuItem>
                            }
                            {
                                currentWindow == 1 &&
                                <MenuItem onClick={() => { markAsUnread(selectedChat?.id); closeChatMenu(); }}>
                                    <ListItemIcon>
                                        <MarkChatUnread fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Marcar como no leido</ListItemText>
                                </MenuItem>
                            }

                            {
                                currentWindow == 0 &&
                                <MenuItem onClick={() => { markAsRead(selectedChat?.id); closeChatMenu(); }}>
                                    <ListItemIcon>
                                        <MarkChatRead fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Marcar como leido</ListItemText>
                                </MenuItem>
                            }
                            {
                                revisaPermisos(['Eliminar chat']) &&
                                <MenuItem onClick={() => { closeChatMenu(); deleteChat(selectedChat?.id); }}>
                                    <ListItemIcon>
                                        <Delete color="error" fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Eliminar chat ({selectedChat?.id})</ListItemText>
                                </MenuItem>
                            }
                            {
                                selectedChat?.isGroup == "1" &&
                                <MenuItem onClick={() => { setCurrGroupID(selectedChat.id); setOpenAddParticipants(true); closeChatMenu(); }}>
                                    <ListItemIcon>
                                        <PersonAdd />
                                    </ListItemIcon>
                                    <ListItemText>Agregar participantes</ListItemText>

                                </MenuItem>

                            }

                        </MenuList>
                    </Menu>
                    <Divider />
                    {
                        isLoading ?
                            <Grid
                                container
                                direction={"row"}
                                sx={{ padding: '20px', height: '100%' }}
                                alignItems="center"
                                justify="center"
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    align="center"
                                    sx={{ height: '100%' }}
                                >
                                    <div className='containerLoading'>
                                        <div className='dot1'></div>
                                        <div className='dot2'></div>
                                        <div className='dot3'></div>
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            <List onScroll={handleScroll} style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '83%' }}>
                                {
                                    chats?.map((chat, key) => {

                                        if (!chat) { return ''; }
                                        if (chat?.pinned != '1') { return ''; }
                                        return <ListItem selected={currentChat?.id == chat.id} onMouseLeave={hideChatMenu} onMouseEnter={() => showChatMenu(chat.id)} button={currentWindow != 3} onClick={() => { if (currentWindow != 3 && !showCheckBox) { onClick(chat, key) } }} disabled={isLoadingChats}>
                                            <Grid container>

                                                <Grid style={{ borderLeft: `0.3em solid`, borderLeftColor: chat.Client?.Status?.color ? chat.Client.Status.color : '', padding: '0.5em' }} item sx={3} md={3} lg={3}>
                                                    {
                                                        showCheckBox ?
                                                            <Checkbox onChange={(e) => { addRemoveChatFromSelect(chat.id, e.target.checked, chat) }} checked={isChatSelected(chat.id)} /> :
                                                            <ListItemIcon >
                                                                <Online
                                                                    overlap="circular"
                                                                    anchorOrigin={{
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    }}
                                                                    variant="dot"
                                                                >
                                                                    <Avatar
                                                                        alt="Lucy Lavender"
                                                                        src="/static/img/avatars/avatar-1.jpg"
                                                                    />
                                                                </Online>

                                                            </ListItemIcon>

                                                    }


                                                </Grid>
                                                <Grid item sx={7} md={7} lg={7}>

                                                    <Badge sx={{ maxWidth: '100%', minWidth: '100%' }} badgeContent={chat.unreadCount} color="success">
                                                        <ListItemText className="texto-contenedor" primary={chat.Client?.firstName ? chat.Client.firstName : chat.name} secondary={chat.lastMessage} />
                                                    </Badge>



                                                </Grid>
                                                {
                                                    (chat.id == idShowChatMenu || chat.id == idKeepChatMenu) ?
                                                        <Grid item sx={2} md={2} lg={2}>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); openChatMenu(chat, e); }} fullWidth sx={{ height: '100%' }}>
                                                                <ArrowDropDown />
                                                            </IconButton>
                                                        </Grid>
                                                        :
                                                        <Grid item sx={2} md={2} lg={2}>
                                                            <IconButton fullWidth sx={{ height: '100%' }}>
                                                                <PushPin fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                }

                                                <Grid item sx={12} md={12} lg={12}>
                                                    <div style={{ overflowY: 'hidden', overflowX: 'hidden', maxHeight: '30px', }}>
                                                        <Button
                                                            startIcon={<WhatsApp style={{ color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF' }} fontSize="small" />}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                // color: getContrastColor(chat.WpSession?.color ? chat.WpSession.color : '#000000'),
                                                                borderColor: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                                                color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                                            }} >
                                                            {chat.WpSession.name}
                                                        </Button>
                                                        {
                                                            chat?.Client?.Status?.name &&
                                                            <Button

                                                                variant="outlined"
                                                                size="small"
                                                                sx={{

                                                                    borderColor: chat.Client.Status.color ? chat.Client.Status.color : '#FFFFFF',
                                                                    color: chat.Client.Status?.color ? chat.Client.Status.color : '#FFFFFF',
                                                                }} >
                                                                {chat.Client.Status.name}
                                                            </Button>
                                                        }
                                                        {
                                                            chat.Client?.Tags.map((tag) => {
                                                                return <Chip key={tag.name} label={tag.name} sx={{ color: 'white', background: tag?.color ? tag.color : 'black' }} />
                                                            })
                                                        }
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </ListItem>
                                    })
                                }
                                {
                                    chats?.map((chat, key) => {

                                        if (!chat) { return ''; }
                                        if (chat?.pinned == '1') { return ''; }
                                        return <ListItem selected={currentChat?.id == chat.id} onMouseLeave={hideChatMenu} onMouseEnter={() => showChatMenu(chat.id)} button={currentWindow != 3} onClick={() => { if (currentWindow != 3 && !showCheckBox) { onClick(chat, key) } }} disabled={isLoadingChats}>
                                            <Grid container>

                                                <Grid style={{ borderLeft: `0.3em solid`, borderLeftColor: chat.Client?.Status?.color ? chat.Client.Status.color : '', padding: '0.5em' }} item sx={3} md={3} lg={3}>
                                                    {
                                                        showCheckBox ?
                                                            <Checkbox onChange={(e) => { addRemoveChatFromSelect(chat.id, e.target.checked, chat) }} checked={isChatSelected(chat.id)} /> :
                                                            <ListItemIcon >
                                                                <Online
                                                                    overlap="circular"
                                                                    anchorOrigin={{
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    }}
                                                                    variant="dot"
                                                                >
                                                                    <Avatar
                                                                        alt="Lucy Lavender"
                                                                        src="/static/img/avatars/avatar-1.jpg"
                                                                    />
                                                                </Online>
                                                            </ListItemIcon>

                                                    }


                                                </Grid>
                                                <Grid item xs={7} md={7} lg={7}>

                                                    <Badge sx={{ maxWidth: '100%', minWidth: '100%' }} badgeContent={chat.unreadCount} color="success">
                                                        <ListItemText className="texto-contenedor" primary={chat.Client?.firstName ? chat.Client.firstName : chat.name} secondary={chat.lastMessage} />
                                                    </Badge>


                                                </Grid>
                                                {
                                                    (chat.id == idShowChatMenu || chat.id == idKeepChatMenu) && <Grid item sx={2} md={2} lg={2}>
                                                        <IconButton onClick={(e) => { e.stopPropagation(); openChatMenu(chat, e); }} fullWidth sx={{ height: '100%' }}>
                                                            <ArrowDropDown />
                                                        </IconButton>
                                                    </Grid>
                                                }


                                                <Grid item sx={12} md={12} lg={12}>
                                                    <div style={{ overflowY: 'hidden', overflowX: 'hidden', maxHeight: '30px', }}>
                                                        <Button
                                                            startIcon={<WhatsApp style={{ color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF' }} fontSize="small" />}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                // color: getContrastColor(chat.WpSession?.color ? chat.WpSession.color : '#000000'),
                                                                borderColor: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                                                color: chat.WpSession?.color ? chat.WpSession.color : '#FFFFFF',
                                                            }} >
                                                            {chat.WpSession.name}
                                                        </Button>
                                                        {
                                                            chat?.Client?.Status?.name &&
                                                            <Button

                                                                variant="outlined"
                                                                size="small"
                                                                sx={{

                                                                    borderColor: chat.Client.Status.color ? chat.Client.Status.color : '#FFFFFF',
                                                                    color: chat.Client.Status?.color ? chat.Client.Status.color : '#FFFFFF',
                                                                }} >
                                                                {chat.Client.Status.name}
                                                            </Button>
                                                        }

                                                        {
                                                            chat.Client?.Tags.map((tag) => {
                                                                return <Chip label={tag.name} sx={{ color: getContrastColor(tag?.color ? tag.color : '#000000'), background: tag?.color ? tag.color : 'black' }} />
                                                            })
                                                        }
                                                    </div>
                                                </Grid>



                                            </Grid>
                                        </ListItem>
                                    })
                                }

                            </List>
                    }

                </Grid>

                {
                    (isLoading || isLoadingChats || currentWindow == 3) && !filters.phone ?
                        <Grid item sx={{ height: '100%' }} xs={12} md={9} lg={9}>
                            <Grid
                                container


                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >

                                {
                                    currentWindow == 3 ?
                                        <span>
                                            Selecciona un cliente para poder chatear con él
                                        </span>
                                        :
                                        <span>
                                            Cargando{isLoadingChats ? ' mensajes. Si es la primera vez que abre este chat puede tardar unos minutos' : ''}, por favor espere
                                        </span>
                                }


                            </Grid>
                        </Grid>
                        :
                        <Grid item sx={{ height: '100%' }} xs={12} md={currentChat && (!showNewMessageModal || isNewClient != null) ? 7 : 8} lg={currentChat && (!showNewMessageModal || isNewClient != null) ? 7 : 9}>
                            {currentChat && <ChatMessagesHeader client={currentChat} setEmailMessages={setEmailMessages} />}
                            {showNewMessageModal ?
                                <ChatMessages ref={bottomEl}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: '100%' }}
                                    >
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid sx={{ minHeight: '150px' }} item xs={3} md={3} lg={3}>
                                                    <ThreeP className="new-chat-icon" />
                                                </Grid>
                                                <Grid sx={{ minWidth: '25%' }} item xs={3} md={3} lg={3}>

                                                    <TextField
                                                        value={currentChat?.WpSession ? currentChat.WpSession : ''}
                                                        select
                                                        fullWidth
                                                        label="Selecciona un whatsapp"
                                                        name="type"
                                                        variant="outlined"
                                                        onChange={(event) => { handleChangeCurrSess(event.target.value) }}

                                                    >
                                                        {
                                                            sessions?.map((session) => (
                                                                <MenuItem key={session.id} value={session}>
                                                                    {session.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>

                                                </Grid>
                                                <Grid item sx={{ minWidth: '25%' }} xs={3} md={3} lg={3}>

                                                    <PhoneInput
                                                        country={navigator.language || navigator.userLanguage}
                                                        enableSearch={true}
                                                        placeholder="Ingresa el número"
                                                        value={currentChat?.phone ? currentChat.phone : ''}
                                                        onChange={(phone) => { handleChangeCurrPhone(phone) }} />


                                                </Grid>
                                                <Grid item sx={{ minWidth: '25%' }} xs={3} md={3} lg={3}>

                                                    <MultiSelect
                                                        style={{ width: '100%' }}
                                                        title="Etiquetas"
                                                        theme={null}
                                                        list={tags}
                                                        onChange={(data) => { handleChangeCurrTags(data) }}
                                                        initSelect={currentChat?.tags ? currentChat.tags : []}

                                                    />
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </ChatMessages>
                                :

                                chatError ?

                                    <GetChatMessagesError onNewChat={openNewChat} />
                                    :
                                    <ChatMessages key={`id-${emailMessages.length}`} ref={bottomEl}>
                                        {
                                            emailMessages?.length > 0 ?

                                                emailMessages.map((m, key) => {

                                                    return (
                                                        <EmailMessageComponent

                                                            messageObj={m}
                                                            subject={m.subject}
                                                            name={m.name}
                                                            avatar="/static/img/avatars/avatar-2.jpg"
                                                            message={m.body}
                                                            isGroup={currentChat?.isGroup == "1"}
                                                            //time={m.timestamp.split(' ')[1]}
                                                            time={m.timestamp.replace('T', ' ').substring(0, 16)}
                                                            time2={`${m.timestamp.replace(' ', 'T')}-05:00`.substring(0, 25).replace('000Z-', '000Z')}
                                                            position={m.fromMe != 1 ? "left" : "right"}
                                                            author={m.author}
                                                            updateMessage={updateMessage}
                                                            indexMessage={key}
                                                        />
                                                    )

                                                })
                                                :
                                                currentMessages.map((m, key) => {

                                                    return (
                                                        <ChatMessageComponent

                                                            messageObj={m}
                                                            name={m.name}
                                                            avatar="/static/img/avatars/avatar-2.jpg"
                                                            message={m.body}
                                                            isGroup={currentChat?.isGroup == "1"}
                                                            //time={m.timestamp.split(' ')[1]}
                                                            time={m.timestamp.replace('T', ' ').substring(0, 16)}
                                                            time2={`${m.timestamp.replace(' ', 'T')}-05:00`.substring(0, 25).replace('000Z-', '000Z')}
                                                            position={m.fromMe != 1 ? "left" : "right"}
                                                            author={m.author}
                                                            updateMessage={updateMessage}
                                                            indexMessage={key}
                                                        />
                                                    )

                                                })

                                        }

                                        <p>{scrollToBottomDelay()}</p>
                                    </ChatMessages>
                            }
                            <Divider />
                            {
                                (currentMessages?.length > 0 || showNewMessageModal) &&
                                <ChatInputMessage

                                    templates={templates}
                                    currentMessage={currentMessage}
                                    filterNameValue={filterNameValue}
                                    setFilterNameValue={setFilterNameValue}
                                    sendMessage={sendMessage}
                                    currentChat={currentChat}
                                    attachedFiles={attachedFiles}
                                    setAttachedFiles={setAttachedFiles}

                                />
                            }


                        </Grid>
                }

                {currentChat?.id && (!showNewMessageModal || isNewClient != null) &&
                    <Grid item style={{ maxHeight: '80vh', overflow: 'auto' }} xs={12} md={2} lg={2}>
                        <ClientInfo
                            md={12}
                            lg={12}
                            products={products}
                            status={status2}
                            chat={currentChat}
                            onSave={saveChatClientInfo}
                            tags={tags}
                        />
                    </Grid>
                }
            </Grid >


            <AddTagmodal
                title={"Asignar etiquetas"}
                open={openTags}
                setOpen={setOpenTags}
                selectedChats={selectedInfoChats}
                selectedChatIDs={selectedChats}
                relaod={() => { setReload(!reload); closeActionMenu() }}
            //  onSave={addTagToClients}
            />


            <AddProductModal
                title={"Asignar productos"}
                open={openProducts}
                setOpen={setOpenProducts}

                onSave={addProductToClients}
            />

            <AddStatusModal
                title={"Asignar estatus"}
                open={openStatus}
                setOpen={setOpenStatus}

                onSave={addStatusToClient}
            />



            <MassiveMsgModal

                forwardingId={0}
                open={openMassiveMessage}
                setOpen={setOpenMassiveMessage}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={() => { setOpenMassiveMessage(false); setReload(!reload); }}
                scheduled={scheduleMsg}
                isSequence={isSequence}
            />

            <ShareModal
                open={openShareModal}
                setOpen={setOpenSharemodal}
                theme={null}
                title={"Compartir chat"}
                users={advisers}
                onSave={shareChat}
                selectedUsers={getSharedUsers()}
            />

            <AddparticipantToGroupModal
                open={openAddParticipants}
                setOpen={setOpenAddParticipants}
                idGroup={currGroupID}
                title={"agregar participantes"}
            />
        </>
    );
}

export default ChatV2;
