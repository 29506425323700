import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import './styles.css'
import api from '../../services/api';
import { useSnackbar } from 'notistack';

const CalendarEventCard = ({ externalEventID, externalAccountID, name, description, startDate, endDate, link, onCancel, allowCancel }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [eventID, setEventID] = useState(null);
    const [accountID, setAccountID] = useState(null);
    useEffect(() => {
        setEventID(externalEventID);
    }, [externalEventID])

    useEffect(() => {
        setAccountID(externalAccountID);
    }, [externalAccountID])

    const cancelEvent = async () => {
        if (!window.confirm(`¿Desea cancelar permanentemente el evento ${name}?`)) {
            return;
        }
        try {
            const { data } = await api.post('/api/googleCalendar/delete', { eventID, accountID })
            enqueueSnackbar("Se cancel+o el evento", {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar("Ocurrio un error al cancelar el evento", {
                variant: 'error',
            });
            return;
        }
        if (onCancel) {
            onCancel();
        }
    }
    return (
        <Card className="custom-card">
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <EventIcon className="icon" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">{name}</Typography>
                        <Typography variant="body1">{description}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {startDate} - {endDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <a href={'/calendario/citas'} target="_blank">Ver en calendario</a>
                    </Grid>
                    <Grid item>
                        <a href={link} target="_blank">Ir</a>  {allowCancel && <Button color="error" onClick={cancelEvent}>Cancelar</Button>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CalendarEventCard;
