import React from 'react';
import styled from 'styled-components';
import api from '../../services/api';



function BotonQr({setCurrentQr, text,estadoModal, setEstadoModal,qr,sessionName,setCurrentSessionName,reconnectSession }) {

	return (
		<div>
			<ContenedorBotones>
				{
					text == 'QR' && <Boton onClick={() => {setEstadoModal(!estadoModal); setCurrentQr(qr); setCurrentSessionName(sessionName)}}>{text}</Boton>
				}
				{
					text == 'Conectado' && <BotonSuccess onClick={() => {}}>{text}</BotonSuccess>
				}
				{
					text == 'Reconectar' && <BotonWarn onClick={async () => {reconnectSession(sessionName)}}>{text}</BotonWarn>
				}
				
				{
					text != 'QR' &&	text != 'Conectado'&& text !=  'Reconectar' && 	<BotonWarn onClick={() => {}}>{text}</BotonWarn>
				}
				
			</ContenedorBotones>
		
		</div>
	)
}

export default BotonQr;

const ContenedorBotones = styled.div`
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

const Boton = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #e81810;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;
	&:hover {
		background: #d03838;
	}
`;

const BotonSuccess = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #09a40b;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;
	&:hover {
		background:#046105;
	}
`;

const BotonWarn = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #ccd802;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;
	&:hover {
		background: #8f9704;
	}
`;


