import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { useEffect } from "react";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const [reload, setReload] = useState(false);



  const handleDrawerToggle = (newValue) => {
    if(newValue){
      setMobileOpen(!newValue);
      return;
    }
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Box sx={{
        display: { xs: "block", lg: "block" }, flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '0%',
      }}>
        <Sidebar
          PaperProps={{ style: { width: drawerWidth } }}
          variant={"temporary"}
          open={mobileOpen}
          onClick={()=> setMobileOpen(false)}
          onClose={handleDrawerToggle}
          items={dashboardItems}
          reload={reload}
        />
      </Box>

      {/*
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
            reload={reload}
          />
        </Box>
  */}

      <AppContent style={{ width: '300px', maxWidth: '100%' }}>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={5}>
          {children}
          <Outlet context={[reload, setReload]} />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

export default Dashboard;
