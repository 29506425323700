import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TagsTableModal from '../../components/TagsTableModal';
import MembershipModal from '../../components/MembershipModal';
import { Helmet } from 'react-helmet-async';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Memberships({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [webhooks, setWebhooks] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentMembership, setCurrentMembership] = useState(null);

  /**
   * 
   */
  const [modules,setModules] = useState([]);
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getMembership", { wpSessions: userInfo.data.userInfo.wpsessions });

        setWebhooks(data.statusList);
      } catch (err) {
        console.log(err)
      }
    }

    async function getModules() {

      try {

      

        const { data } = await api.post("/api/getModules");

        setModules(data.modules);
      } catch (err) {
        console.log(err)
      }
    }
    getModules();
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'description', headerName: 'Descripción', flex: 1, editable: false,
    },
    {
      field: 'maxWhats', headerName: 'Whatsapp permitidos', flex: 1, editable: false,
    },   
    {
      field: 'maxBooks', headerName: 'Libros permitidos', flex: 1, editable: false,
    },   
    {
      field: 'maxUsers', headerName: 'Usuarios permitidos', flex: 1, editable: false,
    },    
    {
      field: 'massiveCall', headerName: 'Llamadas masivas', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {

              <Button
               color={params.row.massiveCall == 1 ? 'success': 'error'}
                onClick={() => { setCurrentMembership(params.row); }}
              >
                {params.row.massiveCall == 1 ? 'Activo': 'Inactivo'}
              </Button>
            }

          </>
        )
      }
    },
    {
      field: 'massiveMessages', headerName: 'Mensajes masivos', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {

              <Button
               color={params.row.massiveMessages == 1 ? 'success': 'error'}
                onClick={() => { setCurrentMembership(params.row); }}
              >
                {params.row.massiveMessages == 1 ? 'Activo': 'Inactivo'}
              </Button>
            }

          </>
        )
      }
    },
    {
      field: 'test', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {
              revisaPermisos(['Modificar planes']) ?
                <Button onClick={() => { setCurrentMembership(params.row); setEstadoModal(true); }}>
                  Editar
                </Button> :
                "Sin acciones"
            }

          </>
        )
      }
    },

  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteMembership", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Paquete eliminado', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar los paquete', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar paquete', {
          variant: 'error',
        });
      }
    });


  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addWebhook = async (newWebhook) => {


    let request = {

      membershipObj: newWebhook

    }

    if (newWebhook?.id) {
      request.id = newWebhook.id;
    }

    if(newWebhook?.Modules){
      request.modules= newWebhook.Modules
    }
    
    try {

      const { data } = await api.post("/api/createMembership", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se creó el paquete', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al crear el paquete', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear el paquete', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Estatus" />

      <Typography variant="h3" gutterBottom display="inline">
        Paquetes
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Admin
        </Link>
        <Link >Paquetes</Link>
        {
          revisaPermisos(['Agregar planes']) &&
          <Button variant='outlined' onClick={() => { setCurrentMembership(null); setEstadoModal(!estadoModal); }} >Nuevo paquete</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={webhooks}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar planes'])}
            onDelete={(data) => { deleteSession(data); }}
          />
          {
            revisaPermisos(['Agregar planes']) &&
            <>

            


              <MembershipModal
                title={`${currentMembership?.id ? 'Editar': 'Nuevo'} paquete`}
                open={estadoModal}
                setOpen={setEstadoModal}
                theme={theme}
                onSave={addWebhook}
                currentMembership={currentMembership}
                modules={modules}
              />
            </>
          }

        </Grid>
      </Grid>


    </>
  );
}
