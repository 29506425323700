import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';

import Modal from '../../components/Modal/Modal';
import { useState } from 'react';
import frame from '../../components/imagenes/frame.png'
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import QRCode from "react-qr-code";
import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet-async';
import WhatsappModal from '../../components/WhatsappModal';
import Loading from '../../components/Loading';
import { width } from '@mui/system';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Sesiones() {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [sessions, _setSessions] = useState([]);

  /**
   * 
   */
  const sessionsRef = React.useRef(sessions);

  const setSessions = data => {
    sessionsRef.current = data;
    _setSessions(data);
  }

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentSessionName, setCurrentSessionName] = useState('');

  /**
 * 
 */
  const [currentSession, setCurrentSession] = useState(null);
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Etiquetas
   */
  const [tags, setTags] = useState([]);

  /*
  * Estatus
  */
  const [status, setStatus] = useState([]);

  const [openMigModal, setOpenMigModal] = useState(false);

  const [selectedSession, setSelectedSession] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [books, setBooks] = useState([]);
  const [selectedBookID, setSelectedBookID] = useState(null)
  const [selectedSheet, setSelectedSheet] = useState(null)

  const [sheets, setSheets] = useState([])

  const [showBooks, setShowBooks] = useState(false)

  React.useEffect(() => {
    async function getSheets() {
      if (selectedBookID) {
        try {
          const { data } = await api.post('/api/getPages', { bookID: selectedBookID });
          setSheets(data.pages)
        } catch (err) {

        }
      }
    }
    getSheets();
  }, [selectedBookID])

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    setIsLoading(true);
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);


        const { data } = await api.post("api/getSessions", { includeTags: true });
        // console.log(data)
        setSessions(data.sessions);
      } catch (err) {
        console.log(err)
      }
    }

    async function getTags() {

      try {
        const { data } = await api.post("/api/getTags");

        setTags(data.tags);
      } catch (err) {
        console.log(err)
      }
    }
    async function getStatus() {

      try {
        const { data } = await api.post("/api/getStatus");
        setStatus(data.statusList);
      } catch (err) {
        console.log(err)
      }
    }

    async function getBooks() {
      try {

        const { data } = await api.get("/api/books");
        setBooks(data.books);

      } catch (err) {
        console.log(err);
      }

    }
    getBooks();
    //setWebhooks(data.statusList);
    getSessions();
    getTags();
    getStatus();
    setIsLoading(false);
  }, [reload]);


  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }
  /**
    * Funcion para actualizar las sesiones con el socket
    */
  const onGetUpdate = React.useCallback((sessionAuth, qr) => {

    let selectedMSession = {};
    // console.log("sesion");
    //  console.log(sessionAuth.data)
    for (let i = 0; i < sessionsRef.current.length; i++) {
      //console.log(`${sessionsRef.current[i].sessionAuth} == ${sessionAuth}`)
      if (sessionsRef.current[i].sessionAuth == sessionAuth) {
        selectedMSession = sessionsRef.current[i];

        // console.log(messageObj);

        selectedMSession.qr = qr;
        selectedMSession.status = "QR";

        // console.log(selectedMsgMassive)
        let newArr = [...sessionsRef.current];
        newArr[i] = selectedMSession
        //newArr = [selectedMsgMassive].concat(newArr)

        // chatListRef.splice(i, 1);
        setSessions([
          ...newArr,
        ]);
        //console.log(`${sessionAuth} ${currentSessionName}`)
        if (sessionAuth == currentSessionName) {
          console.log("Cambia qr");
          setCurrentQr(qr)
        }
        // console.log(selectedChat);
        break;
      }
    }
  }, [sessionsRef, currentSessionName])
  React.useEffect(() => {

    if (socket && sessions.length) {

      if (sessions.length > 0) {

        //socket.emit('sessionStatus', sessionsUser);

        sessions.map((session, key) => {
          //console.log("escuchando"+session);
          socket.on('qr' + session.sessionAuth, (data) => {
            // console.log(data)
            onGetUpdate(data.data.wp, data.data.qr);
          })
          socket.on('waiting' + session.sessionAuth, (data) => {
            // console.log(data)
            setReload(!reload)
          })
          socket.on('fail' + session.sessionAuth, (data) => {
            // console.log(data)
            setReload(!reload)
          })
          socket.on('diconnected' + session.sessionAuth, (data) => {
            // console.log(data)
            setReload(!reload)
          })
          socket.on('chatAdded' + session.sessionAuth, (data) => {
            // console.log(data)
            setReload(!reload)
          })
          socket.on('authenticated' + session.sessionAuth, (data) => {
            // console.log("autenticado")
            setReload(!reload)
          })
        })
      }

    }
  }, [socket, sessions, reload, onGetUpdate])

  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
      editable: true,

    },
    {
      field: 'book', headerName: 'Libro', width: 100, renderCell: (params) => {

        return (
          params.row.Group?.Book?.name || 'Sin libro'
        )
      }
    },
    {
      field: 'sheet', headerName: 'Hoja', width: 100, renderCell: (params) => {

        return (
          params.row.Group?.name || 'Sin hoja'
        )
      }
    },
    {
      field: 'actions', headerName: 'Editar', width: 100, renderCell: (params) => {

        return (
          <Button onClick={() => {
            setCurrentSession(params.row);
            setOpen(true);
          }}>Editar</Button>
        )
      }
    },
    {
      field: 'delete', headerName: 'Eliminar', width: 100, renderCell: (params) => {

        return (
          <Button color="error" onClick={() => {
            deleteSession(params.row);
          }}>Eliminar</Button>
        )
      }
    },
    //{ field: 'opc', headerName: 'Opciones', flex: 1, editable: false, renderCell: (params) => { return (<DeleteIcon></DeleteIcon>) } },
    {
      field: 'qr', headerName: 'Estatus', width: 200, editable: false, renderCell: (params) => {
        // console.log(params.row.qr); 
        return (
          <BotonQr
            setCurrentSessionName={setCurrentSessionName}
            sessionName={params.row.sessionAuth}
            qr={params.row.qr}
            setCurrentQr={setCurrentQr}
            text={params.row.status}
            estadoModal={estadoModal}
            reconnectSession={reconnectSession}
            setEstadoModal={setEstadoModal} />
        )
      }
    },

    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 200,
      editable: true,

    },
    {
      field: 'color',
      headerName: 'Color asignado',
      width: 200,
      editable: true,
      renderCell: (params) => {
        return <Chip sx={{ backgroundColor: params.row.color }} label="Color"></Chip>
      }
    },
    {
      field: 'Tags',
      headerName: 'Etiquetas',
      width: 680,
      editable: true,
      renderCell: (params) => {
        return params.row?.Tags.map((tag) => {
          return <Chip sx={{ backgroundColor: tag.color }} label={tag.name}></Chip>
        })
      }

    },
    {
      field: 'migration2', headerName: 'Estatus de mirgación', width: 150, renderCell: (params) => {

        return params.row.migrated == 1 ? 'Migrado' : 'Sin migrar'

      }
    },
    {
      field: 'migration', headerName: 'Migrar contactos', width: 200, renderCell: (params) => {

        return (
          <Button onClick={() => {
            setCurrentSession(params.row);
            setOpenMigModal(true);

          }}>{params.row.migrated == 1 ? 'Migrar contactos nuevamente' : 'Migrar contactos'}</Button>
        )
      }
    },
    /*{
      field: 'totalChats',
      headerName: 'Contactos cargados',
      flex: 1,
      editable: false,
      renderCell: (params) => {


        if (params.row.loadedChats) {
          return (
            <>
              {`${params.row.loadedChats} / ${params.row.totalChats}`}
            </>
          )
        } else {
          return (
            <>
              0 / 0
            </>
          )
        }



      }
    },
    {
      field: 'welcomeMessage',
      headerName: 'Mensaje Bienvenida',
      flex: 1,
      editable: true,
    },*/

  ];

  const deleteSession = async (session) => {
    setIsLoading(true);
    if (!window.confirm(`¿Desea eliminar el whatsapp ${session.name}?`)) {
      setIsLoading(false);
      return false;
    }
    try {
      const ss = session;
      if (session.migrated != 1) {
        if (!window.confirm(`El whatsapp ${session.name} no ha sido migrado por lo que toda la información de contactos se perderá ¿Desea eliminarlo?`)) {
          setIsLoading(false);
          return;
        }
      }
      const { data } = await api.put("/api/deleteSession", { id: ss.id });

      // console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Sesion eliminada', {
          variant: 'success',
        });
        setReload(!reload);

      } else {

        enqueueSnackbar('Ocurrió un error al eliminar el whatsapp', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al eliminar el whatsapp', {
        variant: 'error',
      });
    }
    setIsLoading(false);
    setReload(!reload)

  }
  /**
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSessions = async (dataSessions) => {
    setIsLoading(true);
    if (!window.confirm(`¿Desea eliminar las sesiones seleccionadas?`)) {
      setIsLoading(false);
      return false;
    }
    for (let i = 0; i < dataSessions.length; i++) {
      try {
        const ss = dataSessions[i];
        if (ss.migrated != 0) {
          if (!window.confirm(`El whatsapp ${dataSessions.name} no ha sido migrado por lo que toda la información de contactos se perderá ¿Desea eliminarlo?`)) {
            continue
          }
        }
        const { data } = await api.put("/api/deleteSession", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Sesion eliminada', {
            variant: 'success',
          });
          setReload(!reload);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar el whatsapp', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar el whatsapp', {
          variant: 'error',
        });
      }
    }
    ;
    setIsLoading(false);
    setReload(!reload)

  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addSession = async (name, color, id, tags, status, sheetID, newSheet, bookName,sheetName ) => {
    //event.preventDefault()

    let sesionN = name;

    if (!name) {
      enqueueSnackbar('Debe seleccionar un nombre', {
        variant: 'warning',
      });
      return
    }
    if (!color) {
      enqueueSnackbar('Debe seleccionar un color', {
        variant: 'warning',
      });
      return
    }

    if (newSheet) {
      if (!bookName) {
        enqueueSnackbar('Debe agregar el nombre del libro', {
          variant: 'warning',
        });
        return
      }
      if (!sheetName) {
        enqueueSnackbar('Debe agregar el nombre de la hoja', {
          variant: 'warning',
        });
        return
      }
    } else {
      if (!sheetID) {
        enqueueSnackbar('Debe seleccionar una hoja y un libro', {
          variant: 'warning',
        });
        return
      }
    }

    let request = {
      id: 0,
      wpsessionObj: {
        name: sesionN,
        color: color
      },
      tags: tags,
      status: status,
      sheetID,
      bookName,sheetName
    }

    if (id) {
      request.id = id;
    } else {
      request.wpsessionObj.id = 1;
      request.wpsessionObj.sessionAuth = sesionN;
    }

    try {

      const { data } = await api.post("/api/createSession", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se agregó el whatsapp', {
          variant: 'success',
        });
        setReload(true);

      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al agregar el whatsapp', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al agregar el whatsapp', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  /**
   * Reconecta una sesion
   * @param {*} sessionName 
   * @returns 
   */
  const reconnectSession = async (sessionName) => {


    try {

      const { data } = await api.post('/api/reconectSession', { sessionAuth: sessionName });

      // console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Reconectando sesion', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Error al reconectar', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al reconectar el whatsapp', {
        variant: 'error',
      });
    }
  }

  const migrateChats = async () => {

    if (!selectedSession) {
      enqueueSnackbar('Debe seleccionar un whatsapp', {
        variant: 'warning',
      });
      return;
    }

    if (showBooks && !selectedSheet) {
      enqueueSnackbar('Debe seleccionar una hoja', {
        variant: 'warning',
      });
      return;
    }
    try {
      const { data } = await api.post('/api/session/migrate', {
        oldSession: currentSession.id,
        newSession: selectedSession,
        sheetID: showBooks ? selectedSheet : null
      });

      if (data.status == "Success") {

        enqueueSnackbar(`Se migraron ${data.count} chats`, {
          variant: 'success',
        });
        setOpenMigModal(false)
        setReload(!reload)
      } else {

        enqueueSnackbar('Ocurrió un error al migrar los chats', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al migrar los chats', {
        variant: 'error',
      });
    }
  }
  return (
    <>
      <Loading open={isLoading} />
      <Helmet title="Whatsapp" />

      <Typography variant="h3" gutterBottom display="inline">
        Whatsapp
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Redes sociales
        </Link>
        <Link >Whatsapp</Link>
        {revisaPermisos(['Agregar Usuarios']) &&

          <Button
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            Nuevo whatsapp
          </Button>

        }
      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={sessions}
            checkboxSelection
            //showDelete={revisaPermisos(['Eliminar Whatsapp'])}
            onDelete={(data) => { deleteSessions(data); }}
            columnVisibilityModel={{
              actions: revisaPermisos(['Modificar Whatsapp']),
              delete: revisaPermisos(['Eliminar Whatsapp'])
            }}
          />

        </Grid>
      </Grid>

      <WhatsappModal
        books={books}
        tags={tags}
        status={status}
        currentSession={currentSession}
        onSave={addSession}
        open={open}
        setOpen={setOpen}
        title={"Whatsapp"}
        sessions={sessions}
      />
      <Modal
        estado={estadoModal}
        cambiarEstado={setEstadoModal}
      >
        <Contenido>
          <QRCode value={currentQr} />
        </Contenido>
      </Modal>

      <Dialog
        disableEnforceFocus
        onClose={() => setOpenMigModal(false)}
        aria-labelledby="customized-dialog-title"
        open={openMigModal}
        width="md"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setOpenMigModal(false)}>
          Migrar contactos
        </DialogTitle >

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="outlined-select-currency"
                select
                label="Whatsapp"
                value={selectedSession}
                onChange={(e) => setSelectedSession(e.target.value)}
                variant="filled"
                color="primary"
                fullWidth

              // helperText="Elige un sistema desde el cual se enviaran los mensajes"
              >
                {sessions.map((sessionWP) => {

                  if (sessionWP.id != currentSession?.id) {
                    return (
                      <MenuItem key={sessionWP.id} value={sessionWP.id}>
                        {sessionWP.name}
                      </MenuItem>
                    )
                  }


                })}
              </TextField>

            </Grid>
            <FormControlLabel
              control={<Checkbox
                checked={showBooks}
                onChange={(e) => setShowBooks(e.target.checked)} />}
              label={"Seleccionar hoja"}
            />
            {
              showBooks &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  select
                  name="book"
                  label="Libro"
                  value={selectedBookID}
                  onChange={e => setSelectedBookID(e.target.value)}
                  fullWidth
                >
                  {books?.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            {
              showBooks &&

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  select
                  disabled={selectedBookID == null}
                  name="sheet"
                  label="Hoja"
                  value={selectedSheet}
                  onChange={e => setSelectedSheet(e.target.value)}
                  fullWidth
                >
                  {sheets?.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenMigModal(false)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={migrateChats}>
            Migrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}



const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;