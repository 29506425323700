import React, { useEffect, useState } from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Helmet } from 'react-helmet-async';
import { Alert, Breadcrumbs, Button, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import './styles.css'
import DayCard from '../../components/DayCard/Index';
import api from '../../services/api';
import { CopyAll } from '@mui/icons-material';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import CalendarFormModal from '../../components/CalendarFormModal';


const CalendarConfig = () => {

  const days = [
    { dayNumber: 0, name: 'Domingo' },
    { dayNumber: 1, name: 'Lunes' },
    { dayNumber: 2, name: 'Martes' },
    { dayNumber: 3, name: 'Miércoles' },
    { dayNumber: 4, name: 'Jueves' },
    { dayNumber: 5, name: 'Viernes' },
    { dayNumber: 6, name: 'Sábado' }
  ];

  const [calendarDays, setCalendarDays] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [whatsapps, setWhatsapps] = useState([]);

  const [userData, setUserData] = useState({})
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(0);
  const [formText, setFormtext] = useState("");

  const [open, setOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  const [reload, setReload] = useState(false);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    async function getForms() {
      try {
        const { data } = await api.get('/api/calendarform');
        setForms(data.calendarForms)
      } catch (err) {

      }
    }
    getForms();
  }, [reload])

  useEffect(() => {
    async function getCalendarDays() {
      try {
        const { data } = await api.get('/api/calendar')
        setCalendarDays(data.days)
      } catch (err) {
        alert("Ocurrió un error al consultar los días")
      }
    }
    async function getaccounts() {

      try {

        const { data } = await api.post("/api/getAccounts", {});

        setAccounts(data.googleAccounts);
      } catch (err) {
        console.log(err)
      }
    }
    async function getWhatsapps() {

      try {

        const { data } = await api.post("/api/v2/getUserSessions", {});

        setWhatsapps(data.sessions);
      } catch (err) {
        console.log(err)
      }
    }
    async function getUserData() {

      try {

        const { data } = await api.get("/api/v2/me");

        setUserData(data);

        if (data) {
          if (data.defautlAccountId) {
            setSelectedAccount(data.defautlAccountId)
          }

          if (data.defaultWhatsapp) {
            setSelectedWhatsapp(data.defaultWhatsapp)
          }

          if (data.calendarText) {
            setFormtext(data.calendarText)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    getaccounts();
    getCalendarDays();
    getUserData();
    getWhatsapps();
  }, [])

  const getDayInfo = (dayNumber) => {
    const day = calendarDays.find((day) => day.day == dayNumber);

    if (day) {
      return day;
    }

    return {
      start: new Date(),
      end: new Date(),
    }
  }


  const saveConfiguration = async () => {
    try {
      await api.post("/api/v2/me", {
        defautlAccountId: selectedAccount,
        defaultWhatsapp: selectedWhatsapp,
        calendarText: formText
      });

      alert("Datos actualizados")
    } catch (err) {
      alert("Ocurrió un error al actualizar la información")
    }
  }

  const getCalendarUrl = () => {
    const urlBase = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

    return `${urlBase}/calendarios/${userData.id}`;

  }

  const getCalendarFormUrl = (formID) => {
    const urlBase = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

    return `${urlBase}/calendarios/${userData.id}/${formID}`;

  }

  const columns = [
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'description', headerName: 'Descripción', flex: 1, editable: false,
    },
    {
      field: 'id', headerName: 'URL', flex: 4, editable: false,
      renderCell: (params) => {

        return (
          <Grid container item md={12} xs={12}>
            <Grid item md={10} xs={10}>
              <TextField
                fullWidth
                value={getCalendarFormUrl(params.row.id)}
                label="URL del calendario"
                name="info"
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} xs={1}>
              <Button onClick={
                () => {
                  navigator.clipboard.writeText(getCalendarFormUrl(params.row.id)).then(() => {
                    alert("Se copió la url")
                  }).catch(err => {
                    console.error('Error al copiar el texto: ', err);
                  });
                }
              } style={{ height: '100%' }} variant="outlined" startIcon={<CopyAll />}></Button>
            </Grid>
            <Grid item md={1} xs={1}>
              <Button style={{ height: '100%' }} variant="contained" onClick={() => window.open(getCalendarFormUrl(params.row.id), '_blank')}>Ir</Button>
            </Grid>


          </Grid>
        )
      }
    },
    {
      field: 'test', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {
              <Button onClick={() => { setSelectedForm(params.row); setOpen(true); }}>
                Editar
              </Button>
            }

          </>
        )
      }
    },
  ]
  return (
    <>
      <Helmet title="Etiquetas" />

      <Typography variant="h3" gutterBottom display="inline">
        Calendario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Configuración
        </Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container
        spacing={2}
        sx={{ height: '80vh' }}


      >
        <Grid container item md={6} xs={12}>

          {/*<Grid item md={12} xs={12}>
            <Typography fontSize={20}>Configuración del calendario</Typography>
          </Grid>
          <Grid container item md={12} xs={12}>
            <Grid item md={10} xs={10}>
              <TextField
                fullWidth
                value={getCalendarUrl()}
                label="URL del calendario"
                name="info"
                variant="outlined"
              />
            </Grid>
            <Grid item md={1} xs={1}>
              <Button style={{height:'40%'}} variant="outlined" startIcon={<CopyAll/>}></Button>
            </Grid>
            <Grid item md={1} xs={1}>
              <Button style={{height:'40%'}} variant="contained" onClick={()=>window.open(getCalendarUrl(), '_blank')}>Ir</Button>
            </Grid>


          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              value={formText}
              label="Texto del formulario"
              name="info"
              variant="outlined"
              onChange={(e) => { setFormtext(e.target.value) }}
              multiline
              rows={6}

            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              select
              label="Correo para calendario"
              value={selectedAccount}
              onChange={(e) => {
                setSelectedAccount(e.target.value)
              }}
              variant="outlined"
              color="primary"
              fullWidth
            >
              {accounts?.map((account) => {

                return (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>)
              })}
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              select
              label="Cuenta de whatsapp para el envio de recordatorios"
              value={selectedWhatsapp}
              onChange={(e) => {
                setSelectedWhatsapp(e.target.value)
              }}
              variant="outlined"
              color="primary"
              fullWidth
            >
              {whatsapps?.map((whatsapp) => {

                return (
                  <MenuItem key={whatsapp.id} value={whatsapp.id}>
                    {whatsapp.name}
                  </MenuItem>)
              })}
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <Button onClick={saveConfiguration} fullWidth variant="contained">Guardar</Button>
            </Grid>*/}
          <div style={{ width: '50vw' }}>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Typography fontSize={20}>Formularios</Typography> /
              <Button onClick={() => { setOpen(true); setSelectedForm(null) }} variant="contained">Agregar formulario</Button>

            </Breadcrumbs>

            <TablaUsuarios
              columns={columns}
              rows={forms}
              checkboxSelection
              showDelete={true}
              onDelete={(data) => { }}
            />
          </div>
        </Grid>
        <Grid container item md={6} xs={12}>
          {
            days.map((day) => {

              const dayInfo = getDayInfo(day.dayNumber);

              return (
                <Grid
                  key={day.dayNumber}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  align="center"

                >
                  <DayCard
                    id={dayInfo.id}
                    dayNumber={day.dayNumber}
                    letter={day.name[0]}
                    name={day.name}
                    selected={dayInfo?.id ? true : false}
                    end={dayInfo.end}
                    start={dayInfo.start}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>

      <CalendarFormModal
        currentCalendarForm={selectedForm}
        open={open}
        setOpen={setOpen}
        accounts={accounts}
        whatsapps={whatsapps}
        reload={() => { setReload(!reload) }}
      />
    </>
  );
};

export default CalendarConfig;
