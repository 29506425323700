import * as React from 'react';

import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import api from '../../services/api';
import { Breadcrumbs, Button, Chip, Divider, FormControlLabel, Grid, Link, Switch, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet-async';
import EstateModal from '../../components/EstateModal';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Estates() {

  const [open,setOpen] = useState(false);
  /**
   * 
  */
  const [estates, setEstates] = useState([]);


  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [wpSessions, setWpSessions] = useState([]);

  /**
   * 
   */
  const [templates, setTemplates] = useState([]);

  const [currentEstate,setCurrentEstate] = useState({});

  /**

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setOpen(false);
    async function getEstates() {

      try {

        const { data } = await api.post("api/getEstate");

        setEstates(data.estates);
      } catch (err) {
        console.log(err)
      }
    }

    async function getTemplates() {
      try {
        const { data } = await api.post("/api/getTemplate");

        setTemplates(data.messageTemplates);

      } catch (err) {
        console.log(err)
      }
    }

    async function getWpSessions() {
      try {
        const userInfo = await api.post("/api/getPermissions");
        const { data } = await api.post("api/getSessions", { wpSessions: userInfo.data.userInfo.wpsessions });

        setWpSessions(data.sessions);
      } catch (err) {
        console.log(err)
      }
    }


    getEstates();
    getTemplates();
    getWpSessions();

  }, [reload]);


  /**
   * Columnas de la tabla
   */
  const columns = [
    { field: 'name', headerName: 'Propiedad',flex: 1},
    { field: 'description', headerName: 'Descripción',flex: 1 },
    {
      field: 'templateID', headerName: 'Plantilla de respuesta', flex: 1, editable: false, renderCell: (params) => {

        return (
          <>{params.row?.MessageTemplate?.name ? params.row.MessageTemplate.name: 'Sin plantilla'}</>
        )
      }
    },
    {
      field: 'wpSessionID', headerName: 'Whatsapp', flex: 1, editable: false, renderCell: (params) => {

        return (
          <>{params.row?.WpSession?.name ? params.row.WpSession.name: 'Sin whatsapp'}</>
        )
      }
    },
    {
      field: 'actions', headerName: 'Mensaje automático', flex: 1, editable: false, renderCell: (params) => {

        return (
          <Button onClick={()=>{
            setCurrentEstate(params.row)
            setOpen(true);
          }}>Asignar</Button>
        )
      }
    },




  ];




  /**
   * Asigna una plantilla y una sessión para poder responder
   * @param {*} data 
   */
  const asingMessageResponse = async (estateObj) => {


    let request = {
      id: estateObj.id,
      estateObj: estateObj

    }


    try {

      const { data } = await api.post("/api/addEstate", request);

      if (data.status == "Success") {

        enqueueSnackbar('Se asignó correctamente el mensaje de respuesta', {
          variant: 'success',
        });
        setReload(true);

      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al asignar el mensaje', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error asignar el mensaje', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Inmobiliaria" />

      <Typography variant="h3" gutterBottom display="inline">
        Propiedades
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Inmobiliaria
        </Link>
        <Link >Propiedades</Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          {
            estates.length == 0 ?
              <div>Aún no se han dado de alta propiedades para esta cuenta</div>
              :
              <TablaUsuarios
                columns={columns}
                rows={estates}
                checkboxSelection
                showDelete={false}
                onDelete={(data) => { }}
                columnVisibilityModel={{
                  actions: true
                }}
              />
          }


        </Grid>
      </Grid>

          <EstateModal
            title={"Agregar respuesta automática"}
            templates={templates}
            whatsapps={wpSessions}
            open={open}
            setOpen={setOpen}
            currentEstate={currentEstate}
            onSave={asingMessageResponse}
          />
    </>
  );
}
