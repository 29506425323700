import { Breadcrumbs, Divider, Grid, Link, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import api from "../../services/api";
import { useSnackbar } from "notistack";


const options = [
    { label: '1 semana', value: 1 },
    { label: '2 semanas', value: 2 },
    { label: '3 semanas', value: 3 },
    { label: '4 semanas', value: 4 },
    { label: '5 semanas', value: 5 },
    { label: '6 semanas', value: 6 },
    { label: '7 semanas', value: 7 },

]

const FRECUENCIA_ELIMINACION_BITACORA = 'FRECUENCIA_ELIMINACION_BITACORA';
const FRECUENCIA_ELIMINACION_ARCHIVOS_CHATS = 'FRECUENCIA_ELIMINACION_ARCHIVOS_CHATS';
/**
 * Componente para el manejo del panel de horas de eliminación
 */
const Panel = () => {

    const [historyFrecuency, setHistoryFrecuency] = useState(1);
    const [filesFrecuency, setFilesFrecuency] = useState(1);

    /**
    * Notifiaciones
    */
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getProperties() {
            try {
                const { data } = await api.get('/api/properties');

                if (data.status == "Success") {
                    data.properties.map((property) => {
                        if (property.name == FRECUENCIA_ELIMINACION_BITACORA) {
                            setHistoryFrecuency(property.value);
                        }
                        if (property.name == FRECUENCIA_ELIMINACION_ARCHIVOS_CHATS) {
                            setFilesFrecuency(property.value);
                        }
                    })
                }
            } catch (err) {
                console.log(err)
            }
        }

        getProperties();
    }, [])
    /**
     * Actualiza la propiedad
     * @param {*} name 
     * @param {*} value 
     */
    const updateProperty = async (name, value) => {
        try {
            const request = {
                name,
                value
            }

            await api.post('/api/properties', request);

            enqueueSnackbar('Se actualizó la propiedad', {
                variant: 'success',
            });


        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <Helmet title="Horarios" />

            <Typography variant="h3" gutterBottom display="inline">
                Horarios
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Administración
                </Link>
                <Link >Horarios</Link>
            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Grid
                container
                spacing={5}
                sx={{ height: '80vh' }}
            >
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Frecuencia de borrado de archivos de chat"
                        value={filesFrecuency}
                        onChange={(e) => { setFilesFrecuency(e.target.value); updateProperty(FRECUENCIA_ELIMINACION_ARCHIVOS_CHATS, e.target.value); }}
                        variant="filled"
                        color="primary"
                        fullWidth
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>


                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Frecuencia de borrado de registros en la bitacora"
                        value={historyFrecuency}
                        onChange={(e) => { setHistoryFrecuency(e.target.value); updateProperty(FRECUENCIA_ELIMINACION_BITACORA, e.target.value); }}
                        variant="filled"
                        color="primary"
                        fullWidth
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </>

    )
}

export default Panel;