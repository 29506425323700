import { useEffect, useState } from "react";
import api from "../../../services/api";
import TablaUsuarios from "../../TablaUsuarios/TablaUsuarios";
import QuotePreview from "../../NewQuote/QuotePreview";
import { Button, Grid } from "@mui/material";
import { CopyAll } from "@mui/icons-material";

export default function QuotesList({ clientID }) {

    const [quotes, setQuotes] = useState([])
    const [selectedQuote, setSelectedQuote] = useState(null)

    async function getQuotes(clientId) {
        try {
            const { data } = await api.get(`/api/quotes/${clientId}`)
            setQuotes(data.quotes)
        } catch (err) {

        }
    }
    useEffect(() => {
        getQuotes(clientID);
    }, [clientID])

    const getQuoteUrl = (id) => {
        const urlBase = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
        return `${urlBase}/quotes/${id}`;

    }

    const columns = [

        {
            field: 'name', headerName: 'Nombre', flex: 1, editable: false,
        },
        {
            field: 'company', headerName: 'Empresa', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    params.row?.Company?.name || 'Sin compañia'
                )
            }
        },
        {
            field: 'address', headerName: 'Dirección', flex: 1, editable: false,
        },
        {
            field: 'url', headerName: 'Link', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    <Button onClick={
                        () => {
                            navigator.clipboard.writeText(getQuoteUrl(params.row.id)).then(() => {
                                alert("Se copió la url")
                            }).catch(err => {
                                console.error('Error al copiar el texto: ', err);
                            });
                        }
                    } style={{ height: '100%' }} variant="outlined" startIcon={<CopyAll />}></Button>
                );
            }
        },
        {
            field: 'ver', headerName: 'Acciones', flex: 1, editable: false,
            renderCell: (params) => {

                return (
                    <Button onClick={() => {
                        setSelectedQuote(params.row);

                    }}>Ver cotización</Button>
                )
            }
        },
    ]
    return (
        <Grid contanier


            sx={{ height: selectedQuote ? '70vh' : '30vh', width: '50vw' }}>
            {
                selectedQuote &&
                <Grid item style={{ textAlign: 'right' }}><Button onClick={() => setSelectedQuote(null)} variant="outlined">X</Button> </Grid>
            }
            {
                selectedQuote ?
                    <QuotePreview
                        externalCompany={selectedQuote.Company}
                        externalQuote={selectedQuote}
                        products={selectedQuote.QuoteItems}
                    />
                    :
                    <TablaUsuarios
                        columns={columns}
                        rows={quotes}
                        checkboxSelection
                        showDelete={false}
                        onDelete={(data) => { }}
                        columnVisibilityModel={{
                            edit: false
                        }}
                    />
            }

        </Grid>

    )
}