
/**MUI */
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';


import api from '../../services/api';
import Papa from "papaparse";
import { Add, CheckBox, ConstructionOutlined, Delete, Done, Event, HighlightOff, Send, Share, ShareLocation } from '@mui/icons-material';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { Breadcrumbs, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Link, TextField, Typography, withStyles } from '@mui/material';
import DatabaseTable from '../../components/DatabaseTable';
import CallModal from '../../components/CallModal';
import { useSnackbar } from 'notistack';
import { SocketContext } from '../../services/socket';
import MassiveMsgModal from '../../components/MassiveMsgModal';
import DatabaseCard from '../../components/DatabaseCard';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import GroupMergeModal from '../../components/GroupMergeModal';
import GroupStructureModal from '../../components/GroupStructureModal';
import ShareModal from '../../components/ShareModal';


import { Helmet } from 'react-helmet-async';
import AddBookModal from '../../components/AddBookModal';
import { getContrastColor } from '../../utils/generalFunctions';
import ClientInfoModal from '../../components/ClientInfoModal';
/**
 * Carga la vista para subir contactos a la base de datos
 * @param {*} param0 
 */
const LoadContacts = ({ theme }) => {

    const [columns, setColumns] = useState([]);

    const [rows, setRows] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [groups, setGroups] = useState([])


    const [db, setDb] = useState(false)

    const [advisers, _setAdvisers] = useState([]);


    const [currentSheet, setCurrentSheet] = useState(null);
    /**
     * 
     */
    const advisersRef = useRef(advisers);

    const setAdvisers = data => {
        advisersRef.current = data;
        _setAdvisers(data);
    }

    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [openCallModal, setOpenCallModal] = useState(false);

    const [openMsgModal, setOpenMsgModal] = useState(false);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);

    const [userId, setUserId] = useState();
    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [currentGroupId, setCurrentGroupId] = useState(null);

    const [showSelectBox, setShowSelectBox] = useState(true);

    const [currentDatabases, setCurrentDatabases] = useState([]);

    const [reload, setReload] = useState(true);

    const [openMergeModal, setOpenMergeModal] = useState(false);

    const [openStructureModal, setOpenStructureModal] = useState(false);

    const [openShareModal, setOpenSharemodal] = useState(false);

    const [currentGroup, setCurrentGroup] = useState({});

    const [showAddClientButton, setShowAddClientButton] = useState(false);

    const [addClientToDB, setAddClientToDB] = useState(false);

    const [showAddBookModal, setShowAddBookModal] = useState(false);

    const [searchText, setSearchText] = useState('');

    const [searchPhone, setSearchPhone] = useState(false);

    const [openShareSheet, setOpenShareSheet] = useState(false);
    const [currentSheetInfo, setCurrentSheetInfo] = useState({});

    const [currentBook, setCurrentBook] = useState(null);

    const [openAddClientModal, setOpenAddClientModal] = useState(false);

    const [status, setStatus] = useState([]);

    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        async function getGroups() {
            try {
                if (searchText) {
                    const { data } = await api.post("/api/getGroups", { filter: { id: userId, name: searchText }, searchPhone });

                    if (data.status == "Success") {
                        setGroups(data.books);
                    } else {

                    }

                } else {
                    const { data } = await api.post("/api/getGroups", { filter: { id: userId, name: searchText } });

                    if (data.status == "Success") {
                        setGroups(data.books);
                    } else {

                    }

                }

            } catch (err) {
                console.log(err);
            }

        }

        if (userId) {
            getGroups();
        }

    }, [reload, userId, searchText, searchPhone])

    useEffect(() => {


        async function getPermissions() {

            try {

                const userInfo = await api.post("/api/getPermissions");
                setPermissions(userInfo.data.userInfo.permissions)
                setUserId(userInfo.data.userInfo.id);

            } catch (err) {
                console.log(err);
            }


        }
        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");
                setAdvisers(data);


            } catch (err) {
                console.log(err);
            }


        }

        async function getTemplates() {

            try {

                const { data } = await api.post("api/getTemplate");

                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err);
            }


        }
        async function getStatus() {


            const { data } = await api.post("api/getStatus");

            if (data.statusList.length > 0) {

                setStatus(data.statusList);

            }

        }

        async function getProducts() {


            const { data } = await api.post("api/getProducts");

            if (data?.products) {

                setProducts(data.products);

            }

        }

        async function getTagsService() {

            try {


                try {

                    const { data } = await api.post("api/getTags");

                    if (data.tags) {
                        setTags(data.tags);
                    }

                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }
        getStatus()
        getProducts()
        getTagsService();
        getPermissions();
        getAdvisers();
        getTemplates();


    }, [])

    const onGetUpdate = useCallback((email, status) => {

        let tempAdvisersList = [...advisersRef.current];

        for (let i = 0; i < tempAdvisersList.length; i++) {
            if (tempAdvisersList[i].email == email) {
                tempAdvisersList[i].status = status;
            }
        }

        setAdvisers(tempAdvisersList)
    }, [advisersRef])

    useEffect(() => {

        if (socket) {


            socket.on(`adviserChange`, (adviserObj) => {
                // console.log("Se conectó un asesor");
                onGetUpdate(adviserObj.email, adviserObj.status);
            });


        }

    }, [socket, onGetUpdate])

    /**
     * Pasa los permisos a todas las vistas
     */
    const [permissions, setPermissions] = useState([]);

    const [groupName, setGroupName] = useState('');

    const [currentInputValue, setCurrentInputvalue] = useState(`1,5500000001,Nombre 1,Apellido 1,Estatus,México,CDMX,Hombre,Profesional
2,5500000002,Nombre 2,Apellido 2,Estatus,México,CDMX,Hombre,Profesional
3,5500000003,Nombre 3,Apellido 3,Estatus,México,CDMX,Hombre,Profesional`);


    const [showInput, setShowInput] = useState(false);


    const createGroup = async (name) => {

        if (!name) {
            if (!groupName) {
                enqueueSnackbar(`El nombre es requerido`, {
                    variant: 'error',
                });
                return false;
            }

        }


        let clientList = [...rows];

        clientList.map((cl) => {
            delete cl.id;
        })


        try {
            const { data } = await api.post('api/Creategroup', {
                group: {
                    name: name ? name : groupName,
                    owner: userId,
                },

                clients: clientList,
                advisers: selectedAdvisers
            });

            if (data.status == "Success") {
                enqueueSnackbar(`Se creó el libro exitosamente`, {
                    variant: 'success',
                });
            } else {
                if (data?.code) {
                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('Ocurrio un error al crear el libro', {
                        variant: 'error',
                    });
                }
            }


            setRows([]);
            setDb(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al crear el libro`, {
                variant: 'error',
            });
        }


    }

    const callMassive = async (currentTemplate, name, phone) => {

        let clientList = [...rows];
        setGroupName(name);

        clientList.map((cl) => {
            delete cl.id;
        })

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...clientList];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
            kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {



                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    delay: 1000,
                    from: phone
                }

                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} mensajes`, {
                    variant: 'success',
                });

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpenCallModal(false);

        createGroup(name);
    }

    /**
     * Recupera un arreglo con lso telefonos
     * @returns 
     */
    const getPhones = () => {
        let phones = [];

        rows.map((cl) => {
            phones.push(cl.phone);
        })

        return phones;
    }

    const deleteDatabases = async () => {

        try {
            const { data } = await api.post('/api/deleteGroup', { books: currentDatabases });

            if (data.status == "Success") {
                enqueueSnackbar(`Se eliminaron ${currentDatabases.length} ${currentDatabases.length > 1 ? 'libros' : 'libros'}`, {
                    variant: 'success',
                });
                setReload(!reload);
            } else {
                throw `Error al borrar datos datos ${data}`
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al eliminar ${currentDatabases.length > 1 ? 'los libros' : 'el libro'}`, {
                variant: 'error',
            });
        }

    }

    const mergeDatabases = async (newName) => {

        if (newName == '') {
            enqueueSnackbar(`Debe ingresar un nuevo nombre para el libro`, {
                variant: 'warning',
            });

            return;
        }

        if (!window.confirm(`Se combinaran ${currentDatabases.length} libros ¿Desea continuar?`)) {
            setOpenMergeModal(false);
            return;
        }
        try {
            const { data } = await api.post('/api/mergeBooks', { books: currentDatabases, newName: newName });

            if (data.status == "Success") {
                enqueueSnackbar(`Se combinaron ${currentDatabases.length} libros`, {
                    variant: 'success',
                });
                setOpenMergeModal(false);
                setReload(!reload);
            } else {
                throw `Error al combinar datos datos ${data}`
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al combinar ${currentDatabases.length > 1 ? ' los libros' : ' el libro'}`, {
                variant: 'error',
            });
        }

    }


    const updateGroupStructure = async (structure) => {
        try {
            let request = {
                id: currentGroup.id,
                group: {
                    columnsStructure: structure
                }
            }
            const { data } = await api.post('/api/updateGroup', request)

            if (data.status == "Success") {
                enqueueSnackbar(`Se guardo el cambio de foram correcta`, {
                    variant: 'success',
                });
                setOpenStructureModal(false);
                setReload(!reload);
            } else {
                throw "Error al definir la estructura"
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al definir la estructura de los clientes`, {
                variant: 'error',
            });
        }
    }


    const shareDatabase = (usersID) => {


        currentDatabases.map(async (database) => {
            try {

                let request = {
                    userIDs: usersID,
                    groupID: database.id
                }
                const { data } = await api.post('/api/shareGroup', request)

                if (data.status == "Success") {
                    enqueueSnackbar(`Se compartio la base de datos ${database.name}`, {
                        variant: 'success',
                    });

                } else {
                    console.log(data);
                    throw "Error al compartir la base de datos"
                }

                setOpenSharemodal(false);
                setReload(!reload);
            } catch (err) {
                console.log(err);
                enqueueSnackbar(`Error al compartir la base de datos`, {
                    variant: 'error',
                });
            }
        })

    }


    const shareSheet = async (usersID, removedUserIDs) => {



        try {

            let request = {
                userIDs: usersID,
                groupID: currentSheet,
                bookID: currentGroupId,
                removedUserIDs
            }
            const { data } = await api.post('/api/group/share', request)

            if (data.status == "Success") {
                enqueueSnackbar(`Se compartio la hoja correctamente`, {
                    variant: 'success',
                });

            } else {
                console.log(data);
                throw "Error al compartir la hoja"
            }

            setOpenShareSheet(false);
            setReload(!reload);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al compartir la hoja`, {
                variant: 'error',
            });
        }


    }

    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, list = permissions, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }


    /**
     * Cambia el nombre de una hoja
     */
    const changeSheetName = async () => {
        try {

            let name = prompt("Ingresa el nuevo nombre de la hoja");

            if (!name) {
                return;
            }
            const request = {
                id: currentSheet,
                group: { name: name }
            }
            const { data } = await api.post('/api/updatePage', request);

            if (data.status == "Success") {
                enqueueSnackbar(`Se actualizó el nombre de la hoja`, {
                    variant: 'success',
                });

                setReload(!reload);
            } else {
                console.log(data);
                throw "Error al cambiar nombre"
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al cambiar nombre`, {
                variant: 'error',
            });
        }
    }

    const getSharedUsers = () => {
        let users = [];
        currentDatabases.map((sheet) => {

            if (sheet.Users.length > 0) {
                users = [...users, ...sheet.Users.map((user) => user.id)]
            }
        })

        return users;
    }
    async function updateBookName(id, book) {
        try {
            await api.post('/api/updateGroup', { id, book })
            enqueueSnackbar(`Libro actualizado`, {
                variant: 'success',
            });
            setReload(!reload)
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Ocurrió un error al editar el nombre`, {
                variant: 'error',
            });
        }
    }
    /**
   * Vista para la edición de un campo simple
   * @param {*} props 
   * @returns 
   */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        const [newValue, setNewValue] = useState(value)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            setNewValue(target.value)
        }

        /**
         * Cuando presiona el botón se guarda el nuevo valor
         */
        const handleCommit = async (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();



            /**
             * Para los objetos dentro de la membresía excluyendo el id
             * se actualiza sin problema
             */

            props.row[props.field] = newValue;

            let request = { [props.field]: newValue };

            await updateBookName(props.row.id, request);


        };



        return (
            <Grid container sx={{ minWidth: '200px;' }} >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        defaultValue={value}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} >
                    <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                        <Done color="success" />
                    </IconButton>
                </Grid>
            </Grid>
        )

    }
    return (
        <>


            <Helmet title="Libros" />

            <Typography variant="h3" gutterBottom display="inline">
                Libros
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Clientes
                </Link>
                <Link >Libros</Link>
                {
                    !showAddClientButton && revisaPermisos(['Crear libros']) &&
                    <Button
                        variant="outlined"
                        startIcon={<Add color="success" />}
                        onClick={() => { setShowAddBookModal(true); }}
                    >
                        Agregar libro
                    </Button>
                }
                {
                    showAddClientButton &&
                    <Button
                        variant="outlined"
                        startIcon={<Add color="success" />}
                        onClick={() => { setAddClientToDB(true); }}
                    >
                        Agregar clientes por lote
                    </Button>
                }
                {
                    showAddClientButton &&
                    <Button
                        variant="outlined"
                        startIcon={<Add color="success" />}
                        onClick={() => { setOpenAddClientModal(true); }}
                    >
                        Agregar cliente individual
                    </Button>
                }

                {
                    showAddClientButton && <Grid
                        justifyContent="flex-end"
                        container
                        style={{
                            width: '80vw'
                        }}
                    >
                        <Button
                            variant="outlined"
                            startIcon={<EditIcon color="primary" />}
                            onClick={() => { changeSheetName() }}

                        >
                            Cambiar nombre
                        </Button>
                        {revisaPermisos(['Compartir hojas']) && <Button
                            variant="outlined"
                            startIcon={<Share color="primary" />}
                            onClick={() => { setOpenShareSheet(true) }}
                        >
                            Compartir
                        </Button>
                        }
                    </Grid>
                }

            </Breadcrumbs>

            <br />
            <Divider my={6} />


            {!db &&
                <>
                    <br />
                    {currentGroupId &&
                        <div style={{ position: 'relative', width: '100%' }}>
                            <IconButton

                                style={{
                                    position: 'absolute',
                                    right: '2%'
                                }} onClick={() => { setCurrentGroupId(null); setShowAddClientButton(false); setAddClientToDB(false) }}>
                                <HighlightOff
                                    color="primary"
                                    style={{ transform: 'scale(1.8)' }}
                                />
                            </IconButton>
                        </div>
                    }

                    <Grid
                        align="center"
                        justifyContent="center"
                        container
                        sx={{ height: '80vh', margin: 0 }}

                    >


                        {!currentGroupId && <Grid
                            item
                            sm={10}
                            md={10}
                            lg={10}

                        >
                            <Grid container align="right"
                                justifyContent="right">

                                {!currentGroupId && showSelectBox &&
                                    < Grid
                                        item
                                        sm={10}

                                    >


                                        {
                                            currentDatabases.length > 0 && revisaPermisos(['Eliminar libros']) &&
                                            <Button color="error" variant="contained" startIcon={<Delete />} onClick={() => {
                                                if (window.confirm(`Se eliminaran ${currentDatabases.length} bases de datos ¿Desea continuar?`)) {
                                                    deleteDatabases();
                                                }

                                            }}>
                                                Eliminar
                                            </Button>
                                        }
                                        {
                                            currentDatabases.length > 0 && revisaPermisos(['Compartir libros']) &&
                                            <Button color="secondary" variant="contained" startIcon={<Share />} onClick={() => {

                                                setOpenSharemodal(true);
                                            }}>
                                                Compartir
                                            </Button>
                                        }
                                        {
                                            currentDatabases.length > 1 && revisaPermisos(['Combinar base de datos']) &&
                                            <Button color={"success"} variant="contained" endIcon={<CachedIcon />} onClick={() => {
                                                setOpenMergeModal(true);
                                            }}>
                                                Combinar
                                            </Button>
                                        }
                                    </Grid>
                                }
                                <Grid
                                    item
                                    sm={10}
                                    md={10}
                                    lg={10}

                                >
                                    <TextField
                                        onChange={e => setSearchText(e.target.value)}
                                        label="Buscar"
                                        value={searchText}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                    md={2}
                                    lg={2}

                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={searchPhone} onChange={(e) => setSearchPhone(e.target.checked)} name="phone" />
                                            }
                                            label="Buscar teléfono"
                                        />
                                    </FormGroup>
                                </Grid>


                            </Grid>


                            <TablaUsuarios


                                rows={groups}
                                columns={[

                                    {
                                        headerName: 'Nombre', field: 'name', hide: false, flex: 1,
                                        editable: true,
                                        renderEditCell: (props) => <SimpleEditField {...props} />
                                    },
                                    {
                                        headerName: 'Whatsapp', field: 'WpSession', hide: false, flex: 1,
                                        renderCell: (params) => {

                                            let showWhatsapps = false;
                                            const whatsapps = params.row?.Groups?.map((sheet) => {
                                                if (sheet.WpSession) {
                                                    const whatsapp = sheet.WpSession;
                                                    showWhatsapps = true;
                                                    return <Chip label={whatsapp.name} sx={{ color: getContrastColor(whatsapp?.color ? whatsapp.color : '#000000'), background: whatsapp?.color ? whatsapp.color : 'black' }} />
                                                }
                                            });
                                            
                                            if (showWhatsapps) {
                                                return whatsapps;
                                            } else {
                                                return <Chip label={"Sin whatsapp"} sx={{ color: getContrastColor('#000000'), background: 'black' }} />
                                            }
                                        }

                                    },
                                    {
                                        headerName: 'Número de clientes', field: 'membersCount', hide: false, flex: 1

                                    },
                                    {
                                        headerName: 'Propietario', field: 'owner', hide: false, flex: 1,
                                        renderCell: (props) => {


                                            return <>
                                                {
                                                    props.row.Users?.map((user) => {
                                                        if (user.id == props.row?.owner) {
                                                            return <Chip label={user.firstName} />
                                                        }

                                                    })
                                                }
                                            </>
                                        }
                                    },
                                    {
                                        headerName: 'Compartido con', field: 'Users', hide: false, flex: 1,
                                        renderCell: (props) => {


                                            return <>
                                                {
                                                    props.row.Users?.map((user) => {
                                                        if (user.id != props.row.owner) {
                                                            return <Chip label={user.firstName} />
                                                        }

                                                    })
                                                }
                                            </>
                                        }
                                    },

                                    {
                                        headerName: 'Opciones', field: 'test', hide: false, flex: 1,
                                        renderCell: (props) => {


                                            return <>
                                                <Button fullWidth onClick={() => { setCurrentGroupId(props.row.id); setCurrentBook(props.row) }} size="small">Abrir libro</Button>
                                                {
                                                    props.row.sheetId && props.row.type != 'Formulario' && <Button fullWidth onClick={() => {
                                                        setCurrentGroup(props.row);
                                                        setOpenStructureModal(true);
                                                    }} size="small">Configurar Columnas</Button>
                                                }
                                            </>
                                        }
                                    },


                                ]}
                                checkboxSelection={showSelectBox}
                                onDelete={(rowsSelected) => {


                                }}
                                onsSelectionChange={(databases) => {

                                    setCurrentDatabases(databases)

                                }}


                            />
                        </Grid>
                        }


                        {/*!currentGroupId &&
                            <Grid
                                item
                                sm={currentGroupId ? 3 : 10}
                                align="center"


                                container
                                sx={{ height: '100%', margin: 0 }}
                                spacing={4}
                            >

                                {
                                    groups.map((g) => (
                                        <Grid item sx={currentGroupId ? 12 : 6} md={currentGroupId ? 12 : 3} lg={currentGroupId ? 12 : 3}>
                                            <DatabaseCard name={g.name} count={g.membersCount} onOpenDatabase={() => { setCurrentGroupId(g.id); }} />
                                        </Grid>

                                    ))
                                }
                            </Grid>*/
                        }
                        {(currentGroupId) &&
                            <Grid
                                item
                                sm={10}

                            >
                                <DatabaseTable
                                    bookOwner={currentBook.owner}
                                    setCurrentSheetObj={setCurrentSheetInfo}
                                    reloadSheet={reload}
                                    theme={theme}
                                    templateList={templates}
                                    advisers={advisers}
                                    showAdviserButton
                                    setCurrentSheet={setCurrentSheet}
                                    groupID={currentGroupId}
                                    setShowAddClientButton={setShowAddClientButton}
                                    addClientToDB={addClientToDB}
                                    setAddClientToDB={setAddClientToDB}
                                />
                            </Grid>}




                    </Grid>


                    <br></br>


                </>}


            <GroupMergeModal
                open={openMergeModal}
                setOpen={setOpenMergeModal}
                mergeDatabases={mergeDatabases}
            />
            <MassiveMsgModal
                theme={theme}
                forwardingId={0}
                open={openMsgModal}
                setOpen={setOpenMsgModal}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={createGroup}
            />
            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templates}
                onSave={(currentTemplate, name, phone) => { callMassive(currentTemplate, name, phone) }}
            />

            <GroupStructureModal
                open={openStructureModal}
                setOpen={setOpenStructureModal}
                structure={currentGroup?.columnsStructure ? JSON.parse(currentGroup.columnsStructure) : {}}
                onSave={updateGroupStructure}
            />

            <ShareModal
                selectedUsers={getSharedUsers()}

                open={openShareModal}
                setOpen={setOpenSharemodal}
                theme={theme}
                title={"Compartir libros"}
                users={advisers}
                onSave={shareDatabase}
            />

            <ShareModal
                selectedUsers={currentSheetInfo?.Users?.map((user) => user.id)}
                open={openShareSheet}
                setOpen={setOpenShareSheet}
                theme={theme}
                title={"Compartir hoja"}
                users={advisers}
                onSave={shareSheet}
            />

            <AddBookModal
                onSave={() => { setShowAddBookModal(false); setReload(!reload) }}
                setOpen={setShowAddBookModal}
                title={"Agregar libro"}
                open={showAddBookModal}
            />
            <ClientInfoModal
                chat={{}}
                open={openAddClientModal}
                setOpen={setOpenAddClientModal}
                reload={() => { setReload(!reload); setOpenAddClientModal(false) }}
                tags={tags}
                status={status}
                products={products}
                newClient
                sheetID={currentSheetInfo.id}
            />
        </>
    )
}

export default LoadContacts;