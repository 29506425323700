import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../services/socket";
import api from "../../services/api";
import { Helmet } from "react-helmet-async";
import { Breadcrumbs, Button, Checkbox, FormControlLabel, Grid, Link, MenuItem, TextField, Typography } from "@mui/material";
import ContactsModal from "../../components/ContactsModal";
import MultiSelect from "../../components/MultiSelect";
import ClientsModal from "../../components/ClientsModal";

export default function MassiveEmail({ isInModal, externalClientList = [], callBack, account, externalSubject }) {


    const [id, setId] = useState(0);

    const [clientList, setClientList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [groupName, setGroupName] = useState('');

    const [subject, setSubject] = useState('');

    const [idTemplate, setIdTemplate] = useState(null);

    const [selectedAccount, setSelectedAccount] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const [currentTags, setCurrentTags] = useState([]);
    const [emailList, setEmailList] = useState([]);

    /**
    * Variable para agregar los contacto
    */
    const [contacts, setContacts] = useState([]);

    const [openContacts, setOpenContacts] = useState(false);

    /**
    * Variable de texto para los conatcos
    * 
    */
    const [contactsString, setContactsString] = useState('');
    /**
    * Socket para el envio masivo
    */
    const socket = useContext(SocketContext);

    const [tags, setTags] = useState([]);


    const [isHtml, setIsHtml] = useState(false);


    /**
    * Variable para los id de archivos
    */
    const [fileIds, setFileIds] = useState([]);

    /** Lista de archivos */
    const [fileList, setFileList] = useState([]);


    const [html, setHtml] = useState(null);

    const [clients, setClients] = useState([]);


    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);


    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    async function getFileList() {
        try {
            const { data } = await api.post('/api/getLogFile');



            setFileList(data.files)
        } catch (err) {
            console.log(err);
        }
    }
    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }

        async function getTemplates() {
            try {
                const { data } = await api.post("/api/getTemplate");
                setTemplates(data.messageTemplates);

            } catch (err) {
                console.log(err);
            }
        }
        getFileList();
        getTemplates();
        getaccounts();
    }, [])

    useEffect(() => {
        setIsLoading(false)
        setId(null)
        setIdTemplate(null);
        setGroupName('');
        setSubject('');
        setSelectedAccount(null);
        async function getTags() {
            /**
                   * Consulta llas etiquetas
                   */
            const dataTags = await api.post("/api/v2/getUserTags", {});

            if (dataTags.data.status == "Success") {
                setTags(dataTags.data.tags)
                //console.log(dataTags.data.tags);
            }
        }
        getTags()
        async function getProducts() {
            try {

                const { data } = await api.post("/api/getProducts");



                if (data.status == "Success") {

                    setProducts(data.products);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los productos", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });

            }
        }
        getProducts();
        async function getstatus() {
            try {
                const { data } = await api.post("/api/getStatus");


                if (data.status == "Success") {

                    setStatus(data.statusList);

                } else {
                    enqueueSnackbar("Ocurrio un error al consultar los estatus", {
                        variant: 'error',
                    });
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar("Ocurrio un error", {
                    variant: 'error',
                });
            }
        }
        getstatus()
    }, [])


    useEffect(() => {
        setClientList(emailList)
    }, [emailList])


    const sendMassiveMail = async () => {

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        let clientPhones = clients.map((client) => client.email) || [];
        const constactsArr = [...emailList, ...externalClientList, ...clientPhones];
        console.log(emailList)
        console.log(clientPhones)
        console.log(externalClientList)
        console.log(constactsArr)
        if (!groupName && !externalSubject) {
            enqueueSnackbar(`El nombre del grupo es requerido`, {
                variant: 'warning',
            });
            return false;
        }

        if (!subject && !externalSubject) {
            enqueueSnackbar(`El asunto del correo es requerido`, {
                variant: 'warning',
            });
            return false;
        }

        if (!idTemplate && !isHtml) {
            enqueueSnackbar(`La plantilla es requerida`, {
                variant: 'warning',
            });
            return false;
        }

        if (!selectedAccount && !account) {
            enqueueSnackbar(`La cuenta de google es requerida`, {
                variant: 'warning',
            });
            return false;
        }

        if (constactsArr.length === 0 && !isInModal) {
            enqueueSnackbar(`Debe agregar al menos un correo`, {
                variant: 'warning',
            });
            return false;
        }
        setIsLoading(true);
        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
    
        const msgObj = {
            name: groupName || externalSubject,
            forwardingId: 0,
            wpId: selectedAccount?.name || account?.name,
            isMedia: true,
            templateId: idTemplate,
            body: '',
            kind: 'Email',
            subject: subject || externalSubject,
            accountID: account?.id || selectedAccount?.id,
            mediaUrl: '',
            twilioPhone: '',
            delay: 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);

            //  console.log(data);
            if (data.status == "Success") {

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} correos`, {
                    variant: 'success',
                });

                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    clients,
                    delay: 1000,
                    tags: currentTags,
                    files: fileIds,
                    html,
                    product: selectedProducts,
                    status: selectedStatus,
                }

                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveMail", socketObj);
                if (isInModal) {
                    callBack();
                    return false;
                }
                await timeout(2000);
                window.location.href = '/bitacora/correos';
            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar los correos`, {
                variant: 'error',
            });
        }

        setIsLoading(false);
    }

    /**
     * Guarda los contactos y los convierte en un array
     * @param {*} contactsString 
     */
    const convertContacts = (contactsString) => {

        setContactsString(contactsString);
        setContacts(contactsString.split('\n'));
        setEmailList(contactsString.split('\n'));
    }


    return (
        <>
            {!isInModal && <Helmet title="Envio Masivo" />}

            <Typography variant="h3" gutterBottom display="inline">
                {isInModal ? "Envio de correo" : "Envio masivo de correos"}
            </Typography>

            {!isInModal && <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Correos
                </Link>
                <Button variant='outlined' onClick={() => setOpenContacts(true)} >Agregar correos</Button>

            </Breadcrumbs>}

            <Grid container sx={{ height: isInModal ? '100%' : '85vh', maxWidth: isInModal ? '100%' : '100vw' }} direction="row"
                alignItems="center"
                justifyContent="center">
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    align="center"
                    sx={{ height: '90%' }}
                >
                    <Grid container spacing={2}>

                        {!externalSubject && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label="Nombre del grupo de envio"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                            />

                        </Grid>}

                        {!externalSubject &&<Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label="Asunto del correo"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                            />

                        </Grid>}



                        {!account &&<Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Cuenta de Google"
                                value={selectedAccount}
                                onChange={(e) => setSelectedAccount(e.target.value)}
                                variant="filled"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                            >
                                {accounts?.map((account) => (
                                    <MenuItem key={account.id} value={account}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>}
                        <Grid item container>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox checked={isHtml} onChange={(e) => setIsHtml(e.target.checked)} />}
                                    label="Agregar html"
                                />
                            </Grid>
                        </Grid>

                        {
                            !isHtml &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField

                                    select
                                    label="Plantilla"
                                    value={idTemplate}
                                    onChange={(e) => setIdTemplate(e.target.value)}
                                    variant="filled"
                                    color="primary"
                                    fullWidth
                                    disabled={isLoading}
                                >
                                    {templates?.map((template) => {

                                        if (template.type != "Llamada") {
                                            return (
                                                <MenuItem key={template.id} value={template.id}>
                                                    {template.name}
                                                </MenuItem>
                                            )
                                        }

                                    })}
                                </TextField>
                            </Grid>
                        }
                        {
                            isHtml &&
                            <TextField
                                onChange={(e) => setHtml(e.target.value)}
                                maxRows={10}
                                minRows={10}

                                multiline
                                fullWidth
                            />
                        }
                        {isHtml && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                            <MultiSelect

                                title="Archivos"
                                list={fileList}
                                onChange={(data) => { setFileIds(data) }}
                                initSelect={fileIds}

                            />
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                            <MultiSelect

                                title="Etiquetas"
                                fullWidth
                                list={tags}
                                onChange={(data) => { setCurrentTags(data) }}
                                initSelect={currentTags}

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField

                                select
                                label="Producto"
                                value={selectedProducts}
                                onChange={(e) => setSelectedProducts(e.target.value)}
                                fullWidth
                                color="primary"

                            >
                                {products?.map((product) => (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField

                                select
                                label="Estatus"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                fullWidth
                                color="primary"
                                fullWidth>
                                {status?.map((s) => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button onClick={sendMassiveMail} variant="contained" color="success">Enviar</Button>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid >
            <ClientsModal
                open={openContacts}
                setOpen={setOpenContacts}
                onChange={(newClients) => setClients(newClients)}
                required={['email']}
            />
            {/*<ContactsModal
                title="Correos"
                contacts={contactsString}
                setContacts={convertContacts}
                open={openContacts}
                    setOpen={setOpenContacts} />*/}
        </>
    )
}