
/**MUI */
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';


import api from '../../services/api';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import MultiSelect from '../../components/MultiSelect';

import './index.css'
import DatabaseTable from '../../components/DatabaseTable';
import { useSnackbar } from 'notistack';



/**
 * Compara la lista de permisos con los permisos asignados
 */
const revisaPermisos = (allow, list, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
        for (let j = 0; j < list.length; j++) {

            if (allow[i] == list[j]) {
                count++;
            }
        }
    }

    return count >= minMatch;
}

/**
 * Carga la vista para subir contactos a la base de datos
 * @param {*} param0 
 */
const Atention = ({ theme }) => {


    const [clients, setClients] = useState([])

    const [tags, setTags] = useState([])

    const [currentIndex, setCurrentIndex] = useState(0);

    const [currentAdviserId, setCurrentAdviserId] = useState(0);

    const [currentClient, setCurrentClient] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        city: '',
        gender: '',
        level: '',
        email: '',
        status: '',
        comments: '',
        tags: [],
        Tags: [],
        Comments: []
    })


    const [isTableViewEnabled, setIsTableViewEnabled] = useState(false);

    const [templates, setTemplates] = useState([]);

    const [reload, setReload] = useState(false);

    const [advisers, setAdvisers] = useState([]);

    const [selectedAdvisers, setSelectedAdvisers] = useState(0);

    const [status, setStatus] = useState([]);

    const [products, setProducts] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const [reloadTags, setReloadTags] = useState(false);

    useEffect(() => {
        async function getPermissions() {

            try {

                const userInfo = await api.post("/api/getPermissions");

                setPermissions(userInfo.data.userInfo.permissions)
                console.log(userInfo.data);

                try {

                    const { data } = await api.post("api/getClients", { adviser: userInfo.data.userInfo.id });
                    setCurrentAdviserId(userInfo.data.userInfo.id);
                    if (data.clients.length > 0) {
                        setClients(data.clients);


                        let currC = { ...data.clients[0], comments: getComments(data.clients[0].Comments), tags: getTags(data.clients[0].Tags) }

                        setCurrentClient(currC);
                    }



                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }


        async function getTemplates() {

            try {

                const { data } = await api.post("api/getTemplate");
                //console.log(userInfo.data);

                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err);
            }


        }

        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");

                setAdvisers(data);


            } catch (err) {
                console.log(err);
            }



        }

        async function getStatus() {


            const { data } = await api.post("api/getStatus");

            if (data.statusList.length > 0) {

                setStatus(data.statusList);

            }

        }

        async function getProducts() {


            const { data } = await api.post("api/getProducts");

            if (data.products.length > 0) {

                setProducts(data.products);

            }
            
        }
        getStatus();
        getAdvisers();
        getPermissions();
        getTemplates();
        getProducts();

    }, [])

    useEffect(() => {
        async function getTagsService() {

            try {


                try {

                    const { data } = await api.post("api/getTags");

                    if (data.tags) {
                        setTags(data.tags);
                    }

                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }

        getTagsService();
    }, [reloadTags])


    useEffect(() => {
        async function getPermissions() {

            try {

                const userInfo = await api.post("/api/getPermissions");

                setPermissions(userInfo.data.userInfo.permissions)

                try {

                    const { data } = await api.post("api/getClients", { adviser: selectedAdvisers == 0 ? userInfo.data.userInfo.id : selectedAdvisers });


                    setCurrentAdviserId(userInfo.data.userInfo.id);
                    if (data.clients.length > 0) {
                        setClients(data.clients);

                    } else {
                        setClients([]);
                    }

                    let tempIndex = currentIndex;
                    if (tempIndex < 0) {
                        tempIndex = 0;
                    }
                    if (tempIndex >= data.clients.length) {
                        if (data.clients.length >= 0) {
                            setCurrentIndex(data.clients.length - 1);
                            let currC = { ...data.clients[data.clients.length - 1], comments: getComments(data.clients[data.clients.length - 1].Comments), tags: getTags(data.clients[data.clients.length - 1].Tags) }

                            setCurrentClient(currC);
                        }

                    } else {
                        let currC = { ...data.clients[tempIndex], comments: getComments(data.clients[tempIndex].Comments), tags: getTags(data.clients[tempIndex].Tags) }
                        setCurrentIndex(tempIndex);
                        setCurrentClient(currC);
                    }

                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }

        getPermissions();

    }, [selectedAdvisers, reload])


    /**
     * Pasa los permisos a todas las vistas
     */
    const [permissions, setPermissions] = useState([]);

    const handleChange = (event) => {
        setCurrentClient({
            ...currentClient,
            [event.target.name]: event.target.value
        })

    }

    const saveUser = async () => {

        let tempC = [...clients]

        tempC[currentIndex] = currentClient;



        let comments = currentClient.comments.split(/\r?\n/);

        let tags = currentClient.tags;


        try {
            console.log({ client: currentClient, id: currentClient.id, comments: comments, tags: tags });
            await api.post('/api/updateClient', { client: currentClient, id: currentClient.id, comments: comments, tags: tags })

            alert("Se guardó el cliente");
        } catch (err) {
            console.log(err);
        }

        setClients(tempC);
    }

    const changeClient = async (direction) => {

        let currentPos = currentIndex + direction;

        setCurrentIndex(currentPos);

        let tempClients = [...clients];

        let tempClient = tempClients[currentPos];
        tempClient.comments = getComments(tempClient.Comments);
        tempClient.tags = getTags(tempClient.Tags);

        tempClients[currentPos] = tempClient;

        setCurrentClient(tempClient);
        setClients(tempClients);

        setReload(!reload);
    }


    const getComments = (commentsArr) => {
        let comments = '';

        commentsArr.map((cm) => {
            comments += cm.comments + '\n';
        })

        return comments;

    }

    const getTags = (tagsArr) => {
        let tags = [];

        tagsArr.map((tg) => {
            tags.push(tg.id);
        })

        return tags;

    }

    const addTag = async () => {
        try {

            const name = window.prompt("Ingresa el nombre de la categoría");

            if (!name) {
                return;
            }
            const { data } = await api.post("/api/createTag", {
                tagObj: { name: name }
            });


            if (data.status == "Success") {

                enqueueSnackbar('Se creó la categoría', {
                    variant: 'success',
                });
                setReload(true);

            } else {

                enqueueSnackbar('Ocurrió un error al crear la categoría', {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar('Ocurrió un error al crear la categoría', {
                variant: 'error',
            });
        }

        setReloadTags(!reloadTags);
    }

    return (
        <>



            <Grid
                container
                spacing={0}

                alignItems="center"
                justifyContent="center"
                sx={{ height: '90vh', padding: '30px' }}

            >

                <Grid item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}>
                    <Grid container align="center"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid sm={6}>
                            <Button fullWidth variant="contained" onClick={() => { setIsTableViewEnabled(false) }}>
                                Vista individual
                            </Button>
                        </Grid>
                        <Grid sm={6}>
                            <Button fullWidth variant="contained" onClick={() => { setIsTableViewEnabled(true) }}>
                                Vista de tabla
                            </Button>
                        </Grid>
                    </Grid>



                </Grid>

                {clients.length != 0 && !isTableViewEnabled &&
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        align="center"
                        alignItems="center"
                        justifyContent="center"
                        container
                        sx={{ height: '100%' }}
                    >

                        <Grid

                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            align="center"
                            alignItems="center"
                            justifyContent="center"
                            container

                            sx={{ height: '100%', margin: 0, padding: '10px' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>
                                {revisaPermisos(['Administrar asesores'], permissions) && <FormControl fullWidth>
                                    <InputLabel id="gender-id">Asesor</InputLabel>
                                    <Select
                                        labelId="gender-id"
                                        id="gender"
                                        name="gender"
                                        fullWidth
                                        value={selectedAdvisers}
                                        label="Asesores asignados"
                                        onChange={(e) => { setSelectedAdvisers(e.target.value) }}
                                    >
                                        {
                                            advisers.map((adviser, key) => (
                                                <MenuItem key={key} value={adviser.id}>{adviser.firstName}</MenuItem>
                                            ))
                                        }


                                    </Select>
                                </FormControl>}
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>
                                <span sx={{}}>{currentIndex + 1}/{clients.length}</span>


                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <TextField
                                    id="firstName"
                                    name="firstName"

                                    label="Nombre"
                                    onChange={handleChange}
                                    value={currentClient.firstName}
                                    fullWidth
                                >
                                </TextField>

                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <TextField
                                    id="lastName"
                                    name="lastName"

                                    label="Apellido"
                                    onChange={handleChange}
                                    value={currentClient.lastName}
                                    fullWidth
                                >
                                </TextField>

                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <TextField
                                    id="phone"
                                    name="phone"

                                    label="Teléfono"
                                    onChange={handleChange}
                                    value={currentClient.phone}
                                    fullWidth
                                >
                                </TextField>

                            </Grid>
                            {/*<Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <FormControl fullWidth>
                                    <InputLabel id="gender-id">Género</InputLabel>
                                    <Select
                                        labelId="gender-id"
                                        id="gender"
                                        name="gender"
                                        fullWidth
                                        value={currentClient.gender}
                                        label="Género"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"Hombre"}>Hombre</MenuItem>
                                        <MenuItem value={"Mujer"}>Mujer</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <TextField
                                    id="country"
                                    name="country"

                                    label="País"
                                    onChange={handleChange}
                                    value={currentClient.country}
                                    fullWidth
                                >
                                </TextField>

                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <TextField
                                    id="city"
                                    name="city"

                                    label="Ciudad"
                                    onChange={handleChange}
                                    value={currentClient.city}
                                    fullWidth
                                >
                                </TextField>

                                    </Grid>
                                     <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="level-id">Nivel académico</InputLabel>
                                    <Select
                                        labelId="level-id"
                                        id="level"
                                        name="level"
                                        fullWidth
                                        value={currentClient.level}
                                        label="Nivel académico"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"Profesional"}>Profesional</MenuItem>
                                        <MenuItem value={"Sin profesión"}>Sin profesión</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>
                                    */}
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}>

                                <FormControl fullWidth>
                                    <InputLabel id="status-id">Estatus</InputLabel>
                                    <Select
                                        labelId="status-id"
                                        id="status"
                                        name="status"
                                        fullWidth
                                        value={currentClient.status}
                                        label="Estatus"
                                        onChange={handleChange}
                                    >
                                        {
                                            status.map((st) => {
                                                return (
                                                    <MenuItem value={st.id}>{st.name}</MenuItem>
                                                )
                                            })
                                        }


                                    </Select>
                                </FormControl>

                            </Grid>


                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <Button fullWidth onClick={addTag}>Agregar categoria</Button>
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <FormControl fullWidth>
                                    <InputLabel id="product-id">Producto</InputLabel>
                                    <Select
                                        labelId="product-id"
                                        id="product"
                                        name="product"
                                        fullWidth
                                        value={currentClient.product}
                                        label="Producto"
                                        onChange={handleChange}
                                    >
                                        {
                                            products.map((product) => {
                                                return (
                                                    <MenuItem value={product.id}>{product.name}</MenuItem>
                                                )
                                            })
                                        }


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <MultiSelect
                                    title="Categorías"
                                    theme={theme}
                                    list={tags}
                                    onChange={(e) => { handleChange({ target: { name: 'tags', value: e } }) }}
                                    initSelect={currentClient.tags}
                                />
                            </Grid>

                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <TextField
                                    id="comments"
                                    name="comments"
                                    multiline
                                    label="Comentarios"
                                    onChange={handleChange}
                                    value={currentClient.comments}
                                    rows={4}
                                    sx={{ maxHeight: '20%' }}
                                    fullWidth
                                >
                                </TextField>

                            </Grid>

                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <Button onClick={saveUser} variant="contained" fullWidth color="success">
                                    Guardar
                                </Button>

                            </Grid>

                        </Grid>



                    </Grid>}

                {clients.length == 0 && !isTableViewEnabled &&
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        align="center"
                        alignItems="center"
                        justifyContent="center"
                        container
                        sx={{ height: '100%' }}
                    >

                        <Grid

                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            align="center"

                            justifyContent="center"
                            container

                            sx={{ height: '100%', margin: 0, padding: '10px' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>
                                {revisaPermisos(['Administrar asesores'], permissions) && <FormControl fullWidth>
                                    <InputLabel id="gender-id">Asesor</InputLabel>
                                    <Select
                                        labelId="gender-id"
                                        id="gender"
                                        name="gender"
                                        fullWidth
                                        value={selectedAdvisers}
                                        label="Asesores asignados"
                                        onChange={(e) => { setSelectedAdvisers(e.target.value) }}
                                    >
                                        {
                                            advisers.map((adviser, key) => (
                                                <MenuItem key={key} value={adviser.id}>{adviser.firstName}</MenuItem>
                                            ))
                                        }


                                    </Select>
                                </FormControl>}
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}>

                                <span sx={{ color: 'black' }}>No hay clientes asignados</span>
                            </Grid>
                        </Grid>
                    </Grid>}

                {
                    isTableViewEnabled &&
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        align="center"
                        alignItems="center"
                        justifyContent="center"
                        container
                        sx={{ height: '100%' }}
                    >

                        <Grid

                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            align="center"
                            alignItems="center"
                            justifyContent="center"
                            container

                            sx={{ height: '100%', margin: 0, padding: '10px' }}
                        >

                            <DatabaseTable
                                advisers={[]}
                                theme={theme}
                                templateList={templates}
                                currentAdviserID={selectedAdvisers == 0 ? currentAdviserId : selectedAdvisers}
                                loadAllClients
                                showAdviserButton={false}
                            />
                        </Grid>
                    </Grid>
                }




            </Grid>
            {
                clients.length != 0 && !isTableViewEnabled && currentIndex != clients.length - 1 &&
                <Button
                    onClick={() => { changeClient(1); }}
                    sx={{ position: 'absolute', top: '45%', right: '10%' }}>
                    <ArrowCircleRightIcon sx={{ fontSize: '65px' }} />
                </Button>
            }
            {
                clients.length != 0 && !isTableViewEnabled && currentIndex != 0 &&
                <Button
                    onClick={() => { changeClient(-1); }}
                    sx={{ position: 'absolute', top: '45%', left: '28%' }}>
                    <ArrowCircleLeftIcon sx={{ fontSize: '65px' }} />
                </Button>
            }


        </>
    )
}

export default Atention;