import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import { CompactPicker, SketchPicker, SliderPicker } from 'react-color';
import MultiSelect from '../MultiSelect';


export default function StatusModal({ title, open, setOpen, onSave, theme, currentStatus,currentSessions=[] }) {


    useEffect(() => {

        if (currentStatus) {
            setwebhookInfo({
                ...currentStatus,
                wpSessions: currentStatus.WpSessions.map((session)=>session.id)
            });
        } else {
            setwebhookInfo({
                name: '',
                color: '',
                WpSessions: [],
                wpSessions: []
            });
        }
    }, [currentStatus]);

    /**
     * Variable para el nuevo perfil
     */
    const [webhookInfo, setwebhookInfo] = useState({
        name: '',
        color: '',
        WpSessions: [],
        wpSessions: []
    });

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setwebhookInfo({
            ...webhookInfo,
            [event.name]: event.value,
        })

    }
    const handleChangeWp = (list) => {
        
        setwebhookInfo({
            ...webhookInfo,
            wpSessions: list
        })
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={webhookInfo.name}
                                label="Nombre del estatus"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ backgroundColor: webhookInfo.color ? webhookInfo.color : 'primary' }}
                            >
                                Color
                            </Button>

                            <SliderPicker
                                color={webhookInfo.color}
                                onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: 'color',
                                            value: e.hex
                                        }
                                    })
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                id="wpsessionId"
                                name="wpSessions"
                                title="Whatsapps"
                                theme={theme}
                                list={currentSessions}
                                onChange={handleChangeWp}
                                initSelect={webhookInfo.wpSessions}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(webhookInfo)}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
