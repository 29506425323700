
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import MultiSelect from '../MultiSelect';
import api from "../../services/api";


export default function UserEditComplement({ showAccounts, showTags, showWhatsapps, showFbPages, duplicate, onChange, theme, pages, systemNames, newProfile, tags = [] }) {

    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", {});

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }
        getaccounts();
    }, [])
    /**
     * Revisa si newProfile contiene datos
     * Si es asi los asigna a la vista para editar
     */
    useEffect(() => {
        if (newProfile) {
            if (newProfile.id || duplicate) {
                //console.log(newProfile);


                /**
                 * Crea una lista con solo los id de sistemas que ya tiene el perfil
                 * guarda la lista en setCurrentSys
                 */
                const newListSys = newProfile.WpSessions.map((sys) => sys.id);
                //console.log(newListSys);
                setCurrentSys(newListSys);

                /**
                * Crea una lista con solo los id de las paginas que ya tiene el perfil
                * guarda la lista en setCurrentTwilio
                */
                const newPageList = newProfile.UserPages.map((up) => up.id);
                //console.log(newListSys);
                setCurrentPages(newPageList);

                let newProfileFinal = {
                    wpsersystems: newListSys,
                    userpages: newPageList,
                    tags: newProfile.Tags?.map((tag) => tag.id),
                    tagsWithoutChat: newProfile.TagsWithoutChat?.map((tag) => tag.id),
                    googleAccounts: newProfile.GoogleAccounts?.map((account) => account.id),
                    showAllWhatsapps: newProfile.showAllWhatsapps,
                    showAllFbPages: newProfile.showAllFbPages,
                    showAllTags: newProfile.showAllTags,
                    defautlAccountId: newProfile.defautlAccountId
                }

                if (duplicate) {
                    delete newProfileFinal.id;
                    newProfileFinal.name = newProfile.name + ' (copia)'
                }
                setProfile(newProfileFinal)
            }
        } else {
            setProfile({

                tags: [],
                tagsWithoutChat: []
            })
        }
    }, [newProfile]);


    /**
     * Variable para el nuevo perfil
     */
    const [profile, setProfile] = useState({

        tags: [],
        tagsWithoutChat: [],
        defautlAccountId: 0
    });


    /**
     * Variable para la lista de sistemas ya asignados
     */
    const [currentSys, setCurrentSys] = useState([]);


    /**
   * Variable para la lista de telefonos de twilio
   */
    const [currentPages, setCurrentPages] = useState([]);


    /**
    * Detecta cuando el multiselect de sistemas cambia
    * @param {*} list 
    */
    const handleChangeSystems = (list) => {
        setCurrentSys(list)
        setProfile({
            ...profile,
            wpsersystems: list
        })
    }


    /**
     * Detecta cuando el multiselect de fb
     * @param {*} list 
     */
    const handleChangePages = (list) => {
        setCurrentPages(list)
        setProfile({
            ...profile,
            userpages: list
        })
    }


    /**
    * Detecta cuando el multiselect de etiquetas cambia
    * @param {*} list 
    */
    const handleChangeTags = (list) => {
        setProfile({
            ...profile,
            tags: list
        })
    }

    /**
   * Detecta cuando el multiselect de etiquetas cambia
   * @param {*} list 
   */
    const handleChangeTagsNoChat = (list) => {
        setProfile({
            ...profile,
            tagsWithoutChat: list
        })
    }

    const handleChangeAccounts = (list) => {
        setProfile({
            ...profile,
            googleAccounts: list
        })
    }

    useEffect(() => {
        onChange(profile);
    }, [profile])
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <MultiSelect
                    title="Cuentas de google"
                    theme={theme}
                    list={accounts}
                    onChange={handleChangeAccounts}
                    initSelect={profile.googleAccounts}
                    disabled={!showAccounts}
                />

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={profile.showAllWhatsapps == 1}
                            onChange={(e) => {
                                setProfile(prevState => ({
                                    ...prevState,
                                    showAllWhatsapps: e.target.checked ? 1 : 0
                                }))
                            }}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label="Asignar todos los whatsapps" />
                <MultiSelect
                    title="Whatsapps asignados"
                    theme={theme}
                    list={systemNames}
                    onChange={handleChangeSystems}
                    initSelect={currentSys}
                    disabled={!showWhatsapps || profile.showAllWhatsapps == 1}
                />

            </Grid>


            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={profile.showAllFbPages == 1}
                            onChange={(e) => {
                                setProfile(prevState => ({
                                    ...prevState,
                                    showAllFbPages: e.target.checked ? 1 : 0
                                }))
                            }}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label="Asignar todas las páginas" />
                <MultiSelect
                    title="FB Páginas"
                    theme={theme}
                    list={pages}
                    onChange={handleChangePages}
                    initSelect={currentPages}
                    disabled={!showFbPages || profile.showAllFbPages == 1}
                />

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={profile.showAllTags == 1}
                            onChange={(e) => {
                                setProfile(prevState => ({
                                    ...prevState,
                                    showAllTags: e.target.checked ? 1 : 0
                                }))
                            }}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label="Asignar todas las etiquetas" />
                <MultiSelect
                    title="Etiquetas con chat"
                    theme={theme}
                    list={tags}
                    onChange={handleChangeTags}
                    initSelect={profile.tags}
                    disabled={!showTags || profile.showAllTags == 1}
                />

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={profile.showAllTags == 1}
                            onChange={(e) => {
                                setProfile(prevState => ({
                                    ...prevState,
                                    showAllTags: e.target.checked ? 1 : 0
                                }))
                            }}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label="Asignar todas las etiquetas" />
                <MultiSelect
                    title="Etiquetas sin chat"
                    theme={theme}
                    list={tags}
                    onChange={handleChangeTagsNoChat}
                    initSelect={profile.tagsWithoutChat || profile.showAllTags == 1}
                    disabled={!showTags}
                />

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    select
                    label="Correo para calendario"
                    value={profile.defautlAccountId}
                    onChange={(e) => {
                        setProfile(prevState => ({
                            ...prevState,
                            defautlAccountId: e.target.value
                        }))
                    }}
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    {accounts?.map((account) => {
    
                        return (
                            <MenuItem key={account.id} value={account.id}>
                                {account.name}
                            </MenuItem>)
                    })}
                </TextField>

            </Grid>




        </>
    );
}
