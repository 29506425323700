import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TagsTableModal from '../../components/TagsTableModal';
import StatusModal from '../../components/StatusModal';
import { Helmet } from 'react-helmet-async';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Status({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [webhooks, setWebhooks] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentStatus, setCurrentStatus] = useState(null);
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getStatus", { wpSessions: userInfo.data.userInfo.wpsessions });

        setWebhooks(data.statusList);
      } catch (err) {
        console.log(err)
      }

      try{
        const { data } = await api.post("api/getSessions");
        // console.log(data)
        setSessionsUser(data.sessions);
      }catch(error){
        console.log(error)
      }
    }
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'color', headerName: 'Color', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {

              <Button
                sx={{
                  color: 'white',
                  background: params.row?.color ? params.row.color : 'black'
                }}
                onClick={() => { setCurrentStatus(params.row); }}
              >
                Color
              </Button>
            }

          </>
        )
      }
    },
    {
      field: 'test', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {
              revisaPermisos(['Modificar estatus']) ?
                <Button onClick={() => { setCurrentStatus(params.row); setEstadoModal(true); }}>
                  Editar
                </Button> :
                "Sin acciones"
            }

          </>
        )
      }
    },

  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteStatus", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Estatus eliminado', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar los estatus', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar estatus', {
          variant: 'error',
        });
      }
    });


  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addWebhook = async (newWebhook) => {


    let request = {

      statusObj: newWebhook

    }

    if (newWebhook?.id) {
      request.id = newWebhook.id;
    }

    try {

      const { data } = await api.post("/api/createStatus", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se creó el estatus', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al crear el estatus', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear el estatus', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Estatus" />

      <Typography variant="h3" gutterBottom display="inline">
        Estatus
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Estatus de clientes</Link>
        {
          revisaPermisos(['Agregar estatus']) &&
          <Button variant='outlined' onClick={() => { setCurrentStatus(null); setEstadoModal(!estadoModal); }} >Nuevo estatus</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={webhooks}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar estatus'])}
            onDelete={(data) => { deleteSession(data); }}
          />
          {
            revisaPermisos(['Agregar estatus']) &&
            <>

            


              <StatusModal
                title={"Nuevo estatus"}
                open={estadoModal}
                setOpen={setEstadoModal}
                theme={theme}
                onSave={addWebhook}
                currentStatus={currentStatus}
                currentSessions={sessionsUser}
              />
            </>
          }

        </Grid>
      </Grid>


    </>
  );
}