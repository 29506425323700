import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../services/api";

export default function FBMassiveMEssageModal({ open, setOpen, callBack }) {

    const [templates, setTemplates] = useState([]);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        async function getTemplates() {
            /**
         * COnsulta las plantillas
         */
            const dataTemplate = await api.post("/api/getTemplate");
            setTemplates(dataTemplate.data?.messageTemplates || [])

        }

        getTemplates();
    }, [])

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Mensajes masivos FB
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={21}>
                            <TextField
                                fullWidth
                                select
                 
                                value={selectedTemplate}
                                label="Selecciona una plantilla"
                                name="type"
                                variant="outlined"
                                onChange={(event) => { setSelectedTemplate(event.target.value); }}

                            >
                                {templates.map((option) => {
                                    if (option.type != "Llamada") {
                                        return <MenuItem key={option.id} value={option}>
                                            {option.name}
                                        </MenuItem>
                                    }

                                })}
                            </TextField>

                        </Grid>
                    </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button  variant="outlined" color="error" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button
                    variant="outlined"
                    component="label"
                    onClick={() => callBack(selectedTemplate)}
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog >


        </>
    )
}