import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

export default function EmailViewModal({ title, emailBody }) {

    const [open, setOpen] = useState(false);

    function handleClose() {
        setOpen(false)
    }

    function handleOpen() {
        setOpen(true)
    }
    return (
        <>
            <Button onClick={handleOpen} variant="contained">Ver</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div
                        dangerouslySetInnerHTML={{ __html: emailBody }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}