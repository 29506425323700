import { useEffect, useState } from 'react'
import { Drawer, Box, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ChatIcon from '@mui/icons-material/Chat';
import GroupIcon from '@mui/icons-material/Group';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WebIcon from '@mui/icons-material/Web';
import PersonIcon from '@mui/icons-material/Person';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import api from '../../services/api';

function BarraDrawer({ permissions }) {
  /**
   * Gestiona la navegacion
   */
  const navigate = useNavigate();

  /**
   * Abre la barra lateral
   */
  const [isDrawerOpen, setisDrawerOpen] = useState(false);

  /**
   * Estilo de la barra
   */
  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };

  const [permissionsUser, setPermissionsUser] = useState([]);


  useEffect(() => {


    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)

      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [permissions]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  return (
    <>
      <IconButton size="large" edge="start" color="inherit" aria-label='logo' onClick={() => setisDrawerOpen(true)}>
        <MenuIcon></MenuIcon>
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setisDrawerOpen(false)}
      >
        <Box width="250px" role="presentation">
          <List sx={style} component="nav" aria-label="mailbox folders">

            <Divider />

            {
              revisaPermisos(['Modificar Perfil', 'Agregar Perfil', 'Eliminar Perfil']) &&

              <ListItem button divider onClick={() => { navigate("/permisos") }}>
                <AddModeratorIcon></AddModeratorIcon>
                <ListItemText primary="Permisos" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }

            {
              revisaPermisos(['Modificar Usuarios', 'Agregar Usuarios', 'Eliminar Usuarios']) &&

              <ListItem button divider onClick={() => { navigate("/usuarios") }}>
                <PersonIcon></PersonIcon>
                <ListItemText primary="Usuarios" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }

            {
              revisaPermisos(['Agregar telefono twilio', 'Eliminar telefono twilio', 'Editar telefono twilio']) &&

              <ListItem button onClick={() => { navigate("/numeros") }}>
                <GroupIcon></GroupIcon>
                <ListItemText primary="Numeros" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }
            {
              revisaPermisos(['Modificar Whatsapp', 'Agregar Whatsapp', 'Eliminar Whatsapp']) &&

              <ListItem button onClick={() => { navigate("/sesiones") }}>
                <GroupIcon></GroupIcon>
                <ListItemText primary="Sesiones" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }
            {
              revisaPermisos(['Sincronizar cuenta social', 'Eliminar cuenta social']) &&

              <ListItem button onClick={() => { navigate("/accounts") }}>
                <GroupIcon></GroupIcon>
                <ListItemText primary="Redes sociales" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }
            {
              revisaPermisos(['Modificar Plantilla', 'Agregar Plantilla', 'Eliminar Plantilla']) &&

              <ListItem button onClick={() => { navigate("/templates") }} >
                <SendIcon></SendIcon>
                <ListItemText primary="Plantillas" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }

            {
              revisaPermisos(['Modificar Speech', 'Agregar Speech', 'Eliminar Speech']) &&
              <ListItem button onClick={() => { navigate("/speech") }}>
                <RecordVoiceOverIcon></RecordVoiceOverIcon>
                <ListItemText primary="Speechs" style={{ paddingLeft: "15px" }} />
              </ListItem>
            }
            {
              revisaPermisos(['Envio Masivo']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/mensajeEnvio") }} >
                  <SendIcon></SendIcon>
                  <ListItemText primary="Envio Masivo" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }

            {
              revisaPermisos(['Bitacora Envio Masivo']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/envios") }} >
                  <SendIcon></SendIcon>
                  <ListItemText primary="Bitacora envios masivos" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }
            {
              revisaPermisos(['Bitacora llamadas Masivas']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/llamadas") }} >
                  <SendIcon></SendIcon>
                  <ListItemText primary="Bitacora llamadas masivas" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }
            {
              revisaPermisos(['Bitacora Envio Webhook']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/enviosWebhook") }} >
                  <SendIcon></SendIcon>
                  <ListItemText primary="Bitacora envios webhook" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }
            {
              revisaPermisos(['Ver Webhooks', 'Modificar Webhooks', 'Agregar Webhooks', 'Eliminar Webhooks']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/webhooks") }} >
                  <WebIcon></WebIcon>
                  <ListItemText primary="Webhooks" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }


            {
              revisaPermisos(['Modificar categorias', 'Agregar categorias', 'Eliminar categorias']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/tags") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Categorías" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }
            {
              revisaPermisos(['Modificar estatus', 'Agregar estatus', 'Eliminar estatus']) &&
              <>
                <Divider light />
                <ListItem button onClick={() => { navigate("/estatus") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Estatus" style={{ paddingLeft: "15px" }} />
                </ListItem>
              </>
            }
            {
              revisaPermisos(['Agregar contactos']) &&
              <>
                <ListItem button onClick={() => { navigate("/contacts") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Contactos" style={{ paddingLeft: "15px" }} />
                </ListItem>
                <Divider light />
              </>
            }
            {
              revisaPermisos(['Atender clientes']) &&
              <>
                <ListItem button onClick={() => { navigate("/atention") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Atencion" style={{ paddingLeft: "15px" }} />
                </ListItem>
                <Divider light />
              </>
            }
            {
              revisaPermisos(['Asignar Clientes']) &&
              <>
                <ListItem button onClick={() => { navigate("/database") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Subir base de datos" style={{ paddingLeft: "15px" }} />
                </ListItem>
                <Divider light />
              </>
            }

            {
              revisaPermisos(['Asignar Clientes']) &&
              <>
                <ListItem button onClick={() => { navigate("/groups") }} >
                  <LocalOfferIcon></LocalOfferIcon>
                  <ListItemText primary="Lista de base de datos" style={{ paddingLeft: "15px" }} />
                </ListItem>
                <Divider light />
              </>
            }

            <ListItem button onClick={() => { navigate("/") }} >
              <LogoutIcon></LogoutIcon>
              <ListItemText primary="Salir" style={{ paddingLeft: "15px" }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default BarraDrawer

