import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const NotificationComponent = () => {

  const [open, setOpen] = useState(false);
  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const checkNotificationPermission = () => {
    if (Notification.permission === "granted") {
     
    } else if (Notification.permission === "denied") {
    
    } else {
      setOpen(true)
    }
  };

  const showNotification = () => {
    const notification = new Notification("Se activaron las notificaciones", {
      body: "",
      // Puedes agregar más opciones como icono, etc.
    });

    notification.onclick = () => {
      console.log('Notificación clickeada');
      // Aquí puedes redirigir al usuario a otra parte de tu aplicación, por ejemplo
    };
  };

  const activeNotifiations = () => {
    Notification.requestPermission().then(permission => {
      console.log('Permission for notifications:', permission);
      if (permission === "granted") {
        // El usuario ha dado permiso
        showNotification();
      }
      setOpen(false)
    });
  }
  return (
    <Dialog
      disableEnforceFocus
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
        <Typography id="transition-modal-description" sx={{ mt: 2 }} variant='h2'>
          Notificaciones
        </Typography>
      </DialogTitle >

      <DialogContent dividers>
        <Typography id="transition-modal-description" sx={{ mt: 2 }} variant='h4'>
          Activa las notificaciones para poder estar al tanto de los nuevos mensajes que llegan
        </Typography>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" onClick={() => {  setOpen(false);activeNotifiations() }}>
          Aceptar
        </Button>
        <Button variant="outlined" color="error" onClick={() => { setOpen(false) }}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationComponent;
