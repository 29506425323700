import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import MultiSelect from '../MultiSelect';
import { ConstructionOutlined } from '@mui/icons-material';
import api from '../../services/api';


export default function ProfileModal({ isOwner, title, duplicate, open, setOpen, onSave, theme, pages, permissions, systemNames, twilioPhones, newProfile, status = [], tags = [] }) {

    const [modules, setModules] = useState([]);
    useEffect(() => {
        async function getPermissionsByModules() {
            try {
                const { data } = await api.get('/api/v2/permissions/')
                setModules(data.modules)
            } catch (err) {

            }
        }
        getPermissionsByModules();
    }, [])
    /**
     * Revisa si newProfile contiene datos
     * Si es asi los asigna a la vista para editar
     */
    useEffect(() => {
        if (newProfile) {
            if (newProfile.id || duplicate) {
                //console.log(newProfile);
                /**
                 * Crea una lista con solo los id de permisos que ya tiene el perfil
                 * guarda la lista en setCurrentPerm
                 */
                const newListP = newProfile.Permissions.map((perm) => perm.id);
                //console.log(newListP);
                setCurrentPerm(newListP);

                /**
                 * Crea una lista con solo los id de sistemas que ya tiene el perfil
                 * guarda la lista en setCurrentSys
                 */
                const newListSys = newProfile.WpSessions.map((sys) => sys.id);
                //console.log(newListSys);
                setCurrentSys(newListSys);

                /**
                 * Crea una lista con solo los id de los telefonso twilio que ya tiene el perfil
                 * guarda la lista en setCurrentTwilio
                 */
                const newListTwilio = newProfile.TwilioPhones.map((ph) => ph.id);
                //console.log(newListSys);
                setCurrentTwilio(newListTwilio);


                /**
                * Crea una lista con solo los id de las paginas que ya tiene el perfil
                * guarda la lista en setCurrentTwilio
                */
                const newPageList = newProfile.UserPages.map((up) => up.id);
                //console.log(newListSys);
                setCurrentPages(newPageList);

                let newProfileFinal = {
                    id: newProfile.id,
                    isAdmin: newProfile.isAdmin,
                    name: newProfile.name,
                    description: newProfile.description,
                    status: newProfile.Statuses?.map((status) => status.id),
                    tags: newProfile.Tags?.map((tag) => tag.id),
                    tagsWithoutChat: newProfile.TagsWithoutChat?.map((tag) => tag.id),
                }

                if (duplicate) {
                    delete newProfileFinal.id;
                    newProfileFinal.name = newProfile.name + ' (copia)'
                }
                setProfile(newProfileFinal)
            }
        } else {
            setProfile({
                name: '',
                description: '',
                tags: [],
                status: [],
                tagsWithoutChat: [],
                isAdmin: 0
            })
        }
    }, [newProfile]);


    /**
     * Variable para el nuevo perfil
     */
    const [profile, setProfile] = useState({
        name: '',
        description: '',
        tags: [],
        status: [],
        tagsWithoutChat: [],
        isAdmin: 0
    });

    /**
     * Variable para la lista de permisos ya asignados
     */
    const [currentPem, setCurrentPerm] = useState([]);
    /**
     * Variable para la lista de sistemas ya asignados
     */
    const [currentSys, setCurrentSys] = useState([]);

    /**
    * Variable para la lista de telefonos de twilio
    */
    const [currentTwilio, setCurrentTwilio] = useState([]);

    /**
   * Variable para la lista de telefonos de twilio
   */
    const [currentPages, setCurrentPages] = useState([]);

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setProfile({
            ...profile,
            [event.name]: event.value,
        })

    }
    /**
     * Detecta cuando el multiselect de perfiles cambia
     * @param {*} list 
     */
    const handleChangePermission = (list) => {
        setCurrentPerm(list);
    }
    /**
    * Detecta cuando el multiselect de sistemas cambia
    * @param {*} list 
    */
    const handleChangeSystems = (list) => {
        setCurrentSys(list)

    }
    /**
     * Detecta cuando el multiselect de twilio cambia
     * @param {*} list 
     */
    const handleChangeTwilio = (list) => {
        setCurrentTwilio(list)

    }

    /**
     * Detecta cuando el multiselect de fb
     * @param {*} list 
     */
    const handleChangePages = (list) => {
        setCurrentPages(list)

    }

    /**
     * Detecta cuando el multiselect de ESTATUS cambia
     * @param {*} list 
     */
    const handleChangeStatus = (list) => {
        setProfile({
            ...profile,
            status: list
        })
    }
    /**
    * Detecta cuando el multiselect de etiquetas cambia
    * @param {*} list 
    */
    const handleChangeTags = (list) => {
        setProfile({
            ...profile,
            tags: list
        })
    }

    /**
   * Detecta cuando el multiselect de etiquetas cambia
   * @param {*} list 
   */
    const handleChangeTagsNoChat = (list) => {
        setProfile({
            ...profile,
            tagsWithoutChat: list
        })
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="md"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid container>
                        <Grid item xs={6}>
                            {title}
                        </Grid>
                        <Grid item xs={6}>
                            {isOwner && <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={profile.isAdmin == 1}
                                        onChange={(e) => {
                                            setProfile(prevState => ({
                                                ...prevState,
                                                isAdmin: e.target.checked ? 1 : 0
                                            }))
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }} />
                                }
                                label="Rol y permisos protegido" />}
                        </Grid>
                    </Grid>

                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={profile.name}
                                label="Nombre del perfil"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={profile.description}
                                label="Descripcion"
                                name="description"
                                variant="outlined"
                                onChange={handleChange}
                            />

                        </Grid>

                        {
                            modules.map((module, index) => {
                                if (index > 0) {
                                    return (
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                            <Typography>{module.name}</Typography>
                                            <MultiSelect
                                                    
                                                theme={theme}
                                                list={module.Permissions}
                                                onChange={handleChangePermission}
                                                initSelect={currentPem}
                                            />
                                        </Grid>

                                    )
                                }

                            })
                        }

                        {/*<Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiSelect
                                title="Permisos"
                                theme={theme}
                                list={permissions}
                                onChange={handleChangePermission}
                                initSelect={currentPem}
                            />

                    </Grid>*/}
                        {/*<Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="Whatsapps asignados"
                                theme={theme}
                                list={systemNames}
                                onChange={handleChangeSystems}
                                initSelect={currentSys}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="Teléfonos twilio asignados"
                                theme={theme}
                                list={twilioPhones}
                                onChange={handleChangeTwilio}
                                initSelect={currentTwilio}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="Etiquetas con chat"
                                theme={theme}
                                list={tags}
                                onChange={handleChangeTags}
                                initSelect={profile.tags}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="Etiquetas sin chat"
                                theme={theme}
                                list={tags}
                                onChange={handleChangeTagsNoChat}
                                initSelect={profile.tagsWithoutChat}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="Estatus"
                                theme={theme}
                                list={status}
                                onChange={handleChangeStatus}
                                initSelect={profile.status}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MultiSelect
                                title="FB Páginas"
                                theme={theme}
                                list={pages}
                                onChange={handleChangePages}
                                initSelect={currentPages}
                            />

                        </Grid>
    */}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(profile, currentPem, currentSys, currentTwilio, currentPages)}>
                        {duplicate ? 'Duplicar' : 'Guardar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
