import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { InputAdornment, ListSubheader, TextField, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false
};

/**
 * Busca los estilos por id para marcar la opción como seleccionada
 * @param {*} name 
 * @param {*} personName 
 * @param {*} theme 
 * @returns 
 */
function getStyles(id, list, theme) {

  const found = list.indexOf(id) > -1;

  return {
    fontWeight:
      found
        ? theme?.typography.fontWeightRegular
        : theme?.typography.fontWeightMedium,
    backgroundColor:
      found
        ? theme.palette.primary.main
        : "",
    color:
      found
        ? theme.palette.primary.contrastText
        : "",
  };
}

export default function MultiSelect({ title, list, disabled = false, onChange, initSelect = [], isList = false, sx = {}, style = {} }, labelStyle, label = "") {

  const theme = useTheme();

  React.useEffect(() => {
    setGeneralList(initSelect)
  }, [initSelect])


  /**
   * Componente para guardar las opciones seleccionada
   */
  const [generalList, setGeneralList] = React.useState(initSelect);

  /**
   * Componente para guardar las opciones seleccionada
   */
  const [filteredList, setFilteredList] = React.useState(list);

  /**
   * Guarda la lista de id seleccionados
   * @param {*} event 
   */
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setGeneralList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    if (onChange) {
      onChange(typeof value === 'string' ? value.split(',') : value);
    }
  };
  /**
   * Regresa el nombre del item para mostrarlos en el chip
   * @param {*} id 
   */
  const getItemName = (id) => {
    const tempItem = list.find(item => item.id === id);
    return tempItem?.name ? tempItem.name : tempItem?.firstName ? tempItem.firstName : '';
  }

  const filterList = ({ target }) => {
    if (target.value) {

      let tempList = isList ? [] : {};
      let regex = new RegExp(target.value, "i");
      if (isList) {
        tempList = list?.filter((item) => regex.test(item))
      } else {
        tempList = list?.filter((item) => regex.test(item?.name) || regex.test(item.firstName))
      }
      setFilteredList(tempList);
    } else {
      setFilteredList(list);
    }
  }
  return (
    <div style={style}>


      <FormControl sx={sx} fullWidth>

        {title && <InputLabel style={labelStyle} id={title}>{title}</InputLabel>}
        <Select
          disabled={disabled}
          theme={theme}
          labelId={title}
          label={title}
          id={title}
          multiple
          value={generalList}
          fullWidth
          onChange={handleChange}
          color="secondary"
          input={<OutlinedInput id={`chip-chip`} label="Chip" />}
          renderValue={(selected) => {

           
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const label = isList ? value : getItemName(value);
                  if(label){
                    return <Chip key={`value-tag-${label}`} label={label} />
                  }
                 
                })}
              </Box>
            )
          }}
          MenuProps={MenuProps}
          onClose={() => setFilteredList(list)}
        >

          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Buscar..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={filterList}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }

              }}
            />
          </ListSubheader>
          {
            isList ?
              filteredList?.map((item, index) => {
                return (
                  <MenuItem
                    key={`tag-${item}${index}`}
                    value={item}
                    style={getStyles(item, generalList, theme)}
                  >
                    {item}
                  </MenuItem>
                )
              })
              :
              filteredList?.map((item, index) => (
                <MenuItem
                  key={`tag-${item.id}${item.name ? item.name : item.firstName}${index}`}
                  value={item.id}
                  style={getStyles(item.id, generalList, theme)}
                >
                  {item.name ? item.name : item.firstName}
                </MenuItem>
              ))
          }

        </Select>

      </FormControl>
    </div>
  );
}
