

import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import CustomFilter from "../CustomFilter"
import { useEffect, useState } from "react";
import { Button, Chip } from "@mui/material";
import { CoPresent } from "@mui/icons-material";

/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const ContactsTable = ({ massiveMsg, onDelete, onEdit, showEdit, showDelete, setCurrent, tagsList = {} }) => {
    /**
     * Cada que cambien  los datos filtra de nuevo
     */
    useEffect(() => {
        setFiltered(massiveMsg);
    }, [massiveMsg])

    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] = useState('');

    /**
    * Guarda los datos filtrados
    */
    const [filtered, setFiltered] = useState(massiveMsg);

    /**
    * Guarda los datos de lso filtros
    */
    const [latsFilters, setLatsFilters] = useState({});

    /**
     * Guarda los números seleccionados
     */
    const [selectedContacts, setSelectedContacts] = useState([]);

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {

        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }

        console.log(filterValues)
        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = massiveMsg.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {


                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

         
                        let exist = false;
                        for (let i = 0; i < filterValues[key].length; i++) {
                            console.log(filterValues[key][i]);
                            if (item[key].toString().toLowerCase().includes(filterValues[key][i].toLowerCase())) {
                                exist = true;

                                break;
                            }
                        }

                        isInFilter = isInFilter && (filterValues[key].indexOf(item[key]) >= 0 || exist);



                    } else {


                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }




                    }
                }


            }
            return isInFilter;
        });

        setFiltered(finalResults);
    };

    const selectContacts = (contactsSelected) => {

        let contArr = [];
        contactsSelected.map((con, key) => {
            contArr.push(con.phone)
        })
        //console.log(contArr);

        setSelectedContacts(contArr);
        setCurrent(contArr);
    }

    return (

        <TablaUsuarios

            rows={filtered}
            columns={[

                {
                    headerName: 'Nombre', field: 'name', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Número', field: 'phone', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Etiquetas', field: 'Tags', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} value={latsFilters[props.field]} isSelect list={tagsList} keys={['name', 'name']} field={props.field} filterFunc={secondFilter} />
                    },
                    renderCell: (params) => {

                        if(params.row.Tags != ''){
                            let tags = params.row.Tags.split(',');


                            return (
                                <>{tags.map((tag,key)=><Chip sx={{ color: 'white',background: 'green' }} key={tag} label={tag} />)}</>
                            )
                        }
                      

                    }
                },



            ]}
            checkboxSelection
            onDelete={onDelete}
            onsSelectionChange={selectContacts}
            pageSize={10}
        />

    )
}

export default ContactsTable; 