import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import QuoteInfo from "../QuoteInfo";
import QuotePreview from "../QuotePreview";
import QuoteItems from "../QuoteItems";
import { useSnackbar } from "notistack";

export default function QuoteModal({ open, setOpen, client, id, externalQuote, externalCompany, externalProducts, externalTemplate, callBack }) {

    const [quote, setQuote] = useState({ total: 0 })
    const [company, setCompany] = useState({ ...client });
    const [products, setProducts] = useState([]);

    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [view, setView] = useState(0);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        async function getTemplates() {
            try {
                const { data } = await api.post("/api/getTemplate");
                let templates = [];
                data.messageTemplates?.map((template)=>{
                    if(template.type == "Cotizacion"){
                        templates.push(template); 
                    }
                })
                
                setTemplates(templates);

            } catch (err) {

            }
        }

        getTemplates();
    }, [])

    useEffect(() => {
        if (externalQuote) {
            setQuote(externalQuote)
        }

    }, [externalQuote])

    useEffect(() => {
        if (externalCompany) {
            setCompany(externalCompany)
        }
    }, [externalCompany])


    useEffect(() => {
        if (externalProducts) {
            setProducts(externalProducts)
        }
    }, [externalProducts])

    useEffect(() => {
        if (externalTemplate) {
            setSelectedTemplate(templates.length > 0 ? templates[0] : externalTemplate)
        }
    }, [externalTemplate])


    function handleClose() {
        setOpen(false)
    }


    async function createQuote() {
        try {

            if (id) {
                const { data } = await api.post(`/api/quotes/${id}`, { products, quote, company });
                enqueueSnackbar("Se creó la cotización de correctamente", {
                    variant: 'success',
                });
            } else {
                const { data } = await api.post('/api/quotes', { products, quote, company });
                enqueueSnackbar("Se creó la cotización de correctamente", {
                    variant: 'success',
                });
            }

            if (callBack) {
                callBack()
            }
            handleClose();
        } catch (err) {
            enqueueSnackbar("No se encontraron contactos para agregar", {
                variant: 'error',
            });
        }
    }
    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            maxWidth="lg"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Factura
            </DialogTitle >

            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            select
                            label="Plantilla"
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.target.value)}
                            variant="filled"
                            color="primary"
                            fullWidth
                        >
                            {templates?.map((template) => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>


                    <Grid item container xs={12} md={6}>
                        {selectedTemplate != null && <Grid item xs={12} md={12}>

                            <QuoteInfo
                                client={client}
                                setCompanyExternal={setCompany}
                                setQuoteExternal={setQuote}
                                products={products}
                                externalComany={externalCompany}
                                externalQuote={externalQuote}
                            />
                        </Grid>}

                        {selectedTemplate != null && <Grid item xs={12} md={12}>
                            <QuoteItems setProducts={setProducts} externalProducts={externalProducts} />

                        </Grid>}
                    </Grid>


                    {selectedTemplate != null && <Grid item xs={12} md={6}>
                        <QuotePreview
                            externalCompany={company}
                            externalQuote={quote}
                            products={products}
                        />
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="contained" color="primary" onClick={createQuote}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
}