import { Breadcrumbs, Divider, Grid, Link, Typography } from "@mui/material";

import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading";

import api from "../../services/api";

import MassiveTable from "../../components/MassiveTable";

import { SocketContext } from '../../services/socket';
import MassiveModal from "../../components/MassiveModal";
import { useNavigate } from "react-router-dom";
import WebhookMsgModal from "../../components/WebhookMsgModal";
import MassiveMsgModal from "../../components/MassiveMsgModal";
import CallModal from "../../components/CallModal";
import { Helmet } from "react-helmet-async";

/**
 * Vista para la consulta de envios masivos
 * @returns 
 */
const CallHistory = ({ theme }) => {
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Variable que recibirá el objeto de mensajes masivos
     * incluye su lista de mensajes
     */
    const [messages, _setMessages] = useState([]);

    /**
     * 
     */
    const messagesRef = useRef(messages);

    const setMessages = data => {
        messagesRef.current = data;
        _setMessages(data);
    }
    /**
     * Variable para mostrar y coultar el modal
     */
    const [open, setOpen] = useState(false);
    /**
     * Variable para mostrar y coultar el modal de reenvio
     */
    const [openResent, setOpenResent] = useState(false);
    /**
     * Variable para mostrar y coultar el modal de reenvio
     */
    const [openCallModal, setOpenCallModal] = useState(false);
    /**
     * Variable pararecargar la tabla sin recargar la página
     */
    const [reload, setReload] = useState(false);
    /**
     * Variable para mostrar la página de carga
     */
    const [isLoading, setIsLoading] = useState(true);

    const [templates, setTemplates] = useState([]);
    /**
    * Variable que contiene la lista de permisos del usuario
    */
    const [permissionsUser, setPermissionsUser] = useState([]);

    /**
    * Variable que contiene la lista sistemas sobre los que tiene permiso
    */
    const [sessions, setSessions] = useState([]);

    /**
    *  id del objeto seleccionado dentro del array
    */
    const [curentMsgId, setCurrentMsgId] = useState(0);

    /**
     * Socket para lo refrebte al chat
     */
    const socket = useContext(SocketContext);
    /**
     * Constante para navegar entre las vistas
     */
    const navigate = useNavigate();

    const [userId, setUserId] = useState(0);

    /**
     * Se ejecuta al inicio
     */
    useEffect(() => {


        /**
         * Consulta los permisos del usuario
         * Con los sistemas consulta los mensajes masivos que puede ver
         */
        async function getMassiveMsg() {
            try {
                try {

                    const userInfo = await api.post("/api/getPermissions");


                    setPermissionsUser(userInfo.data.userInfo.permissions)
                    setSessions(userInfo.data.userInfo.wpsessions);
                    setUserId(userInfo.data.userInfo.id);

                    /**
                     * Si no tiene los permisos entonces lo manda a la página de no permitidos
                     */
                    if (!revisaPermisos(['Bitacora Envio Masivo'], userInfo.data.userInfo.permissions)) {
                        navigate('/notAllowed')
                    }

                    // console.log(userInfo.data.userInfo.wpsessions)
                    const { data } = await api.post("/api/massive/list", { kind: 'Llamada',sessions: ['Twilio'], userId: userInfo.data.userInfo.id });

                    /**
                     * Valida el estatus Success
                     * sino manda un error al usuario
                     */


                    if (data.status == 'Success') {


                        setMessages(data.messages);
                        setIsLoading(false)
                    } else {

                        console.log(data);
                        enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                            variant: 'error',
                        });
                    }

                } catch (err) {
                    enqueueSnackbar('Ocurrió un error al consultar los permisos del usuario', {
                        variant: 'error',
                    });
                    console.log(err);
                }


            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                    variant: 'error',
                });
                console.log(err);
            }

        }
        async function getTemplates() {

            try {

                const { data } = await api.post("api/getTemplate");
                //console.log(userInfo.data);
                console.log(data);
                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err);
            }


        }
        getTemplates()
        setOpenResent(false);
        setOpenCallModal(false);
        getMassiveMsg();
    }, [reload])


    /**
     * Funcion para obtener los mensajes y agregarlos
     */
    const onGetUpdate = useCallback((id, messageObj) => {

        let selectedMsgMassive = {};

        let found = false;
        // console.log(chatListRef.current)
        for (let i = 0; i < messagesRef.current.length; i++) {

            if (messagesRef.current[i].id === id) {


                selectedMsgMassive = messagesRef.current[i];

                // console.log(messageObj);

                selectedMsgMassive.totalMessagesSend = messageObj?.count;

                for (let msgIndex in selectedMsgMassive.MassiveMessageLists) {

                    if (selectedMsgMassive.MassiveMessageLists[msgIndex].contact == messageObj?.contact) {
                        found = true;
                        selectedMsgMassive.MassiveMessageLists[msgIndex].status = messageObj?.status ? messageObj.status : 'Pendiente';
                        break
                    }
                }

                if (!found) {
                    //console.log(messageObj);

                    let tempMassiveSelect = { ...selectedMsgMassive };
                    console.log(tempMassiveSelect)
                    tempMassiveSelect.MassiveMessageLists = [...tempMassiveSelect.MassiveMessageLists, messageObj.newContact]

                    // console.log(selectedMsgMassive)
                    let newArr = [...messagesRef.current];
                    newArr[i] = tempMassiveSelect
                    //newArr = [selectedMsgMassive].concat(newArr)
                    // console.log(newArr);
                    // chatListRef.splice(i, 1);
                    setMessages([
                        ...newArr,
                    ]);
                    // console.log(selectedChat);
                    break;

                } else {
                    // console.log(selectedMsgMassive)
                    let newArr = [...messagesRef.current];
                    newArr[i] = selectedMsgMassive
                    //newArr = [selectedMsgMassive].concat(newArr)

                    // chatListRef.splice(i, 1);
                    setMessages([
                        ...newArr,
                    ]);
                    // console.log(selectedChat);
                    break;
                }

            }
        }


    }, [messagesRef])

    /** 
     * Inicializa el sokcet para recibir los mensajes
     */
    useEffect(() => {

        if (socket && sessions.length > 0) {

            messages.map((msg, key) => {
                socket.on(`MSG_SUCCESS${msg.id}`, (messageObj) => {
                    //console.log("Llegó un nuevo mensaje de conteo");
                    //console.log(messageObj)
                    onGetUpdate(msg.id, messageObj);
                });
            })

        }

    }, [socket, sessions, messages, onGetUpdate])




    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, list = permissionsUser, minMatch = 1) => {
        let count = 0;

        console.log(allow);
        console.log(list)

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }

    const getCurrentMsgs = (id) => {
        for (let i = 0; i < messages.length; i++) {
            console.log(id)

            if (messages[i].id == id) {
                console.log(messages[i].MassiveMessageLists)
                return messages[i].MassiveMessageLists;
            }

        }
    }

    const getCurrentTwilioPhone = (id) => {
        for (let i = 0; i < messages.length; i++) {
            console.log(id)

            if (messages[i].id == id) {

                return messages[i].twilioPhone;
            }

        }
    }

    const getWhatsapp = (id) => {
        for (let i = 0; i < messages.length; i++) {
            // console.log(id)

            if (messages[i].id == id) {

                return messages[i].wpId;
            }

        }

        return null;
    }

    const getCurrentMsgsArr = (id) => {

        let arr = [];
        for (let i = 0; i < messages.length; i++) {
            // console.log(id)

            if (messages[i].id == id) {

                for (let j = 0; j < messages[i].MassiveMessageLists.length; j++) {
                    if(messages[i]?.MassiveMessageLists[j]?.contact){
                        arr.push(messages[i].MassiveMessageLists[j].contact);
                    }
                }
            }

        }
        console.log(arr);
        return arr;
    }


    /**
     * Actualiza el número de teléfono de la bitacora
     * @param {*} id 
     * @param {*} phone 
     */
    const onEditPhone = async (id, phone) => {

        // console.log(phone);
        try {
            const { data } = await api.post('/api/msgListUpdate', { id: id, contact: phone, status: "Modificado" });

            if (data.status == "Success") {
                enqueueSnackbar(`Número actualizado`, {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                variant: 'error',
            });
        }

        reloadMain();

    }

    const reloadMain = () => {
        setReload(!reload)
    }


    const callMassive = async (currentTemplate, name, phone) => {

        let clientList = getCurrentMsgsArr(curentMsgId);

        /**
         * Array de contactos para enviar los mensajes
         * Integrantes del grupo que se creará
         */
        let constactsArr = [];
        clientList.map((cl) => {
            let phone = cl;
            constactsArr.push({ phone: phone });
        })

        console.log(constactsArr);


        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: curentMsgId,
            wpId: 'Twilio',
kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            twilioPhone: phone,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {



                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    delay: 1000,
                    from: phone
                }

                console.log(socketObj)
                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} mensajes`, {
                    variant: 'success',
                });

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpenCallModal(false);


    }

    const onResendAll = (resendMsgs) => {
        let contacts = [];

        resendMsgs.map((c) => {
            contacts.push({ phone: c.contact })
        })
        const socketObj = {
            id: curentMsgId,
            contacts: contacts,
            delay: 1000,
            from: getCurrentTwilioPhone(curentMsgId)?.trim(),
            resend: true,
            resendAll: true
        }
        console.log("Inicia reenvio de todos");
        console.log(socketObj);

        if (resendMsgs.length > 0) {

            socket.emit('sendMassiveCall', socketObj);
            enqueueSnackbar(`Reenviando llamadas`, {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(`No hay llamadas en la bitacora`, {
                variant: 'error',
            });
        }
    }

    const onResend = (resendMsgs) => {
        let contacts = [];

        resendMsgs.map((c) => {
            contacts.push({ phone: c.contact })
        })
        const socketObj = {
            id: curentMsgId,
            contacts: contacts,
            delay: 1000,
            from: getCurrentTwilioPhone(curentMsgId)?.trim(),
            resend: true
        }
        console.log("Inicia renvio de seleccionados");
        console.log(socketObj);

        if (resendMsgs.length > 0) {


            socket.emit('sendMassiveCall', socketObj);
            enqueueSnackbar(`Reenviando ${resendMsgs.length} llamadas seleccionadas`, {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(`No hay llamadas en la bitacora`, {
                variant: 'error',
            });
        }
    }

    /**
     * Metodo para el envio del cambio del nombre
     */
    const editGroupName = async (requestObj) => {

        try {
            const { data } = await api.post('/api/massivemessage/udpate', requestObj);

            if (data.status == 'Success') {
                enqueueSnackbar(`Nombre del grupo actualizado`, {
                    variant: 'success',
                });
            } else {
                throw 'Error al actualizar el grupo';
            }
            reloadMain();
        } catch (err) {
            enqueueSnackbar(`Error al actualizar el grupo`, {
                variant: 'error',
            });
        }

    }
    return (
        <>
            <Helmet title="Llamadas" />

            <Typography variant="h3" gutterBottom display="inline">
                Llamadas
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Historial
                </Link>
                <Link >Llamadas</Link>

            </Breadcrumbs>
            <br />
            <Divider my={6} />
            <Loading open={isLoading} />
            <WebhookMsgModal
                title={"Bitacora de llamadas"}
                showResendButton={revisaPermisos(['Enviar llamada masiva'])}
                //theme={props}
                forwardingId={curentMsgId}
                open={open}
                setOpen={setOpen}
                messages={messages.length > 0 ? getCurrentMsgs(curentMsgId) : []}
                onEdit={onEditPhone}
                onResednAll={(resendMsgs) => {
                    onResendAll(resendMsgs);
                }}
                onResend={(resendMsgs) => {

                    onResend(resendMsgs);
                }}
            />


            <MassiveMsgModal
                title={"Reenvio"}
                theme={theme}
                setOpen={setOpenResent}
                open={openResent}
                forwardingId={curentMsgId}
                whatsapp={getWhatsapp(curentMsgId)}
                contactsExternal={getCurrentMsgsArr(curentMsgId)}
                reloadMain={reloadMain}
                disableNav
            />
            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templates}
                onSave={(currentTemplate, name, phone) => {

                    callMassive(currentTemplate, name, phone)
                }}
            />
            <Grid
                container

                sx={{ padding: '20px', height: '80vh' }}
                alignItems="center"
                justify="center"
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '100%' }}
                >
                    <MassiveTable
                        massiable
                        massiveMsg={messages}
                        onEdit={editGroupName}
                        onDelete={async (ids) => {

                            await Promise.all(ids.map(async (group) => {
                                try {
                                    const { data } = await api.post('/api/massive/delete', { id: group.id });

                                    if (data.status == "Success") {
                                        enqueueSnackbar(`Grupo eliminado ${group.name} exitosamente`, {
                                            variant: 'success',
                                        });
                                    } else {
                                        throw "Error, el id es requerido";
                                    }
                                } catch (err) {
                                    enqueueSnackbar(`Ocurrio un error al eliminar el grupo ${group.name}`, {
                                        variant: 'error',
                                    });
                                }

                                return true;
                            }))

                            reloadMain();
                        }}
                        forwardingId={curentMsgId}
                        setCurrent={(id) => {
                            setCurrentMsgId(id);
                            setOpen(true);

                        }}
                        showResent={(id) => {
                            setCurrentMsgId(id);
                            setOpenCallModal(true);
                            console.log(getCurrentMsgsArr(curentMsgId));

                        }}
                        kind={"call"}
                        onResend={onResend}
                        onResendAll={onResendAll}
                        showDelete={revisaPermisos(['Eliminar bitacora llamadas masivas'])}
                        showResendButton={false}
                        showResendAllButton={revisaPermisos(['Enviar llamada masiva'])}
                    />
                </Grid>

            </Grid>



        </>
    )
}

export default CallHistory;