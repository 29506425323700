import { Grid } from "@mui/material";

import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading";

import api from "../../services/api";

import MassiveTable from "../../components/MassiveTable";

import { SocketContext } from '../../services/socket';
import MassiveModal from "../../components/MassiveModal";
import { useNavigate } from "react-router-dom";
import DetailMassiveTable from "../DetailMassiveTable";
import WebhookMsgModal from '../WebhookMsgModal';
/**
 * Vista para la consulta de envios masivos
 * @returns 
 */
const DetailRegister = (props) => {
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Variable que recibirá el objeto de mensajes masivos
     * incluye su lista de mensajes
     */
    const [messages, _setMessages] = useState([]);

    /**
     * 
     */
    const messagesRef = useRef(messages);

    const setMessages = data => {
        messagesRef.current = data;
        _setMessages(data);
    }
    /**
     * Variable para mostrar y coultar el modal
     */
    const [open, setOpen] = useState(false);
    /**
     * Variable pararecargar la tabla sin recargar la página
     */
    const [reload, setReload] = useState(false);
    /**
     * Variable para mostrar la página de carga
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
    * Variable que contiene la lista de permisos del usuario
    */
    const [permissionsUser, setPermissionsUser] = useState([]);

    /**
    * Variable que contiene la lista sistemas sobre los que tiene permiso
    */
    const [sessions, setSessions] = useState([]);

    /**
    *  id del objeto seleccionado dentro del array
    */
    const [curentMsgId, setCurrentMsgId] = useState(0);

    /**
     * Socket para lo refrebte al chat
     */
    const socket = useContext(SocketContext);
    /**
     * Constante para navegar entre las vistas
     */
    const navigate = useNavigate();

    /**
     * Se ejecuta al inicio
     */
    useEffect(() => {


        /**
         * Consulta los permisos del usuario
         * Con los sistemas consulta los mensajes masivos que puede ver
         */
        async function getMassiveMsg() {
            try {
                try {

                    const userInfo = await api.post("/api/getPermissions");


                    setPermissionsUser(userInfo.data.userInfo.permissions)
                    setSessions(userInfo.data.userInfo.wpsessions);

                    /**
                     * Si no tiene los permisos entonces lo manda a la página de no permitidos
                     */
                    if (!revisaPermisos(['Bitacora Envio Masivo'], userInfo.data.userInfo.permissions)) {
                        navigate('/notAllowed')
                    }

                    // console.log(userInfo.data.userInfo.wpsessions)
                    const { data } = await api.post("/api/massive/list", { forwardingId: props.row.id ? props.row.id : 0 });

                    /**
                     * Valida el estatus Success
                     * sino manda un error al usuario
                     */


                    if (data.status == 'Success') {

                        // console.log(data.messages);
                        setMessages(data.messages);
                        setIsLoading(false)
                    } else {
                        enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                            variant: 'error',
                        });
                    }

                } catch (err) {
                    enqueueSnackbar('Ocurrió un error al consultar los permisos del usuario', {
                        variant: 'error',
                    });
                    console.log(err);
                }


            } catch (err) {
                enqueueSnackbar('Ocurrió un error al consultar los perfiles', {
                    variant: 'error',
                });
                console.log(err);
            }

        }
        getMassiveMsg();
    }, [reload])

    useEffect(() => { console.log(props); }, [props])


    /**
     * Funcion para obtener los mensajes y agregarlos
     */
    const onGetUpdate = useCallback((id, messageObj) => {

        let selectedMsgMassive = {};

        let found = false;
        // console.log(chatListRef.current)
        for (let i = 0; i < messagesRef.current.length; i++) {

            if (messagesRef.current[i].id === id) {


                selectedMsgMassive = messagesRef.current[i];

                // console.log(messageObj);

                selectedMsgMassive.totalMessagesSend = messageObj.count;

                for (let msgIndex in selectedMsgMassive.MassiveMessageLists) {
                    if (selectedMsgMassive.MassiveMessageLists[msgIndex].contact == messageObj.contact) {
                        found = true;
                        selectedMsgMassive.MassiveMessageLists[msgIndex].status = messageObj.status ? messageObj.status : "Reenviando";
                        break
                    }
                }

                if (!found) {
                    //console.log(messageObj);

                    let tempMassiveSelect = { ...selectedMsgMassive };
                    console.log(tempMassiveSelect)
                    tempMassiveSelect.MassiveMessageLists = [...tempMassiveSelect.MassiveMessageLists, messageObj.newContact]

                    // console.log(selectedMsgMassive)
                    let newArr = [...messagesRef.current];
                    newArr[i] = tempMassiveSelect
                    //newArr = [selectedMsgMassive].concat(newArr)
                    // console.log(newArr);
                    // chatListRef.splice(i, 1);
                    setMessages([
                        ...newArr,
                    ]);
                    // console.log(selectedChat);
                    break;

                } else {
                    // console.log(selectedMsgMassive)
                    let newArr = [...messagesRef.current];
                    newArr[i] = selectedMsgMassive
                    //newArr = [selectedMsgMassive].concat(newArr)

                    // chatListRef.splice(i, 1);
                    setMessages([
                        ...newArr,
                    ]);
                    // console.log(selectedChat);
                    break;
                }

            }
        }


    }, [messagesRef])

    /** 
     * Inicializa el sokcet para recibir los mensajes
     */
    useEffect(() => {

        if (socket && sessions.length > 0) {

            messages.map((msg, key) => {
                socket.on(`MSG_SUCCESS${msg.id}`, (messageObj) => {
                    //console.log("Llegó un nuevo mensaje de conteo");
                    //console.log(messageObj)
                    onGetUpdate(msg.id, messageObj);
                });
            })

        }

    }, [socket, sessions, messages, onGetUpdate])




    /**
     * Compara la lista de permisos con los permisos asignados
     */
    const revisaPermisos = (allow, list = permissionsUser, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }

    const getCurrentMsgs = (id) => {
        for (let i = 0; i < messages.length; i++) {
            console.log(id)

            if (messages[i].id == id) {
                console.log(messages[i].MassiveMessageLists)
                return messages[i].MassiveMessageLists;
            }

        }
    }

    const getCurrentTwilioPhone = (id) => {
        for (let i = 0; i < messages.length; i++) {
            console.log(id)

            if (messages[i].id == id) {

                return messages[i].twilioPhone;
            }

        }
    }

    const onEditPhone = async (id, phone) => {

        console.log(phone);
        try {
            const { data } = await api.post('/api/msgListUpdate', { id: id, contact: phone, status: "Modificado" });

            if (data.status == "Success") {
                enqueueSnackbar(`Número actualizado`, {
                    variant: 'success',
                });
            } else {
                console.log(data)
                enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Ocurrió un error al actualizar el número`, {
                variant: 'error',
            });
        }

    }

    const onResendAll = (resendMsgs) => {
        let contacts = [];

        resendMsgs.map((c) => {
            contacts.push({ phone: c.contact })
        })
        const socketObj = {
            id: curentMsgId,
            contacts: contacts,
            delay: 1000,
            from: getCurrentTwilioPhone(curentMsgId)?.trim(),
            resend: true,
            resendAll: true
        }
        console.log("Inicia reenvio de todos");
        console.log(socketObj);

        if (resendMsgs.length > 0) {

            socket.emit('sendMassiveCall', socketObj);
            enqueueSnackbar(`Reenviando llamadas`, {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(`No hay llamadas en la bitacora`, {
                variant: 'error',
            });
        }
    }

    const onResend = (resendMsgs) => {
        let contacts = [];

        resendMsgs.map((c) => {
            contacts.push({ phone: c.contact })
        })
        const socketObj = {
            id: curentMsgId,
            contacts: contacts,
            delay: 1000,
            from: getCurrentTwilioPhone(curentMsgId)?.trim(),
            resend: true
        }
        console.log("Inicia renvio de seleccionados");
        console.log(socketObj);

        if (resendMsgs.length > 0) {


            socket.emit('sendMassiveCall', socketObj);
            enqueueSnackbar(`Reenviando ${resendMsgs.length} llamadas seleccionadas`, {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(`No hay llamadas en la bitacora`, {
                variant: 'error',
            });
        }
    }
    return (
        <>

            <Loading open={isLoading} />
            <WebhookMsgModal
                title={"Bitacora de mensajes"}
                //theme={props}
                forwardingId={props.row.id ? props.row.id : 0}
                open={open}
                setOpen={setOpen}
                messages={messages.length > 0 ? getCurrentMsgs(curentMsgId) : []}
                onEdit={onEditPhone}
                onResednAll={(resendMsgs) => {

                    if (props.kind == "call") {
                        onResendAll(resendMsgs);
                        return;
                    }
                    if (resendMsgs.length > 0) {

                        socket.emit('resendMassiveMsg', { idMassiveMg: resendMsgs[0].msgMassiveId });
                        enqueueSnackbar(`Reenviando mensajes`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`No hay mensajes en la bitacora`, {
                            variant: 'error',
                        });
                    }
                }}
                onResend={(resendMsgs) => {

                    if (props.kind == "call") {
                        onResend(resendMsgs)
                        return;
                    }
                    console.log("Inicia envio");

                    if (resendMsgs.length > 0) {

                        let arrMsg = [];


                        for (let i = 0; i < resendMsgs.length; i++) {
                            arrMsg.push(resendMsgs[i].id)
                        }

                        // console.log("Enviando");
                        // console.log(resendMsgs);

                        let socketObjTest = { idMassiveMg: resendMsgs[0].msgMassiveId, pengindIds: arrMsg };
                        // console.log(socketObjTest);


                        socket.emit('resendMassiveMsg', socketObjTest);
                        enqueueSnackbar(`Reenviando ${resendMsgs.length} mensajes seleccionados`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`No hay mensajes en la bitacora`, {
                            variant: 'error',
                        });
                    }
                }}
            />

            <Grid
                container

                sx={{ padding: '20px', height: '400px' }}
                alignItems="center"
                justify="center"
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '100%' }}
                >
                    <DetailMassiveTable
                        massiveMsg={messages}
                        onDelete={() => { }}
                        setCurrent={(id) => {
                            setCurrentMsgId(id);
                            setOpen(true);

                        }}
                    />
                </Grid>

            </Grid>



        </>
    )
}

export default DetailRegister;