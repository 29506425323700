import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import MensajeEnvio from '../MensajeEnvio/MensajeEnvio';
import TablaUsuarios from '../TablaUsuarios/TablaUsuarios';

export default function MassiveMsgModal({ title, open, setOpen, theme, forwardingId, whatsapp, contactsExternal, reloadMain, scheduled, isSequence }) {

    /**
     * Carga los nuevos mensajes
     */
    useEffect(() => {
        const tempContacts = convertContacts(contactsExternal);
        setSelectedContacts(contactsExternal);
        setContacts(tempContacts);

    }, [contactsExternal]);

    const [contacts, setContacts] = useState();
    const [selectedContacts, setSelectedContacts] = useState();
    const [serachText, setSerachText] = useState();


    const convertContacts = (tempContacts) => {
        let contacsArrObj = [];

        tempContacts.map((contact, key) => {
            contacsArrObj.push({ id: key, phone: contact });
        })

        return contacsArrObj;
    }
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    const filterPhones = (phone) =>{
        setSerachText(phone)
        const tempContacts = convertContacts(contactsExternal);
        if(phone ){
            const filterdContacts = tempContacts.filter(c=> c.phone.includes(phone))
            setContacts(filterdContacts)
        }else{
           
            setContacts(tempContacts)
        }
       


    }
    return (
        <>
            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"


            >


                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >


                <DialogContent dividers>
                    <Grid
                        container

                        sx={{ height: '70vh', minWidth: '100%' }}
                        alignItems="center"
                        justify="center">

                        <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}

                        ></Grid>
                        <Grid
                            item
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            alignItems="center"
                            justify="center"
                            sx={{ height: '40vh', }}
                        >
                            <TextField
                                fullWidth
                                onChange={e=> filterPhones(e.target.value)}
                                value={serachText}
                                label="Buscar"
                            />
                            <TablaUsuarios
                                rows={contacts}
                                columns={[

                                    {
                                        headerName: 'Número', field: 'phone', flex: 1
                                    },

                                ]}
                                checkboxSelection

                                onsSelectionChange={(rowsSelected) => {

                                    let selectedTemp = [];
                                    rowsSelected.map((r) => {
                                        selectedTemp.push(r.phone);
                                    })
                                    setSelectedContacts(selectedTemp);
                                }}

                            />

                        </Grid>
                        <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}

                        ></Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            align="center"
                        >
                            <MensajeEnvio
                                theme={theme}
                                forwardingId={forwardingId}
                                whatsapp={whatsapp}
                                disableNav
                                contactsExternal={selectedContacts}
                                reloadMain={reloadMain}
                                scheduled={scheduled}
                                isSequence={isSequence}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>

                </DialogActions>
            </Dialog>

        </>
    );
}
