import * as React from 'react';


import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';



import { useState } from 'react';
import api from '../../services/api';

import { Autocomplete, Breadcrumbs, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Link, Switch, TextField, Typography } from '@mui/material';

import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet-async';

import ChatBotMessageModal from '../../components/ChatBotMessageModal';
import { getContrastColor } from '../../utils/generalFunctions';
import { LoadingButton } from '@mui/lab';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function PagePost() {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);


  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState({
    id: 0,
    name: '',
    replyPostMessage: '',
    dmMessage: '',
    status: 'Active',
    pageId: 0,
    accountId: 0
  });

  const [pages, setPages] = useState([]);
  const [selectedPageID, setSelectedPageID] = useState(0);

  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(0);
  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();


  React.useEffect(() => {
    setOpen(false)
    async function getAccounts() {
      try {
        const { data } = await api.post("/api/accounts");
        setAccounts(data.data);
      } catch (err) {
        console.log(err)
      }

    }
    async function getPosts() {
      try {
        const { data } = await api.post("/api/pagepost/get");
        setPosts(data.posts);
      } catch (err) {
        console.log(err)
      }

    }
    getAccounts();
    getPosts();
  }, [reload]);


  React.useEffect(() => {

    async function getPages() {
      try {
        const { data } = await api.post("/api/userpages", { accountID: currentPost.accountId });

        setPages(data.data);
      } catch (err) {
        console.log(err)
      }

    }

    if(currentPost.accountId && currentPost.accountId != 0){
      getPages();
    }
  
  }, [currentPost.accountId]);




  /**
   * Actualiza el post para tener su configuración
   * @param {*} data 
   */
  const configurePost = async () => {

    if (!currentPost?.accountId) {
      enqueueSnackbar(`Debe seleccionar una cuenta`, {
        variant: 'warning',
      });
      return;
    }
    if (!currentPost?.pageId) {
      enqueueSnackbar(`Debe seleccionar una página`, {
        variant: 'warning',
      });
      return;
    }
    if (!currentPost?.id) {
      enqueueSnackbar(`Se debe proporcionar un id de publicación`, {
        variant: 'warning',
      });
      return;
    }

    if (!currentPost?.dmMessage) {
      enqueueSnackbar(`Se debe proporcionar un mensaje para el post`, {
        variant: 'warning',
      });
      return;
    }


    if (!currentPost?.replyPostMessage) {
      enqueueSnackbar(`Se debe proporcionar un mensaje para messenger`, {
        variant: 'warning',
      });
      return;
    }
    setIsLoading(true)
    let request = {
      postID: currentPost.id,
      pageID: currentPost?.pageId,
      newObj: currentPost

    }


    try {

      const { data } = await api.post("/api/pagepost", request);

      if (data.status == "Success") {

        enqueueSnackbar('Se configuró la publicación de forma correcta', {
          variant: 'success',
        });
        setReload(!reload);
       
      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al configurar la aplicación', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al configurar la aplicación', {
        variant: 'error',
      });
    }
    setIsLoading(false);
   
    setReload(!reload)
  }


  /**
   * Actualiza el post para tener su configuración
   * @param {*} data 
   */
  const deactivePost = async (postObj, status) => {

    setIsLoading(true)
    if (!postObj?.id) {
      enqueueSnackbar(`Se debe proporcionar un id de publicación`, {
        variant: 'warning',
      });
      return;
    }

    let request = {
      postID: postObj.id,
      pageID: postObj?.pageId,
      newObj: { ...postObj, status: status }

    }


    try {

      const { data } = await api.post("/api/pagepost", request);

      if (data.status == "Success") {

        enqueueSnackbar('Se configuró la publicación de forma correcta', {
          variant: 'success',
        });
        setReload(true);

      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrió un error al configurar la aplicación', {
            variant: 'error',
          });
        }

      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al configurar la aplicación', {
        variant: 'error',
      });
    }
    setIsLoading(false)
    setReload(!reload)
  }

  /**
     * Columnas de la tabla
     */
  const columns = [
    {
      field: 'edit', headerName: 'Editar',
      renderCell: (params) => {
        return <Button onClick={() => {
          console.log(params.row)
          setCurrentPost(params.row);
          setOpen(true);
        }}>Editar</Button>
      }
    },
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Publicación',
      flex: 1,
      editable: true,

    },
    {
      field: 'replyPostMessage',
      headerName: 'Respuesta comentarios',
      flex: 1,
      editable: true,

    },

    {
      field: 'dmMessage',
      headerName: 'Mensaje',
      flex: 1,
      editable: true,

    },
    {
      field: 'status', headerName: 'Estatus', flex: 1,
      renderCell: (params) => {
        return <Chip label={params.row?.status == 'Active' ? "Activo" : 'Desactivado'} sx={{ color: getContrastColor(params.row.Status?.color ? params.row.Status.color : '#000000'), background: params.row?.status == 'Active' ? 'green' : 'red' }} />
      }
    },
    {
      field: 'deactive', headerName: 'Acciones',
      renderCell: (params) => {
        return <LoadingButton variant="outlined" loading={isLoading && currentPost.id == params.row.id} disabled={isLoading && currentPost.id != params.row.id} onClick={async () => {
          setCurrentPost(params.row)
          await deactivePost(params.row, params.row.status == "Active" ? 'Disabled' : 'Active');
        }}>{params.row.status == "Active" ? 'Desactivar' : 'Activar'}</LoadingButton>
      }
    },
  ];

  const handlePostConfig = (event) => {
    setCurrentPost({
      ...currentPost,
      [event.target.name]: event.target.value
    })
  }

  const getAccountByID = (id) =>{
    let tempAccount = {};
    accounts.map(account => {if(account.id == id){tempAccount = account}});
    return tempAccount;
  }

  const getPageByID = (id) =>{
    let tempPage = {};
    pages.map(page => {if(page.id == id){tempPage = page}});
    return tempPage;
  }
  return (
    <>
      <Helmet title="Whatsapp" />

      <Typography variant="h3" gutterBottom display="inline">
        Facebook
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Posts
        </Link>
        <Button
          variant="outlined"
          onClick={() => {
            setCurrentPost({
              id: 0,
              name: '',
              replyPostMessage: '',
              dmMessage: '',
              status: 'Active'
            }); setOpen(true)
          }}
        >
          Configurar POST
        </Button>
      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          {
            posts.length == 0 ?
              <div>No existen post configurados</div>
              :
              <TablaUsuarios
                columns={columns}
                rows={posts}
                checkboxSelection
                showDelete={false}
                onDelete={(data) => { }}
                columnVisibilityModel={{
                  actions: false
                }}
              />
          }


        </Grid>
      </Grid>
      <Dialog
        disableEnforceFocus
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          Configurar POST
        </DialogTitle >

        <DialogContent dividers fullWidth
          maxWidth="md">
          <Grid
            container

            spacing={4}
            alignItems="center"
            justify="center"
            sx={{ minWidth: '100%' }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              align="center"
            >
              <TextField

                fullWidth
                value={currentPost.name}
                label="Ingresa el nombre para identificar la publicación"
                name="name"
                variant="outlined"
                onChange={handlePostConfig}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              align="center"
            >
              <Autocomplete
                options={accounts}
                getOptionLabel={(account) => account.name}
                id="accounts"
                defaultValue={()=>getAccountByID(currentPost.accountId)}
                clearOnEscape
                onChange={(event, newValue) => {

                  if (newValue) {
                    setCurrentPost({
                      ...currentPost,
                      accountId: newValue.id
                    })
                  }

                }}

                renderInput={(params) => (

                  <TextField {...params} label="Selecciona una cuenta" variant="outlined" />

                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              align="center"
            >
              <Autocomplete
                disabled={pages?.length == 0 || !pages?.length}
                options={pages}
                getOptionLabel={(page) => page.name}
                id="pages"
                name="pageId"
                defaultValue={()=>getPageByID(currentPost.pageId)}
                clearOnEscape
                onChange={(event, newValue) => {

                  if (newValue) {
                    setCurrentPost({
                      ...currentPost,
                      pageId: newValue.id
                    })
                  }

                }}

                renderInput={(params) => (

                  <TextField {...params} label="Selecciona una página" variant="outlined" />

                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              align="center"
            >
              <TextField
                disabled={currentPost.pageId == 0}
                fullWidth
                value={currentPost.id}
                label="Ingresa el id de la publicación"
                name="id"
                variant="outlined"
                onChange={handlePostConfig}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              align="center"
            >
              <TextField
                fullWidth
                value={currentPost.replyPostMessage}
                label="Ingresa el mensaje para responder a los comentarios de la publicación"
                name="replyPostMessage"
                variant="outlined"
                onChange={handlePostConfig}
                multiline
                rows={6}

              />
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              align="center"
            >
              <TextField
                fullWidth
                value={currentPost.dmMessage}
                label="Ingresa el mensaje para responder a través de messenger"
                name="dmMessage"
                variant="outlined"
                onChange={handlePostConfig}
                multiline
                rows={6}

              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} variant="outlined" color="error" onClick={() => setOpen(false)}>
            Cancelar
          </LoadingButton>
          <LoadingButton loading={isLoading} variant='contained' color="success" disabled={!currentPost.accountId || !currentPost.pageId || !currentPost.id || !currentPost.dmMessage || !currentPost.replyPostMessage} onClick={configurePost}>Guardar</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}