import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Chip, Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import MultiSelect from '../MultiSelect';


export default function SpeechModal({ title, open, setOpen, onSave, theme, speech }) {



    /**
    * 
    */
    useEffect(() => {

        if (speech.id) {
            setSpeechInfo({
                name: speech.name,
                word: speech.word,
                replace: speech.replace,
            })
            setCurrentWords(speech?.replace?.split(','));
            setId(speech.id)
        } else {
            setSpeechInfo({
                name: '',
                word: '',
                replace: '',
            })
            setCurrentWords([]);
            setId(null)
        }

    }, [speech]);

    /**
     * Id del speech en caso de editar
     */
    const [id, setId] = useState(null);
    /**
     * Variable para el nuevo perfil
     */
    const [speechInfo, setSpeechInfo] = useState({
        name: '',
        word: '',
        replace: '',
    });

    /**
     * Variable para la lista de sistemas asignaods
     */
    const [currentWords, setCurrentWords] = useState(speech?.replace?.split(','));


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setSpeechInfo({
            ...speechInfo,
            [event.name]: event.value,
        })

    }
    /**
     * Agrega palabras al arreglo
     */
    const addWord = ()=>{

        let word = prompt('Ingresa la nueva palabara para el speech');

        let arrWord = [...currentWords];
        arrWord.push(word);
        setCurrentWords(arrWord);

        setSpeechInfo({
            ...speechInfo,
            replace: arrWord.toString(),
        })
    }

    const removeWord = (index) => {
        let arrWord = [...currentWords];
        arrWord.splice(index, 1);

        setCurrentWords(arrWord);

        setSpeechInfo({
            ...speechInfo,
            replace: arrWord.toString(),
        })
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={speechInfo.name}
                                label="Nombre del Speech"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={speechInfo.word}
                                label="Palabra a sustituir"
                                name="word"
                                variant="outlined"
                                onChange={handleChange}
                                multiline
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                           
                                {currentWords?.map((option,key) => (
                                    <Chip label={option} color="primary" clickable onClick={(event)=>{ removeWord(key) }} />
                                ))}
                           

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button fullWidth color="primary" onClick={addWord}>
                                    Agregar palabras
                            </Button>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => onSave(id, speechInfo)}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
