import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { InsertDriveFile } from '@mui/icons-material';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import { Grid } from '@mui/material';

export default function DatabaseCard({ title = "Clientes", aditionalInfo = () => <></>, actionName = "Abrir base de datos", action, button, icon, description }) {
    return (
        <Card sx={{ maxWidth: '100%', minWidth: '100%', minHeight: 300, position: 'relative' }}>
            <CardContent>

                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {description}



                </Typography>

                {
                    icon()
                }
                <Grid>
                    {
                        aditionalInfo()
                    }
                </Grid>

            </CardContent>
            {
                !action ? <div style={{
                    position: 'absolute',
                    left: 0,
                    bottom: '18px',
                    width: '100%',
                }}>
                    {button()}
                </div>
                    :
                    <Button sx={{
                        position: 'absolute',
                        left: 0,
                        bottom: '18px'
                    }} fullWidth onClick={action} size="small">{actionName}</Button>
            }


        </Card>
    );
}
