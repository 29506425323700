import { Person } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function QuoteInfo({ client, setQuoteExternal, setCompanyExternal, products, externalQuote, externalComany }) {
    const [quote, setQuote] = useState({});
    const [company, setCompany] = useState({});

    useEffect(() => {
        console.log(externalQuote)
        console.log(externalComany)
        console.log(client)
        if (externalQuote) {
            setQuote(externalQuote);
        } else {
            console.log("Entrando al else")
            setQuote({
                number: '',
                address: '',
                email: '',
                phone: '',
                clientId: client?.id,
                city: '',
                country: '',
            })
        }
        if (externalComany) {
            setCompany(externalComany);
        } else {
            setCompany({ addres: client?.address, phone: client?.phone, clientName: client?.name || client?.Chat?.name })

        }

    }, [])

    useEffect(() => {
        let total = 0.0;
        products?.map((product) => {
            let precioUnit = product.unitPrice ? parseFloat(product.unitPrice) : 0;
            let cantidadTemp = product.quantity ? parseFloat(product.quantity) : 1;
            total += (parseFloat(cantidadTemp) * parseFloat(precioUnit));

        })
        console.log(quote)
        if (quote.clientId) {
            setQuote({ ...quote, total: total.toFixed(2) })
            setQuoteExternal({ ...quote, total: total.toFixed(2) })
        }

    }, [products])

    const handleChangeQuote = (e) => {
        const event = e.target;
        setQuoteExternal({
            ...quote,
            [event.name]: event.value,
        })
        setQuote({
            ...quote,
            [event.name]: event.value,
        })
    }

    const handleChangeCompany = (e) => {
        const event = e.target;
        setCompanyExternal({
            ...company,
            [event.name]: event.value,
        })
        setCompany({
            ...company,
            [event.name]: event.value,
        })
    }
    return (
        <Grid container spacing={4} >


            <Grid item container spacing={4} xs={6} md={6}>
                <Grid item xs={8} md={8}>
                    <Typography>Datos del cliente</Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                    <Button startIcon={<Person />}></Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Número de orden"
                        name="number"
                        value={quote?.number}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Empresa"
                        name="name"
                        value={company?.name}
                        onChange={handleChangeCompany}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Nombre dle cliente"
                        name="clientName"
                        value={company?.clientName}
                        onChange={handleChangeCompany}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Dirección"
                        name="address"
                        value={company?.address}
                        onChange={handleChangeCompany}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Teléfono"
                        name="phone"
                        value={company?.phone}
                        onChange={handleChangeCompany}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Correo"
                        name="email"
                        value={company?.email}
                        onChange={handleChangeCompany}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>

            </Grid>
            <Grid item container spacing={2} xs={6} md={6}>
                <Grid item xs={8} md={8}>
                    <Typography>Datos del vendedor</Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                    <Button startIcon={<Person />}></Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Empresa"
                        name="name"
                        value={quote?.name}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Dirección"
                        name="address"
                        value={quote?.address}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Teléfono"
                        name="phone"
                        value={quote?.phone}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Correo"
                        name="email"
                        value={quote?.email}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Impuesto (porcentaje)"
                        name="taxes"
                        value={quote?.taxes}
                        onChange={handleChangeQuote}
                        variant="filled"
                        color="primary"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}