import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react";

/**
 * Componente que muestra el filtro de la tabla
 */
const CustomFilter = ({ title, field, isSelect, list, filterFunc, isArray, keys, value, isString, width = 150 }) => {

    useEffect(() => {


        setSelectValue(value ? value : []);


    }
        , [value]);

    const [selectValue, setSelectValue] = useState(value ? value : []);
    /**
     * Manda el valor seleccionado o utilizado para el filtro
     */
    const handleChange = (e) => {
        let value = e.target.value;


        if (isSelect) {
            setSelectValue(e.target.value);

            if (isString) {
                filterFunc(field, e.target.value.toString());
            } else {
                filterFunc(field, e.target.value);
            }

        } else {
            filterFunc(field, e.target.value);
        }

    }
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center" sx={{ height: '53px;', minWidth: width }}>
            {
                !isSelect ?
                    <>


                        <TextField
                            label={title}
                            fullWidth
                            autoComplete='off'
                            type='text'
                            onChange={handleChange}
                            
                            onClick={e => e.stopPropagation()}
                        />

                    </>
                    :

                    <FormControl>

                        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                        <Select
                            autoComplete='off'
                            type='text'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectValue}
                            label={title}
                            multiple
                            fullWidth
                            onChange={handleChange}
                            style={{ width: '10vw' }}
                            onClick={e => e.stopPropagation()}
                        >
                            {list?.map((item) => {
                                if (isArray) {
                                    return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                                } else {
                                    return (<MenuItem key={item[keys[0]]} value={item[keys[0]]}>{item[keys[1]]}</MenuItem>)
                                }


                            })}
                        </Select>
                    </FormControl>



            }

        </Grid>
    )
}

export default CustomFilter;