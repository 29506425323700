

import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import CustomFilter from "../CustomFilter"
import { useEffect, useState } from "react";
import { Grid, IconButton, TextField } from "@mui/material";
import { Done } from "@mui/icons-material";

/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const WebhookMsgTable = ({ massiveMsg, onEdit, showResendButton, onResendAll, onResend, isEmail }) => {
    /**
     * Cada que cambien  los datos filtra de nuevo
     */
    useEffect(() => {
        //  console.log(massiveMsg)
        setFiltered(massiveMsg);
    }, [massiveMsg])

    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] = useState('');

    /**
    * Guarda los datos filtrados
    */
    const [filtered, setFiltered] = useState(massiveMsg);

    /**
   * Guarda los datos filtrados
   */
    const [selected, setSelected] = useState([]);

    /**
    * Guarda los datos de lso filtros
    */
    const [latsFilters, setLatsFilters] = useState({});

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {

        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }

        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = massiveMsg.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

                    } else {

                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }
                }


            }
            return isInFilter;
        });

        setFiltered(finalResults);
    };

    /**
     * Vista para la edición de un campo simple
     * @param {*} props 
     * @returns 
     */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        // console.log(props);

        const [newValue, setNewValue] = useState(props.row.contact || props.row.phone)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            api.setEditCellValue({ id, field: 'status', value: 'Modificado' })
            api.commitCellChange({ id, field: 'status' })
            api.setCellMode(id, 'status', "view");
            setNewValue(target.value)
        }

        const handleCommit = (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();

            onEdit(props.row.id, value);

        }


        return (
            <Grid container sx={{ width: '100%' }} >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        value={newValue}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} >
                    <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                        <Done color="success" />
                    </IconButton>
                </Grid>
            </Grid>
        )

    }

    return (

        <TablaUsuarios
            isEmail={isEmail}
            rows={filtered}
            columns={[
                {
                    headerName: 'ID', field: 'id', flex: 1, hide: false, editable: true,

                },
                {
                    headerName: 'Número', field: 'phone', flex: 1, hide: false, editable: true,
                    renderEditCell: (props) => <SimpleEditField  {...props} />,
                    renderCell: (props) => {
                        return (
                            <>
                                {props.row.phone || props.row.contact}
                            </>
                        )
                    }
                    ,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Estatus Twilio', field: 'twilioStatus', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Duracion de llamda', field: 'duration', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Estatus', field: 'status', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Fecha', field: 'createdAt', flex: 1, hide: false,
                    renderCell: (props) => {

                        if (props.row?.createdAt) {
                            props.row?.createdAt.replace(/T/g, ' ')
                            let newDate = props.row?.createdAt?.split(' ');

                            return (
                                <>
                                    {newDate[0]}
                                </>
                            )
                        } else {
                            return (
                                <>
                                    Sin fecha
                                </>
                            )
                        }

                    },
                },

                {
                    headerName: 'Hora', field: 'createdAtHora', flex: 1, hide: false,
                    renderCell: (props) => {

                        if (props.row?.createdAt) {
                            props.row?.createdAt.replace(/T/g, ' ')
                            let newDate = props.row?.createdAt?.split(' ');

                            return (
                                <>
                                    {newDate[1]}
                                </>
                            )
                        } else {
                            return (
                                <>
                                    Sin hora
                                </>
                            )
                        }

                    },
                },
                {
                    headerName: 'Fecha Programada', field: 'sendAt', flex: 1, hide: false,
                    renderCell: (props) => {

                        if (props.row?.sendAt) {
                            props.row?.sendAt.replace(/T/g, ' ')
                            let newDate = props.row?.sendAt?.split(' ');

                            return (
                                <>
                                    {newDate[0]}
                                </>
                            )
                        } else {
                            return (
                                <>
                                    Sin fecha
                                </>
                            )
                        }

                    },
                },
                {
                    headerName: 'Hora Programada', field: 'hora', flex: 1, hide: false,
                    renderCell: (props) => {

                        if (props.row?.sendAt) {
                            props.row?.sendAt.replace(/T/g, ' ')
                            let newDate = props.row?.sendAt?.split(' ');

                            return (
                                <>
                                    {newDate[1]}
                                </>
                            )
                        } else {
                            return (
                                <>
                                    Sin hora
                                </>
                            )
                        }

                    },
                },

            ]}
            checkboxSelection
            // onDelete={onDelete}
            onsSelectionChange={(values) => { setSelected(values); }}
            showResend
            showResendButton={showResendButton}
            onResendAll={onResendAll}
            onResend={(values) => { onResend(values) }}
        />

    )
}

export default WebhookMsgTable; 