import { Breadcrumbs, Divider, Grid, Typography, Link, Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';


import EmailEditor from 'react-email-editor';
import { Helmet } from 'react-helmet-async';
import api from '../../services/api';
import { useSearchParams } from 'react-router-dom';


const TemplateEditor = () => {
    const emailEditorRef = useRef(null);

    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentTemplateID, setCurrentTemplateID] = useState(null);

    const [currentTemplate, setCurrentTemplate] = useState(null);

    const [getTemplate, setGetTemplate] = useState(true);

    const [name, setName] = useState('');

    useEffect(() => {
       
        setCurrentTemplate(null);
        setName('');
        setCurrentTemplateID(searchParams.get("templateID"));
        setGetTemplate(true);
    }, [searchParams])


    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {

            if(!name){
                enqueueSnackbar("El nombre no puede estar vacio", {
                    variant: 'warning',
                });
                return;
            }
            const { design, html } = data;

            const blob = new Blob([html], { type: 'text/html' });


            const fileName = 'mi-archivo.html';
            const file = new File([blob], fileName, { type: 'text/html' });

            const templateBlob = new Blob([JSON.stringify(design)], { type: 'application/json' });


            const templateFileName = 'formato.json';
            const templateFile = new File([templateBlob], templateFileName, { type: 'application/json' });


            /**
             * Envia el archivo para subirlo
             */
            const formData = new FormData();
            formData.append('file', file)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (currentTemplate) {
                try {
                    const { data } = await api.post('/api/deleteFile', { fileName: currentTemplate.filePath });

                } catch (err) {
                    console.log(err)
                }
            }

            let fileID = 0;
            try {
                const { data } = await api.patch('/api/upload', formData, config);

                /**
                 * Recupera la ruta del archivo subido
                 */
                let filePath = data.fielName;

                let templateFilePath = '';
                {
                    /**
                     * Envia el archivo para subirlo
                     */
                    const formData2 = new FormData();
                    formData2.append('file', templateFile)

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    const { data } = await api.patch('/api/upload', formData2, config);
                    templateFilePath = data.fielName;
                }


                /** regista el archivo */
                {
                    let request = {};

                    let newFile = {name: name};

                    newFile.path = filePath;

                    request.fileObj = newFile;

                    if (currentTemplate) {
                        request.id = currentTemplate?.Files[0].id;
                    }

                    const { data } = await api.post("/api/addLogFile", request);

                    if (data.status == "Success") {

                        if (currentTemplate) {
                            fileID = currentTemplate?.Files[0].id
                        } else {
                            fileID = data.file.id;
                        }

                    } else {
                        if (data?.code) {
                            enqueueSnackbar(data.userMsg, {
                                variant: 'warning',
                            });
                        } else {
                            enqueueSnackbar(`Ocurrió un error al agregar el Archivo`, {
                                variant: 'error',
                            });
                        }

                        return;

                    }
                }


                let idTemplateTemp = 0;
                /** Genera la plantilla */
                {
                    let request = {
                        id: 0,
                        templateObj: {
                            name: 'Plantilla de correo',
                            type: 'Correo',
                            filePath: templateFilePath,
                            message: '',
                            fileName: templateFilePath
                        },
                        words: []

                    }

                    if (currentTemplate) {
                        request.id = currentTemplate.id;
                    }

                    const { data } = await api.post("/api/createTemplate", request);
                    idTemplateTemp = data?.template?.id;

                }
                /**
                 * Agrega el archivo html
                 */
                {
                    const { data } = await api.post("/api/addTempFile", { id: idTemplateTemp, files: [fileID] });

                    if (data.status == "Success") {
                        enqueueSnackbar(`Se ${currentTemplate?.id ? 'modificó' : 'creó'} la plantilla de forma correcta`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
                            variant: 'error',
                        });
                        return;
                    }
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
                    variant: 'error',
                });

            }
        });
    };

    const onReady = async () => {
        // editor is ready
        // you can load your template here;

        if (currentTemplateID && getTemplate) {
            setGetTemplate(false);
            try {


                let currTempTemplate = {};
                try {


                    const { data } = await api.post("/api/getTemplateByID", { id: currentTemplateID });
                    currTempTemplate = data.messageTemplate;
                    setCurrentTemplate(data.messageTemplate);
                    setName(data.messageTemplate.name);
                } catch (err) {
                    enqueueSnackbar(`Ocurrió un error al cargar la plantilla`, {
                        variant: 'error',
                    });
                }

                if (!currTempTemplate) {
                    return;
                }

                let filePath = currTempTemplate.filePath;
                const response = await fetch(`${process.env.REACT_APP_BACK_URL}/media/${filePath}`);

                if (!response.ok) {
                    throw new Error('Error al cargar el archivo JSON');
                }

                const data = await response.json();
                emailEditorRef.current.editor.loadDesign(data);
            } catch (err) {
                enqueueSnackbar(`Ocurrió un error al cargar la plantilla`, {
                    variant: 'error',
                });
            }
        }


    };

    return (

        <>
            <Helmet title="Etiquetas" />

            <Typography variant="h3" gutterBottom display="inline">
                Plantilla de correo
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Plantillas
                </Link>
                <Link >Correo</Link>

            </Breadcrumbs>
            <br />
            <Divider my={6} />


            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >
                <TextField
                    label="Nombre de la plantilla"
                   
                    color="primary"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}>

                </TextField>
            </Grid>
            <EmailEditor style={{ height: '80vh' }} ref={emailEditorRef} onReady={onReady} />

            <Button style={{
                position: 'absolute',
                left: '47%',
                minWidth: '132px',
                bottom: '17px'
            }} variant="contained" color="success" onClick={() => exportHtml()}>Guardar</Button >

        </>
    );
};

export default TemplateEditor;