import * as React from 'react';

import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TwilioModal from '../../components/TwilioModal';
import { Helmet } from 'react-helmet-async';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function TwilioPhones({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [twilioPhones, setTwilioPhones] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * El telefono seleccionado
   */
  const [currentTwilioPhone, setCurrentTwilioPhone] = useState({})

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getTwilioPhones", { wpSessions: userInfo.data.userInfo.wpsessions });

        setTwilioPhones(data.twilioPhones);
      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      field: 'actions', headerName: 'Editar', flex: 1, editable: false, renderCell: (params) => {
        return (
          <Button onClick={() => { setCurrentTwilioPhone(params.row); setEstadoModal(true) }}>Editar</Button>
        )
      }, hideable: true
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'phone', headerName: 'Teléfono', flex: 1, editable: false,
    },
  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteTwilio", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Teléfono eliminada', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar el teléfono', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar el teléfono', {
          variant: 'error',
        });
      }
    });


  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addWebhook = async (newWebhook) => {


    let request = {

      twilioPhone: newWebhook

    }

    if (newWebhook.id) {
      request.id = newWebhook.id
    }
    try {

      const { data } = await api.post("/api/createTwilioPhone", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar(`Se ${newWebhook.id ? 'actualizó' : 'agregó'} el teléono exitosamente`, {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar(`Ocurrió un error al ${newWebhook.id ? 'actualizar' : 'agregar'} el teléfono`, {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar(`Ocurrió un error al  ${newWebhook.id ? 'actualizar' : 'agregar'} el teléfono`, {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Twilio" />

      <Typography variant="h3" gutterBottom display="inline">
      Twilio
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Redes sociales
        </Link>
        <Link >Twilio</Link>
        { revisaPermisos(['Agregar telefono twilio']) &&

          <Button
            variant="outlined"
            onClick={() => { setEstadoModal(!estadoModal); setCurrentTwilioPhone(null) }}
          >
            Nuevo teléfono
          </Button>

        }
      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={twilioPhones}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar telefono twilio'])}
            onDelete={(data) => { deleteSession(data); }}
            columnVisibilityModel={{ actions: revisaPermisos(['Editar telefono twilio']) }}
          />
          {
            revisaPermisos(['Agregar telefono twilio']) &&
         
           


              <TwilioModal
                title={currentTwilioPhone ? "Editar teléfono" : "Nuevo teléfono"}
                open={estadoModal}
                setOpen={setEstadoModal}
                wpsessions={sessionsUser}
                theme={theme}
                wpsessionsId={sessionsUserNum}
                onSave={addWebhook}
                currentTwilioPhone={currentTwilioPhone}
              />
          }

        </Grid>
      </Grid>


    </>
  );
}


