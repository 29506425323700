
/**
 * Componente para mostrar las hojas de un libro
 */

import { Add, Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import api from '../../services/api';
import PageModal from '../PageModal';
import './index.css';

const BookFooter = ({ bookID,bookOwner, onPageChange, addClientToDB, setAddClientToDB, reload, reloadClients, setReloadClients }) => {

    const scrollRef = useRef();

    const scroll = (scrollOffset) => {
        scrollRef.current.scrollLeft += scrollOffset;
    };

    const { enqueueSnackbar } = useSnackbar();
    const [currentPages, setCurrentPages] = useState([]);

    const [selectedPage, setSelectedPage] = useState(null);

    const [open, setOpen] = useState(false);

    const [reloadLocal, setReloadLocal] = useState(false);

    const [userId, setUserId] = useState(null)

    const isSelected = (id) => {

        return selectedPage?.id == id ? 'contained' : 'outlined';
    }

    const getColor = (id) => {

        return selectedPage?.id == id ? 'secondary' : 'primary';
    }


    useEffect(() => {

        async function getPages() {
            try {

                const { data } = await api.post('/api/getPages', { bookID: bookID });
                setCurrentPages(data.pages);
                
                if(data?.pages?.length > 0){
                    const page = data.pages[0];
                    setSelectedPage(page); 
                    onPageChange(page.id, page);
                }
            } catch (err) {

            }
        }
        async function getUserId() {

            try {
                const userInfo = await api.post("/api/getPermissions");
                setUserId(userInfo.data.userInfo.id);

            } catch (err) {
                console.log(err);
            }

        }
        getUserId()
        getPages();
    }, [bookID, reload, reloadLocal])



    const deletePages = async (event, id) => {

        const resp = window.confirm("¿Desea eliminar la página seleccionada?");

        if (!resp) {
            return;
        }

        event.preventDefault()

        try {

            const { data } = await api.post('/api/deletePages', { groups: [{ id: id }] });

            enqueueSnackbar(`Se eliminó la página`, {
                variant: 'success',
            });

            setReloadLocal(!reloadLocal);
            setReloadClients(!reloadClients);
        } catch (err) {
            console.log(err);
        }
    }


    useEffect(() => {


        if (addClientToDB) {
            setOpen(true);
        }
    }, [addClientToDB])

    return (
        <>
            <Grid container>
                <Grid item xs={2} sm={2} md={1} lg={1}><Button onClick={() => scroll(-100)} >{"<"}</Button></Grid>
                <Grid item xs={8} sm={8} md={10} lg={10}>
                    <div ref={scrollRef} className="footer-container">
                        <Button startIcon={<Add color="success" />} color="secondary" onClick={() => { setOpen(true); }}> Agregar hoja</Button>
                        {
                            currentPages?.map((page) => {
                                return <Button endIcon={<>{userId == bookOwner&&<Delete onClick={(event) => { deletePages(event, page.id) }} />}</>} color={getColor(page.id)} variant={isSelected(page.id)} onClick={() => { setSelectedPage(page); onPageChange(page.id, page); }}>{page.name}</Button>
                            })
                        }


                    </div>
                </Grid>
                <Grid item xs={2} sm={2} md={1} lg={1}><Button onClick={() => scroll(100)} >{">"}</Button></Grid>
            </Grid>
            <Dialog
                disableEnforceFocus
                onClose={() => { setOpen(false) }}
                maxWidth={'md'}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => { setOpen(false) }}>
                    {addClientToDB ? `Agregar clientes a la hoja ${selectedPage.name}` : "Nueva hoja"}
                </DialogTitle >

                <DialogContent dividers>

                    <PageModal databaseID={selectedPage?.id} groupID={bookID} addClientToDB={addClientToDB} setAddClientToDB={setAddClientToDB} onClose={() => { setReloadClients(!reloadClients); setReloadLocal(!reloadLocal); setOpen(false); }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={() => { setOpen(false); setAddClientToDB(false); }}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}

export default BookFooter;