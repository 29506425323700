import { Button, Grid } from "@mui/material"
import { useState } from "react"
import PageSelect from "./PageSelect"

export default function MultiPageSelect({onComplete}) {

    const [pages, setPages] = useState([])

    function addPage() {
        setPages(oldPages => [...oldPages, { book: {}, page: {}, isNewBook: false, isNewPage: false }])
    }

    function handleChangeData(index, book, page, isNewBook, isNewPage) {
        let tempPages = [...pages]
        tempPages[index] = { book, page, isNewBook, isNewPage }
        setPages(tempPages)
    }

    function handleDeleteItem(index) {
        let tempPages = [...pages]
        tempPages.splice(index, 1);
        setPages(tempPages)
    }

    function addPages(){
        /**Validacion de que todos los campos esten completos */
        onComplete(pages)
    }
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Button onClick={addPage} fullWidth variant="contained">Agregar hoja</Button>
            </Grid>
            {
                pages.map((page, key) => (
                    <Grid container item key={key} pacing={4}>

                        <PageSelect
                            index={key}
                            extSelectedBook={page.book}
                            extSelectedPage={page.page}
                            changeData={handleChangeData}
                        />
                        <Grid item xs={12}>
                            <Button color="error" variant="outlined" onClick={() => handleDeleteItem(key)}>Eliminar</Button>
                        </Grid>
                    </Grid>
                ))
            }
             <Grid item xs={12}>
                <Button color="success" onClick={addPages} fullWidth variant="contained">Continuar</Button>
            </Grid>
        </Grid>
    )
}