import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Alert, Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';


import api from '../../services/api';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';


export default function ClientCalendarEventModal({ open, setOpen, adviserID, date, dayNumber, text, name, formId, image }) {
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Variable para guardar los datos del usuario
     */
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [summary, setSummary] = useState('');

    const [hourList, setHourList] = useState([]);

    const [selectedHour, setSelectedHour] = useState(null);
    const [isLoading, setIsLoaging] = useState(false)
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        async function getHours() {
            try {
                const { data } = await api.post(`/api/client/calendar/${adviserID}/available`, { date, day: dayNumber })
                setHourList(data.hours)
            } catch (err) {

                enqueueSnackbar("Ocurrio un error al consultar los horarios", {
                    variant: 'error',
                });
            }
        }
        getHours();
    }, [date, dayNumber])


    const createEvent = async () => {
        try {


            if (!summary) {
                enqueueSnackbar("Debe agregar un titulo", {
                    variant: 'warning',
                });
                return;
            }

            if (!email) {
                enqueueSnackbar("Debe agregar un correo", {
                    variant: 'warning',
                });
                return;
            }

            if (!phone) {
                enqueueSnackbar("Debe agregar un teléfono", {
                    variant: 'warning',
                });
                return;
            }

            if (!selectedHour) {
                enqueueSnackbar("Debe seleccionar una hora", {
                    variant: 'warning',
                });
                return;
            }
            setIsLoaging(true)
            const programmedDate = new Date(date);
            programmedDate.setHours(selectedHour);

            const request = {

                summary,
                adviserID,
                email,
                phone,
                programmedDate,
                formId
            }

            const { data } = await api.post('/api/client/calendar', request);

            if (data.status == "Error") {
                enqueueSnackbar(data.msg, {
                    variant: 'error',
                });

            } else {
                enqueueSnackbar("Cita programada", {
                    variant: 'success',
                });
                setOpen(false)
            }

            // setOpen(false)
        } catch (err) {
            enqueueSnackbar("Ocurrió un error al agendar la cita", {
                variant: 'error',
            });
        }
        setIsLoaging(false)
    }

    function convertToAMPM(horasNum) {
        const ampm = horasNum >= 12 ? 'PM' : 'AM';
        horasNum = horasNum % 12 || 12; // Si es 0, establecerlo como 12

        return `${horasNum} ${ampm}`
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Programar cita ({name})
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                        {image && <Grid item xs={3}>
                            <img style={{ width: '100%' }} src={`${process.env.REACT_APP_BACK_URL}/media/${image}`} />
                        </Grid>}

                        <Grid item xs={image ? 9 : 12}>
                            <Alert severity="info">{text}</Alert>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth name="summary" label="Titulo"
                                value={summary} onChange={(e) => setSummary(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <PhoneInput
                                country={navigator.language || navigator.userLanguage}
                                enableSearch={true}
                                placeholder="Teléfono"
                                value={phone ? phone : ''}
                                onChange={(phone) => { setPhone(phone) }}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                type='email'
                                fullWidth name="email" label="Correo"
                                value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Hora de la cita"
                                value={selectedHour}
                                onChange={(e) => setSelectedHour(e.target.value)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {hourList?.map((hour) => (
                                    <MenuItem key={hour} value={hour}>
                                        {convertToAMPM(hour)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button disabled={isLoading} variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button disabled={isLoading} variant="outlined" color="success" onClick={createEvent}>
                        Guardar
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
}
