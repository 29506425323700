import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Checkbox, Dialog, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, TextField } from '@mui/material';
import api from '../../services/api';
import MultiSelect from '../MultiSelect';
import { Label } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

export default function AddTagmodal({ title, open, selectedChats, setOpen,relaod,selectedChatIDs, isFromFB,theme }) {


    const [tags, setTags] = useState([]);
    const [addedTags, setAddedTags] = useState([]);
    const [removedTags, setRemovedTags] = useState([]);
    const [chatTags, setChatTags] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [chatIDs, setChatIDs] = useState([]);

    const resetValues = () => {
        setAddedTags([]);
        setRemovedTags([]);
    }

    useEffect(() => {
        setTags(null);

        async function getDatabases() {
            try {
                const { data } = await api.post("/api/v2/getUserTags");
                setTags(data.tags);
            } catch (err) {
                console.log(err);
            }
        }

        getDatabases();
    }, [])

    useEffect(() => {
        resetValues();

        let tempchatTags = [];

        selectedChats?.map((chat) => {
            chat?.Client?.Tags.map((tag) => {
                tempchatTags.push(tag.id);
            })
        })
        setChatTags(tempchatTags);
    }, [selectedChats])

    useEffect(() => {
        setChatIDs(selectedChatIDs);
    }, [selectedChatIDs])


    const handleClose = () => {
        setOpen(false);
    }

    const addOrRemoveTag = (id) => {
        let tempAddedTags = [...addedTags]
        let tempremovedTags = [...removedTags]
        const index = tempAddedTags.indexOf(id);
        const removedIndex = tempremovedTags.indexOf(id);

        /** Si no exitse lo agregamos y lo quitamos de los removed tags */
        if (index == -1) {

            tempAddedTags.push(id);

            if (removedIndex > -1) {
                tempremovedTags.splice(index, 1);
            }

        } else {

            tempAddedTags.splice(index, 1);

            if (removedIndex == -1) {
                tempremovedTags.push(id);
            }
        }

        setRemovedTags(tempremovedTags)
        setAddedTags(tempAddedTags)
    }



    const saveTags = async () => {

        try {
            const { data } = await api.post('/api/addTagToClients', {

                clients: chatIDs,
                addedTags: addedTags,
                removedTags: removedTags,
                isFromChat: true,
                isFromFB
            });

            if (data.status == "Success") {
                enqueueSnackbar("Se cambiaron las etiquetas de forma correcta", {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar("Ocurrio un error al cammbiar la etiquetas", {
                    variant: 'error',
                });
            }

            setOpen(false);
            relaod();
        } catch (err) {
            console.log(err)
            enqueueSnackbar("Ocurrio un error al cammbiar la etiquetas", {
                variant: 'error',
            });
        }
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MenuList>
                                {
                                    tags?.map((tag) => (
                                        <MenuItem key={tag.id}>
                                            <Checkbox
                                                checked={addedTags.indexOf(tag.id) > -1}
                                                indeterminate={addedTags.indexOf(tag.id) == -1 && chatTags.indexOf(tag.id) > -1 && removedTags.indexOf(tag.id) == -1}
                                                onClick={() => { addOrRemoveTag(tag.id) }}
                                            />
                                            <ListItemIcon >
                                                <Label style={{ color: tag.color ? tag.color : 'black' }} fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{tag.name}</ListItemText>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={()=>saveTags()}>

                        Asignar etiquetas

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
