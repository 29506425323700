import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function ModuleCard({ title, action, icon, description }) {
    return (
        <Card sx={{ maxWidth: '100%', minWidth: '100%', minHeight: 260, position: 'relative' }}>
            <CardContent>

                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <br></br>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {description}
                </Typography>

                {
                    icon()
                }

            </CardContent>



            <Button sx={{
                position: 'absolute',
                left: 0,
                bottom: '18px'
            }} fullWidth onClick={action} size="small">Activar</Button>



        </Card>
    );
}
