import * as React from 'react';

import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet-async';
import { useGoogleLogin } from '@react-oauth/google';
import { LoadingButton } from '@mui/lab';
import AddGoogleContactsModal from '../../components/AddGoogleContactsModal';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function GoogleContacts({ theme }) {



    /**
    * Variable que recibirá el objeto de sesiones
    * incluye su lista de mensajes
    */
    const [accounts, setAccounts] = useState([]);

    /**
     * Variable que contiene la lista de permisos del usuario
     */
    const [permissionsUser, setPermissionsUser] = useState([]);

    /**
    * Variable que contiene la lista sistemas sobre los que tiene permiso
    */
    const [sessionsUser, setSessionsUser] = useState([]);

    /**
    * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
    */
    const [sessionsUserNum, setSessionsUserNum] = useState([]);


    /**
    * Variable para recargar
    */
    const [reload, setReload] = useState(false);


    /**
      * Notifiaciones
      */
    const { enqueueSnackbar } = useSnackbar();


    const [filterName, setFilterName] = useState(null);


    const [syncContacts, setSyncContacts] = useState(false);

    const [open, setOpen] = useState(false);
    
    React.useEffect(() => {



        async function getSessions() {

            try {

                const userInfo = await api.post("/api/getPermissions");


                setPermissionsUser(userInfo.data.userInfo.permissions)
                setSessionsUser(userInfo.data.userInfo.wpsessions);
                setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);


            } catch (err) {
                console.log(err)
            }
        }
        getSessions();

    }, [reload]);

    React.useEffect(() => {
        async function getaccounts() {

            try {

                const { data } = await api.post("/api/getAccounts", { name: filterName });

                setAccounts(data.googleAccounts);
            } catch (err) {
                console.log(err)
            }
        }
        getaccounts();

    }, [filterName, reload])

    /**
      * Compara la lista de permisos con los permisos asignados
      */
    const revisaPermisos = (allow, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < permissionsUser.length; j++) {
                // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
                if (allow[i] == permissionsUser[j]) {
                    count++;
                }
            }
        }
        //  console.log(`${count} >= ${minMatch}`);
        // console.log(count >= minMatch);
        return count >= minMatch;
    }

    const synContacts = async (id) => {
        setSyncContacts(true);
        try {

            const { data } = await api.post("/google/syncContacts", { id: id });

            // console.log(data);

            if (data.status == "Success") {

                enqueueSnackbar('Se sincronizaron los contactos', {
                    variant: 'success',
                });
                setReload(true);

            } else {

                enqueueSnackbar('Ocurrió un error al sincronizar los contactos', {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar('Ocurrió un error al sincronizar los contactos', {
                variant: 'error',
            });
        }
        setSyncContacts(false);
    }
    /**
     * Columnas de la tabla
     */
    const columns = [

        {
            field: 'name', headerName: 'Nombre', flex: 1, editable: false,
        },
        {
            field: 'status', headerName: 'Estatus', flex: 1, editable: false,
        },
        {
            field: 'sync', headerName: 'Acciones', flex: 1, editable: false,
            renderCell: (params) => {
                return <LoadingButton loading={syncContacts} variant="contained" onClick={() => synContacts(params.row.id)} color="success">Sincronizar contactos</LoadingButton>
            }
        },

    ];
    /**
     * 
     * Elimina una sesion por el id
     * @param {*} id 
     */
    const deleteSession = async (dataSessions) => {

        dataSessions.map(async (ss, key) => {
            try {

                const { data } = await api.put("/api/deleteAccount", { id: ss.id });

                // console.log(data);

                if (data.status == "Success") {

                    enqueueSnackbar('Sesión eliminada eliminado', {
                        variant: 'success',
                    });
                    setReload(true);

                } else {

                    enqueueSnackbar('Ocurrió un error al eliminar la sesión', {
                        variant: 'error',
                    });
                }

            } catch (err) {
                console.log(err)
                enqueueSnackbar('Ocurrió un error al eliminar la sesión', {
                    variant: 'error',
                });
            }
        });


    }

    const login = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/pubsub https://www.googleapis.com/auth/gmail.modify',
        onSuccess: async ({ code }) => {
            addAccount(code);
        },
        flow: 'auth-code',
    });
    /**
     * Crea un nuevo producto
     * @param {*} data 
     */
    const addAccount = async (code) => {


        let request = {

            code: code

        }

        try {

            const { data } = await api.post("/google/addAccount", request);

            //console.log(data);

            if (data.status == "Success") {

                enqueueSnackbar('Se sincronizó la cuenta de forma correcta', {
                    variant: 'success',
                });
                setReload(true);

            } else {

                enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
                    variant: 'error',
                });
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
                variant: 'error',
            });
        }

        setReload(!reload)
    }
    return (
        <>
            <Helmet title="Cuentas de Google" />

            <Typography variant="h3" gutterBottom display="inline">
                Google
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Cuentas
                </Link>

                {
                    revisaPermisos(['Sincronizar cuenta de google']) &&
                    <Button variant="contained" onClick={() => login()}>
                        Sincronizar cuenta de google 🚀{' '}
                    </Button>
                }
                {revisaPermisos(['Agregar contactos']) &&
                    <Button variant="contained" onClick={() => { setOpen(true); }}>
                        Agregar contactos
                    </Button>
                }

            </Breadcrumbs >
            <br />
            <Divider my={6} />
            <Grid
                container

                sx={{ padding: '20px', height: '80vh' }}
                alignItems="center"
                justify="center"
            >
                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    align="center"
                    sx={{ height: '10%' }}
                >
                    <TextField
                        label="Buscar cuenta"
                        fullWidth
                        onChange={(e) => { setFilterName(e.target.value) }}
                    />
                </Grid>
                <Grid
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    align="center"
                    sx={{ height: '10%' }}
                >
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '100%' }}
                >
                    <TablaUsuarios
                        columns={columns}
                        rows={accounts}
                        checkboxSelection
                        showDelete={revisaPermisos(['Eliminar productos'])}
                        onDelete={(data) => { deleteSession(data); }}
                        columnVisibilityModel={{
                            edit: revisaPermisos(['Modificar productos'])
                        }}
                    />

                </Grid>
            </Grid>

            <AddGoogleContactsModal
                open={open}
                setOpen={setOpen}
                theme={null}
                title={"Agregar contactos"}

            />
        </>
    );
}