import * as React from 'react';

import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import ProductsTableModal from '../../components/ProductsTableModal';
import { Helmet } from 'react-helmet-async';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Products({ theme }) {


  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [products, setProducts] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentSessionName, setCurrentSessionName] = useState('');


  const [currentProduct, setCurrentProduct] = useState(null)
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();


  const [filterName, setFilterName] = useState(null);

  React.useEffect(() => {


    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);


      } catch (err) {
        console.log(err)
      }
    }
    getSessions();

  }, [reload]);

  React.useEffect(() => {
    async function getProducts() {

      try {

        const { data } = await api.post("/api/getProducts", { name: filterName });

        setProducts(data.products);
      } catch (err) {
        console.log(err)
      }
    }
    getProducts();

  }, [filterName])

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [
    {
      field: 'edit', headerName: 'Editar', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <Button onClick={() => {
            setCurrentProduct(params.row);
            setEstadoModal(true);
          }}>Editar</Button>
        )
      }
    },
    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'description', headerName: 'Descripción', flex: 1, editable: false,
    },

  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    const ids = dataSessions.map((product) => product.id)
    try {

      const { data } = await api.put("/api/deleteProduct", { ids });

      // console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se eliminaron los productos', {
          variant: 'success',
        });
        setReload(!reload)

      } else {

        enqueueSnackbar('Ocurrió un error al eliminar los productos', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al eliminar los productos', {
        variant: 'error',
      });
    }

    

  }
  /**
   * Crea un nuevo producto
   * @param {*} data 
   */
  const addWebhook = async (newProduct) => {


    let request = {

      productObj: newProduct

    }

    if (newProduct.id) {
      request.id = newProduct.id;
    }

    try {

      const { data } = await api.post("/api/createProduct", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se creó el producto correctamente', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al crear el producto', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear el producto', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }
  return (
    <>
      <Helmet title="Productos" />

      <Typography variant="h3" gutterBottom display="inline">
        Productos
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Personalización
        </Link>
        <Link >Productos</Link>
        {
          revisaPermisos(['Agregar productos']) &&
          <Button variant='outlined' onClick={() => { setCurrentProduct(null); setEstadoModal(true); }} >Nuevo producto</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          align="center"
          sx={{ height: '10%' }}
        >
          <TextField
            label="Buscar producto"
            fullWidth
            onChange={(e) => { setFilterName(e.target.value) }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          align="center"
          sx={{ height: '10%' }}
        >
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={products}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar productos'])}
            onDelete={(data) => { deleteSession(data); }}
            columnVisibilityModel={{
              edit: revisaPermisos(['Modificar productos'])
            }}
          />
          {
            revisaPermisos(['Agregar productos', 'Modificar productos']) &&
            <>




              <ProductsTableModal
                title={currentProduct ? "Editar producto" : "Nuevo producto"}
                open={estadoModal}
                setOpen={setEstadoModal}
                product={currentProduct}
                theme={theme}
                onSave={addWebhook}
              />
            </>
          }

        </Grid>
      </Grid>


    </>
  );
}



const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
		align-items: center;
	}
`;