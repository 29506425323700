
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from '@mui/material';
import { useState } from 'react';
/**
 * Componente principal
 * @returns 
 */
export default function ContactsModal({ title = "Contactos", open, setOpen, contacts, setContacts, emails, setEmails, names, setNames }) {


  /**
   * Cierra el modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  const [activeEmails, setactiveEmails] = useState(!!emails);
  const [activeNames, setactiveNames] = useState(!!names);

  const getGridWidth = () => {
    if (activeEmails && activeNames) {
      return 4;
    } else if (activeEmails || activeNames) {
      return 6;
    } else {
      return 12
    }
  }

  const clearNames = (checked) => {
    if (!checked) {
      setNames("")
    }
    setactiveNames(checked)
  }

  const clearEmails = (checked) => {
    if (!checked) {
      setEmails("")
    }
    setactiveEmails(checked)
  }
  return (
    <Dialog
      disableEnforceFocus
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}




    >


      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle >


      <DialogContent dividers>
        <Grid container>
          <Grid item container>
            {setNames != null && <Grid item>
              <FormControlLabel
                control={<Checkbox checked={activeNames} onChange={(e) => clearNames(e.target.checked)} />}
                label="Agregar nombres"
              />
            </Grid>
            }
            {setEmails != null && <Grid item>
              <FormControlLabel
                control={<Checkbox checked={activeEmails} onChange={(e) => clearEmails(e.target.checked)} />}
                label="Agregar correos"
              />
            </Grid>
            }
          </Grid>
          {activeNames &&
            <Grid item md={getGridWidth()}>
              <Typography>Nombres</Typography>
              <textarea value={names} style={{ maxWidth: '100%' }} onChange={(e) => { setNames(e.target.value) }} name="names" cols="65" rows="24">

              </textarea>
            </Grid>
          }

          <Grid item md={getGridWidth()}>
            <Typography>Teléfonos</Typography>
            <textarea value={contacts} style={{ maxWidth: '100%' }} onChange={(e) => { setContacts(e.target.value) }} name="phones" cols="65" rows="24">

            </textarea>
          </Grid>

          {activeEmails &&
            <Grid item md={getGridWidth()}>
              <Typography>Correos</Typography>
              <textarea value={emails} style={{ maxWidth: '100%' }} onChange={(e) => { setEmails(e.target.value) }} name="emails" cols="65" rows="24">

              </textarea>
            </Grid>}

        </Grid>


      </DialogContent>


      <DialogActions>
        <Button variant="outlined" color="success" onClick={handleClose}>
          Guardar
        </Button>

      </DialogActions>
    </Dialog>

  );
}