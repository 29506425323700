import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import MultiSelect from '../MultiSelect';
import { Delete } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import api from '../../services/api';


export default function TemplateModal({ title, open, setOpen, onSave, theme, template, addFile, removeFile, wordList }) {

    /**
     * 
     */
    useEffect(() => {


        if (template.name) {
            setwebhookInfo(template);
            setFiles(template.Files);

            let tempFIlesIds = [];

            template.Files.map((f, k) => {
                tempFIlesIds.push(f.id);
            });
            setFileIds(tempFIlesIds);
            setId(template.id);
            setCurrentWordsList(template.words)
        } else {
            setFile(null)
            setwebhookInfo({
                name: '',
                message: '',
                fileName: ''
            });
            setFileName(null);

            setFiles([]);
            setFileIds([]);
            setCurrentWordsList([])
            setId(null);
        }

        setIsLoading(false);

        async function getFileList() {
            try {
                const { data } = await api.post('/api/getLogFile');


                
                setFileList(data.files)
            } catch (err) {
                console.log(err);
            }
        }
        getFileList();
    }, [template]);


    /**
     * Variable para el nuevo template
     */
    const [webhookInfo, setwebhookInfo] = useState({
        name: '',
        type: 'Mensaje',
        filePath: '',
        message: '',
        fileName: ''
    });

    /**
     *  Id del webhook 
     * Necesita cambiar cada que se abre la ventana
     */
    const [id, setId] = useState(null);
    /**
     * Archivo para el envio adjunto
     */
    const [file, setFile] = useState(null);

    const [fileName, setFileName] = useState(null);

    /**
     * Variable para los archivo 
     */
    const [files, setFiles] = useState([]);


    /**
     * Variable para los archivos subidos a elegir
     */
    const [fileList, setFileList] = useState([]);

    /**
    * Variable para los id de archivos
    */
    const [fileIds, setFileIds] = useState([]);

    /**
     * Variable para la lista de speechs 
     */
    const [currentWordsList, setCurrentWordsList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Agrega un archivo al arreglo
     */
    const addFileLocal = async (file) => {
        setIsLoading(true);

        let filesTemp = [...files]
        filesTemp.push(file);
        //console.log(filesTemp);
        setFiles(filesTemp);


        let fileIdsTemp = [...fileIds]


        const resp = await addFile(file, id);
        fileIdsTemp.push(resp.id);


        setwebhookInfo({
            ...webhookInfo,
            filePath: resp.path,
        })

        setFileIds(fileIdsTemp);
        setIsLoading(false);
    }

    /**
     * Elimina un archivo del arreglo
     * @param {*} file 
     */
    const removeFileLocal = async (index) => {

        let filesTemp = [...files]
        filesTemp.splice(index, 1);

        let fileIdsTemp = [...fileIds]

        await removeFile(fileIdsTemp[index], id);
        fileIdsTemp.splice(index, 1);

        setFiles(filesTemp);
        setFileIds(fileIdsTemp);
    }
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;

        setwebhookInfo({
            ...webhookInfo,
            [event.name]: event.value,
        })


    }




    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={webhookInfo.name}
                                label="Nombre de la plantilla"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}

                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                select
                                value={webhookInfo.type}
                                label="Tipo de pantilla"
                                name="type"
                                variant="outlined"
                                onChange={handleChange}

                            >
                                {["Mensaje", "Llamada"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>

                        {
                            webhookInfo.type == "Mensaje" && <Grid item xs={12} sm={12} md={12} lg={12}>


                                <TextField
                                    fullWidth
                                    value={webhookInfo.message}
                                    label="Plantilla de mensaje"
                                    name="message"
                                    variant="outlined"
                                    onChange={handleChange}
                                    multiline
                                />


                            </Grid>}
                        {
                            webhookInfo.type == "Mensaje" && <Grid item xs={12} sm={12} md={12} lg={12}>
                                <MultiSelect
                                    title="Speechs asignados"
                                    theme={theme}
                                    list={wordList}
                                    onChange={(data) => { setCurrentWordsList(data); }}
                                    initSelect={currentWordsList}

                                />

                            </Grid>
                        }
                        {/*
                            webhookInfo.type == "Mensaje" ?
                                <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                    <Button
                                        variant="contained"
                                        component="label"
                                        sx={{ width: '80%' }}

                                    >
                                        {
                                            file || fileName ?
                                                file?.name || fileName
                                                :
                                                "Subir archivo"
                                        }
                                        <input
                                            type="file"
                                            multiple={false}
                                            hidden
                                            onChange={(e) => { addFileLocal(e.target.files[0]) }}
                                        />
                                    </Button>


                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                    <LoadingButton
                                        loading={isLoading}
                                        loadingPosition="start"
                                        variant="contained"
                                        component="label"
                                        sx={{ width: '80%' }}
                                    >
                                        {
                                            file || fileName ?
                                                file?.name || fileName
                                                :
                                                "Subir audio"
                                        }
                                        <input
                                            type="file"
                                            accept=".mp3,audio/*"
                                            multiple={false}
                                            hidden
                                            onChange={(e) => { addFileLocal(e.target.files[0]) }}
                                        />
                                    </LoadingButton>
                                </Grid>

                                    }

                        {
                            webhookInfo.type != "Mensaje" && <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p style={{ fontWeight: 800 }}>*El archivo de audio debe ser mp3 y no contener números en el nombre, solo letras en mayúsculas y minúsculas</p>
                            </Grid>
                        */}
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                            <MultiSelect
                                title="Archivos"
                                theme={theme}
                                list={fileList}
                                onChange={(data) => { setFileIds(data) }}
                                initSelect={fileIds}

                            />
                        </Grid>
                        {/*
                            files?.map((fileT, key) => {

                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                        <Button
                                            key={key}
                                            variant="outlined"
                                            component="label"
                                            sx={{ width: '80%' }}

                                        >
                                            {fileT.name ? fileT.name : ''}
                                            {fileT.path ? fileT.path : ''}
                                        </Button>
                                        <Button onClick={() => { removeFileLocal(key) }} variant="outlined" startIcon={<Delete />}></Button>
                                    </Grid>
                                )

                            })*/
                        }



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button disabled={isLoading} theme={theme} variant="contained" color="primary" onClick={() => onSave(webhookInfo, fileIds, currentWordsList)}>
                        {
                            template ? "Guardar" : "Agregar"
                        }
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
