import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import TablaUsuarios from '../TablaUsuarios/TablaUsuarios';
import { useEffect } from 'react';


export default function TemplateAddModal({ title,fileIds,setFileIds, open, setOpen,setMsg,setFileName,onSave, theme, templateList }) {


    useEffect(()=>{

    },[])
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    const getFileIds = (arr) =>{
        return arr.map(a => a?.id);
    }
    /**
     * Columnas de la tabla
     */
    const columns = [
        {
            headerName: 'Acciones', field: 'bitacora', flex: 1, hide: false,
            renderCell: (params) => {

                return (
                    <Button onClick={() => {

                        let fileIdsArr =[];
                     
                        if(fileIds){
                            let localFileArr = getFileIds(params.row.Files);
                            let tempFileIds = [...fileIds,...localFileArr];

                            fileIdsArr = tempFileIds.filter(function(item, pos) {
                                return tempFileIds.indexOf(item) == pos;
                            })
                        }else{
                            fileIdsArr = getFileIds(params.row.Files);
                        }
                      
                        setFileIds([...fileIdsArr]);
                        setMsg(params.row);
                        setFileName(params.row.fileName);
                        setOpen(false);
                    }}>
                        Agregar
                    </Button>

                )
            }
        },
        {
            field: 'name', headerName: 'Nombre', flex: 1, editable: false,
        },

    ];

    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2} sx={{height: '70vh', width:'30vw'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TablaUsuarios
                                columns={columns}
                                rows={templateList}
                                showDelete={false}
  
                            />
                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
