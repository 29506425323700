import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import Papa from "papaparse";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import api from "../../services/api";

const AddparticipantToGroupModal = ({ idGroup, open, setOpen, title, theme }) => {

    const [id, setId] = useState(0);

    const [currWindow, setCurrWindow] = useState(1);
    const [file, setFile] = useState(null);

    const [phoneList, setPhoneList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);


    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        setIsLoading(false)
        setCurrWindow(1)
        setPhoneList([]);
        setFile(null);
        setId(idGroup)
    }, [idGroup])


    const handleChangeFile = (fileTemp) => {

        const reader = new FileReader();


        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            let phoneObjects = [];
            csv?.data.map((obj, index) => {

                if (obj.phone) {
                    phoneObjects.push({
                        phone: obj.phone,
                        status: 'Pendiente'
                    });
                }
                obj.id = index;
            })

            if (phoneObjects.length == 0) {
                enqueueSnackbar("No se encontraron contactos para agregar", {
                    variant: 'warning',
                });
                setFile(null)
            }
            setPhoneList(phoneObjects);
        };
        setFile(fileTemp)
        reader.readAsText(fileTemp);


    }

    const formatTextToNumbers = (text) => {

        const phones = text.split(/\r?\n/);

        let phoneObjects = [];

        phones.map((phone) => {
            if (phone) {
                phoneObjects.push({
                    phone: phone,
                    status: 'Pendiente'
                });
            }

        })
        setPhoneList(phoneObjects);

    }


    const addParticipants = async () => {
        setIsLoading(true);

        try {
            await Promise.all(phoneList.map(async (phone, index) => {
                const data = await addIndividualParticipant(phone);
                updatePhoneStatus(index, data);
                return true;
            }))
        } catch (err) {
            console.log(err)
        }
        setIsLoading(false);
    }

    const updatePhoneStatus = (index, data) => {
        let tempPhoneList = [...phoneList];
        tempPhoneList[index].status = data.status;
        tempPhoneList[index].comment = data.comment;
        setPhoneList(tempPhoneList);
    }

    const addIndividualParticipant = async (phone) => {
        try {
            const { data } = await api.post('/api/group/addParticpant', { id: id, phone: phone.phone })

            return data;

        } catch (err) {
            console.log(err)
            return "Error"
        }
    }
    const handleClose = () => {
        setOpen(false);


    }

    const getButtonColorByStatus = (status) => {

        if (status == "Pendiente") {
            return 'secondary';
        }
        if (status == "Error") {
            return 'error';
        }
        if (status == "Exitoso") {
            return 'success';
        }
    }
    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle >

            <DialogContent dividers>

                <Grid container spacing={2}>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            onClick={() => setCurrWindow(1)}
                            variant={currWindow == 2 ? 'filled' : 'outlined'}
                            fullWidth
                        >
                            Pegar contactos
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            onClick={() => setCurrWindow(2)}
                            variant={currWindow == 1 ? 'filled' : 'outlined'}
                            fullWidth
                        >
                            Subir contactos
                        </Button>
                    </Grid>
                    {
                        ((phoneList.length > 0) || isLoading) &&
                        <Grid
                            sx={{ maxHeight: '300px', minHeight: '300px', overflowY: 'scroll' }}
                            item xs={12} sm={12} md={12} lg={12}>

                            {
                                phoneList.map((phone, index) => (
                                    <Grid key={index} container >

                                        <Grid xs={phone.comment ? 3: 6} sm={phone.comment ? 3: 6} md={phone.comment ? 3: 6} lg={phone.comment ? 3: 6}>
                                            <Button variant='' fullWidth> {phone.phone}</Button>

                                        </Grid>
                                        <Grid xs={phone.comment ? 3: 6} sm={phone.comment ? 3: 6} md={phone.comment ? 3: 6} lg={phone.comment ? 3: 6}>
                                            <Button color={getButtonColorByStatus(phone.status)} fullWidth>{phone.status}</Button>

                                        </Grid>
                                        {phone.comment &&

                                            <Grid xs={6} sm={6} md={6} lg={6}>
                                                <p>{phone.comment}</p>

                                            </Grid>
                                        }

                                    </Grid>
                                ))
                            }

                        </Grid>
                    }
                    {
                        currWindow == 1 && !isLoading &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                maxRows={phoneList.length > 0 ? 1 : 10}
                                minRows={phoneList.length > 0 ? 1 : 10}
                                inputProps={{ maxLength: 50 }}
                                multiline
                                fullWidth
                                sx={{ minHeight: phoneList.length > 0 ? '10px' : '300px' }}
                                onChange={(e) => { formatTextToNumbers(e.target.value) }}

                            />
                        </Grid>
                    }

                    {
                        currWindow == 2 && !isLoading &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                fullWidth
                                variant="outlined"
                                component="label"
                                sx={{ minHeight: phoneList.length > 0 ? '10px' : '300px' }}
                            >
                                {
                                    file?.name ? file.name : "Subir archivo"
                                }
                                <input
                                    type="file"
                                    multiple={false}
                                    hidden
                                    onChange={(e) => { handleChangeFile(e.target.files[0]) }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                            </Button>
                        </Grid>
                    }



                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                    Cancelar
                </Button>
                <LoadingButton theme={theme} loading={isLoading} variant="contained" color="primary" onClick={() => addParticipants()}>

                    Agregar participantes

                </LoadingButton>
            </DialogActions>
        </Dialog>

    )

}

export default AddparticipantToGroupModal;