
/**MUI */
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';


import api from '../../services/api';
import Papa from "papaparse";
import { Add, ConstructionOutlined, Delete, Event, HighlightOff, Send, Share } from '@mui/icons-material';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { Breadcrumbs, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Link, TextField, Typography, withStyles } from '@mui/material';
import DatabaseTable from '../../components/DatabaseTable';
import CallModal from '../../components/CallModal';
import { useSnackbar } from 'notistack';
import { SocketContext } from '../../services/socket';
import MassiveMsgModal from '../../components/MassiveMsgModal';
import DatabaseCard from '../../components/DatabaseCard';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupMergeModal from '../../components/GroupMergeModal';
import GroupStructureModal from '../../components/GroupStructureModal';
import ShareModal from '../../components/ShareModal';

import LoginButtonExample from '../Test';
import { Helmet } from 'react-helmet-async';
import { PersonAdd } from 'react-ionicons';
import ClientListTable from '../../components/ClientListTable';
/**
 * Carga la vista para subir contactos a la base de datos
 * @param {*} param0 
 */
const ContactList = ({ theme }) => {

    const [columns, setColumns] = useState([]);

    const [rows, setRows] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [groups, setGroups] = useState([])


    const [db, setDb] = useState(false)

    const [advisers, _setAdvisers] = useState([]);


    const [currentSheet, setCurrentSheet] = useState(null);
    /**
     * 
     */
    const advisersRef = useRef(advisers);

    const setAdvisers = data => {
        advisersRef.current = data;
        _setAdvisers(data);
    }

    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [templates, setTemplates] = useState([]);

    const [openCallModal, setOpenCallModal] = useState(false);

    const [openMsgModal, setOpenMsgModal] = useState(false);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);

    const [userId, setUserId] = useState();
    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [currentGroupId, setCurrentGroupId] = useState(null);

    const [showSelectBox, setShowSelectBox] = useState(false);

    const [currentDatabases, setCurrentDatabases] = useState([]);

    const [reload, setReload] = useState(true);

    const [openMergeModal, setOpenMergeModal] = useState(false);

    const [openStructureModal, setOpenStructureModal] = useState(false);

    const [openShareModal, setOpenSharemodal] = useState(false);

    const [currentGroup, setCurrentGroup] = useState({});

    const [showAddClientButton, setShowAddClientButton] = useState(false);

    const [addClientToDB, setAddClientToDB] = useState(false);
    /**
     * Notifiaciones
     */
    const { enqueueSnackbar } = useSnackbar();

    

    useEffect(() => {
        async function getGroups() {
            try {

                const { data } = await api.post("/api/getGroups");
               
                if (data.status == "Success") {
                    setGroups(data.books);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }

        async function getPermissions() {

            try {

                const userInfo = await api.post("/api/getPermissions");
                //console.log(userInfo.data);

                setPermissions(userInfo.data.userInfo.permissions)
                setUserId(userInfo.data.userInfo.id);

            } catch (err) {
                console.log(err);
            }


        }
        async function getAdvisers() {

            try {

                const { data } = await api.get("api/users/all");
                setAdvisers(data);


            } catch (err) {
                console.log(err);
            }


        }

        async function getTemplates() {

            try {

                const { data } = await api.post("api/getTemplate");
                //console.log(userInfo.data);
                console.log(data);
                setTemplates(data.messageTemplates);


            } catch (err) {
                console.log(err);
            }


        }

        getPermissions();
        getAdvisers();
        getTemplates();
        getGroups();

    }, [])

    const onGetUpdate = useCallback((email, status) => {

        let tempAdvisersList = [...advisersRef.current];

        for (let i = 0; i < tempAdvisersList.length; i++) {
            if (tempAdvisersList[i].email == email) {
                tempAdvisersList[i].status = status;
            }
        }

        setAdvisers(tempAdvisersList)
    }, [advisersRef])

    useEffect(() => {

        if (socket) {


            socket.on(`adviserChange`, (adviserObj) => {
                console.log("Se conectó un asesor");
                //console.log(messageObj)
                onGetUpdate(adviserObj.email, adviserObj.status);
            });


        }

    }, [socket, onGetUpdate])

    /**
     * Pasa los permisos a todas las vistas
     */
    const [permissions, setPermissions] = useState([]);

    const [groupName, setGroupName] = useState('');

    const [currentInputValue, setCurrentInputvalue] = useState(`1,5500000001,Nombre 1,Apellido 1,Estatus,México,CDMX,Hombre,Profesional
2,5500000002,Nombre 2,Apellido 2,Estatus,México,CDMX,Hombre,Profesional
3,5500000003,Nombre 3,Apellido 3,Estatus,México,CDMX,Hombre,Profesional`);


    const [showInput, setShowInput] = useState(false);

    const handleChangeFile = (event) => {
        // If user clicks the parse button without
        // a file we show a error
        console.log(event)

        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();

        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });

            const parsedData = csv?.data;
            const columns = Object.keys(parsedData[0]);

            csv?.data.map((obj, index) => {
                if (Object.keys(obj).length == 1) {


                    obj.firstName = "Por Definir"
                    obj.lastName = "Por Definir"
                    obj.status = "Por Definir"
                    obj.country = "Por Definir"
                    obj.city = "Por Definir"
                    obj.gender = "Por Definir"
                    obj.level = "Por Definir"
                }
                obj.id = index;
            })

            setColumns(columns);
            setRows(parsedData);

        };
        reader.readAsText(event.target.files[0]);
    }

    const handleShowInput = () => {

        setShowInput(!showInput);

    }

    const validaInput = () => {
        try {

            let arr = currentInputValue.split('\n');

            if (arr.length < 2) {
                alert("Es necesario ingrear más de un regsitro");
                return;
            }

            if (arr[0].split(",").length == 1) {

                let tempRows = [];

                arr.map((value) => {

                    let tempArrObj = value.split(",");

                    let newObj = {
                        "id": tempArrObj[0],
                        "phone": tempArrObj[0],
                        "firstName": "Por Definir",
                        "lastName": "Por Definir",
                        "status": "Por Definir",
                        "country": "Por Definir",
                        "city": "Por Definir",
                        "gender": "Por Definir",
                        "level": "Por Definir",
                    }

                    tempRows.push(newObj);
                })

                setRows(tempRows);
                return;

            } else {
                if (arr[0].split(",").length < 9) {
                    alert("El formato de los datos no es correcto");
                    return;
                }
            }



            let tempRows = [];

            arr.map((value) => {

                let tempArrObj = value.split(",");

                let newObj = {
                    "id": tempArrObj[0],
                    "phone": tempArrObj[1],
                    "firstName": tempArrObj[2],
                    "lastName": tempArrObj[3],
                    "status": tempArrObj[4],
                    "country": tempArrObj[5],
                    "city": tempArrObj[6],
                    "gender": tempArrObj[7],
                    "level": tempArrObj[8]
                }

                tempRows.push(newObj);
            })

            setRows(tempRows);


        } catch (err) {
            console.log(err);
            alert("El formato de los datos no es correcto");
            return;
        }
    }

    const createGroup = async (name) => {

        if (!name) {
            if (!groupName) {
                enqueueSnackbar(`El nombre es requerido`, {
                    variant: 'error',
                });
                return false;
            }

        }


        let clientList = [...rows];

        clientList.map((cl) => {
            delete cl.id;
        })


        try {
            const { data } = await api.post('api/Creategroup', {
                group: {
                    name: name ? name : groupName,
                    owner: userId,
                },

                clients: clientList,
                advisers: selectedAdvisers
            });

            if (data.status == "Success") {
                enqueueSnackbar(`Se creó el libro exitosamente`, {
                    variant: 'success',
                });
            } else {
                if (data?.code) {
                    enqueueSnackbar(data.userMsg, {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('Ocurrio un error al crear el libro', {
                        variant: 'error',
                    });
                }
            }


            setRows([]);
            setDb(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al crear el libro`, {
                variant: 'error',
            });
        }


    }

    const callMassive = async (currentTemplate, name, phone) => {

        let clientList = [...rows];
        setGroupName(name);

        clientList.map((cl) => {
            delete cl.id;
        })

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...clientList];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {



                const socketObj = {
                    id: data.msgObj.id,
                    contacts: constactsArr,
                    delay: 1000,
                    from: phone
                }

                console.log(socketObj)
                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} mensajes`, {
                    variant: 'success',
                });

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpenCallModal(false);

        createGroup(name);
    }

    /**
     * Recupera un arreglo con lso telefonos
     * @returns 
     */
    const getPhones = () => {
        let phones = [];

        rows.map((cl) => {
            phones.push(cl.phone);
        })

        return phones;
    }

    const deleteDatabases = async () => {

        try {
            const { data } = await api.post('/api/deleteGroup', { books: currentDatabases });

            if (data.status == "Success") {
                enqueueSnackbar(`Se eliminaron ${currentDatabases.length} ${currentDatabases.length > 1 ? 'libros' : 'libros'}`, {
                    variant: 'success',
                });
                setReload(!reload);
            } else {
                throw `Error al borrar datos datos ${data}`
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al eliminar ${currentDatabases.length > 1 ? 'los libros' : 'el libro'}`, {
                variant: 'error',
            });
        }

    }

    const mergeDatabases = async (newName) => {

        if (newName == '') {
            enqueueSnackbar(`Debe ingresar un nuevo nombre para el libro`, {
                variant: 'warning',
            });

            return;
        }

        if (!window.confirm(`Se combinaran ${currentDatabases.length} libros ¿Desea continuar?`)) {
            setOpenMergeModal(false);
            return;
        }
        try {
            const { data } = await api.post('/api/mergeBooks', { books: currentDatabases, newName: newName });

            if (data.status == "Success") {
                enqueueSnackbar(`Se combinaron ${currentDatabases.length} libros`, {
                    variant: 'success',
                });
                setOpenMergeModal(false);
                setReload(!reload);
            } else {
                throw `Error al combinar datos datos ${data}`
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al combinar ${currentDatabases.length > 1 ? ' los libros' : ' el libro'}`, {
                variant: 'error',
            });
        }

    }


    const updateGroupStructure = async (structure) => {
        try {
            let request = {
                id: currentGroup.id,
                group: {
                    columnsStructure: structure
                }
            }
            const { data } = await api.post('/api/updateGroup', request)

            if (data.status == "Success") {
                enqueueSnackbar(`Se guardo el cambio de foram correcta`, {
                    variant: 'success',
                });
                setOpenStructureModal(false);
                setReload(!reload);
            } else {
                throw "Error al definir la estructura"
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al definir la estructura de los clientes`, {
                variant: 'error',
            });
        }
    }


    const shareDatabase = (usersID) => {


        currentDatabases.map(async (database) => {
            try {

                let request = {
                    userIDs: usersID,
                    groupID: database.id
                }
                const { data } = await api.post('/api/shareGroup', request)

                if (data.status == "Success") {
                    enqueueSnackbar(`Se compartio la base de datos ${database.name}`, {
                        variant: 'success',
                    });

                } else {
                    console.log(data);
                    throw "Error al compartir la base de datos"
                }

                setOpenSharemodal(false);
                setReload(!reload);
            } catch (err) {
                console.log(err);
                enqueueSnackbar(`Error al compartir la base de datos`, {
                    variant: 'error',
                });
            }
        })

    }

    /**
 * Compara la lista de permisos con los permisos asignados
 */
    const revisaPermisos = (allow, list = permissions, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < list.length; j++) {

                if (allow[i] == list[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }

    const testLead = async () => {
        let lead = {
            "entry":
                [{
                    "id": "101845856151688", "time": 1674319260, "changes":
                        [{
                            "value": { "adgroup_id": "23852436985950464", "ad_id": "23852436985950464", "created_time": 1674319259, "leadgen_id": "1514303369047019", "page_id": "101845856151688", "form_id": "1357084448373117" }, "field": "leadgen"
                        }]
                }], "object": "page"
        }


        try {
            const { data } = await api.post('/test', lead);

        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Cambia el nombre de una hoja
     */
    const changeSheetName = async () => {
        try {

            let name = prompt("Ingresa el nuevo nombre de la hoja");

            if (!name) {
                return;
            }
            const request = {
                id: currentSheet,
                group: { name: name }
            }
            const { data } = await api.post('/api/updatePage', request);

            if (data.status == "Success") {
                enqueueSnackbar(`Se actualizó el nombre de la hoja`, {
                    variant: 'success',
                });

                setReload(!reload);
            } else {
                console.log(data);
                throw "Error al cambiar nombre"
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al cambiar nombre`, {
                variant: 'error',
            });
        }
    }
    return (
        <>


            <Helmet title="Libros" />

            <Typography variant="h3" gutterBottom display="inline">
                Whatsapp
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    Clientes
                </Link>
                <Link >Contactos</Link>

            </Breadcrumbs>

            <br />
            <Divider my={6} />



            <Grid
                align="center"
                justifyContent="center"
                container
                sx={{ height: '80vh', margin: 0 }}

            >


                <Grid
                    item
                    sm={10}

                >
                    <ClientListTable
                        loadAllClients
                        loadAsContacts
                        reloadSheet={reload}
                        theme={theme}
                        templateList={templates}
                        advisers={advisers}
                        showAdviserButton
                        setCurrentSheet={setCurrentSheet}
                        groupID={currentGroupId}
                        books={groups}
                        setShowAddClientButton={setShowAddClientButton}
                        addClientToDB={addClientToDB}
                        setAddClientToDB={setAddClientToDB}
                    />
                </Grid>

            </Grid>


            <br></br>
            <Grid
                align="center"
                justifyContent="center"
                container
            >
                {!currentGroupId && showSelectBox &&
                    < Grid
                        item
                        sm={10}

                    >
                        <Button color="primary" variant="contained" startIcon={<CancelIcon />} onClick={() => { setShowSelectBox(false) }}>
                            Cancelar
                        </Button>

                        {
                            currentDatabases.length > 0 && revisaPermisos(['Eliminar libros']) &&
                            <Button color="error" variant="contained" startIcon={<Delete />} onClick={() => {
                                if (window.confirm(`Se eliminaran ${currentDatabases.length} bases de datos ¿Desea continuar?`)) {
                                    deleteDatabases();
                                }

                            }}>
                                Eliminar
                            </Button>
                        }
                        {
                            currentDatabases.length > 0 && revisaPermisos(['Compartir libros']) &&
                            <Button color="secondary" variant="contained" startIcon={<Share />} onClick={() => {

                                setOpenSharemodal(true);
                            }}>
                                Compartir
                            </Button>
                        }
                        {
                            currentDatabases.length > 1 && revisaPermisos(['Combinar base de datos']) &&
                            <Button color={"success"} variant="contained" endIcon={<CachedIcon />} onClick={() => {
                                setOpenMergeModal(true);
                            }}>
                                Combinar
                            </Button>
                        }
                    </Grid>
                }

            </Grid>




            <GroupMergeModal
                open={openMergeModal}
                setOpen={setOpenMergeModal}
                mergeDatabases={mergeDatabases}
            />
            <MassiveMsgModal
                theme={theme}
                forwardingId={0}
                open={openMsgModal}
                setOpen={setOpenMsgModal}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={createGroup}
            />
            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templates}
                onSave={(currentTemplate, name, phone) => { callMassive(currentTemplate, name, phone) }}
            />

            <GroupStructureModal
                open={openStructureModal}
                setOpen={setOpenStructureModal}
                structure={currentGroup?.columnsStructure ? JSON.parse(currentGroup.columnsStructure) : {}}
                onSave={updateGroupStructure}
            />

            <ShareModal
                open={openShareModal}
                setOpen={setOpenSharemodal}
                theme={theme}
                title={"Compartir libros"}
                users={advisers}
                onSave={shareDatabase}
            />
        </>
    )
}

export default ContactList;