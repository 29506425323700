import { Reply } from "@mui/icons-material";
import { Button } from "@mui/material";
import SendEmailModal from "../SendEmailModal";
import { useState } from "react";

export default function ReplyButton({ account, subject, email }) {

    const [open, setOpen] = useState(false)


    return (
        <>

            <Button
                onClick={() => setOpen(true)}
                variant="outlined"
                startIcon={<Reply />
                }>Responder
            </Button>

            <SendEmailModal
                open={open}
                setOpen={setOpen}
                emails={[email]}
                account={account}
                subject={subject}
            />
        </>
    )
}