

import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import CustomFilter from "../CustomFilter"
import { useEffect, useState } from "react";
import { Button } from "@mui/material";


/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const DetailMassiveTable = ({ massiveMsg, onDelete, onEdit, showEdit, showDelete,setCurrent }) => {
    /**
     * Cada que cambien  los datos filtra de nuevo
     */
    useEffect(() => {
        setFiltered(massiveMsg);
    }, [massiveMsg])

    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] =useState('');

    /**
    * Guarda los datos filtrados
    */
    const [filtered, setFiltered] = useState(massiveMsg);

    /**
    * Guarda los datos de lso filtros
    */
    const [latsFilters, setLatsFilters] = useState({});

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {

        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }

        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = massiveMsg.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

                    } else {

                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }
                }


            }
            return isInFilter;
        });
        
        setFiltered(finalResults);
    };

   
    return (

        <TablaUsuarios
        
          
            rows={filtered}
            columns={[
                {
                    headerName: 'Fecha', field: 'updatedAt', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.updatedAt} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Grupo', field: 'name', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Mensaje', field: 'body', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Sistema', field: 'wpId', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },
                {
                    headerName: 'Conteo', field: 'totalMessages', flex: 1, hide: false,
   
                    renderCell: (params) => {

                        return (
                            <> 
                                {params.row.totalMessagesSend} / {params.row.totalMessages ? params.row.totalMessages : '?'}
                            </>
                           
                        )
                    }
                },
                {
                    headerName: 'Ver bitacora', field: 'bitacora', flex: 1, hide: false,
                    renderCell: (params) => {

                        return (
                            <Button onClick={()=>setCurrent(params.row.id)}>Ver bitacora</Button>
                           
                        )
                    }
                },

            ]}
            
            onDelete={onDelete}

        />

    )
}

export default DetailMassiveTable; 