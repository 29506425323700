import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuotePreview from "../../components/NewQuote/QuotePreview";
import axios from "axios";

export default function ExternalQuoteView() {

    const { quoteID } = useParams();
    const [quote, setQuote] = useState(null)
    useEffect(() => {
        async function getQuote() {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/ext/quotes/info`, { quoteId: quoteID })
                
                setQuote(data.quote)
            } catch (err) {

            }
        }
        getQuote();
    }, [])
    return (
        <div style={{  justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div >
                {
                    quote &&
                    <QuotePreview
                        externalCompany={quote.Company}
                        externalQuote={quote}
                        products={quote.QuoteItems}
                    />
                }
            </div>
        </div>
    )
}