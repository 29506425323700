import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';



export default function CalendarFormModal({ open, setOpen, currentCalendarForm, accounts = [], whatsapps = [], reload }) {

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {

        if (currentCalendarForm) {
            setForm({
                ...currentCalendarForm
            });
        } else {
            setForm({
                id: null,
                name: '',
                description: '',
                emailID: 0,
                whatsappID: 0
            });
        }
    }, [currentCalendarForm]);

    /**
     * Variable para el nuevo perfil
     */
    const [form, setForm] = useState({
        id: null,
        name: '',
        description: '',
        emailID: 0,
        whatsappID: 0,
        filePath: '',
        fileName: ''
    });

    const [attachedFile, setAttachedFile] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto de form
     * @param {*} e 
     */
    const handleChange = (e) => {
        const event = e.target;
        const newForm = {
            ...form,
            [event.name]: event.value,
        }
   
        
        setForm(newForm)

    }

    const updateFileFormData = (filePath, fileName) => {

        const newForm = {
            ...form,
            filePath, fileName
        }

        setForm(newForm)

    }


    const addOrUpdateForm = async () => {
        try {
            const { data } = await api.post('/api/calendarform', { calendarFormObj: form, id: form.id })
            if (reload) {
                reload();
            }
            setOpen(false)

            enqueueSnackbar(`Calendario ${form.id ? "actualizado" : "creado"} exitosamente`, {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(`Ocurrió un error al crear el calendario`, {
                variant: 'error',
            });

        }
    }

    const uploadFile = async (file) => {
        setLoadingFile(true);
        try {



            const formData = new FormData();
            formData.append('file', file)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let filePath = '';

            try {
                const { data } = await api.patch('/api/upload', formData, config);

                /**
                 * Recupera la ruta del archivo subido
                 */

                filePath = data.fielName;

                updateFileFormData(filePath, file.name)
 
                setAttachedFile(file);

                setLoadingFile(false);
                return false;
            } catch (err) {
                console.log(err);
                enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
                    variant: 'error',
                });

            }

            if (!filePath) {

                enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
                    variant: 'error',
                });

            }
        } catch (err) {
            enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
                variant: 'error',
            });
        }
        setLoadingFile(false);
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Formulario
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={form.name}
                                label="Nombre del formulario"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                value={form.description}
                                label="Descripción"
                                name="description"
                                variant="outlined"
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                select
                                label="Cuenta de Google"
                                value={form.emailID}
                                onChange={handleChange}
                                name="emailID"
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {accounts?.map((account) => (
                                    <MenuItem key={account.id} value={account.id}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                select
                                label="Whatsapp"
                                value={form.whatsappID}
                                onChange={handleChange}
                                name="whatsappID"
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {whatsapps?.map((whatsapp) => (
                                    <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                        {whatsapp.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LoadingButton
                                loading={loadingFile}
                                variant="outlined"
                                component="label"
                                fullWidth
                            >
                                {
                                    attachedFile?.name ? attachedFile.name : form.fileName || "Subir archivo"
                                }
                                <input
                                    type="file"
                                    multiple={false}
                                    hidden
                                    onChange={(e) => { uploadFile(e.target.files[0]); }}
                                />
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={loadingFile} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </LoadingButton>
                    <LoadingButton loading={loadingFile} variant="contained" color="primary" onClick={addOrUpdateForm}>
                        {form.id ? "Actualizar" : "Crear"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
