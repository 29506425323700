import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import './MensajeEnvio.css';
import Mensajes from '../../components/Mensajes/Mensajes';
import PantallaModal from '../../components/PantallaModal/ChildModal';

import { Button, Checkbox, Fab, FormControlLabel, Grid, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContactsIcon from '@mui/icons-material/Contacts';
import { CheckBox, ConstructionOutlined, Delete, Edit, Navigation } from '@mui/icons-material';
import api from '../../services/api';

import { SocketContext } from '../../services/socket';
import ContactsModal from '../ContactsModal';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import ContactsListModal from '../ContactsListModal';
import TemplateAddModal from '../TemplateAddModal';
import insertTextAtCursor from "insert-text-at-cursor";
import MultiSelect from '../MultiSelect';
import Loading from '../../components/Loading';
import FilesModal from '../FilesModal';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { isConstructorDeclaration } from 'typescript';
import ClientsModal from '../ClientsModal';
import WhatsappSessionAlert from '../WhatsappSessionAlert';

function MensajeEnvio({ theme, forwardingId, whatsapp, disableNav, contactsExternal = [], reloadMain, scheduled, isSequence, openContacts, setOpenContacts, useOldVersion }) {

  const textAreaRef = useRef(null);

  /**
   * Constante para navegar entre las vistas
   */
  const navigate = useNavigate();

  /**
  * Lista de los templates para insertar
  */
  const [templateList, setTemplateList] = useState([]);

  /**
  * Lista de los mensajes programables
  */
  const [sequences, setSequences] = useState([]);

  /**
   * Variable para mostrar la página de carga
   */
  const [isLoading, setIsLoading] = useState(true);

  const [userId, setUserId] = useState(0);

  /**  */
  const [sequenceID, setSequenceID] = useState(0);

  /** Lista de archivos */
  const [fileList, setFileList] = useState([]);

  /** Permite activar la programacion de archivos de manera local */
  const [localSchedule, setLocalSchedule] = useState(false);

  /** Permite guardar la plantilla en caso de necesitarlo */
  const [saveTamplate, setSaveTamplate] = useState(false);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);


  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);


  /**
  * Barra de notificaciones
  */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Se ejecuta al inicio
   * Consulta permisos y valida los sistemas
   */
  useEffect(() => {
    setIsLoading(true);
    async function getSessions() {
      try {
        const { data } = await api.post("/api/v2/getUserSessions");
        setSessions(data.sessions);
      } catch (err) {

      }
    }
    async function getPermissions() {

      try {

        const userInfo = await api.post("/api/getPermissions");

        setPermissions(userInfo.data.userInfo.permissions)
        //setSessions(userInfo.data.userInfo.wpsessions);
        setUserId(userInfo.data.userInfo.id);

        /**
         * Si no tiene los permisos entonces lo manda a la página de no permitidos
         */
        if (!revisaPermisos(['Envio Masivo'], userInfo.data.userInfo.permissions)) {
          //  navigate('/notAllowed')
        }

        const { data } = await api.post("api/getContacts", { sessions: userInfo.data.userInfo.wpsessionsNum });

        if (data.status == "Success") {
          setContactsList(data.contacts)
        } else {

        }

        /**
         * Consulta llas etiquetas
         */
        const dataTags = await api.post("/api/v2/getUserTags", { wpSessions: userInfo.data.userInfo.wpsessions });

        if (dataTags.data.status == "Success") {
          setTags(dataTags.data.tags)
          //console.log(dataTags.data.tags);
        }

        /**
         * COnsulta las plantillas
         */
        const dataTemplate = await api.post("/api/getTemplate", { wpSessions: userInfo.data.userInfo.wpsessions });

        if (dataTemplate.data.status == "Success") {
          setTemplateList(dataTemplate.data.messageTemplates)
          //console.log(dataTemplate.data.messageTemplates);
        }

      } catch (err) {
        console.log(err);
      }

      async function getFileList() {
        try {
          const { data } = await api.post('/api/getLogFile');



          setFileList(data.files)
        } catch (err) {
          console.log(err);
        }
      }
      getFileList();
      getSessions();
      setIsLoading(false);

    }

    async function getSpeechs() {
      const { data } = await api.post("api/getSpeech");

      //console.log(data)
      setSpeechs(data.words);
    }

    async function getSequences() {
      const { data } = await api.post("/api/sequence/get");
      setSequences(data.scheduleSequenceList);
    }

    async function getProducts() {
      try {

        const { data } = await api.post("/api/getProducts");



        if (data.status == "Success") {

          setProducts(data.products);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar los productos", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error", {
          variant: 'error',
        });

      }
    }

    async function getstatus() {
      try {
        const { data } = await api.post("/api/getStatus");


        if (data.status == "Success") {

          setStatus(data.statusList);

        } else {
          enqueueSnackbar("Ocurrio un error al consultar los estatus", {
            variant: 'error',
          });
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar("Ocurrio un error", {
          variant: 'error',
        });
      }
    }

    getPermissions();
    getSpeechs();
    getSequences();
    getProducts();
    getstatus();
    if (socket) {
      socket.on('MSG_SUCCESS', (id) => {
        //console.log(`Mensaje exitoso ${id} `);
      })
    }

  }, [])

  useEffect(() => {
    setLocalSchedule(scheduled);
  }, [scheduled])



  /**Lsita de contactos
   */
  const [contactsList, setContactsList] = useState([])

  /**Lsita de etiqueTAS
  */
  const [tags, setTags] = useState([])

  /**
   * COntactos seleccionados
   */
  const [contactsSelectList, setContactsSelectList] = useState([])

  const [myValue, setMyValue] = useState('')

  /**
   * Variables para abrir y cerrar e modal
   */
  const [open, setOpen] = useState(false);

  /**
   * Variables para abrir y cerrar el modal de plantillas
   */
  const [openT, setOpenT] = useState(false);

  /**
   * Nombre del grupo de envio
   */
  const [name, setName] = useState('');

  /**
   * Variables para abrir y cerrar el modal de templates
   */
  const [openTemplates, setOpenTemplates] = useState(false);


  /**
  * Variables para abrir y cerrar e modal de contactos
  */
  const [openContactsList, setOpenContactsList] = useState(false);

  /**
   * Variable para contar cuantos speech se han agregado
   * Al inicio es un objeto
   */
  const [speechs, setSpeechs] = useState([]);

  /**
   * Variable para contar cuantos speech se han agregado
   * Al inicio es un objeto
   */
  const [selectedSpeechs, setSelectedSpeechs] = useState([]);

  /**
  * Variable para agregar los contactos
  * (objeto o array ? )
  */
  const [contacts, setContacts] = useState([]);
  const [names, setNames] = useState([]);
  const [emails, setEmails] = useState([]);
  /**
  * Variable de texto para los conatcos
  * 
  */
  const [contactsString, setContactsString] = useState('');
  const [namesString, setNamesString] = useState('');
  const [emailsString, setEmailsString] = useState('');
  /**
   * Pasa los permisos a todas las vistas
   */
  const [permissions, setPermissions] = useState([]);

  /**
   * Pasa las sesiones a todas vistas
   */
  const [sessions, setSessions] = useState([]);

  /**
   * La session (whatsapp) desde donde se va a enviar
   */
  const [selectedSession, setSelectedSession] = useState("");

  /**
   * El mensjae a enviar
   */
  const [message, setMessage] = useState("");

  /**
   * Variable para el archivo 
   */
  const [file, setFile] = useState(null);

  /**
   * Variable para los archivo 
   */
  const [files, setFiles] = useState([]);

  /**
   * Ruta del archivo de la plantilla
   */
  const [fileName, setFileName] = useState(null);

  /**
   * Variable para la ruta del archivo
   */
  const [filePath, setFilePath] = useState(null);

  /**
  * Variable para los id de archivos
  */
  const [fileIds, setFileIds] = useState([]);

  /**
   * Socket para el envio masivo
   */
  const socket = useContext(SocketContext);

  /**
   * Tiempo de espera 
   */
  const [delay, setDelay] = useState(5);

  /**
   * Modal para los archivos
   */

  const [openFiles, setOpenFiles] = useState(false);


  const [currentTemplate, setCurrentTemplate] = useState(0);

  const [sendAt, setSendAt] = useState(new Date());

  /**
   * Nombre de la plantilla en caso de guardarla
   */
  const [templateName, setTemplateName] = useState(null);

  const [currentTags, setCurrentTags] = useState([]);

  const [clients, setClients] = useState([]);
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  /**
   * Envia los mensajes masivos al socket
   */
  const sendMassive = async () => {

    /**
     * Array de contactos para enviar los mensajes
     * Integrantes del grupo que se creará
     */
    let clientPhones = clients.map((client) => client.phone) || [];
    const constactsArr = [...contacts, ...contactsSelectList, ...contactsExternal, ...clientPhones];

    if (constactsArr.length == 0) {
      enqueueSnackbar(`Debe agregar al menos un número`, {
        variant: 'warning',
      });
      return;
    }

    if (!name) {
      enqueueSnackbar(`Debe agregar un nombre de grupo`, {
        variant: 'warning',
      });
      return;
    }

    if (!whatsapp && !selectedSession) {
      enqueueSnackbar(`Debe seleccionar un whatsapp`, {
        variant: 'warning',
      });
      return;
    }

    if (saveTamplate && useOldVersion) {
      if (!templateName) {
        enqueueSnackbar(`Debe agregar un nombre para la plantilla`, {
          variant: 'warning',
        });
        return;
      }
    }

    let templateId = null;

    if (useOldVersion) {

      const newTemplate = {
        id: 0,
        name: templateName,
        type: 'Mensaje',
        message: message,
        fileName: '',
        status: saveTamplate ? 0 : 1
      }
      templateId = await saveAsTemplate(newTemplate, fileIds);
    }

    if (!currentTemplate?.id && !templateId) {
      enqueueSnackbar(`Debe seleccionar una plantilla`, {
        variant: 'warning',
      });
      return;
    }


    /**
     * Objeto para crear un grupo de envio (sin integrantes)
     */
    const msgObj = {
      name: name,
      forwardingId: forwardingId ? forwardingId : 0,
      wpId: whatsapp ? whatsapp : selectedSession,
      templateId: useOldVersion ? templateId : currentTemplate.id,
      isMedia: false,
      body: message,
      mediaUrl: '',
      kind: 'Whatsapp',
      userSend: userId,
      delay: delay * 1000,
      status: 'Pendiente',
      totalMessagesSend: 0,
      totalMessagesLost: 0,
      totalMessages: constactsArr.length
    }

    setIsLoading(true);
    try {


      /**
       * Crea el grupo de envio (sin integrantes aún)
       */
      const request = { messageObj: msgObj, words: selectedSpeechs };

      //console.log(request);
      /** ------------------------------------------------------------ */
      const { data } = await api.post('/api/massive/create', request);
      //  console.log(data);
      if (data.status == "Success") {



        const socketObj = {
          id: data.msgObj.id,
          clients,
          product: selectedProducts,
          status: selectedStatus,
          contacts: constactsArr,
          wpsession: whatsapp ? whatsapp : selectedSession,
          delay: delay,
        }

        socket.emit("sendMassiveMessage", socketObj);
        /**
         * Id del envio masivo
         */
        let idMsgEnv = data.msgObj.id;
        /**
         * Crea la relación de archivos
         * Con la lista de envio masivo
         */
        try {


          const { data } = await api.post("/api/addFilesMsg", { id: idMsgEnv, files: fileIds });

          if (data.status == "Success") {

          } else {
            enqueueSnackbar(`Ocurrió un error al subir los archivos`, {
              variant: 'error',
            });
            return null;
          }

        } catch (err) {

          console.log(err);
          enqueueSnackbar(`Error al subir los archivos`, {
            variant: 'error',
          });

          return false;
        }





        if (reloadMain) {
          reloadMain(name);
        }
        enqueueSnackbar(`Se estan enviando ${constactsArr.length} mensajes`, {
          variant: 'success',
        });
        await timeout(2000);
        window.location.href = '/bitacora/mensajes';
      } else {
        enqueueSnackbar(`Error al crear el grupo de envio`, {
          variant: 'error',
        });
      }


    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error al enviar mensajes`, {
        variant: 'error',
      });
    }

    setIsLoading(false);

  }

  /**
   * Programa los mensajes masivos
   */
  const scheduleMessages = async () => {

    /**
     * Array de contactos para enviar los mensajes
     * Integrantes del grupo que se creará
     */
    let clientPhones = clients.map((client) => client.phone) || [];
    const constactsArr = [...contacts, ...contactsSelectList, ...contactsExternal, ...clientPhones];


    if (constactsArr.length == 0) {
      enqueueSnackbar(`Debe agregar al menos un número`, {
        variant: 'warning',
      });
      return;
    }

    if (!name) {
      enqueueSnackbar(`Debe agregar un nombre de grupo`, {
        variant: 'warning',
      });
      return;
    }

    if (!whatsapp && !selectedSession) {
      enqueueSnackbar(`Debe seleccionar un whatsapp`, {
        variant: 'warning',
      });
      return;
    }
    let templateId = null;

    if (useOldVersion) {

      const newTemplate = {
        id: 0,
        name: name,
        type: 'Mensaje',
        message: message,
        fileName: '',
        status: saveTamplate ? 0 : 1
      }
      templateId = await saveAsTemplate(newTemplate, fileIds);
    }

    if (!currentTemplate?.id && !templateId) {
      enqueueSnackbar(`Debe seleccionar una plantilla`, {
        variant: 'warning',
      });
      return;
    }


    /**
     * Objeto para crear un grupo de envio (sin integrantes)
     */
    const msgObj = {
      name: name,
      forwardingId: forwardingId ? forwardingId : 0,
      wpId: whatsapp ? whatsapp : selectedSession,
      isMedia: false,
      body: message,
      mediaUrl: '',
      kind: 'Whatsapp',
      userSend: userId,
      delay: delay * 1000,
      status: 'Programado',
      templateId: useOldVersion ? templateId : currentTemplate.id,
      //kind: scheduled ? "Mensaje programado" : "Envio masivo",
      totalMessagesSend: 0,
      totalMessagesLost: 0,
      totalMessages: constactsArr.length
    }

    setIsLoading(true);
    try {


      /**
       * Crea el grupo de envio (sin integrantes aún)
       */
      const request = { messageObj: msgObj, words: selectedSpeechs };

      //console.log(request);
      /** ------------------------------------------------------------ */
      const { data } = await api.post('/api/massive/create', request);
      //  console.log(data);
      if (data.status == "Success") {

        /**
         * Id del envio masivo
         */
        let idMsgEnv = data.msgObj.id;

        try {

          const request = {
            msgObject: {
              name: name,
              delay: delay * 1000,
              status: 'Programado',
              type: 'Mensaje',
              user: userId,
              idMassive: idMsgEnv,
              idTemplate: currentTemplate.id,
              sendAt: sendAt
            },
            contacts: constactsArr,
            names,
            emails
          }

          const response = await api.post('/api/createSchedule', request);

          if (response.data.status == "Success") {
            enqueueSnackbar(`Se programaron los mensajes para la fecha ${sendAt}`, {
              variant: 'success',
            });

            enqueueSnackbar(`Redireccionando a la bitacora`, {
              variant: 'success',
            });
            await timeout(2000);

            window.location.href = '/bitacora/mensajes';
          } else {
            console.log(data)
            enqueueSnackbar(`Error al programar el mensaje`, {
              variant: 'error',
            });
          }
        } catch (err) {
          enqueueSnackbar(`Error al programar el mensaje`, {
            variant: 'error',
          });
        }


        if (reloadMain) {
          reloadMain(name);
        }


      } else {
        enqueueSnackbar(`Error al crear el grupo de envio`, {
          variant: 'error',
        });
      }


    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error al enviar mensajes`, {
        variant: 'error',
      });
    }

    setIsLoading(false);
  }


  /**
   * Programa los mensajes masivos
   */
  const scheduleSequenceMessages = async () => {

    /**
     * Array de contactos para enviar los mensajes
     * Integrantes del grupo que se creará
     */
    let clientPhones = clients.map((client) => client.phone) || [];
    const constactsArr = [...contacts, ...contactsSelectList, ...contactsExternal, ...clientPhones];


    /**
     * Objeto para crear un grupo de envio (sin integrantes)
     */
    const msgObj = {
      name: name,
      forwardingId: forwardingId ? forwardingId : 0,
      wpId: whatsapp ? whatsapp : selectedSession,
      isMedia: false,
      body: message,
      mediaUrl: '',
      kind: 'Whatsapp',
      userSend: userId,
      delay: delay * 1000,
      status: 'Programado',
      templateId: currentTemplate.id,
      sequenceID: sequenceID,
      //kind: scheduled ? "Mensaje programado" : "Envio masivo",
      totalMessagesSend: 0,
      totalMessagesLost: 0,
      totalMessages: constactsArr.length
    }

    setIsLoading(true);
    try {


      /**
       * Crea el grupo de envio (sin integrantes aún)
       */
      const request = { messageObj: msgObj, words: selectedSpeechs };

      //console.log(request);
      /** ------------------------------------------------------------ */
      const { data } = await api.post('/api/massive/create', request);
      //  console.log(data);
      if (data.status == "Success") {

        /**
         * Id del envio masivo
         */
        let idMsgEnv = data.msgObj.id;

        try {

          const request = {
            msgObject: {
              name: name,
              delay: delay * 1000,
              status: 'Programado',
              type: 'Secuencia',
              user: userId,
              idMassive: idMsgEnv,
              //idTemplate: currentTemplate.id,
              //sendAt: sendAt
            },
            sequenceID: sequenceID,
            contacts: constactsArr,
            names,
            emails,
          }

          const response = await api.post('/api/createSchedule', request);

          if (response.data.status == "Success") {
            enqueueSnackbar(`Se programaron los mensajes para la fecha ${sendAt}`, {
              variant: 'success',
            });

            enqueueSnackbar(`Redireccionando a la bitacora`, {
              variant: 'success',
            });
            await timeout(2000);

            window.location.href = '/bitacora/mensajes';
          } else {
            console.log(data)
            enqueueSnackbar(`Error al programar el mensaje`, {
              variant: 'error',
            });
          }
        } catch (err) {
          enqueueSnackbar(`Error al programar el mensaje`, {
            variant: 'error',
          });
        }


        if (reloadMain) {
          reloadMain(name);
        }
      } else {
        enqueueSnackbar(`Error al crear el grupo de envio`, {
          variant: 'error',
        });
      }


    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error al enviar mensajes`, {
        variant: 'error',
      });
    }

    setIsLoading(false);
  }

  /**
   * Revisa el tipo de envio que se debe hacer
   */
  const checkSendType = () => {
    if (isSequence) {
      scheduleSequenceMessages();
      return;
    }
    if (scheduled || localSchedule) {
      scheduleMessages();
    } else {
      sendMassive();
    }
  }
  /**
  * Compara la lista de permisos con los permisos asignados
  */
  const revisaPermisos = (allow, list = permissions, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < list.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == list[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }
  /**
   * Guarda los contactos y los convierte en un array
   * @param {*} contactsString 
   */
  const convertContacts = (contactsString) => {

    setContactsString(contactsString);
    setContacts(contactsString.split('\n'));
  }

  const convertNames = (n) => {

    setNamesString(n);
    setNames(n.split('\n'));

  }

  const convertEmails = (e) => {

    setEmailsString(e);
    setEmails(e.split('\n'));

  }


  /**
   * Opciones para el botón flotante
   */
  const options = [

    { name: "Agregar números", action: () => { setOpenContacts(true) }, icon: <ContactsIcon /> },
    { name: "Lista de números", action: () => { setOpenContactsList(true) }, icon: <ContactsIcon /> }
  ]

  /**
   * Sube un archivo y lo guarda en files
   * @param {*} file 
   */
  const addFile = async (file, webhookId) => {

    /**
     * Envia el archivo para subirlo
     */
    const formData = new FormData();
    formData.append('file', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    let filePath = '';

    try {
      const { data } = await api.patch('/api/upload', formData, config);

      /**
       * Recupera la ruta del archivo subido
       */
      filePath = data.fielName;
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }

    if (!filePath) {

      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }

    /**
     * Registra el archivo den la tabla FILES
     */

    try {
      const { data } = await api.post("/api/addFiles", { files: [filePath] });

      return { path: filePath, id: data.files[0] };

    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al subir el archivo ${file.name}`, {
        variant: 'error',
      });
      return null;
    }
  }
  /**
    * Agrega un archivo al arreglo
    */
  const addFileLocal = async (file) => {
    let filesTemp = [...files]
    filesTemp.push(file);
    //console.log(filesTemp);
    setFiles(filesTemp);


    let fileIdsTemp = [...fileIds]


    const resp = await addFile(file, 0);
    fileIdsTemp.push(resp.id);

    setFileIds(fileIdsTemp);

  }

  /**
   * Elimina un archivo del arreglo
   * @param {*} file 
   */
  const removeFileLocal = async (index) => {

    let filesTemp = [...files]
    filesTemp.splice(index, 1);

    let fileIdsTemp = [...fileIds]

    fileIdsTemp.splice(index, 1);

    setFiles(filesTemp);
    setFileIds(fileIdsTemp);
  }
  /**
   * Agrega el template a los mensajes
   * @param {*} template 
   */
  const addTemplate = (template) => {
    try {
      insertTextAtCursor(textAreaRef.current, template.message);
    } catch (err) {
      textAreaRef.current.value = template.message;
    }

    setFilePath(template.filePath)
    setFileName(template.fileName);
  }

  /**
   * Crea una nueva plantilla
   * @param {*} data 
   */
  const saveAsTemplate = async (newTemplate, files) => {

    try {

      const request = {
        id: 0,
        templateObj: newTemplate,
        words: [],
        tags: currentTags
      }


      const { data } = await api.post("/api/createTemplate", request);


      if (data.status == "Success") {

        let idTemplateTemp = data?.template?.id;

        if (!newTemplate.id || newTemplate.id == 0) {
          try {

            //  console.log({ id:idTemplateTemp, files: files});
            const { data } = await api.post("/api/addTempFile", { id: idTemplateTemp, files: files });

            if (data.status == "Success") {

            } else {
              enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
                variant: 'error',
              });
              console.log(data);
            }
          } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrió un error al subir el archivo`, {
              variant: 'error',
            });

          }
        }

        return idTemplateTemp;

      } else {

        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(`Ocurrió un error al crear la plantilla`, {
            variant: 'error',
          });
        }
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear la plantilla', {
        variant: 'error',
      });
    }

    return null;
  }

  return (
    <>

      <Loading open={isLoading} />
      <div className="fondochat">
        <div className="chat">

          <Grid
            container

            spacing={2}
            alignItems="center"
            justify="center"
          >


            <Grid item sm={12} md={12} align="center">
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
                fontFamily='Radio Canada'
                color="white"
              >
                Mensaje Masivo
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              align="center"
              sx={{ height: '10%' }}
            >
              <div style={{ width: '80%' }}>
                <WhatsappSessionAlert onChange={() => { }} />
              </div>
            </Grid>

            {!whatsapp &&
              <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Whatsapp"
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  variant="filled"
                  color="primary"

                  sx={{ width: '80%' }}
                // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                >
                  {sessions.map((sessionWP) => (
                    <MenuItem key={sessionWP.sessionAuth} value={sessionWP.sessionAuth}>
                      {sessionWP.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <TextField
                fullWidth
                value={name}
                label="Nombre del grupo"
                name="name"
                variant="filled"
                color="primary"
                sx={{ width: '80%' }}
                onChange={(e) => { setName(e.target.value) }}

              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <TextField
                id="delays"
                // select
                label="Tiempo de espera"
                value={delay}
                onChange={(e) => setDelay(e.target.value)}
                variant="filled"
                color="primary"

                sx={{ width: '80%' }}
              // helperText="Elige un sistema desde el cual se enviaran los mensajes"
              >

              </TextField>
            </Grid>
            {
              useOldVersion &&
              <Grid item sm={12} md={12} align="center">
                <FormControlLabel
                  label="Programar mensaje"
                  control={<Checkbox checked={localSchedule} onChange={e => { setLocalSchedule(e.target.checked) }} />}
                />
                <FormControlLabel
                  label="Guardar plantilla"
                  control={<Checkbox checked={saveTamplate} onChange={e => { setSaveTamplate(e.target.checked) }} />}
                />
              </Grid>
            }
            {
              useOldVersion && saveTamplate &&
              <Grid item sm={12} md={12} align="center">
                <TextField
                  fullWidth
                  value={templateName}
                  label="Nombre de la plantilla"
                  name="templateName"
                  variant="filled"
                  color="primary"
                  sx={{ width: '80%' }}
                  onChange={(e) => { setTemplateName(e.target.value) }}

                />
              </Grid>
            }
            {!isSequence && !useOldVersion && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Grid item xs={12} sm={12} md={12} lg={21}>
                <TextField
                  fullWidth
                  select
                  sx={{ width: '80%' }}
                  value={currentTemplate}
                  label="Selecciona una plantilla"
                  name="type"
                  variant="outlined"
                  onChange={(event) => { setCurrentTemplate(event.target.value); }}

                >
                  {templateList.map((option) => {
                    if (option.type != "Llamada") {
                      return <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    }

                  })}
                </TextField>

              </Grid>
            </Grid>

            }
            {useOldVersion && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Button
                variant="contained"
                component="label"
                sx={{ width: '80%' }}
                onClick={() => { setOpenT(true); textAreaRef.current.focus(); }}
              >
                Agregar plantilla
              </Button>

            </Grid>}
            {isSequence && !useOldVersion && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Grid item xs={12} sm={12} md={12} lg={21}>
                <TextField
                  fullWidth
                  select
                  sx={{ width: '80%' }}
                  value={sequenceID}
                  label="Selecciona un secuencia de mensajes"
                  name="type"
                  variant="outlined"
                  onChange={(event) => { setSequenceID(event.target.value); }}

                >
                  {sequences.map((option) => {

                    return <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>


                  })}
                </TextField>

              </Grid>
            </Grid>

            }
            {useOldVersion &&
              <Grid item sm={12} md={12} align="center">
                <textarea multiline ref={textAreaRef} style={{ width: '80%' }} onChange={(e) => { setMessage(e.target.value) }} name="Text1" cols="40" rows="15" />

              </Grid>}
            {/*!scheduled && <Grid item xs={12} sm={12} md={12} lg={12}>
              <MultiSelect
                label="Speechs"
                labelStyle={{ color: 'white' }}
                style={{ textAlign: 'center', }}
                sx={{ width: '80%' }}
                title="Speechs asignados"
                theme={theme}
                list={speechs.length ? speechs : []}
                onChange={(data) => { console.log(data); setSelectedSpeechs(data); }}
                initSelect={selectedSpeechs}

              />

          </Grid>*/}

            {/**useOldVersion && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Button
                variant="contained"
                component="label"
                sx={{ width: '80%' }}
                onClick={() => { setOpenFiles(true) }}
              >
                Archivos
              </Button>

        </Grid>*/}



            {
              useOldVersion && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <MultiSelect
                  style={{ width: '80%' }}
                  title="Archivos"
                  theme={theme}
                  list={fileList}
                  onChange={(data) => { setFileIds(data) }}
                  initSelect={fileIds}

                />
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <MultiSelect
                style={{ width: '80%' }}
                title="Etiquetas"
                theme={theme}
                list={tags}
                onChange={(data) => { setCurrentTags(data) }}
                initSelect={currentTags}

              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField

                select
                label="Producto"
                value={selectedProducts}
                onChange={(e) => setSelectedProducts(e.target.value)}

                color="primary"
                style={{ width: '80%' }}
              >
                {products?.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField

                select
                label="Estatus"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}

                color="primary"
                style={{ width: '80%' }}
              >
                {status?.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </TextField>

            </Grid>
            {(scheduled || localSchedule) && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <LocalizationProvider sx={{ width: '80%' }} dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Fecha programada"
                  value={sendAt}
                  inputFormat={"YYYY-MM-DD HH:mm"}

                  onChange={(newDate) => setSendAt(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>}

            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Button
                color="success"
                variant="contained"
                component="label"
                disabled={isLoading}
                sx={{ width: '80%' }}
                onClick={checkSendType}
              >
                {scheduled || localSchedule ? "Programar" : "Enviar"}
              </Button>

            </Grid>

            <Grid item sm={12} md={12} align="center">

              {/*<ContactsModal
                contacts={contactsString}
                setContacts={convertContacts}
                emails={emailsString}
                setEmails={convertEmails}
                names={namesString}
                setNames={convertNames}
                open={openContacts}
                setOpen={setOpenContacts}
          />*/}
              <ClientsModal
                open={openContacts}
                setOpen={setOpenContacts}
                onChange={(newClients) => setClients(newClients)}
              />
              <ContactsListModal tagsList={tags} contacts={contactsList} setContacts={setContactsSelectList} open={openContactsList} setOpen={setOpenContactsList} />
              <TemplateAddModal fileIds={fileIds} setFileIds={setFileIds} title={"Agregar plantilla"} open={openT} setOpen={setOpenT} theme={theme} setMsg={addTemplate} templateList={templateList} setFileName={
                (fileName) => {
                  setFileName(fileName);
                }
              } />

              <FilesModal
                title={"Archivos adjuntos"}
                open={openFiles}
                setOpen={setOpenFiles}
                addFileLocal={addFileLocal}
                removeFileLocal={removeFileLocal}
                theme={theme}
                files={files}
              />
            </Grid>

          </Grid>


          {/** <div style={{ textAlign: 'center', position: 'fixed', bottom: 16, width: '100%' }}>
            <Fab variant="extended" onClick={sendMassive}>
              <Navigation sx={{ mr: 1 }} />
              Enviar
            </Fab>
          </div>
 MOdal para los archivos */}



          {/*!forwardingId && <SpeedDial
            FabProps={{ style: { backgroundColor: "#144985" } }}
            ariaLabel="Opciones"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon openIcon={<Edit />} />}
          >
            {
              options.map((option, key) =>
                <SpeedDialAction
                  icon={option.icon}
                  tooltipTitle={option.name}
                  onClick={option.action}
                />
              )
            }

          </SpeedDial>*/}

        </div>
      </div>
    </>

  );
}

export default MensajeEnvio;