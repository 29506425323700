import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Helmet } from 'react-helmet-async';
import { Breadcrumbs, Button, Divider, Grid, Link, Typography } from '@mui/material';
import './styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import api from '../../services/api';
import CalendarEventCard from '../../components/CalendarEventCard';
import esLocale from '@fullcalendar/core/locales/es';
import CalendarEventBox from '../../components/CalendarEventBox';
const localizer = momentLocalizer(moment);



const CalendarDates = () => {


  const [calendarDays, setCalendarDays] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function getCalendarDays() {
      try {
        const { data } = await api.get('/api/calendar')
        setCalendarDays(data.days)
      } catch (err) {
        alert("Ocurrió un error al consultar los días")
      }
    }
    async function getCalendarEvents() {
      try {
        const { data } = await api.get('/api/events')
        setEvents(data.events)
      } catch (err) {
        alert("Ocurrió un error al consultar los días")
      }
    }
    getCalendarEvents();
    getCalendarDays();
  }, [])



  const deshabilitarLunes = date => {
    const enableDay = calendarDays.find((day) => day.day == date.getDay())
    if (!enableDay) {
      return {
        style: {
          // backgroundColor: 'lightgray',
          //color: 'gray', 
          cursor: 'not-allowed'
        },
        onClick: () => { }
      };
    }
    return {
      style: {
        backgroundColor: 'lightgreen',
        cursor: 'pointer'

      },
    };
  };

  const handleSelect = ({ start, end }) => {
    console.log('Seleccionaste:', start, end);
  };

  const EventoPredeterminadoConOnClick = ({ event }) => {
    return (
      <div>
        <strong>{event.title}</strong>
      </div>
    );
  };

  const handleEventoSeleccionado = event => {
    window.open(event.link, '_blank');
  };

  function convertirFechasDeEventos(eventos) {
    return eventos.map(evento => ({
      ...evento,
      start: new Date(evento.start), // Convertir cadena a objeto de fecha
      end: new Date(evento.end),     // Convertir cadena a objeto de fecha
    }));
  }
  return (
    <>
      <Helmet title="Etiquetas" />

      <Typography variant="h3" gutterBottom display="inline">
        Calendario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Citas
        </Link>

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <Calendar
            localizer={localizer}
            events={convertirFechasDeEventos(events)}
            selectable={true}
            onSelectSlot={handleSelect}
            dayPropGetter={deshabilitarLunes}
            components={{
              event: EventoPredeterminadoConOnClick, // Usa el componente de evento extendido
            }}
           // onSelectEvent={handleEventoSeleccionado}
            locales={[esLocale]}
            locale="es" // Set the locale to Spanish
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            components={{
              event: (event) => (
                <CalendarEventBox
                  description={event.event.description}
                  name={event.event.title}
                  startDate={event.event.start}
                  endDate={event.event.end}
                  link={event.event.link}
                  onCancel={() => { }}
                  externalAccountID={event.event.googleAccountID}
                  externalEventID={event.event.eventID}
                  allowCancel={true}
                  event={event}
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarDates;
