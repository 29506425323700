import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import BotonQr from '../../components/BotonQr/BotonQr';


import { useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Link, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Edit, FileCopy } from '@mui/icons-material';
import TagsTableModal from '../../components/TagsTableModal';
import OrganizationModal from '../../components/OrganizationModal';
import { Helmet } from 'react-helmet-async';
/**
 * Contenedor para gestionar las sesiones de whatsapp
 * @returns 
 */
export default function Organizations({ theme }) {

  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [webhooks, setWebhooks] = useState([]);



  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso (id)
  */
  const [sessionsUserNum, setSessionsUserNum] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  /**
   * 
   */
  const [currentQr, setCurrentQr] = useState(0);

  /**
   * 
   */
  const [currentOrganization, setCurrentOrganization] = useState(null);

  /**
   * Lista de paquetes
   */
  const [memberships, setMemberships] = useState([]);
  /**
    * Notifiaciones
    */
  const { enqueueSnackbar } = useSnackbar();


  const [modalAgregar, setModalAgregar] = useState(false); // Abrir cerrar modal agregar 

  const [perfilseleccionado, setPerfilSeleccionado] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",

  })

  const [currentGroup,setCurrentGroup] = useState({});

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false)
    async function getSessions() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setSessionsUserNum(userInfo.data.userInfo.wpsessionsNum);

        const { data } = await api.post("/api/getOrganization", { wpSessions: userInfo.data.userInfo.wpsessions });

        setWebhooks(data.userGroups);
      } catch (err) {
        console.log(err)
      }
    }

    async function getMemberships() {
      try {

        const { data } = await api.post("/api/getMembership");

        setMemberships(data.statusList);
      } catch (err) {
        console.log(err)
      }

    }
    getMemberships();
    getSessions();

  }, [reload]);

  /**
    * Compara la lista de permisos con los permisos asignados
    */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }


  /**
   * Columnas de la tabla
   */
  const columns = [

    {
      field: 'name', headerName: 'Nombre', flex: 1, editable: false,
    },
    {
      field: 'status', headerName: 'Estatus', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {

              <Button
                color={params.row.status == 'Activo' ? 'success' : 'error'}
                onClick={() => { setCurrentOrganization(params.row); }}
              >
                {params.row.status}
              </Button>
            }

          </>
        )
      }
    },
    {
      field: 'Membership', headerName: 'Membresia', flex: 1, editable: false,
      renderCell: (params) => {

        return (params.row?.Membership?.name)
      }
    },

    {
      field: 'test', headerName: 'Acciones', flex: 1, editable: false,
      renderCell: (params) => {

        return (
          <>
            {
              revisaPermisos(['Modificar organizaciones']) ?
                <Button onClick={() => { setCurrentOrganization(params.row); setEstadoModal(true); }}>
                  Editar
                </Button> :
                "Sin acciones"
            }
            {
              revisaPermisos(['Modificar organizaciones']) ?
                <Button onClick={() => { setCurrentGroup(params.row); abrirCerrarModalinsertar() }}>
                  Agregar usuario
                </Button> :
                "Sin acciones"
            }
          </>
        )
      }
    },

  ];
  /**
   * 
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/deleteOrganization", { id: ss.id });

        // console.log(data);

        if (data.status == "Success") {

          enqueueSnackbar('Organización eliminada', {
            variant: 'success',
          });
          setReload(true);

        } else {

          enqueueSnackbar('Ocurrió un error al eliminar los estatus', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar estatus', {
          variant: 'error',
        });
      }

      setReload(!reload)
    });


  }
  /**
   * Crea una nueva sesion
   * @param {*} data 
   */
  const addWebhook = async (newWebhook) => {


    let request = {

      userGroup: newWebhook

    }

    if (newWebhook?.id) {
      request.id = newWebhook.id;
    }

    try {

      const { data } = await api.post("/api/createOrganization", request);

      //console.log(data);

      if (data.status == "Success") {

        enqueueSnackbar('Se creó la organización', {
          variant: 'success',
        });
        setReload(true);

      } else {

        enqueueSnackbar('Ocurrió un error al crear la organización', {
          variant: 'error',
        });
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar('Ocurrió un error al crear la organización', {
        variant: 'error',
      });
    }

    setReload(!reload)
  }


  /**
   * Manda peticion para agregar usuario
   */
  const agregarUsuario = async () => {
    /**
     * Valida todos los campos
     */

    if (!perfilseleccionado.firstName || perfilseleccionado.firstName == '') {
      enqueueSnackbar('El nombre es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.lastName || perfilseleccionado.lastName == '') {
      enqueueSnackbar('El apellido es requerido', {
        variant: 'warning',
      });
      return false;
    }

    if (!perfilseleccionado.email || perfilseleccionado.email == '') {
      enqueueSnackbar('El correo es requerido', {
        variant: 'warning',
      });
      return false;
    }



    if (!perfilseleccionado.password) {
      enqueueSnackbar('La contraseña es requerida', {
        variant: 'warning',
      });
      return false;
    }
    setModalAgregar(false);

    /**
     * Eliminamos las propiedades que no son necesarias para crear un usuario
     */

    let newUser = {
      email: perfilseleccionado.email,
      firstName: perfilseleccionado.firstName,
      lastName: perfilseleccionado.lastName,
      userGroupId: currentGroup.id
    }
    /**
     * Agrega la contraseña solo si se cambió
     */
    if (perfilseleccionado.password) {
      newUser.password = perfilseleccionado.password;
    }
    let requestData = {
      userObj: newUser
    }

    try {

   
      const { data } = await api.post(`/api/users/addToGroup`, requestData);
      /**
       * Si responde correctamente 
       * Manda un mensaje al usuario
       */


      if (data.status == 'Success') {
        setReload(!reload);
        enqueueSnackbar(`Usuario agregado correctamente`, {
          variant: 'success',
        });
      } else {
        if (data?.code) {
          enqueueSnackbar(data.userMsg, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar('Ocurrio un error al agregar el usuario', {
            variant: 'error',
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Ocurrio un error inesperado, intenta más tarde', {
        variant: 'error',
      });
      console.log(err);
    }
  }


  const abrirCerrarModalinsertar = () => {
    setPerfilSeleccionado({
      email: "",
      firstName: "",
      lastName: "",
      password: "",

    })
    setModalAgregar(!modalAgregar);
  } // abrir o cerrar el modal agregar usuario 

  const handleChange = e => {
    const { name, value } = e.target; // recibe el name y value 
    setPerfilSeleccionado(prevState => ({
      ...prevState,// llena el objeto con los valores pasados 
      [name]: value
    }))

  }// capturar los datos que el usuario ingresa al agregar perfil 

  return (
    <>
      <Helmet title="Estatus" />

      <Typography variant="h3" gutterBottom display="inline">
        Organizaciones
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link>
          Admin
        </Link>
        <Link >Organizaciones</Link>
        {
          revisaPermisos(['Agregar organizaciones']) &&
          <Button variant='outlined' onClick={() => { setCurrentOrganization(null); setEstadoModal(!estadoModal); }} >Nueva organización</Button>
        }

      </Breadcrumbs>
      <br />
      <Divider my={6} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={webhooks}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar organizaciones'])}
            onDelete={(data) => { deleteSession(data); }}
          />
          {
            revisaPermisos(['Agregar organizaciones']) &&
            <>




              <OrganizationModal
                title={`${currentOrganization?.id ? 'Editar' : 'Nueva'} organización `}
                open={estadoModal}
                setOpen={setEstadoModal}
                theme={theme}
                onSave={addWebhook}
                currentOrganization={currentOrganization}
                memberships={memberships}
              />
            </>
          }

        </Grid>
      </Grid>

      <Dialog
        disableEnforceFocus
        onClose={abrirCerrarModalinsertar}
        aria-labelledby="customized-dialog-title"
        open={modalAgregar}
      >
        <DialogTitle id="customized-dialog-title" onClose={abrirCerrarModalinsertar}>
          Agregar usuario
        </DialogTitle >

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField fullWidth label='Nombre de Usuario' name="firstName" onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>

              <TextField fullWidth label='Apellidos' name="lastName" onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth label='Correo' name="email" onChange={handleChange} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth label='Contraseña' name="password" onChange={handleChange} type="password" />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>

          <Button color='error' variant="outlined" onClick={() => abrirCerrarModalinsertar()}>Cancelar</Button>
          <Button color='primary' variant="outlined" onClick={() => agregarUsuario()}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
