import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import ClientInfo from "../ClientInfo";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { useSnackbar } from "notistack";



const ClientInfoModal = ({ open, setOpen, chat, tags, products = [], status = [], reload, newClient = false,sheetID}) => {


    const [currentChat, setCurrentChat] = useState(chat);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setCurrentChat(chat)
    }, [chat])


    const saveChatClientInfo = async (chat) => {
        try {
            let response = { status: "Error" }
       
            if (newClient) {

                const { data } = await api.post('/api/client/add', { client: chat.Client, tags: chat.Client.tags,sheetID  });

                response = data;
        
            } else {
                const { data } = await api.post('/api/updateChatClient', { chat
                    
                 });
                response = data;
            }

            if (response.status == "Success") {
                if (reload) {
                    reload();

                    if(newClient){
                        enqueueSnackbar("Cliente creado con exito", {
                            variant: 'success',
                        });
                    }else{
                        enqueueSnackbar("Cliente actualizado con exito", {
                            variant: 'success',
                        });
                    }
                    
                }
                ;
            } else {
                enqueueSnackbar("Ocurrio un error al guardar la información", {
                    variant: 'error',
                });

            }
        } catch (err) {

            enqueueSnackbar("Ocurrio un error al guardar la información", {
                variant: 'error',
            });
        }

        return true;
    }

    function handleClose() {
        setOpen(false)
    }
    return (
        <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Información del cliente
            </DialogTitle >

            <DialogContent dividers>

                <ClientInfo
                    newClient={newClient}
                    products={products}
                    status={status}
                    chat={currentChat}
                    onSave={saveChatClientInfo}
                    tags={tags}
                    md={12}
                    lg={12}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                    Cerrar
                </Button>

            </DialogActions>
        </Dialog>

    )

}

export default ClientInfoModal;