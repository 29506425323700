import { useContext, useEffect, useState } from "react";

import api from "../../services/api";
import { Alert, Grid } from "@mui/material";
import { SocketContext } from "../../services/socket";


export default function WhatsappSessionAlert({ onChange, selectedWhatsapp, showOnlyOne }) {

    const [whastapps, setWhatsapps] = useState([])
    const [errorSessions, setErrorSessions] = useState([])

    const [reload, setReload] = useState(false);

    const socket = useContext(SocketContext);

    async function getSessions() {
        try {
            const { data } = await api.post("/api/v2/getUserSessions");
            if (onChange) {
                onChange(data.sessions)
            }
            setWhatsapps(data.sessions);
        } catch (err) {

        }
    }
    async function getErrorSessions() {
        let errorSessionsList = whastapps.filter((whatsapp) => whatsapp.status != 'Conectado')

        setErrorSessions(errorSessionsList);
    }

    useEffect(() => {
        if (whastapps.length > 0) {
            whastapps.forEach((session) => {
                const qrHandler = (data) => setReload(reload => !reload);
                const waitingHandler = () => setReload(reload => !reload);
                const failHandler = () => setReload(reload => !reload);
                const disconnectedHandler = () => setReload(reload => !reload);
                const chatAddedHandler = () => setReload(reload => !reload);
                const authenticatedHandler = () => setReload(reload => !reload);

                socket?.on('qr' + session.sessionAuth, qrHandler);
                socket?.on('waiting' + session.sessionAuth, waitingHandler);
                socket?.on('fail' + session.sessionAuth, failHandler);
                socket?.on('diconnected' + session.sessionAuth, disconnectedHandler);
                socket?.on('chatAdded' + session.sessionAuth, chatAddedHandler);
                socket?.on('authenticated' + session.sessionAuth, authenticatedHandler);


                session.handlers = {
                    qrHandler,
                    waitingHandler,
                    failHandler,
                    disconnectedHandler,
                    chatAddedHandler,
                    authenticatedHandler
                };
            });


            return () => {
                whastapps.forEach((session) => {
                    if (session.handlers) {
                        socket.off('qr' + session.sessionAuth, session.handlers.qrHandler);
                        socket.off('waiting' + session.sessionAuth, session.handlers.waitingHandler);
                        socket.off('fail' + session.sessionAuth, session.handlers.failHandler);
                        socket.off('diconnected' + session.sessionAuth, session.handlers.disconnectedHandler);
                        socket.off('chatAdded' + session.sessionAuth, session.handlers.chatAddedHandler);
                        socket.off('authenticated' + session.sessionAuth, session.handlers.authenticatedHandler);
                    }
                });
            };
        }
    }, [whastapps]);


    useEffect(() => {
        getSessions();
    }, [reload])

    useEffect(() => {
        getErrorSessions();
    }, [whastapps])


    const getStatusColor = (status) => {
        switch (status) {
            case 'QR':
                return 'red';
            default:
                return 'blue';
        }
    }

    const getStatusMessage = (status) => {
        switch (status) {
            case 'QR':
                return 'es necesario escanear';
            case 'Esperando':
                return 'espere mientras la sesión se conecta automaticamente';
            case 'Reconectar':
                return 'es necesario volver a conectar';
            default:
                return 'es necesario escanear';
        }
    }
    return (
        <div>
            {
                errorSessions.length > 0 &&
                <Grid container style={{ maxHeight: '7vh', overflowY: 'auto' }} spacing={4}>
                    {
                        errorSessions.map((whatsapp, index) => {

                            if (showOnlyOne) {

                                if (selectedWhatsapp == whatsapp?.sessionAuth) {
                                    return (
                                        <Grid item style={{width:'100%'}}>
                                            <Alert severity="warning" key={index}>
                                                <span>El whatsapp <strong>{whatsapp?.name}</strong> se encuentra en estatus <span style={{ color: getStatusColor(whatsapp?.status) }}>{whatsapp?.status}</span> {getStatusMessage(whatsapp?.status)}</span>
                                            </Alert>
                                        </Grid>
                                    )
                                }
                            } else {
                                return (
                                    <Grid item>
                                        <Alert severity="warning" key={index}>
                                            <span>El whatsapp <strong>{whatsapp?.name}</strong> se encuentra en estatus <span style={{ color: getStatusColor(whatsapp?.status) }}>{whatsapp?.status}</span> {getStatusMessage(whatsapp?.status)}</span>
                                        </Alert>
                                    </Grid>
                                )
                            }

                        })
                    }
                </Grid>
            }
        </div>


    )
}