import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import api from '../../services/api';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function CallModal({ title, open, setOpen, onSave, theme, templateList,scheduled }) {


    const [currentTemplate, setCurrentTemplate] = useState({});
    const [name, setName] = useState("");
    const [currentPhone, setCurrenPhone] = useState("");
    const [phones, setPhones] = useState([]);
    const [sendAt, setSendAt] = useState(new Date());

    useEffect(() => {
        setCurrentTemplate({});

        async function getPhones() {
            try {
                const userInfo = await api.post("/api/getPermissions");
                setPhones(userInfo.data.userInfo.phones);
            } catch (err) {
                console.log(err);
            }
        }

        getPhones();
    }, [])


    const callUsers = () => {
        console.log(currentTemplate);
    }

    const handleClose = () => {
        setOpen(false);
    }



    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth

                                value={name}
                                label="Nombre del grupo"
                                name="name"
                                variant="outlined"
                                onChange={(event) => { setName(event.target.value); }}

                            >
                            </TextField>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                select
                                value={currentPhone}
                                label="Selecciona un teléfono"
                                name="type"
                                variant="outlined"
                                onChange={(event) => { setCurrenPhone(event.target.value); }}

                            >
                                {phones?.map((option) => (
                                    <MenuItem key={option.id} value={option.phone}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                select
                                value={currentTemplate}
                                label="Selecciona una plantilla"
                                name="type"
                                variant="outlined"
                                onChange={(event) => { setCurrentTemplate(event.target.value); }}

                            >
                                {templateList.map((option) => {
                                    if(option.type== "Llamada"){
                                        return <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                    }
                                   
                                })}
                            </TextField>

                        </Grid>
                        {scheduled && <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                            <LocalizationProvider sx={{ width: '80%' }} dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Fecha programada"
                                    value={sendAt}
                                    inputFormat={"YYYY-MM-DD HH:mm"}

                                    onChange={(newDate) => setSendAt(newDate)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => { setOpen(false) }}>
                        Cancelar
                    </Button>
                    <Button theme={theme} variant="contained" color="primary" onClick={() => { onSave(currentTemplate, name, currentPhone,sendAt); console.log(currentPhone) }}>
                        {
                            scheduled ? "Programar llamada": "Llamar"
                        }
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}
